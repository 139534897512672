import React from 'react';
import { TabItem } from '@ui/Tabs2';
import { FacebookPagePostType } from '@globals';
import { ComposeTabId } from '../ChooseItemFromListDialog/consts';
import { ReactComponent as PublishedIcon } from '../ChooseItemFromListDialog/images/published-icon.svg';
// import { ReactComponent as ScheduledIcon } from '../ChooseItemFromListDialog/images/scheduled-icon.svg';
import { ReactComponent as PromotedIcon } from '../ChooseItemFromListDialog/images/promoted-icon.svg';
import { ReactComponent as PostUrlIcon } from '../ChooseItemFromListDialog/images/post-url-icon.svg';

export const MESSENGER_TABS_ITEMS: () => TabItem[] = () => [
  {
    id: ComposeTabId.all,
    title: window.i18next.t('consts-string-1086-all-posts'),
  },
  {
    id: FacebookPagePostType.published,
    title: window.i18next.t('consts-string--843-published'),
    icon: <PublishedIcon />,
  },
  // {
  //   id: FacebookPagePostType.scheduled,
  //   title: window.i18next.t('consts-string-1843-scheduled'),
  //   icon: <ScheduledIcon />,
  // },
  {
    id: FacebookPagePostType.promoted,
    title: window.i18next.t('consts-string--930-promoted'),
    icon: <PromotedIcon />,
  },
  {
    id: ComposeTabId.postUrl,
    title: window.i18next.t('consts-string-8200-post-url'),
    icon: <PostUrlIcon />,
  },
];
