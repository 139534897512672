import { useLayoutEffect } from 'react';

interface ItemRefetchOnDialogOpenParams {
  itemsLoading: boolean;
  action(): void;
}

export const useActionOnDialogOpen = ({
  itemsLoading,
  action,
}: ItemRefetchOnDialogOpenParams) => {
  useLayoutEffect(() => {
    if (!itemsLoading) {
      action();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);
};
