import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import client from '@common/services/ApolloService';
import { Router } from 'react-router-dom';
import { globalHistory } from '@utils/Routing';
import {
  WhatsappImportUser,
  WhatsappImportUserProps,
} from './WhatsappImportUser';
import ng from 'angular';
import { react2angular } from 'react2angular';

export const WhatsappImportUserWrapper: React.FC<WhatsappImportUserProps> = (
  props,
) => (
  <ApolloProvider client={client}>
    <Router history={globalHistory}>
      <WhatsappImportUser {...props} />
    </Router>
  </ApolloProvider>
);

export const ngWhatsappImportUsers = ng
  .module('app.components.whatsappImportUser', [])
  .component(
    'whatsappImportUser',
    react2angular(WhatsappImportUserWrapper, ['onDone'], []),
  ).name;
