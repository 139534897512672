import React from 'react';
import {
  ServiceMessage,
  ServiceMessageType,
  ServiceMessageIntent,
} from '../../modern-ui/ServiceMessage2';
import {
  Button,
  ButtonIntent,
  ButtonSize,
  ButtonColorWay,
  ButtonTextColorWay,
} from '../../modern-ui/_deprecated/Button';

interface IUpgradeToProServiceMessageProps {
  onClick: () => void;
}

export const UpgradeToProServiceMessage: React.SFC<IUpgradeToProServiceMessageProps> = ({
  onClick,
}) => (
  <ServiceMessage
    style={{ minWidth: '1111px', width: 'calc(100vw - 192px)' }}
    type={ServiceMessageType.error}
    intent={ServiceMessageIntent.onpage}
    hideErrorIcon
    renderContentEnd={() => (
      <Button
        intent={ButtonIntent.primary}
        size={ButtonSize.s}
        colorWay={ButtonColorWay.white}
        onClick={onClick}
        className="test-pro-plan-button"
        textColorWay={ButtonTextColorWay.red}
      >
        {window.i18next.t(
          'UpgradeToProServiceMessage-JSXText-1227-upgrade-to-pro',
        )}
      </Button>
    )}
  >
    {window.i18next.t(
      'UpgradeToProServiceMessage-JSXText--131-people-tab-is-available-only-to-pro-users-your-bot-is-on-the-free-plan',
    )}
  </ServiceMessage>
);
