import React from 'react';
import cn from 'classnames';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import css from './Workspace.css';

interface WorkspaceLoaderProps {
  className?: string;
}

export const WorkspaceLoader: React.FC<WorkspaceLoaderProps> = ({
  className,
}) => (
  <LoadingPlaceholder
    className={cn(css.loader, className)}
    height={72}
    color="white"
  />
);
