import React from 'react';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import * as css from './WhatsappImportUserDialogFooter.css';

interface WhatsappImportUserDialogFooterProps {
  backButtonTitle?: string;
  nextStepButtonTitle?: string;
  onNextStepButtonClick?: VoidFunction;
  onBackButtonClick?: VoidFunction;
  showBackButton?: boolean;
  disabled?: boolean;
}

export const WhatsappImportUserDialogFooter: React.FC<WhatsappImportUserDialogFooterProps> =
  ({
    backButtonTitle,
    nextStepButtonTitle,
    onNextStepButtonClick,
    onBackButtonClick,
    showBackButton,
    disabled,
  }) => {
    const { t } = useSafeTranslation();

    return (
      <>
        <Spacer factor={4} />
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          gapFactor={3}
          className={css.box}
        >
          {showBackButton && (
            <Button
              intent="secondary"
              onClick={onBackButtonClick}
              data-testid="import-contacts__previous-button"
            >
              {backButtonTitle ||
                t('modernComponents.WhatsAppImportUser.dialog.Footer.back')}
            </Button>
          )}
          <Button
            disabled={disabled}
            intent="primary"
            onClick={onNextStepButtonClick}
            data-testid="import-contacts__next-button"
          >
            {nextStepButtonTitle ||
              t('modernComponents.WhatsAppImportUser.dialog.Footer.newtStep')}
          </Button>
        </Flex>
      </>
    );
  };
