import ng from 'angular';
import { react2angular } from 'react2angular';
import { AddSkillMutation_addSkill_block_cards_ToDoPlugin_config_todo as ITodo } from '../AddSkill/@types/AddSkillMutation';
import {
  getTodoCardConfig,
  isAllTodoCheckedInCard,
  TodoConfig,
  insertBlockTitleToBlockLinkIfNeed,
} from '../skillUtils';
import { updateBlockValidStatusInGqlCache } from '../../Aside/BlocksGroupsQueries';
import { TodoPlugin } from './TodoPlugin';
import { sendEvent } from '../../../utils/Analytics';

const ngTodoPlugin = ng
  .module('app.modernComponents.todoPluginBox.todoPlugin', [])
  .component(
    'todoPlugin',
    react2angular(
      TodoPlugin,
      ['config', 'onTodoChecked', 'onBlockLinkClick'],
      [],
    ),
  ).name;

export const ngTodoPluginBox = ng
  .module('app.modernComponents.todoPluginBox', [ngTodoPlugin])
  .component('todoPluginBox', {
    template: () =>
      `<todo-plugin
  ng-if="$ctrl.block.id"
  config="todoConfig"
  on-todo-checked="onTodoChecked"
  on-block-link-click="onBlockLinkClick"
 ></todo-plugin>`,
    controller: ($rootScope, $scope, $timeout, PluginCardService) => {
      'ngInject';

      $scope.todoConfig = {
        title: '',
        items: [],
      };
      $scope.$watch(
        () => getTodoCardConfig($scope.$ctrl.block, $scope.$ctrl.index),
        async (todoConfig: TodoConfig) => {
          $scope.todoConfig = await insertBlockTitleToBlockLinkIfNeed(
            todoConfig,
            $rootScope.stateParams.botId,
          );
        },
        true,
      );
      $scope.onTodoChecked = (todoId: string) => {
        const { block } = $scope.$ctrl;
        const todoCardIndex = $scope.$ctrl.index;
        const todoCard = block.cards[todoCardIndex];
        sendEvent({
          category: 'plugin todo',
          action: 'item click',
        });
        $scope.$evalAsync(async () => {
          const todoItem = todoCard.config.todo.find(
            (todoItem: ITodo) => todoItem.id === todoId,
          );
          todoItem.done = !todoItem.done;
          if (isAllTodoCheckedInCard(todoCard)) {
            await $timeout(async () => {
              const blockIdObj = { id: todoCard.id };
              block.cards.splice(todoCardIndex, 1);
              updateBlockValidStatusInGqlCache({ block });
              await PluginCardService.remove(blockIdObj);
            }, 200);
          } else {
            PluginCardService.save({
              ...todoCard,
              blockId: block.id,
              position: todoCardIndex,
            });
          }
        });
      };
      $scope.onBlockLinkClick = (id: string) => {
        console.warn('Go to block:', id); // eslint-disable-line no-console
        $rootScope.stateHistory.push(
          `/bot/${$rootScope.stateParams.botId}/structure/${id}`,
        );
      };
    },
    bindings: {
      block: '<',
      index: '<',
    },
  }).name;
