import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import {
  useConnectInstagram,
  useInstagramAccount,
} from '@utils/Data/Instagram/BotAccount';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { goToGetInstagramAuthPermissions } from '@utils/FacebookPages/goToGetPermissions';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { CenteredLoader } from '@ui/Loader';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';
import {
  ConnectInstagramPermissions,
  ConnectInstagramPermissionsType,
} from '../ConnectInstagramPermissions';
import { ConnectInstagramGivePermissions } from '../ConnectInstagramGivePermissions';

import { getConnectInstagramTroubleshootingState } from './helpers';
import { ConnectInstagramTroubleshootingState } from './types';

import * as css from './ConnectInstagramTroubleshooting.css';

const ConnectInstagramTroubleshootingIsProfessional: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <Flex flexDirection="column">
      <Trans
        i18nKey="common.Instagram.dialog.troubleshooting.isProfessional.description1"
        t={t}
      >
        <Type size="15px">
          Go to Instagram app, click <strong>Settings</strong> →{' '}
          <strong>Account</strong> →{' '}
          <strong>Switch to Professional Account</strong> and follow the
          instructions.
        </Type>
      </Trans>
      <br />
      <Trans
        i18nKey="common.Instagram.dialog.troubleshooting.isProfessional.description2"
        t={t}
      >
        <Type size="15px">
          Click <strong>Next</strong> after you’ve made sure you have a
          Professional Account.
        </Type>
      </Trans>
    </Flex>
  );
};

const getFacebookPageInstagramSettingsUrl = (
  pageId: string,
  pageTitle: string,
) =>
  `https://www.facebook.com/${pageTitle.replace(
    /\s/g,
    '-',
  )}-${pageId}/settings/?tab=instagram_management`;
const ConnectInstagramTroubleshootingIsConnectedToPage: React.FC = () => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { page, loading } = usePageConnected(botId);
  return (
    <Flex flexDirection="column">
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <Trans
            i18nKey="common.Instagram.dialog.troubleshooting.isConnectedToPage.description1"
            t={t}
          >
            <Type size="15px">
              Choose your Facebook Page, click{' '}
              <a
                className={css.link}
                href={getFacebookPageInstagramSettingsUrl(
                  page?.id!,
                  page?.title!,
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Page settings</strong> → <strong>Instagram</strong>
              </a>{' '}
              and connect your Instagram.
            </Type>
          </Trans>
          <br />
          <br />
          <Trans
            i18nKey="common.Instagram.dialog.troubleshooting.isConnectedToPage.description2"
            t={t}
          >
            <Type size="15px">
              Click <strong>Next</strong> after you’ve connected your Instagram
              Account to Page.
            </Type>
          </Trans>
        </>
      )}
    </Flex>
  );
};

const ConnectInstagramTroubleshootingIsAdmin: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <Flex flexDirection="column">
      <Trans
        i18nKey="common.Instagram.dialog.troubleshooting.isAdmin.description1"
        t={t}
      >
        <Type size="15px">
          Choose your Facebook Page, click <strong>Page settings</strong> →{' '}
          <strong>Page roles</strong> and follow the instructions.
        </Type>
      </Trans>
      <br />
      <Trans
        i18nKey="common.Instagram.dialog.troubleshooting.isAdmin.description2"
        t={t}
      >
        <Type size="15px">
          Click <strong>Next</strong> after you’ve made sure you are an admin of
          the Page.
        </Type>
      </Trans>
    </Flex>
  );
};

const ConnectInstagramTroubleshootingMessagesAccess: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <Flex flexDirection="column">
      <Type size="15px">
        {t(
          'common.Instagram.dialog.troubleshooting.messagesAccess.description1',
        )}
      </Type>
      <br />
      <Trans
        i18nKey="common.Instagram.dialog.troubleshooting.messagesAccess.description2"
        t={t}
      >
        <Type size="15px">
          2. Go your Instagram Business account <strong>Settings</strong> →{' '}
          <strong>Privacy</strong> → <strong>Messages</strong> and check that
          the Allow Access to Messages toggle is on
        </Type>
      </Trans>
      <br />
      <Trans
        i18nKey="common.Instagram.dialog.troubleshooting.messagesAccess.description3"
        t={t}
      >
        <Type size="15px">
          Click <strong>Next</strong> after you’ve allowed access to messages.
        </Type>
      </Trans>
    </Flex>
  );
};

interface ConnectInstagramTroubleshootingProps {
  initialState?: ConnectInstagramTroubleshootingState;
  onUpdated(error?: any): void;
  onClose(): void;
  onBeforeGivePermission(): any;
}
export const ConnectInstagramTroubleshooting: React.FC<ConnectInstagramTroubleshootingProps> =
  ({ initialState, onUpdated, onClose, onBeforeGivePermission }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId()!;
    const { instagramConnectionProblem } = useInstagramAccount(botId);
    const { connectInstagram, instagramIsConnecting } = useConnectInstagram(
      botId,
      onUpdated,
    );
    const [
      connectInstagramTroubleshootingState,
      setConnectInstagramTroubleshootingState,
    ] = useState(
      initialState ??
        (instagramConnectionProblem
          ? getConnectInstagramTroubleshootingState(instagramConnectionProblem)
          : ConnectInstagramTroubleshootingState.isProfessional),
    );
    const handleNext = () => {
      if (
        connectInstagramTroubleshootingState ===
        ConnectInstagramTroubleshootingState.isConnectedToPage
      ) {
        sendEvent({
          category: 'instagram connect popup',
          action: 'click next',
          label: 'troubleshooting',
          propertyBag: {
            purpose: 'get instagram auth permissions',
            problem: connectInstagramTroubleshootingState,
          },
        });
        goToGetInstagramAuthPermissions();
      } else {
        sendEvent({
          category: 'instagram connect popup',
          action: 'click next',
          label: 'troubleshooting',
          propertyBag: {
            purpose: 'connect',
            problem: connectInstagramTroubleshootingState,
          },
        });
        connectInstagram();
      }
    };
    useEffect(() => {
      if (!instagramIsConnecting && instagramConnectionProblem) {
        setConnectInstagramTroubleshootingState(
          getConnectInstagramTroubleshootingState(instagramConnectionProblem),
        );
      }
    }, [instagramConnectionProblem, instagramIsConnecting]);

    if (
      connectInstagramTroubleshootingState ===
      ConnectInstagramTroubleshootingState.checkPermissions
    )
      return (
        <ConnectInstagramPermissions
          onConnected={onUpdated}
          type={ConnectInstagramPermissionsType.check}
        />
      );

    if (
      connectInstagramTroubleshootingState ===
      ConnectInstagramTroubleshootingState.getPermissions
    )
      return (
        <ConnectInstagramGivePermissions
          onBeforeGivePermission={onBeforeGivePermission}
          onAfterGivePermission={onClose}
        />
      );

    return (
      <ConnectInstagramDialogContent
        header={t(
          `common.Instagram.dialog.troubleshooting.${connectInstagramTroubleshootingState}.header`,
        )}
        footerRight={
          <Button
            intent="primary"
            onClick={handleNext}
            disabled={instagramIsConnecting}
            data-testid={`instagram-connect-dialog__troubleshooting-${connectInstagramTroubleshootingState}__next-button`}
            loading={instagramIsConnecting}
          >
            {t('common.Instagram.dialog.troubleshooting.next')}
          </Button>
        }
        data-testid={`instagram-connect-dialog__troubleshooting-${connectInstagramTroubleshootingState}`}
      >
        {connectInstagramTroubleshootingState ===
          ConnectInstagramTroubleshootingState.isProfessional && (
          <ConnectInstagramTroubleshootingIsProfessional />
        )}
        {connectInstagramTroubleshootingState ===
          ConnectInstagramTroubleshootingState.isConnectedToPage && (
          <ConnectInstagramTroubleshootingIsConnectedToPage />
        )}
        {connectInstagramTroubleshootingState ===
          ConnectInstagramTroubleshootingState.isAdmin && (
          <ConnectInstagramTroubleshootingIsAdmin />
        )}
        {connectInstagramTroubleshootingState ===
          ConnectInstagramTroubleshootingState.messagesAccess && (
          <ConnectInstagramTroubleshootingMessagesAccess />
        )}
      </ConnectInstagramDialogContent>
    );
  };
