import React from 'react';
import { Portal } from '@utils/Portal/Portal';
import { FB_YOUTUBE_FRAME_PLAYER_PORTAL_ID } from './constants';

interface PortalForYoutubePlayer {
  style?: React.CSSProperties;
}

export const PortalForYoutubePlayer: React.FC<PortalForYoutubePlayer> = ({
  children,
  style,
}) => (
  <Portal portalElementId={FB_YOUTUBE_FRAME_PLAYER_PORTAL_ID}>
    <div
      style={{
        position: 'absolute',
        right: 0,
        zIndex: 101,
        ...style,
      }}
    >
      {children}
    </div>
  </Portal>
);
