import React from 'react';
import { Tooltip2 } from '@ui/Tooltip2';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import * as css from './ReferralDescription.css';

export const ReferralTooltip = () => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  return (
    <Tooltip2
      hoverable
      placement={isSmallScreenSize ? 'bottom' : 'right'}
      content={
        <Type size="12px" color="white">
          {t('modernComponents.ReferralProgram.description.tooltip')}
        </Type>
      }
    >
      {(ref, bind) => (
        <Flex inline className={css.infoIconContainer}>
          <Icon
            icon="info"
            className={css.infoIcon}
            {...bind}
            ref={ref}
            noColor
          />
        </Flex>
      )}
    </Tooltip2>
  );
};
