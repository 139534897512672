import React, { useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Button, ButtonIntent } from '@ui/_deprecated/Button';
import { Spacer } from '@ui/Spacer';
import { Loader } from '@ui/Loader';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { HorizontalInputGroup } from '@ui/HorizontalInputGroup';
import { visuallyHidden } from '@utils/css/styles';
import { LegacyPremiumStripeInput as StripeInput } from '@ui/StripeInput';

interface PaymentsProps {
  onSubmit: () => void;
  submitText: string;
  ready: boolean;
  submitting: boolean;
  formErrorMessage?: string;
}

export const Payments: React.FC<PaymentsProps> = ({
  ready,
  submitting,
  formErrorMessage,
  submitText,
  onSubmit,
}) => {
  const [stripeElementsAreReady, setStripeElementsAreReady] = useState(false);
  const handleCardNumberReady = () => setStripeElementsAreReady(true);
  const paymentIsReady = ready && stripeElementsAreReady;

  const formStyle = paymentIsReady ? undefined : visuallyHidden;

  return (
    <div>
      {!paymentIsReady && (
        <Flex
          flexDirection="column"
          fullHeight
          alignItems="center"
          justifyContent="center"
        >
          <Loader />
        </Flex>
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmit();
        }}
        style={formStyle}
      >
        <StripeInput
          as={CardNumberElement}
          disabled={submitting}
          onReady={handleCardNumberReady}
          placeholder={window.i18next.t('Payments-string--212-card-number')}
        />
        <Spacer factor={2} />
        <HorizontalInputGroup>
          <StripeInput
            as={CardExpiryElement}
            disabled={submitting}
            placeholder="MM/YY"
          />
          <StripeInput as={CardCvcElement} disabled={submitting} />
        </HorizontalInputGroup>
        <Spacer />
        {formErrorMessage && (
          <>
            <Type color="red" size="15px_DEPRECATED">
              {formErrorMessage}
            </Type>
            <Spacer />
          </>
        )}
        <div style={{ textAlign: 'right' }}>
          {submitting ? (
            <Loader />
          ) : (
            <Button intent={ButtonIntent.primary} disabled={submitting}>
              {submitText}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
