import { useFormik } from 'formik';
import { PaymentsConfigQuery_bot_payments_config } from '@utils/Payment/hooks/@types/PaymentsConfigQuery';
import * as Yup from 'yup';
import Maybe from 'graphql/tsutils/Maybe';

interface ConnectStripeFormikArgs {
  paymentsConfig: Maybe<PaymentsConfigQuery_bot_payments_config>;
  onSubmit: (paymentsConfig: PaymentsConfigQuery_bot_payments_config) => void;
}

const DEFAULT_CONFIG: PaymentsConfigQuery_bot_payments_config = {
  __typename: 'PaymentsConfig',
  terms_of_service_url: '',
  merchant_emails: [],
};

export const useConnectStripeFormik = ({
  paymentsConfig,
  onSubmit,
}: ConnectStripeFormikArgs) =>
  useFormik<PaymentsConfigQuery_bot_payments_config>({
    initialValues: paymentsConfig || DEFAULT_CONFIG,
    validationSchema: Yup.object().shape({
      terms_of_service_url: Yup.string().nullable().url().required(),
    }),
    onSubmit,
  });
