import React from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '@ui/Button';
import { Tooltip2 } from '@ui/Tooltip2';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import css from './InfoButton.css';

interface InfoButtonProps {
  className?: string;
  isWhatsappEnabled: boolean;
}

export const InfoButton: React.FC<InfoButtonProps> = ({
  className,
  isWhatsappEnabled,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Tooltip2
      placement="top-end"
      content={
        <>
          {!isWhatsappEnabled && (
            <>
              {t('PromoCode.firstMonthDiscountAvailable')}
              <br />
              <br />
            </>
          )}
          {t('PromoCode.youCanApplyOnePromoCodePer', {
            type: t(
              isWhatsappEnabled ? 'PromoCode.phoneNumber' : 'PromoCode.page',
            ),
          })}
        </>
      }
    >
      {(ref, bind) => (
        <ButtonUnstyled
          ref={ref}
          {...bind}
          className={cn(css.infoButton, className)}
          data-testId="promocode-info-button"
        >
          <Type size="15px" weight="medium" color="accent1Normal">
            {t('PromoCode.info')}
          </Type>
        </ButtonUnstyled>
      )}
    </Tooltip2>
  );
};
