import { EventEmitter } from '@utils/EventEmitter';

export interface TestThisButtonEventPayload {
  marked: boolean;
}

export const testFlowButtonMarkedChange = 'testFlowButtonMarkedChange';

export const testThisButtonEventEmitter = new EventEmitter();

export const markTestFlowButton = () => {
  return testThisButtonEventEmitter.emit(testFlowButtonMarkedChange, {
    marked: true,
  });
};

export const unmarkTestFlowButton = () => {
  return testThisButtonEventEmitter.emit(testFlowButtonMarkedChange, {
    marked: false,
  });
};
