export const PLAYER_OPTIONS = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 1,
    fs: 0,
    modestbranding: 1,
    disablekb: 1,
    rel: 0,
    showinfo: 0,
  } as const,
};

export const VIDEO_DEFAULT_WIDTH = 400;
export const VIDEO_MIN_WIDTH = 240;
export const VIDEO_MAX_WIDTH = 1200;
export const VIDEO_BIG_WIDTH = 800;
export const VIDEO_RATE = 0.5625;
