import { useQuery } from 'react-apollo';
import { WHATSAPP_IMPORT_LIMITS_QUERY } from './queries';
import { useCurrentBotId } from '@utils/Routing';
import {
  WhatsappImportLimitsQuery,
  WhatsappImportLimitsQueryVariables,
} from './@types/WhatsappImportLimitsQuery';

export const useWhatsappImportLimits = () => {
  const botId = useCurrentBotId()!;
  const queryResult = useQuery<
    WhatsappImportLimitsQuery,
    WhatsappImportLimitsQueryVariables
  >(WHATSAPP_IMPORT_LIMITS_QUERY, {
    variables: { botId },
  });

  return queryResult;
};
