import { useMutation } from '@apollo/react-hooks';
import { CREATE_PREMIUM_PAYMENT_INTENT } from '../../queries';

import { CreatePremiumPaymentIntent } from '../../@types/CreatePremiumPaymentIntent';

export const usePremiumIntentSetup = () => {
  const [invokeSetupIntent, { data }] = useMutation<CreatePremiumPaymentIntent>(
    CREATE_PREMIUM_PAYMENT_INTENT,
  );

  const setupIntent = async () => {
    const { data } = await invokeSetupIntent();
    return {
      clientSecret: data?.createPremiumPaymentIntent?.client_secret,
    };
  };

  return {
    setupIntent,
    clientSecret: data?.createPremiumPaymentIntent?.client_secret,
  };
};
