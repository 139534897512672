import React from 'react';
import i18next from 'i18next';
import { DefaultDialog } from '@ui/Dialog';
import { Autofocus } from '@ui/Autofocus';
import { Button, ButtonIntentKey } from '@ui/Button';
import { ConfirmRow } from '@ui/Dialog/ActionDialogParts';
import * as s from './RemoveEntityDialog.css';

export interface RemoveEntityDialogProps {
  onSubmit: () => void;
  renderHeading: () => React.ReactNode;
  renderActionText: () => React.ReactNode;
  renderNoteText?: () => React.ReactNode;
  renderCancelText?: () => React.ReactNode;
  onDismiss?: () => void;
  mobileAdaptive?: boolean;
  intent?: ButtonIntentKey;
}

interface RemoveEntityDialogState {
  [formValue: string]: string | null;
}

export class RemoveEntityDialog extends React.Component<
  RemoveEntityDialogProps,
  RemoveEntityDialogState
> {
  constructor(props: RemoveEntityDialogProps) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setFieldValue(name: string, value: string) {
    this.setState({ [name]: value });
  }

  handleSubmit() {
    this.props.onSubmit();
  }

  render() {
    const {
      renderHeading,
      renderActionText,
      renderNoteText,
      renderCancelText,
      mobileAdaptive,
      onDismiss,
      intent,
    } = this.props;

    return (
      <DefaultDialog
        onDismiss={onDismiss}
        header={renderHeading()}
        mobileAdaptive={mobileAdaptive}
      >
        <div>
          {renderNoteText ? (
            renderNoteText()
          ) : (
            <>
              <span>
                {i18next.t(
                  'RemoveEntityDialog-JSXText--129-this-action-cannot-be-undone',
                )}
              </span>
              <br />
              <span>
                {i18next.t('RemoveEntityDialog-JSXText--853-are-you-sure')}
              </span>
            </>
          )}
        </div>
        <ConfirmRow>
          <Button
            data-testid="cancel-button"
            intent="secondary"
            onClick={onDismiss}
          >
            {renderCancelText
              ? renderCancelText()
              : i18next.t('RemoveEntityDialog-JSXText-2673-cancel')}
          </Button>
          <Autofocus
            shouldFocus
            render={({ bind }) => (
              <Button
                data-testid="submit-button"
                intent={intent ?? 'red'}
                type="submit"
                onClick={this.handleSubmit}
                ref={bind.ref}
                className={s.button}
              >
                {renderActionText()}
              </Button>
            )}
          />
        </ConfirmRow>
      </DefaultDialog>
    );
  }
}
