import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { WorkspaceAnchorStatistics } from '../WorkspaceAnchorStatistics/WorkspaceAnchorStatistics';
import { ButtonUnstyled } from '@ui/Button';
import Maybe from 'graphql/tsutils/Maybe';
import { Spacer } from '@ui/Spacer';
import { BotTitleInput } from '@ui/BotListItem/BotTitleInput/BotTitleInput';
import { NestedMenuItem } from '@ui/NestedMenu/types';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PopperMenu, PopperMenuProps } from '@ui/NestedMenu';
import { IconButton } from '@ui/IconButton';
import { getWorkspaceDataByStatus } from '../workspaceStatus';
import { PaymentDetailsStatus } from '@globals';
import css from './WorkspaceAnchor.css';

export interface WorkspaceAnchorProps<MenuItemType extends NestedMenuItem> {
  changeExtended: VoidFunction;
  extended: boolean;
  title: string;
  status: Maybe<PaymentDetailsStatus>;
  isUsers: boolean;
  limitation: Maybe<{
    reached: Maybe<number>;
    limit: Maybe<number>;
  }>;
  pages: Maybe<{
    reached: number;
    limit: number;
  }>;
  titleEditing: boolean;
  onTitleChange(newTitle: string): void;
  menuItems: PopperMenuProps<MenuItemType>['items'];
  onMenuItemSelect: PopperMenuProps<MenuItemType>['onItemSelect'];
  readOnly: boolean;
  onBillingClick: VoidFunction;
}

export const WorkspaceAnchor = <MenuItemType extends NestedMenuItem>({
  changeExtended,
  extended,
  title,
  status,
  limitation,
  pages,
  titleEditing,
  onTitleChange,
  onMenuItemSelect,
  menuItems,
  readOnly,
  onBillingClick,
  isUsers,
}: WorkspaceAnchorProps<MenuItemType>) => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      className={cn(css.workspaceAnchor, titleEditing && css.editing)}
      onClick={titleEditing ? undefined : changeExtended}
      data-testid="workspace_bots_table__table_header"
    >
      <Icon
        icon="triangle"
        className={cn(css.triangle, extended && css.extended)}
        data-testid="workspace_bots_table__triangle_button"
      />
      {titleEditing ? (
        <BotTitleInput
          onTitleChange={onTitleChange}
          title={title}
          className={css.titleInput}
        />
      ) : (
        <>
          <div className={css.titleContainer}>
            <Flex>
              <Type
                size="18px"
                weight="medium"
                data-testid="workspace_bots_table__workspace_title"
              >
                {title}
              </Type>
              {status && (
                <div
                  className={cn(css.status)}
                  style={{
                    background: getWorkspaceDataByStatus(status)?.color,
                  }}
                >
                  <Type
                    size="15px"
                    weight="semibold"
                    color="white"
                    data-testid="workspace_bots_table__workspace_status"
                  >
                    {getWorkspaceDataByStatus(status)?.title}
                  </Type>
                </div>
              )}
            </Flex>
            {pages && (
              <Type
                size="15px"
                weight="regular"
                color="greyDark"
                data-testid="workspace_bots_table__reached_limit"
              >
                {pages.reached} / {pages.limit} {t('BotList.pages')}
              </Type>
            )}
          </div>
          {!readOnly && (
            <>
              <WorkspaceAnchorStatistics
                className={css.conversation}
                maxCount={limitation?.limit ?? 0}
                count={limitation?.reached ?? 0}
                chargingEntityLabel={
                  isUsers ? t('BotList.users') : t('BotList.conversations')
                }
                content={
                  isUsers
                    ? t('BotList.usersTooltip')
                    : t('BotList.conversationsTooltip')
                }
              />

              <Flex className={css.lastColumn}>
                <ButtonUnstyled
                  onClick={onBillingClick}
                  className={css.billing}
                  data-testid="workspace_bots_table__billing_button"
                >
                  {t('BotList.billing')}
                </ButtonUnstyled>
                <Spacer horizontalFactor={1.5} />
                {menuItems.length ? (
                  <PopperMenu
                    placement="left-start"
                    onItemSelect={onMenuItemSelect}
                    items={menuItems}
                    menuStopPropagation
                  >
                    {(ref, { setOpen }) => (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpen(true);
                        }}
                        icon="more"
                        ref={ref}
                        className={css.moreButton}
                        data-testid="bot_list_item__bot_menu_button"
                      />
                    )}
                  </PopperMenu>
                ) : (
                  <Spacer factor={9} horizontalFactor={9} />
                )}
              </Flex>
            </>
          )}
        </>
      )}
    </Flex>
  );
};
