import { parse, CsvError } from 'csv-parse/dist/esm/sync';

export { CsvError };

type Delimiter = ',' | ';' | '\t';

const AVAILABLE_DELIMETERS: Delimiter[] = [',', ';', '\t'];

export const getDelimiter = (
  parsedLine: string[],
  unparsedLine: string,
): Delimiter => {
  const delimitersString =
    parsedLine.reduce((ul, current) => ul.replace(current, ''), unparsedLine) ||
    ',';

  const delimetersInLine = Array.from(
    delimitersString.replaceAll('"', ''),
  ) as Delimiter[];

  const delimetersCounter = delimetersInLine.reduce((ctr, c: Delimiter) => {
    if (!ctr.has(c)) {
      ctr.set(c, 0);
    }
    ctr.set(c, ctr.get(c)! + 1);
    return ctr;
  }, new Map<Delimiter, number>());

  return (
    [...delimetersCounter.entries()].reduce((accumulator, element) =>
      element[1] > accumulator[1] ? element : accumulator,
    )[0] || ','
  );
};

interface ParseCsvRes {
  fields: string[];
  delimiter: Delimiter;
  lines: string[][]; // already parsed
}

export const parseCsv = (csv: string): ParseCsvRes => {
  const parsedCsv = parse(csv, {
    delimiter: AVAILABLE_DELIMETERS,
    skip_records_with_error: true,
  });
  const firstLine = csv.split('\n', 1)?.[0]?.trim();
  const fields = parsedCsv[0].map((field: string) => field?.trim());

  return {
    fields,
    lines: parsedCsv.slice(1),
    delimiter: getDelimiter(fields, firstLine),
  };
};
