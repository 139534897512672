import { useQuery } from 'react-apollo';
import { PagesWithSubscriptionsQuery } from './@types/PagesWithSubscriptionsQuery';
import { PAGES_WITH_SUBSCRIPTIONS_QUERY } from './queries';

export const usePagesWithSubscriptions = () => {
  const { data, ...queryState } = useQuery<PagesWithSubscriptionsQuery>(
    PAGES_WITH_SUBSCRIPTIONS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
    },
  );

  return {
    pages: data?.pages,
    ...queryState,
  };
};
