import React, { useEffect } from 'react';
import cn from 'classnames';
import { useFormik } from 'formik';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { ReactComponent as ArrowMessage } from './arrow-message.svg';
import { Spacer } from '@ui/Spacer';
import * as Yup from 'yup';
import css from './RequestDemoForm.css';
import { RequestDemoQuery_requestDemo } from '@utils/Data/Premium/@types/RequestDemoQuery';
import {
  ChatfuelUseCase,
  Industry,
  RequestDemoInput,
  SourceAboutUs,
} from '@globals';
import {
  getChatfuelUseCaseItems,
  getIndustryItems,
  initialRequestForm,
  getSourceAboutUsItems,
  RequestDemoStatus,
} from './consts';
import Maybe from 'graphql/tsutils/Maybe';
import { LoadingInput } from './LoadingInput/LoadingInput';
import { useCreateRequestDemo } from '@utils/Data/Premium/hooks';
import {
  RequestDemoCombobox,
  RequestDemoComboboxProps,
} from './RequestDemoCombobox/RequestDemoCombobox';
import { isSomeEnum } from '@utils/isSomeEnum';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { removeTypename } from '@utils/GQL/utils';
import { PhoneInput } from '@ui/PhoneInput/PhoneInput';
import { InputLoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { getClid } from 'cf-common/src/analytics';

interface RequestDemoFormValue
  extends Omit<RequestDemoQuery_requestDemo, 'industry' | '__typename'>,
    Omit<RequestDemoInput, 'industry'> {
  industry: Industry | null;
  email: string;
  name: string;
  industry_other: string | null;
  company: string | null;
  phone: string | null;
  use_case: ChatfuelUseCase | null;
  use_case_other: string | null;
  source: SourceAboutUs | null;
  source_other: string | null;
  clid: string | null;
}

export interface RequestDemoFormProps {
  rootClassName?: string;
  formClassName?: string;
  banerClassName?: string;
  requestDemoFormValue?: Maybe<RequestDemoQuery_requestDemo>;
  loading?: boolean;
  onSubmitted(status: RequestDemoStatus, input: RequestDemoInput): void;
}

export const RequestDemoForm: React.FC<RequestDemoFormProps> = ({
  rootClassName,
  formClassName,
  banerClassName,
  requestDemoFormValue,
  loading,
  onSubmitted,
}) => {
  const { t } = useSafeTranslation();
  const [
    createRequestDemo,
    { loading: createRequestDemoLoading, data: createRequestDemoData },
  ] = useCreateRequestDemo();

  const readOnly =
    !!requestDemoFormValue?.email ||
    !!createRequestDemoData?.createRequestDemo.id;

  const {
    handleSubmit,
    values,
    errors,
    setValues,
    validateField,
    setFieldValue,
    handleChange,
  } = useFormik<RequestDemoFormValue>({
    initialValues: requestDemoFormValue
      ? removeTypename(requestDemoFormValue)
      : initialRequestForm,
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required(),
      name: Yup.string().min(2).required(),
      industry: Yup.string().min(2).required(),
    }),
    validateOnChange: false,
    onSubmit: async (value) => {
      const input = {
        ...value,
        industry: value.industry!,
        phone: value.phone && value.phone.length > 2 ? value.phone : null,
        clid: getClid(),
      };
      try {
        await createRequestDemo({
          variables: {
            request: input,
          },
        });

        onSubmitted(RequestDemoStatus.success, input);
      } catch {
        onSubmitted(RequestDemoStatus.error, input);
      }
    },
  });

  useEffect(() => {
    if (requestDemoFormValue) setValues(removeTypename(requestDemoFormValue));
  }, [requestDemoFormValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const disabled =
    !!requestDemoFormValue || !!createRequestDemoData?.createRequestDemo.id;

  const industryItems = getIndustryItems();
  const chatfuelUseCaseItems = getChatfuelUseCaseItems();
  const sourceAboutUsItems = getSourceAboutUsItems();

  const handleChangeAndError = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    const text = name === 'email' ? value.trim() : value;
    await setFieldValue(name, text);
    validateField(name);
  };

  const handleIndustryChange: RequestDemoComboboxProps['onChange'] = async (
    item,
  ) => {
    if (typeof item.value === 'string' && isSomeEnum(Industry, item.value))
      await setValues({
        ...values,
        industry: item.value,
        industry_other: (item.otherValue as string) ?? undefined,
      });
    validateField('industry');
  };

  const handleUseCaseChange: RequestDemoComboboxProps['onChange'] = (item) => {
    if (
      typeof item.value === 'string' &&
      isSomeEnum(ChatfuelUseCase, item.value)
    )
      setValues({
        ...values,
        use_case: item.value,
        use_case_other: (item.otherValue as string) ?? undefined,
      });
  };

  const handleSourceChange: RequestDemoComboboxProps['onChange'] = (item) => {
    if (typeof item.value === 'string' && isSomeEnum(SourceAboutUs, item.value))
      setValues({
        ...values,
        source: item.value,
        source_other: (item.otherValue as string) ?? undefined,
      });
  };

  return (
    <div className={cn(css.root, rootClassName)}>
      <Flex flexDirection="column" className={cn(css.banner, banerClassName)}>
        <ArrowMessage />
        <Spacer factor={5} />
        <Type className={css.title} size="44px" weight="semibold">
          {t('Premium.requestDemo.contactOur')}
        </Type>
        <Spacer factor={5} />
        <Type className={css.description} size="18px">
          {t('Premium.requestDemo.wellHelp')}
        </Type>
      </Flex>
      <form
        className={cn(css.form, formClassName)}
        onSubmit={handleSubmit}
        data-testid="request-demo-form"
      >
        <Flex className={css.container} flexWrap justifyContent="space-between">
          <div className={css.halfInput}>
            <LoadingInput
              disabled={disabled}
              value={values.email}
              name="email"
              data-testid="request-demo-form__email-input"
              onChange={handleChangeAndError}
              labelText={`${t('Premium.requestDemo.email')}*`}
              placeholder={t('Premium.requestDemo.email')}
              error={!!errors.email}
              loading={loading}
              readOnly={readOnly}
              renderErrorText={() => t('Premium.requestDemo.enterEmail')}
            />
          </div>
          <div className={css.halfInput}>
            <LoadingInput
              disabled={disabled}
              value={values.name}
              name="name"
              data-testid="request-demo-form__name-input"
              onChange={handleChangeAndError}
              labelText={`${t('Premium.requestDemo.name')}*`}
              placeholder={t('Premium.requestDemo.name')}
              loading={loading}
              readOnly={readOnly}
              error={!!errors.name}
              renderErrorText={() => t('Premium.requestDemo.enterName')}
            />
          </div>
          <div
            className={css.input}
            data-testid="request-demo-form__industry-dropdown"
          >
            <RequestDemoCombobox
              selectedItem={
                industryItems.find(({ id }) => id === values.industry) ?? null
              }
              otherValue={values.industry_other}
              onChange={handleIndustryChange}
              loading={loading}
              readOnly={readOnly}
              name="industry"
              labelText={t('Premium.requestDemo.yourIndustry')}
              error={
                errors.industry
                  ? t('Premium.requestDemo.enterIndustry')
                  : undefined
              }
              items={industryItems}
            />
          </div>
          <div className={css.halfInput}>
            <LoadingInput
              disabled={disabled}
              labelText={t('Premium.requestDemo.company')}
              name="company"
              data-testid="request-demo-form__company-name-input"
              onChange={handleChange}
              placeholder={t('Premium.requestDemo.companyName')}
              value={values.company ?? ''}
              readOnly={readOnly}
              loading={loading}
              renderErrorText={() => ''}
            />
          </div>
          <div className={css.halfInput}>
            <LoadingInput
              disabled={disabled}
              name="phone"
              value={values.phone ?? ''}
              readOnly={readOnly}
              input={
                <div
                  className={css.phoneContainer}
                  data-testid="request-demo-form__phone-input"
                >
                  <PhoneInput
                    onChange={(value) => {
                      setFieldValue('phone', value);
                    }}
                    value={values.phone ?? ''}
                    containerClassName={css.phone}
                    allowDropdown={false}
                    disabled={disabled}
                    format
                    placeholder="+0 (123) 456 7890"
                    loader={<InputLoadingPlaceholder />}
                  />
                </div>
              }
              labelText={t('Premium.requestDemo.phoneNumber')}
              loading={loading}
              renderErrorText={() => ''}
            />
          </div>
          <div
            className={css.input}
            data-testid="request-demo-form__usecase-dropdown"
          >
            <RequestDemoCombobox
              selectedItem={
                chatfuelUseCaseItems.find(({ id }) => id === values.use_case) ??
                null
              }
              otherValue={values.use_case_other}
              onChange={handleUseCaseChange}
              loading={loading}
              readOnly={readOnly}
              name="useCase"
              labelText={t('Premium.requestDemo.whatDoYou')}
              items={chatfuelUseCaseItems}
            />
          </div>
          <div
            className={css.input}
            data-testid="request-demo-form__source-dropdown"
          >
            <RequestDemoCombobox
              selectedItem={
                sourceAboutUsItems.find(({ id }) => id === values.source) ??
                null
              }
              otherValue={values.source_other}
              onChange={handleSourceChange}
              loading={loading}
              readOnly={readOnly}
              name="source"
              labelText={t('Premium.requestDemo.howDidYou')}
              items={sourceAboutUsItems}
            />
          </div>
        </Flex>
        <Spacer factor={5} />
        <Button
          disabled={disabled}
          className={css.submit}
          type="submit"
          data-testid="request-demo-form__submit-button"
          loading={createRequestDemoLoading}
        >
          {t('Premium.requestDemo.submit')}
        </Button>
      </form>
    </div>
  );
};
