import { findMostFrequent } from 'cf-common/src/utils/Array/findMostFrequent';

const PHONE_FILTER_REGEXP = /\d/g;
const PHONE_SYMBOLS_QTY = [10, 11, 12, 13];

const softMatchPhone = (str: string) =>
  PHONE_SYMBOLS_QTY.includes(str.match(PHONE_FILTER_REGEXP)?.length || 0);

/**
 * Ищем колонку с даннымии максимально похожими на номер телефона в большинстве строк.
 * Выбираем колонки с количеством числовых символов PHONE_SYMBOLS_QTY,
 * предпочитаем колонки с меньшим length - PHONE_SYMBOLS_QTY и наиболее часто встречаюищиеся
 */
export const getPhoneFieldIndex = (parsedLines: string[][]) => {
  const phoneFieldIndex = findMostFrequent(
    parsedLines
      .map(
        (line) =>
          line
            .map((field, index) => [
              softMatchPhone(field) ? index : -1,
              field.trim().length - PHONE_SYMBOLS_QTY[0],
            ])
            .filter(([index]) => index !== -1)
            .sort(([_, a], [__, b]) => a - b)[0]?.[0],
      )
      .filter((n) => n !== undefined),
  );
  return phoneFieldIndex ?? -1;
};
