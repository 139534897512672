import React from 'react';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';

interface TestStepsProps {
  steps: {
    text: React.ReactNode;
    src: string;
  }[];
}

export const TestSteps: React.FC<TestStepsProps> = ({ steps }) => (
  <>
    {steps.map(({ text, src }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={index}>
        {index > 0 && <Spacer factor={6} />}
        <Flex>
          <img src={src} alt="" width={150} height={100} />
          <Spacer horizontalFactor={6} />
          <Type size="15px_DEPRECATED">{text}</Type>
        </Flex>
      </React.Fragment>
    ))}
  </>
);
