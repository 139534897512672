import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useCreateRequestDemo } from '@utils/Data/Premium/hooks';
import React from 'react';
import { RequestDemoStatus } from '../consts';
import { ReactComponent as RocketSvg } from './rocket.svg';
import { ReactComponent as WarningSvg } from './warning.svg';
import { RequestDemoInput } from '@globals';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getApiHostname } from 'cf-common/src/domain';

interface RequestDemoSubmitDialogProps {
  formState: RequestDemoStatus;
  setFromState(state: RequestDemoStatus): void;
  mobileAdaptive?: boolean;
  onClose: VoidFunction;
  requestDemoInput: RequestDemoInput;
  landing: boolean;
}

export const RequestDemoSubmitDialog: React.FC<RequestDemoSubmitDialogProps> =
  ({
    formState,
    setFromState,
    mobileAdaptive,
    onClose,
    requestDemoInput,
    landing,
  }) => {
    const { t } = useSafeTranslation();
    const [createRequestDemo, { loading }] = useCreateRequestDemo({
      onCompleted() {
        setFromState(RequestDemoStatus.success);
      },
      onError() {
        setFromState(RequestDemoStatus.error);
      },
    });

    const handleBackInChatfuel = () => {
      window.parent.location.href = getApiHostname();
    };

    const handleTryAgain = () => {
      createRequestDemo({
        variables: {
          request: requestDemoInput,
        },
      });
    };

    return (
      <DefaultDialog
        header={null}
        onDismiss={onClose}
        mobileAdaptive={mobileAdaptive}
      >
        {formState === RequestDemoStatus.success ? (
          <Flex flexDirection="column" alignItems="center">
            <RocketSvg />
            <Spacer factor={2} />
            <Type
              size="24px"
              weight="semibold"
              align="center"
              whiteSpace="pre-wrap"
            >
              {t('Premium.requestDemo.weveGo')}
            </Type>
            <Spacer factor={5} />
            {landing ? (
              <Button onClick={handleBackInChatfuel}>
                {t('Premium.requestDemo.back')}
              </Button>
            ) : (
              <Button onClick={onClose}>
                {t('Premium.requestDemo.close')}
              </Button>
            )}
            <Spacer factor={10} />
          </Flex>
        ) : (
          <Flex flexDirection="column" alignItems="center">
            <WarningSvg />
            <Spacer factor={2} />
            <Type
              size="24px"
              weight="semibold"
              align="center"
              whiteSpace="pre-wrap"
            >
              {t('Premium.requestDemo.wentWrong')}
            </Type>
            <Spacer factor={5} />
            <Button loading={loading} onClick={handleTryAgain}>
              {t('Premium.requestDemo.tryAgain')}
            </Button>
            <Spacer factor={10} />
          </Flex>
        )}
      </DefaultDialog>
    );
  };
