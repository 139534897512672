import { EventEmitter } from '@utils/EventEmitter';
import { Platform } from '@globals';

export interface TestThisBlockPayload {
  blockId?: string;
}

export const TEST_THIS_BLOCK_START_TEST_REQUEST = 'TEST_THIS_BLOCK_EVENT';
export const TEST_THIS_BLOCK_DISMISS_REQUEST =
  'TEST_THIS_BLOCK_DISMISS_REQUEST';
export const TEST_THIS_USER_SEND_MESSAGE = 'TEST_USER_SEND_MESSAGE';

export const testThisBlockEventEmitter = new EventEmitter();

export const testThisInChatfuel = (blockId?: string, platform?: Platform) => {
  return testThisBlockEventEmitter.emit(TEST_THIS_BLOCK_START_TEST_REQUEST, {
    blockId,
    platform,
  });
};

export const testThisInChatfuelDismiss = () => {
  return testThisBlockEventEmitter.emit(TEST_THIS_BLOCK_DISMISS_REQUEST);
};

export const emitUsedSendMessageEvent = () => {
  return testThisBlockEventEmitter.emit(TEST_THIS_USER_SEND_MESSAGE);
};
