import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { DefaultDialog } from '@ui/Dialog';

interface ShopifyDiscountsCodeDeleteDialogProps {
  code: string;
  onDelete(): void;
  onDismiss(): void;
}
export const ShopifyDiscountsCodeDeleteDialog: React.FC<ShopifyDiscountsCodeDeleteDialogProps> =
  ({ code, onDelete, onDismiss }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultDialog
        header={t('shopify.openShopifyStore.codeDeleteDialog.header', { code })}
        onDismiss={onDismiss}
        footer={
          <Flex justifyContent="flex-end">
            <Button
              intent="secondary"
              onClick={() => {
                onDismiss();
                // event.preventDefault();
                // event.stopPropagation();
              }}
              // onMouseDown={(event) => {
              //   event.preventDefault();
              //   event.stopPropagation();
              //   event.nativeEvent.stopImmediatePropagation();
              // }}
            >
              {t('shopify.openShopifyStore.codeDeleteDialog.cancel')}
            </Button>
            <Spacer horizontalFactor={4} />
            <Button intent="red" onClick={onDelete}>
              {t('shopify.openShopifyStore.codeDeleteDialog.delete')}
            </Button>
          </Flex>
        }
      >
        <Type size="15px_DEPRECATED">
          {t('shopify.openShopifyStore.codeDeleteDialog.description')}
        </Type>
      </DefaultDialog>
    );
  };
