import React, { useState } from 'react';
import { UpdatePaymentMethodModal } from './UpdatePaymentMethodModal';
import { Modal } from '../../../modern-ui/Modal';
import { Type } from '../../../modern-ui/Type';
import { Spacer } from '../../../modern-ui/Spacer';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';
import { DefaultDialog } from '../../../modern-ui/Dialog/DefaultDialog';

/**
 * Kinda like a state machine. For more complex flows consider
 * an actual state machine
 */
enum Steps {
  updatePaymentMethod,
  done,
}

interface Props {
  onDismiss: () => void;
}
export const UpdatePaymentMethodFlow: React.FC<Props> = ({ onDismiss }) => {
  const [step, setStep] = useState<Steps>(Steps.updatePaymentMethod);
  if (step === Steps.updatePaymentMethod) {
    return (
      <UpdatePaymentMethodModal
        onDismiss={onDismiss}
        onSuccess={() => {
          setStep(Steps.done);
        }}
      />
    );
  }
  if (step === Steps.done) {
    return (
      <Modal onDismiss={onDismiss}>
        <DefaultDialog
          onDismiss={onDismiss}
          header={
            <span style={{ lineHeight: '30px' }}>
              {window.i18next.t(
                'UpdatePaymentMethodFlow-JSXText-9710-thanks-youve-updated-your',
              )}
              <br />
              {window.i18next.t(
                'UpdatePaymentMethodFlow-JSXText-1133-payment-method',
              )}
            </span>
          }
        >
          <Type size="15px_DEPRECATED">
            {window.i18next.t(
              'UpdatePaymentMethodFlow-JSXText--738-new-card-is-now-linked-to-your-account-all-future-payments-will-be-charged-to-this-card',
            )}
          </Type>
          <Spacer />
          <div style={{ textAlign: 'right' }}>
            <Button intent={ButtonIntent.primary} onClick={onDismiss}>
              {window.i18next.t('UpdatePaymentMethodFlow-JSXText-9889-great')}
            </Button>
          </div>
        </DefaultDialog>
      </Modal>
    );
  }
  return null;
};
