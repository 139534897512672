import { getConfigureTabBotData_bot_pricingData as PricingData } from '@utils/Data/Pricing/@types/getConfigureTabBotData';

export const calculateNextProPlanTier = (
  usersCount: number,
  priceList: PricingData['pricing'],
) => {
  if (priceList === null) {
    return {};
  }

  const targetIndex = priceList.findIndex(
    (userValue) => userValue[0] > usersCount,
  );

  const [nextCountReachableUsers, nextPriceReachableUsers] =
    priceList[targetIndex !== -1 ? targetIndex : priceList.length - 1];

  return { nextPriceReachableUsers, nextCountReachableUsers };
};
