import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import image1Src from './images/TestCommentsAutoreplyDialog_1.png';
import image2Src from './images/TestCommentsAutoreplyDialog_2.png';
import image3Src from './images/TestCommentsAutoreplyDialog_3.png';
import image4Src from './images/TestCommentsAutoreplyDialog_4.png';
import { TestSteps } from './components';

const TEST_STEPS = () => [
  {
    text: (
      <>
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText-1676-1-set-up-an-autoreply-rule-and-toggle',
        )}
        <br />
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText-5429-the-entry-point-on',
        )}
      </>
    ),
    src: image1Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText--130-2-go-to-the-post-on-your-facebook',
        )}
        <br />
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText-2719-page-change-the-commenting-as',
        )}
        <br />
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText--191-setting-from-your-page-to-your',
        )}
        <br />
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText-1228-personal-account',
        )}
      </>
    ),
    src: image2Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText-2679-3-post-a-comment',
        )}
      </>
    ),
    src: image3Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestCommentsAutoreplyDialog-JSXText-1687-4-review-the-response-from-your-bot-in-messenger',
        )}
      </>
    ),
    src: image4Src,
  },
];

export const TestCommentsAutoreplyDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => (
  <DefaultDialog
    dialogStyle={{
      width: 572,
    }}
    header={window.i18next.t(
      'TestCommentsAutoreplyDialog-string-1273-test-your-comments-autoreply-entry-point',
    )}
    onDismiss={onRequestClose}
  >
    <TestSteps steps={TEST_STEPS()} />
  </DefaultDialog>
);
