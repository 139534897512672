import React, { useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';
import { BotStatus, getBotStatus, getBotPrice } from '@utils/Bot';

import { Button } from '@ui/Button';
import { Anchor } from '@ui/Links';
import { Loader } from '@ui/Loader';
import { Modal } from '@ui/Modal';
import { Spacer } from '@ui/Spacer';

import { PricingPopup } from '@components/PricingPopup';
import { useProPlanUnpause } from '@utils/Data/Pricing/hooks';
import { getConfigureTabBotData_bot as Bot } from '@utils/Data/Pricing/@types/getConfigureTabBotData';
import { useShowChurnSurveyAdviceDialog } from '@components/CancelProDialog/ChurnSurveyAdvice/showChurnSurveyAdviceDialog';
import { PaymentStatusModal } from '../PaymentStatus';
import { BillingDetailsModal } from '../BillingDetails';
import { Tos } from '../Tos';
import { ProPlanDisplayPrice } from '../ProPlanDisplayPrice';

import { getPaymentStatus } from '../../../Payments/utils/getPaymentStatus';
import * as css from './ProPlanManagement.css';
import { DateFormat } from '@utils/DateTime';

enum PopupType {
  none = 'none',
  billing = 'billing',
  cancel = 'cancel',
  payments = 'payments',
  pricing = 'pricing',
  cancelOpened = 'cancelOpened',
}

type PaymentCallback = (flag: boolean) => void;
type PaymentHandler = (
  botHasPage: boolean,
  callback?: PaymentCallback,
  onFinish?: () => void,
) => void;

interface ProPlanManagementProps {
  bot: Bot;
  termsChecked: boolean;
  setTermsChecked(value: boolean): void;

  onGoPro: PaymentHandler;
  onUpdatePayment: PaymentHandler;
  onCancelProPlan: () => Promise<any>;

  setLoading: (loading: boolean) => void;

  // pass in props for storybook
  abtFlags?: { [key: string]: number | boolean | undefined };

  discountIsApplicable?: boolean;
  discountIsApplied?: boolean;
  discountFactor?: number;
  discountPercentage?: number;
  discountExpiresAt?: number;
}

const sendUpgradeToProEvent = () => {
  sendEvent({
    category: 'pro plan payments',
    action: 'upgrade',
  });
};

export const ProPlanManagement: React.FC<ProPlanManagementProps> = ({
  bot,
  termsChecked,
  setTermsChecked,
  setLoading,
  onCancelProPlan,
  onGoPro,
  onUpdatePayment,
  discountIsApplicable = false,
  discountIsApplied = false,
  discountFactor = 1,
  discountPercentage = 0,
  discountExpiresAt,
}) => {
  const { t } = useSafeTranslation();
  const [popup, setPopup] = useState(PopupType.none);

  const { show: showChurnSurvey, loading: cancelProLoading } =
    useShowChurnSurveyAdviceDialog();

  const [unpauseProPlan, { loading: loadingUnpauseProPlan }] =
    useProPlanUnpause(bot.id);

  const hidePopup = () => {
    setPopup(PopupType.none);
  };

  useEffect(() => {
    setLoading(cancelProLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelProLoading]);

  useEffect(() => {
    if (popup === PopupType.cancel) {
      setPopup(PopupType.cancelOpened);
      showChurnSurvey({
        onGoBack: () => {
          setPopup(PopupType.payments);
        },
        onClose: hidePopup,
        onCancelPro: async () => {
          await onCancelProPlan();
        },
      });
    }
  }, [showChurnSurvey, popup, onCancelProPlan]);

  const status = getBotStatus(bot);
  const { isPaused, isPausedWithDebt, isPro, isPremium, needUpdatePayment } =
    getPaymentStatus(status);
  if (isPremium) {
    return (
      <div className={css.leftColumn}>
        <div className={css.leftColumnSubheader}>
          {t(
            'ProPlanManagement-JSXText-1762-you-have-access-to-all-the-pro-plan-features',
          )}
        </div>
      </div>
    );
  }

  const { pricingData, paymentInformation, id, status: botStatus } = bot;
  const { currentUsers = 0, currency } = pricingData || {};
  const price = getBotPrice(bot);

  const nextPaymentDate = DateFormat.MMMMDD(
    Number(paymentInformation.nextBillingDate!),
  );
  const needUpgrade = !isPro && !isPaused && !needUpdatePayment;

  const discount =
    discountIsApplicable || discountIsApplied
      ? {
          factor: discountFactor,
          percentage: discountPercentage,
          expiresAt: discountIsApplied
            ? paymentInformation.nextBillingDate!
            : discountExpiresAt!,
        }
      : undefined;

  return (
    <div className={css.leftColumn}>
      {popup === PopupType.pricing && (
        <Modal onDismiss={hidePopup}>
          <PricingPopup
            discount={discount}
            pricingData={pricingData!}
            currency={currency}
            onDismiss={hidePopup}
          />
        </Modal>
      )}
      {popup === PopupType.payments && (
        <PaymentStatusModal
          status={status}
          botId={id}
          onUpdateCard={() => {
            hidePopup();
            sendEvent({
              category: 'pro plan payments',
              action: 'update card click',
              label: 'show',
            });
            onUpdatePayment(true, () => {
              setPopup(PopupType.payments);
            });
          }}
          onUpdateBilling={() => {
            setPopup(PopupType.billing);
            sendEvent({
              category: 'pro plan payments',
              action: 'update billing address',
              label: 'show',
            });
          }}
          onCancelProPlan={() => {
            setPopup(PopupType.cancel);
            sendEvent({
              category: 'pro plan payments',
              action: 'cancel pro subscription',
              label: 'show',
            });
          }}
          onDismiss={hidePopup}
        />
      )}
      {popup === PopupType.billing && (
        <BillingDetailsModal
          botId={id}
          onRequestClose={() => {
            setPopup(PopupType.payments);
          }}
        />
      )}
      <div className={css.leftColumnSubheader}>
        {(isPro || isPaused) && (
          <div>
            {t('ProPlanManagement-JSXText-2123-next-payment-on')}{' '}
            <span>{nextPaymentDate}</span>
          </div>
        )}
        <div>
          {t(
            'modernComponents.ProPlanSection.ProPlanManagement.forSubscribers',
            { count: currentUsers },
          )}
        </div>
      </div>
      <ProPlanDisplayPrice
        className={css.price}
        price={price}
        currency={currency ?? undefined}
        discountFactor={discountFactor}
        discountIsApplicable={discountIsApplicable}
      />
      {!isPaused && !isPausedWithDebt && (
        <div className={css.disclaimer}>
          {t('ProPlanManagement-JSXText--180-price-adjusts-according-to')}
          <br />
          {t('ProPlanManagement-JSXText-1030-the')}{' '}
          <Anchor
            data-testid="numberOfSubscribers"
            intent="subtitle"
            size="regular"
            onClick={() => {
              setPopup(PopupType.pricing);
              sendEvent({
                category: 'pro plan payments',
                action: 'click',
                label: 'pricing',
              });
            }}
          >
            {t('ProPlanManagement-JSXText-1201-number-of-subscribers')}
          </Anchor>
        </div>
      )}
      {needUpdatePayment && (
        <Button
          onClick={() => {
            onUpdatePayment(true);
            sendEvent({
              category: 'pro plan payments',
              action: 'manage pro plan',
            });
          }}
          intent="red"
          className={css.upgradeButton}
          disabled={!termsChecked}
        >
          {t('ProPlanManagement-JSXText--925-update-payment')}
        </Button>
      )}
      {needUpgrade && (
        <Button
          onClick={() => {
            sendUpgradeToProEvent();
            onGoPro(!!botStatus?.page_info?.id, setLoading);
          }}
          intent={bot.user_limit_reached ? 'red' : 'primary'}
          className={cn(css.upgradeButton, 'test-upgrade-to-pro-button')}
          disabled={!termsChecked}
          data-testid="upgrade-to-pro-button"
        >
          {t('ProPlanManagement-JSXText-1227-upgrade-to-pro')}
        </Button>
      )}
      {(needUpgrade || status === BotStatus.expiredDebt) && (
        <Tos
          termsChecked={termsChecked}
          setTermsChecked={setTermsChecked}
          onChange={() => {
            sendEvent({
              category: 'pro plan payments',
              action: !termsChecked ? 'enable' : 'disable',
              label: 'terms of service',
            });
          }}
        />
      )}
      {isPaused && (
        <>
          <Button
            disabled={loadingUnpauseProPlan}
            intent="primary"
            onClick={() => {
              sendEvent({
                category: 'pro plan payments',
                action: 'unpause',
              });
              unpauseProPlan({
                variables: { botId: id },
              });
            }}
          >
            {t('ProPlanManagement-JSXText-2816-unpause')}
            {loadingUnpauseProPlan && <Loader size="xs" />}
          </Button>
          <Spacer factor={4} />
        </>
      )}
      {(isPro || isPaused || isPausedWithDebt) && (
        <div>
          <Anchor
            intent="internal"
            onClick={() => {
              setPopup(PopupType.payments);
              sendEvent({
                category: 'pro plan payments',
                action: 'manage pro plan',
              });
            }}
          >
            {t('ProPlanManagement-JSXText--121-manage-pro-plan')}
          </Anchor>
        </div>
      )}
    </div>
  );
};
