import React from 'react';
import { Flex } from '@ui/Flex';
import { DefaultDialog } from '@ui/Dialog';
import { Button } from '@ui/Button';
import { Type } from '@ui/Type';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import imageUrl from './images/TestFacebookMessengerAdsDialog.png';
import * as css from './TestFacebookMessengerAdsDialog.css';

export const TestFacebookMessengerAdsDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => (
  <DefaultDialog
    dialogStyle={{
      width: 572,
    }}
    header={window.i18next.t(
      'TestFacebookMessengerAdsDialog-string--239-test-your-facebook-ad-entry-point',
    )}
    footer={
      <Button
        onClick={() => {
          sendEvent({
            category: 'flow',
            action: 'click Go to Ads Manager',
            label: 'test dialog',
          });
          window.open(
            'https://www.facebook.com/business/tools/ads-manager',
            '_blank',
          );
          onRequestClose();
        }}
      >
        {window.i18next.t(
          'TestFacebookMessengerAdsDialog-JSXText--193-go-to-ads-manager',
        )}
      </Button>
    }
    onDismiss={onRequestClose}
  >
    <Flex
      alignItems="flex-end"
      justifyContent="center"
      className={css.imageBox}
    >
      <img src={imageUrl} alt="" className={css.image} />
    </Flex>
    <Spacer factor={4} />
    <div>
      <Type size="15px_DEPRECATED">
        {window.i18next.t(
          'TestFacebookMessengerAdsDialog-JSXText--181-to-test-your-ad-experience-starting-from-the-greeting-go-to-the-ad-settings-in-ads-manager-and-click-preview-in-messenger',
        )}
      </Type>
    </div>
  </DefaultDialog>
);
