import React from 'react';
import cn from 'classnames';
import * as css from './SectionBox.css';

export const SectionBox = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <section className={cn(className, css.sectionBox)} ref={ref} {...props} />
));
