import React from 'react';
import { Portal } from 'react-portal';
import { WhiteLabelUserData } from '@utils/WhiteLabelUtils';
import { TestThis } from './TestThis';
import * as css from './QuickAccessToolbar.css';
import { useStartIntercom } from './useStartIntercom';
import {  INTERCOM_Z_INDEX } from './consts';

const Toolbar: React.FC = () => {
  useStartIntercom();
  return (
    <Portal>
      <div
        className={css.toolbar}
      >
        <TestThis />
      </div>
      <style>
        {`
        .intercom-launcher,
        iframe.intercom-launcher-frame,
        iframe.intercom-launcher-badge-frame,
        div.intercom-messenger-frame,
        iframe.intercom-notifications-frame,
        .intercom-namespace iframe {
          position: absolute!important;
          z-index: ${INTERCOM_Z_INDEX}!important;
         }
         `}
        {`
        .intercom-namespace iframe[name="intercom-notifications-frame"] {
          z-index: ${INTERCOM_Z_INDEX + 1}!important;
        }
        `}
        {`
        .intercom-lightweight-app {
          position: unset!important;
          z-index: unset!important;
        }
       `}
        {`
        .intercom-namespace div[style="opacity: 1;"] {
          display: none;
        }
       `}
        {`
        #alvis-nbcl-container {
          position: absolute;
          z-index: ${INTERCOM_Z_INDEX + 2};
          width: 100%
        }
        #alvis-nbcl-launcher {
          position: absolute!important;
          z-index: 2!important;
        }
       `}
      </style>
    </Portal>
  );
};

export const QuickAccessToolbar: React.FC = () => {
  return (
    <WhiteLabelUserData>
      {({ loading, isWhiteLabelUser }) => {
        if (loading || isWhiteLabelUser) {
          return null;
        }
        return <Toolbar />;
      }}
    </WhiteLabelUserData>
  );
};
