import { useLazyQuery } from '@apollo/react-hooks';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { useCallback } from 'react';
import {
  FacebookPagePostByUrl,
  FacebookPagePostByUrlVariables,
} from '../@types/FacebookPagePostByUrl';
import { FACEBOOK_PAGE_POST_BY_URL } from '../queries';

export const useGetPostByUrl = () => {
  const botId = useCurrentBotId();
  const { pageId } = usePageConnected(botId);

  const [
    getPostByUrlQuery,
    { data: postByUrlData, loading: postByUrlLoading },
  ] = useLazyQuery<FacebookPagePostByUrl, FacebookPagePostByUrlVariables>(
    FACEBOOK_PAGE_POST_BY_URL,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const postByUrl = postByUrlData?.page?.post;

  const getPostByUrl = useCallback(
    (url: string) => {
      if (pageId) {
        getPostByUrlQuery({
          variables: {
            pageId,
            url,
          },
        });
      }
    },
    [pageId, getPostByUrlQuery],
  );

  return {
    getPostByUrl,
    postByUrl,
    postByUrlLoading,
  };
};
