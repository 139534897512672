import { BotTestingQuery_botTesting as TestThisMessageItem } from '@utils/Data/BotTesting/@types/BotTestingQuery';

export const EMPTY_MESSAGE_DATA = {
  __typename: 'Message' as const,
  text: null,
  buttons: null,
  quick_replies: null,
  attachment: null,
  gallery: null,
  reactions: null,
  receipt: null,
  sticker_id: null,
  send_to: null,
  typing_timeout_milliseconds: null,
  whatsapp_list_reply: null,
  whatsapp_list: null,
  whatsapp_template: null,
  whatsapp_location: null,
  whatsapp_context: null,
};

export const createMessage = (
  id: string,
  text: string,
  from: string,
): TestThisMessageItem => ({
  __typename: 'BotTestingItem',
  id,
  mid: id,
  message: {
    ...EMPTY_MESSAGE_DATA,
    text,
  },
  from: { __typename: 'BotTestingAuthor', type: from },
  date: Date.now(),
});

export const createUserMessage = (
  id: string,
  text: string,
): TestThisMessageItem => createMessage(id, text, 'user');
