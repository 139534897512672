import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { sendEvent } from '@utils/Analytics';
import { useInstagramAccount } from '@utils/Data/Instagram/BotAccount';
import { showIntercom } from '@utils/Intercom';
import { useCurrentBotId } from '@utils/Routing';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';

interface ConnectInstagramErrorProps {
  onGoBack(): void;
}
export const ConnectInstagramError: React.FC<ConnectInstagramErrorProps> = ({
  onGoBack,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { fbError } = useInstagramAccount(botId);
  const handleGoBack = () => {
    sendEvent({
      category: 'instagram connect popup',
      action: 'click go back',
      label: 'error',
      propertyBag: { botId },
    });
    onGoBack();
  };
  return (
    <ConnectInstagramDialogContent
      header={t('common.Instagram.dialog.error.header')}
      footerLeft={
        <Button
          intent="secondary"
          data-testid="instagram-connect-dialog__error__back-button"
          icon={<Icon icon="leftArrow" size="12px" />}
          onClick={handleGoBack}
        >
          {t('common.Instagram.dialog.error.backButton')}
        </Button>
      }
      footerRight={
        <Button
          intent="primary"
          data-testid="instagram-connect-dialog__error__contact-button"
          onClick={() => {
            showIntercom();
          }}
        >
          {t('common.Instagram.dialog.error.contactButton')}
        </Button>
      }
      data-testid="instagram-connect-dialog__error"
    >
      <Flex flexDirection="column">
        <Type size="15px">
          {fbError?.code && (
            <>
              {t('common.Instagram.dialog.error.error', {
                errorCode: fbError?.code,
              })}
              <br />
            </>
          )}
          {t('common.Instagram.dialog.error.description')}
        </Type>
      </Flex>
    </ConnectInstagramDialogContent>
  );
};
