import React from 'react';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Type } from '../../modern-ui/Type';
import {
  ServerStorageItemKeys,
  useServerStorage,
} from '../../utils/ServerStorage';
import OpenBook from '../../assets2/images/emojis/open_book.png';
import { DismissButton } from './components/DismissButton';

import * as css from './SponsoredMessageBanner.css';

export const SponsoredMessageBanner: React.FC = () => {
  const { t } = useSafeTranslation();
  const [isDismissed, setIsDismissed, { loading, saving }] = useServerStorage(
    ServerStorageItemKeys.isReengageSponsoredMessageBannerDismissed,
  );

  const [isDismissVisible, setIsDismissVisible] = React.useState(false);
  const handleMouseEnter = () => {
    setIsDismissVisible(true);
  };
  const handleMouseLeave = () => {
    setIsDismissVisible(false);
  };

  const handleDismiss = async () => {
    await setIsDismissed(true);
  };

  return isWhiteLabelDomain() || isDismissed || loading || saving ? null : (
    <div
      className={css.SponsoredMessageBannerContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isDismissVisible && (
        <DismissButton
          onClick={handleDismiss}
          className={css.SponsoredMessageBannerDismissContainer}
        />
      )}
      <div className={css.SponsoredMessageBannerText}>
        <Type size="15px_DEPRECATED" weight="regular">
          {t(
            'SponsoredMessageBanner-JSXText--234-to-reach-your-users-outside-the-24-h-messaging-window-please-use-sponsored-messaging',
          )}
        </Type>
      </div>
      <a
        target="_blank"
        href={t('common.helpDocLinks.sponsored_message_ep')}
        className={css.SponsoredMessageBannerButton}
        rel="noopener noreferrer"
      >
        <img
          alt={t('SponsoredMessageBanner-string--120-open-book-emoji')}
          src={OpenBook}
          aria-label={t('SponsoredMessageBanner-string--212-how-to')}
          className={css.SponsoredMessageBannerButtonIcon}
        />
        {t(
          'SponsoredMessageBanner-JSXText-1990-how-to-send-sponsored-messages-↗',
        )}
      </a>
    </div>
  );
};
