import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { BotStatus } from '@utils/Bot';
import { downloadInvoice } from '@utils/downloadInvoice';
import { plurals } from '@utils/Plurals';

import { DefaultDialog } from '@ui/Dialog';
import { Modal } from '@ui/Modal';
import { Anchor } from '@ui/Links';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { formatPrice } from '@utils/Format/priceFormatter';

import { calculateNextProPlanTier } from '../../utils/calculateNextProPlanTier';
import { CUSTOM_PRICING_URL } from '../../../Payments/constants/customPricing';
import { isWarning } from '../../../Premium/Payments/PaymentStatus/subscriptionStatus';
import { ExistingCard, PaymentHistory, PaymentStatus } from '../../../Payments';
import {
  PaymentInterval,
  paymentIntervalToString,
} from '@utils/Payment/paymentInterval';

import {
  getBotPaymentHistory as BotPaymentHistory,
  getBotPaymentHistory_bot_paymentInformation_payments as Payment,
  getBotPaymentHistoryVariables as BotPaymentHistoryVariables,
} from './@types/getBotPaymentHistory';
import {
  getBotPaymentInformation as BotPaymentInformation,
  getBotPaymentInformationVariables as BotPaymentInformationVariables,
} from './@types/getBotPaymentInformation';
import { getInvoice, getInvoiceVariables } from './@types/getInvoice';

import * as css from './PaymentStatusModal.css';
import { DateFormat } from '@utils/DateTime';
import { PaymentInfoRow } from './components/PaymentInfoRow';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { showTransferPlanDialog } from '../../../TransferPlanDialog';

interface PaymentStatusModalProps {
  botId: string;

  onDismiss(): void;

  onUpdateCard(): void;

  onUpdateBilling(): void;

  onCancelProPlan(): void;

  status: BotStatus;
}

export const PaymentStatusModal: React.FC<PaymentStatusModalProps> = ({
  botId,
  onDismiss,
  onUpdateCard,
  onUpdateBilling,
  onCancelProPlan,
  status,
}) => {
  const { t } = useSafeTranslation();

  return (
    <Modal onDismiss={onDismiss}>
      <DefaultDialog
        header={t('PaymentStatusModal-string--173-manage-pro-plan')}
        onDismiss={onDismiss}
        dialogStyle={{ width: '520px' }}
        contentStyle={{
          maxHeight: 'calc(100vh - 20px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={css.dialogContent}>
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <PaymentInfo
            botId={botId}
            status={status}
            onUpdateCard={onUpdateCard}
            onUpdateBilling={onUpdateBilling}
            onCancelProPlan={onCancelProPlan}
            onDismiss={onDismiss}
          />
          {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
          <ProPaymentHistory botId={botId} />
        </div>
      </DefaultDialog>
    </Modal>
  );
};

const BOT_PAYMENT_INFO = gql`
  query getBotPaymentInformation($botId: String!) {
    bot(id: $botId) {
      id
      billingDetails {
        id
        city
        company_name
        country
        email
        postcode
        state
        street
      }
      paymentInformation {
        nextBillingDate
        status
        active_card {
          card4LastDigits
        }
      }
      pricingData {
        currency
        currentPrice
        currentUsers
        currentMaxUsers
        pricing
        reached_limit
      }
    }
  }
`;

type PaymenInfoProps = {
  botId: string;
  onUpdateCard(): void;
  onUpdateBilling(): void;
  onCancelProPlan(): void;
  onDismiss(): void;
  status: BotStatus;
};
const PaymentInfo: React.FC<PaymenInfoProps> = ({
  botId,
  status,
  onUpdateCard,
  onUpdateBilling,
  onCancelProPlan,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  const { data, loading, error } = useQuery<
    BotPaymentInformation,
    BotPaymentInformationVariables
  >(BOT_PAYMENT_INFO, {
    variables: { botId },
  });

  if (error) {
    return (
      <Type color="red" size="15px_DEPRECATED">
        {t(
          'PaymentStatusModal-JSXText--843-could-not-fetch-payment-information-please-try-again',
        )}
      </Type>
    );
  }

  if (loading || !data?.bot) {
    return <Spacer factor={5} />;
  }

  const {
    paymentInformation,
    pricingData,
    billingDetails: { company_name, street },
  } = data.bot;

  const isWarningState = isWarning(paymentInformation);
  const {
    currency,
    currentUsers = 0,
    currentPrice = 0,
    pricing = [],
  } = pricingData || {};
  const {
    nextPriceReachableUsers = currentPrice,
    nextCountReachableUsers = currentUsers,
  } = calculateNextProPlanTier(currentUsers, pricing);
  const billingAddress = company_name
    ? `${company_name}${street ? `, ${street}` : ''}`
    : '';

  return (
    <>
      {paymentInformation.nextBillingDate && (
        <PaymentStatus
          amount={currentPrice}
          currency={currency ?? undefined}
          intervalName={PaymentInterval.month}
          nextPaymentDate={new Date(Number(paymentInformation.nextBillingDate))}
        />
      )}
      {status !== BotStatus.paused &&
        status !== BotStatus.pausedAndExpiredDebt && (
          <div className={css.statusText}>
            <Type size="15px_DEPRECATED">
              {t('PaymentStatusModal-JSXText-1514-you-have')}{' '}
              {plurals(currentUsers, 'user', 'users')}
            </Type>
            <Type size="15px_DEPRECATED">
              {t('PaymentStatusModal-JSXText--277-next-tier-is')}{' '}
              {nextCountReachableUsers}{' '}
              {t('PaymentStatusModal-JSXText--148-users-for')}{' '}
              {formatPrice(nextPriceReachableUsers, {
                currency: currency ?? undefined,
                decimals: 0,
              })}
              /{paymentIntervalToString()[PaymentInterval.month]}
            </Type>
          </div>
        )}
      <Spacer />
      <ExistingCard
        onUpdate={onUpdateCard}
        error={isWarningState}
        cardLastNumbers={paymentInformation.active_card?.card4LastDigits || ''}
      />
      <Spacer factor={3} />
      <PaymentInfoRow
        info={billingAddress}
        title={t('PaymentStatusModal-string-2023-billing-address')}
        buttonTitle={t('PaymentStatusModal-string--175-update')}
        onClick={onUpdateBilling}
        data-testid="configure_pro_update-billing"
      />
      <Spacer factor={6} />
      <PaymentInfoRow
        info={`${t(
          'PaymentStatusModal-Template--569-valid-until',
        )}${DateFormat.DDMMMMYYYY(Number(paymentInformation.nextBillingDate))}`}
        title={t('PaymentStatusModal-string--100-pro-plan')}
        buttonTitle={t('PaymentStatusModal-string-2011-cancel')}
        onClick={onCancelProPlan}
        data-testid="configure_pro_cancel"
        actionContent={
          status !== BotStatus.paused && (
            <>
              <Spacer factor={1} />
              <Anchor
                intent="external"
                hideArrow
                data-testid="configure_transfer-plan"
                onClick={() => {
                  onDismiss();
                  showTransferPlanDialog();
                }}
              >
                {t('PaymentStatusModal-string-2011-transfer-plan')}
              </Anchor>
            </>
          )
        }
      />
      <Spacer />
      {paymentInformation.status == null && (
        <>
          <Spacer factor={3} />
          <i>
            <Type size="15px_DEPRECATED">
              {t(
                'PaymentStatusModal-JSXText--148-unexpected-subscription-status-please',
              )}{' '}
            </Type>
            <Anchor intent="external" href={CUSTOM_PRICING_URL} target="_blank">
              {t('PaymentStatusModal-JSXText--339-contact-us')}
            </Anchor>
            .
          </i>
        </>
      )}
      {isWarningState && (
        <Type as="p" color="red" size="15px_DEPRECATED">
          {t(
            'PaymentStatusModal-JSXText-2383-we-were-unable-to-process-your-payment',
          )}
        </Type>
      )}
      <Spacer factor={3} />
    </>
  );
};

const BOT_PAYMENT_HISTORY = gql`
  query getBotPaymentHistory($botId: String!) {
    bot(id: $botId) {
      id
      paymentInformation {
        payments {
          currency
          activeUsers
          amount
          last4CardDigits
          date
          stripeResult {
            id
            failed
          }
        }
      }
    }
  }
`;

const INVOICE = gql`
  query getInvoice($id: String!, $botId: String) {
    invoice(id: $id, botId: $botId) {
      id
      html
    }
  }
`;

const { Card, Download, PaymentDate, Price, Users } = PaymentHistory;

const ProPaymentHistory: React.FC<{
  botId: string;
}> = ({ botId }) => {
  const { t } = useSafeTranslation();
  const { data, loading, error, client } = useQuery<
    BotPaymentHistory,
    BotPaymentHistoryVariables
  >(BOT_PAYMENT_HISTORY, {
    variables: { botId },
  });

  if (error) {
    return (
      <Type color="red" size="15px_DEPRECATED">
        {t(
          'PaymentStatusModal-JSXText--131-could-not-fetch-payment-history-please-try-again',
        )}
      </Type>
    );
  }
  const payments = data?.bot.paymentInformation.payments;

  if (loading || !payments) {
    return <CenteredLoader />;
  }

  return (
    <div className={css.paymentHistory}>
      <PaymentHistory<Payment>
        listHeight={336}
        itemHeight={48}
        header={t('PaymentStatusModal-string-7012-invoices')}
        items={payments}
        getRejectedItem={({ stripeResult }) => !!stripeResult?.failed}
        rowClassName={css.paymentHistoryRow}
        columns={[
          {
            columnId: 'date',
            render: (item) => <PaymentDate date={item.date!} />,
          },
          {
            columnId: 'users',
            render: (item) => <Users count={item.activeUsers!} />,
          },
          {
            columnId: 'card',
            render: (item) => <Card last4={item.last4CardDigits!} />,
          },
          {
            columnId: 'price',
            render: (item) => (
              <Price amount={item.amount!} currency={item.currency!} />
            ),
          },
          {
            columnId: 'download',
            render: ({ stripeResult, date }) =>
              stripeResult?.id && !stripeResult.failed ? (
                <Download
                  onDownload={async () => {
                    const res = await client.query<
                      getInvoice,
                      getInvoiceVariables
                    >({
                      query: INVOICE,
                      variables: { id: stripeResult.id!, botId },
                      fetchPolicy: 'no-cache',
                    });
                    if (res.data.invoice && date) {
                      downloadInvoice(date, res.data.invoice.html);
                    }
                  }}
                />
              ) : null,
          },
        ]}
      />
    </div>
  );
};
