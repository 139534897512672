import React, { HTMLProps } from 'react';
import { Flex } from '@ui/Flex';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';

interface ItemLoadingPlaceholderProps extends HTMLProps<HTMLDivElement> {
  hideCheckboxLoader?: boolean;
}

export const ItemLoadingPlaceholder: React.FC<ItemLoadingPlaceholderProps> = ({
  hideCheckboxLoader,
  ...props
}) => (
  <div {...props}>
    {Array(5)
      .fill(null)
      .map((_, index) => (
        <Flex
          alignItems="center"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{ marginBottom: 12, height: 40 }}
        >
          {!hideCheckboxLoader && (
            <LoadingPlaceholder
              style={{ width: 18, height: 18, marginRight: 12 }}
            />
          )}
          <LoadingPlaceholder
            style={{ width: 32, height: 32, marginRight: 12 }}
          />
          <div>
            <LoadingPlaceholder
              style={{ width: 410, height: 8, marginBottom: 8 }}
            />
            <LoadingPlaceholder style={{ width: 105, height: 8 }} />
          </div>
        </Flex>
      ))}
  </div>
);
