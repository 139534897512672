import { isEmpty } from 'ramda';
import { ShopifyDiscountsCodeSelectStatus } from './types';
import { ShopifyDiscountsCodesQuery_shopifyDiscountsCodes } from '../../@types/ShopifyDiscountsCodesQuery';

export const getShopifyDiscountsCodeSelectStatus = (
  search: string,
  codes: ShopifyDiscountsCodesQuery_shopifyDiscountsCodes[],
  error: boolean,
  loading: boolean,
  hasPermission: boolean,
  hasAccount: boolean,
) => {
  if (loading) {
    return ShopifyDiscountsCodeSelectStatus.loading;
  }
  if (!hasAccount) {
    return ShopifyDiscountsCodeSelectStatus.needAccount;
  }
  if (!hasPermission) {
    return ShopifyDiscountsCodeSelectStatus.needPermission;
  }
  if (error) {
    return ShopifyDiscountsCodeSelectStatus.error;
  }
  if (isEmpty(codes)) {
    return search
      ? ShopifyDiscountsCodeSelectStatus.empty
      : ShopifyDiscountsCodeSelectStatus.zero;
  }
  return ShopifyDiscountsCodeSelectStatus.list;
};
