import React from 'react';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import {
  adCommentsAutoreplyEntryPointFragment_config_adAccount,
  adCommentsAutoreplyEntryPointFragment_config_posts,
} from '@components/Plugins/AdCommentsAutoreplyEntryPoint/@types/adCommentsAutoreplyEntryPointFragment';
import { ChooseAdPostsList, ChooseAdPostsListProps } from './ChooseAdPostsList';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export interface ChooseAdPostsContentSubmitProps {
  adAccount: adCommentsAutoreplyEntryPointFragment_config_adAccount | null;
  posts: adCommentsAutoreplyEntryPointFragment_config_posts[];
}

export interface ChooseAdPostsContentProps extends ChooseAdPostsListProps {
  isEditingPosts: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChooseAdPostsContent: React.FC<ChooseAdPostsContentProps> = ({
  isEditingPosts,
  searchValue,
  setSearchValue,
  onSubmit,
  ...listProps
}) => {
  const { t } = useSafeTranslation();

  return (
    <>
      <Input
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        renderIcon={() => <Icon icon="search" color="grey" />}
        placeholder={t(
          'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.ChooseAdPostsContent.placeholder',
        )}
      />

      <Spacer factor={6} />

      <ChooseAdPostsList {...listProps} />

      <Spacer factor={6} />

      <Flex alignItems="center" justifyContent="flex-end">
        <Button onClick={onSubmit}>
          {isEditingPosts
            ? t(
                'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.ChooseAdPostsContent.save',
              )
            : t(
                'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.ChooseAdPostsContent.add',
              )}
        </Button>
      </Flex>
    </>
  );
};
