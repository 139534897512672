import React from 'react';
import { StepProps } from '../types';
import { WhatsappImportUserDialogFooter } from '../../components/WhatsappImportUserDialogFooter';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import * as css from './InProgressStep.css';

export const InProgressStep: React.FC<StepProps> = () => {
  const { t } = useSafeTranslation();

  return (
    <>
      <Flex className={css.box} alignItems="center" justifyContent="center">
        <Flex alignItems="center" flexDirection="column">
          <CenteredLoader size="l" />
          <Spacer factor={7} />
          <Type color="baseSecondary" as="div">
            {t('modernComponents.WhatsAppImportUser.dialog.InProgress.text1')}
          </Type>
          <Type color="baseSecondary" as="div">
            {t('modernComponents.WhatsAppImportUser.dialog.InProgress.text2')}
          </Type>
        </Flex>
      </Flex>
      <WhatsappImportUserDialogFooter
        disabled
        nextStepButtonTitle={t(
          'modernComponents.WhatsAppImportUser.dialog.InProgress.next',
        )}
      />
    </>
  );
};
