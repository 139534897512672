import React from 'react';
import { Type } from '@ui/Type';
import {
  WorkspaceConversationWrapper,
  WorkspaceConversationWrapperProps,
} from '../WorkspaceConversationWrapper/WorkspaceConversationWrapper';

export interface WorkspaceQwerProps extends WorkspaceConversationWrapperProps {
  chargingEntityLabel: string;
}

export const WorkspaceStatistics: React.FC<WorkspaceQwerProps> = ({
  count,
  maxCount,
  className,
  chargingEntityLabel,
}) => (
  <WorkspaceConversationWrapper
    count={count}
    maxCount={maxCount}
    className={className}
  >
    <Type
      size="15px"
      weight="regular"
      color="greyDark"
      data-testid="bot-list__list-item__charging_entity"
    >
      {chargingEntityLabel}
    </Type>
  </WorkspaceConversationWrapper>
);
