import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import image1Src from './images/TestSendToMessengerDialog_1.png';
import image2Src from './images/TestSendToMessengerDialog_2.png';
import image3Src from './images/TestSendToMessengerDialog_3.png';
import { TestSteps } from './components';

const TEST_STEPS = () => [
  {
    text: (
      <>
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText--183-1-open-your-websites-pages-editor',
        )}
        <br />
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText--189-and-navigate-to-the-page-where-you',
        )}
        <br />
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText--188-want-the-entry-point-to-appear-insert',
        )}
        <br />
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText--142-the-generated-code-there',
        )}
      </>
    ),
    src: image1Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText--869-2-toggle-the-entry-point-on',
        )}
        <br />
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText-6522-click-the-button-once-it-appears',
        )}
        <br />
        {window.i18next.t('TestSendToMessengerDialog-JSXText-1837-on-the-page')}
      </>
    ),
    src: image2Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText--606-3-review-the-response-from-your',
        )}
        <br />
        {window.i18next.t(
          'TestSendToMessengerDialog-JSXText--128-bot-in-messenger',
        )}
      </>
    ),
    src: image3Src,
  },
];

export const TestSendToMessengerDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => (
  <DefaultDialog
    dialogStyle={{
      width: 572,
    }}
    header={window.i18next.t(
      'TestSendToMessengerDialog-string-3762-test-your-send-to-messenger-entry-point',
    )}
    onDismiss={onRequestClose}
  >
    <TestSteps steps={TEST_STEPS()} />
  </DefaultDialog>
);
