import React, { useCallback, useEffect, useState } from 'react';
import { sendEvent, sendEventOnceByCategoryAndAction } from '@utils/Analytics';
import {
  BotTabs,
  getTabLink,
  useCurrentBotId,
  useCurrentFlowId,
} from '@utils/Routing';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { getShopifyFeatureStatus } from '@utils/Integrations/Shopify/utils/getShopifyFeatureStatus';

import { Modal } from '@ui/Modal';

import { Popper } from 'react-popper';
import { Placement } from 'popper.js';
import { Portal } from 'react-portal';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  ShopifyDiscountsCodeInput,
  ShopifyDiscountsCodeType,
  ShopifyDiscountsCodeValueType,
  ShopifyFeatureType,
} from '@globals';

import { ShopifyDiscountsCodeSelect } from './components/ShopifyDiscountsCodeSelect';
import { ShopifyDiscountsCodeEditor } from './components/ShopifyDiscountsCodeEditor';
import { ShopifyDiscountsCodeDeleteDialog } from './components/ShopifyDiscountsCodeDeleteDialog';
import {
  useShopifyDiscountsCodesCreate,
  useShopifyDiscountsCodesDelete,
  useShopifyDiscountsCodesList,
  useShopifyDiscountsCodesUpdate,
} from './hooks';
import { ShopifyDiscountsCodesQuery_shopifyDiscountsCodes as ShopifyDiscountsCode } from './@types/ShopifyDiscountsCodesQuery';
import { NONE_CODE_ID } from './components/ShopifyDiscountsCodeSelect/ShopifyDiscountsCodeSelect';
import * as css from './ShopifyDiscounts.css';

interface ShopifyDiscountsCodeProps {
  onSelect(code: ShopifyDiscountsCode | null): void;
  onClose(): void;
  discountTypes?: ShopifyDiscountsCodeType[];
  referenceElement: Element | undefined;
  offset?: string;
  placement?: Placement;
}

const SHOPIFY_DISCOUNT_DEFAULT: ShopifyDiscountsCodeInput = {
  code: '',
  discount_type: ShopifyDiscountsCodeType.common,
  value: 10,
  value_type: ShopifyDiscountsCodeValueType.percentage,
  start_date: Date.now().toString(10),
};

export const ShopifyDiscounts: React.FC<ShopifyDiscountsCodeProps> = ({
  onSelect,
  onClose,
  discountTypes,
  referenceElement,
  offset = '0px, 10px',
  placement = 'bottom-end',
}) => {
  const botId = useCurrentBotId();
  const flowId = useCurrentFlowId();
  const { t } = useSafeTranslation();
  const {
    shopifyDiscountsCodesList,
    shopifyDiscountsCodesListError,
    shopifyDiscountsCodesListLoading,
    shopifyDiscountsCodesListRefetch,
  } = useShopifyDiscountsCodesList();
  const { createShopifyDiscountsCode, createShopifyDiscountsCodeError } =
    useShopifyDiscountsCodesCreate();
  const { updateShopifyDiscountsCode, updateShopifyDiscountsCodeError } =
    useShopifyDiscountsCodesUpdate();
  const { deleteShopifyDiscountsCode, deleteShopifyDiscountsCodeError } =
    useShopifyDiscountsCodesDelete();
  const [selectedShopifyDiscount, setSelectedShopifyDiscount] =
    useState<ShopifyDiscountsCode | null>(null);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [deletingDiscount, setDeletingDiscount] =
    useState<ShopifyDiscountsCode | null>(null);

  const {
    account: shopifyAccount,
    loading: shopifyAccountLoading,
    goToConnectAccount,
    connectAccountInProgress,
    connectAccountInCalled,
  } = useShopifyAccount();

  const handleEditorClose = useCallback(() => {
    if (isCreating) {
      setIsCreating(false);
    }
    if (selectedShopifyDiscount) {
      setSelectedShopifyDiscount(null);
    }

    sendEvent({
      category: 'shopify discount',
      action: 'discount editor closed',
      propertyBag: { botId, flowId },
    });
  }, [botId, flowId, isCreating, selectedShopifyDiscount]);

  const handleDeleteModalClose = useCallback(() => {
    if (deletingDiscount) {
      setDeletingDiscount(null);
    }
  }, [deletingDiscount]);

  useEffect(() => {
    if (
      deleteShopifyDiscountsCodeError ||
      updateShopifyDiscountsCodeError ||
      createShopifyDiscountsCodeError
    ) {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: t('common.ToasterMessages.somethingWentWrong'),
        },
      });
    }
  }, [
    deleteShopifyDiscountsCodeError,
    updateShopifyDiscountsCodeError,
    createShopifyDiscountsCodeError,
    t,
  ]);

  useEffect(() => {
    sendEventOnceByCategoryAndAction({
      category: 'shopify discount',
      action: 'popup shown',
      propertyBag: { botId, flowId },
    });
  }, [botId, flowId]);

  useEffect(() => {
    if (botId && flowId) {
      sendEvent({
        category: 'shopify discount',
        action: 'popup opened',
        propertyBag: { botId, flowId },
      });
    }
  }, [botId, flowId]);

  if (isCreating || selectedShopifyDiscount) {
    return (
      <Modal onDismiss={handleEditorClose} isOpen>
        <ShopifyDiscountsCodeEditor
          discountCode={
            selectedShopifyDiscount ??
            (SHOPIFY_DISCOUNT_DEFAULT as ShopifyDiscountsCode)
          }
          onSubmit={(discountCode) => {
            if (isCreating) {
              sendEvent({
                category: 'shopify discount',
                action: 'discount created',
                propertyBag: { botId, flowId },
              });
              createShopifyDiscountsCode(discountCode);
            } else {
              sendEvent({
                category: 'shopify discount',
                action: 'discount updated',
                propertyBag: { botId, flowId },
              });
              updateShopifyDiscountsCode(discountCode);
            }
            handleEditorClose();
          }}
          onClose={handleEditorClose}
        />
      </Modal>
    );
  }

  if (deletingDiscount) {
    return (
      <Modal onDismiss={handleDeleteModalClose} isOpen>
        <ShopifyDiscountsCodeDeleteDialog
          code={deletingDiscount.code}
          onDelete={() => {
            sendEvent({
              category: 'shopify discount',
              action: 'discount deleted',
              propertyBag: { botId, flowId },
            });
            deleteShopifyDiscountsCode(
              deletingDiscount.price_rule_id,
              deletingDiscount.code,
            );
            handleDeleteModalClose();
          }}
          onDismiss={handleDeleteModalClose}
        />
      </Modal>
    );
  }

  return (
    <Portal>
      <Popper
        referenceElement={referenceElement}
        placement={placement}
        modifiers={{
          offset: {
            offset,
          },
        }}
      >
        {({ ref: popperRef, style, scheduleUpdate }) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div style={style} ref={popperRef} className={css.popup}>
            <ShopifyDiscountsCodeSelect
              discountTypes={discountTypes}
              codes={shopifyDiscountsCodesList}
              error={!!shopifyDiscountsCodesListError}
              loading={
                shopifyDiscountsCodesListLoading || shopifyAccountLoading
              }
              refetch={shopifyDiscountsCodesListRefetch}
              onPermissionRequest={() => {
                goToConnectAccount(
                  shopifyAccount!.domain,
                  getTabLink(BotTabs.flows, botId, {
                    flowId,
                  }),
                );
                sendEvent({
                  category: 'shopify discount',
                  action: 'permissions requested',
                  propertyBag: { botId, flowId },
                });
              }}
              hasPermission={
                !!(
                  shopifyAccount &&
                  getShopifyFeatureStatus(
                    shopifyAccount,
                    ShopifyFeatureType.discounts,
                  )
                )
              }
              permissionRequestLoading={
                connectAccountInProgress || connectAccountInCalled
              }
              onCreate={() => {
                sendEvent({
                  category: 'shopify discount',
                  action: 'discount editor opened',
                  propertyBag: { botId, flowId, isCreation: true },
                });
                setIsCreating(true);
              }}
              onChange={(discountCode) => {
                sendEvent({
                  category: 'shopify discount',
                  action: 'discount editor opened',
                  propertyBag: { botId, flowId, isCreation: false },
                });
                setSelectedShopifyDiscount(discountCode);
              }}
              onDelete={(discountCode) => {
                setDeletingDiscount(discountCode);
                sendEvent({
                  category: 'shopify discount',
                  action: 'discount delete click',
                  propertyBag: { botId, flowId },
                });
              }}
              onSelect={(discountCode) => {
                sendEvent({
                  category: 'shopify discount',
                  action: 'discount selected',
                  propertyBag: { botId, flowId },
                });
                onSelect(
                  discountCode.id === NONE_CODE_ID ? null : discountCode,
                );
              }}
              onClickOutside={() => {
                sendEvent({
                  category: 'shopify discount',
                  action: 'popup closed',
                  propertyBag: { botId, flowId },
                });
                onClose();
              }}
              onDiscountListChange={scheduleUpdate}
              onClose={onClose}
            />
          </div>
        )}
      </Popper>
    </Portal>
  );
};
