import React from 'react';

import { CheckBox } from '../../../../modern-ui/CheckBox';

import * as css from './Tos.css';

type TosProps = {
  termsChecked: boolean;
  setTermsChecked(value: boolean): void;
  onChange(): void;
};
export const Tos: React.FC<TosProps> = ({
  termsChecked,
  setTermsChecked,
  onChange,
}) => (
  <div className={css.tos}>
    <CheckBox
      checked={termsChecked}
      onChange={() => {
        setTermsChecked(!termsChecked);
        onChange();
      }}
    />
    <span className={css.infoText}>
      <span>{window.i18next.t('Tos-JSXText-4626-i-accept-the')}</span>
      <a
        href="https://chatfuel.com/files/TermsOfUse.pdf"
        target="_blank"
        rel="noreferrer noopener"
        className={css.termsLink}
      >
        {window.i18next.t('Tos-JSXText--135-terms-of-service')}
      </a>
    </span>
  </div>
);
