import React, { useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';

interface ChatErrorProps {
  onTryAgainClick(): void;
}

export const ChatError: React.FC<ChatErrorProps> = ({ onTryAgainClick }) => {
  const { t } = useSafeTranslation();

  useEffect(() => {
    sendEvent({
      category: 'test in chatfuel',
      action: 'error shown',
    });
  }, []);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ flex: '1' }}
    >
      <Type size="15px_DEPRECATED">
        {t('modernComponents.TestThis.ChatError.somethingWrong')}
      </Type>
      <Spacer factor={5} />
      <Button
        intent="secondary"
        onClick={onTryAgainClick}
        data-testid="test-this_try-again"
      >
        {t('modernComponents.TestThis.ChatError.tryAgain')}
      </Button>
    </Flex>
  );
};
