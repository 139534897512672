import { formatPricePromo } from '@utils/Format/priceFormatter';
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { getStripePromise } from 'cf-common/src/utils/Stripe/getStripePromise';
import { PaymentInterval } from '@utils/Payment/paymentInterval';
import { PlanDisplayPrice } from '@components/Payments';
import { DefaultPaymentModal } from './DefaultPaymentModal';

interface PaymentsModalProps {
  onDismiss: () => void;
  onSuccess: () => void;
  amount: number;
  currency?: string;
  planName: string;
  intervalName: PaymentInterval;
}

export const PaymentsModal: React.FC<PaymentsModalProps> = ({
  onDismiss,
  onSuccess,
  amount,
  currency,
  planName,
  intervalName,
}) => {
  const stripe = getStripePromise(window.CHATFUEL_CONFIG.STRIPE_PUBLIC_KEY!);

  return (
    <Elements stripe={stripe}>
      <DefaultPaymentModal
        header={window.i18next.t(
          'PaymentsModal-string-1047-sign-up-for-premium',
        )}
        submitText={window.i18next.t(
          'PaymentsModal-string--103-submit-payment',
        )}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
      >
        <Flex justifyContent="space-between" alignItems="flex-end">
          <div style={{ paddingBottom: 1 }}>
            <Type color="greyDark" size="15px_DEPRECATED">
              {window.i18next.t('PaymentsModal-JSXText--134-your-status')}
            </Type>
            <br />
            <Type size="18px">{planName}</Type>
          </div>
          <PlanDisplayPrice
            style={{ lineHeight: 1 }}
            displayAmount={formatPricePromo(amount / 100, { currency })}
            intervalName={intervalName}
            displayAmountFontSize={48}
            intervalNameFontSize={14}
          />
        </Flex>
        <Spacer />
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'PaymentsModal-JSXText--677-enter-your-card-details-below-to-submit-your-first-payment-for-future-payments-well-automatically-charge-your-card',
          )}
        </Type>
      </DefaultPaymentModal>
    </Elements>
  );
};
