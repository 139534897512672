import React from 'react';
import { Modal } from '@ui/Modal';

import { BillingDetailsDialog } from './BillingDetailsDialog';
import { BillingDetailsModalProps } from './types';

export const BillingDetailsModal: React.FC<BillingDetailsModalProps> = (
  props,
) => {
  return (
    <Modal onDismiss={props.onRequestClose}>
      <BillingDetailsDialog {...props} />
    </Modal>
  );
};
