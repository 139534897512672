import React from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { Modal } from '../../../../modern-ui/Modal';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogCloseButton,
} from '../../../../modern-ui/Dialog';
import { Type } from '../../../../modern-ui/Type';
import { Flex } from '../../../../modern-ui/Flex';
import { Spacer } from '../../../../modern-ui/Spacer';
import { Button, ButtonIntent } from '../../../../modern-ui/_deprecated/Button';
import emojiImagePath from './face-with-party-horn-and-party-hat.png';
import { AdminNameQuery } from './@types/AdminNameQuery';

const ADMIN_NAME_QUERY = gql`
  query AdminNameQuery {
    me {
      id
      name
    }
  }
`;

export const SuccessModal: React.FC<{ onDismiss: () => void }> = ({
  onDismiss,
}) => {
  return (
    <Modal onDismiss={onDismiss}>
      <Dialog>
        <Query<AdminNameQuery> query={ADMIN_NAME_QUERY}>
          {({ data, loading, error }) => {
            if (error) {
              throw error;
            }
            if (loading || !data) {
              return null;
            }
            return (
              <DialogContent>
                <DialogCloseButton
                  onClick={onDismiss}
                  aria-label={window.i18next.t(
                    'SuccessModal-string-6520-close',
                  )}
                />
                <Spacer factor={15} />
                <Flex
                  flexDirection="column"
                  fullHeight
                  alignItems="center"
                  justifyContent="center"
                >
                  <picture>
                    <source srcSet={`${emojiImagePath} 1x`} />
                    <img
                      height="100"
                      width="100"
                      src={emojiImagePath}
                      alt={window.i18next.t(
                        'SuccessModal-string--194-congratulations',
                      )}
                    />
                  </picture>
                </Flex>
                <Spacer factor={15} />
                <DialogHeading>
                  {window.i18next.t(
                    'SuccessModal-JSXText-1074-congratulations',
                  )}
                  {data.me.name}!
                </DialogHeading>
                <Type as="p" size="15px_DEPRECATED">
                  {window.i18next.t(
                    'SuccessModal-JSXText-7379-youre-now-a-premium-user-enjoy-full-access-to',
                  )}
                </Type>
                <Type as="p" size="15px_DEPRECATED">
                  {window.i18next.t(
                    'SuccessModal-JSXText--150-quick-start-training-premium-support-and-advanced',
                  )}
                </Type>
                <Type size="15px_DEPRECATED">
                  {window.i18next.t('SuccessModal-JSXText--420-features')}
                </Type>
                <Spacer factor={10} />
                <div style={{ textAlign: 'right' }}>
                  <Button intent={ButtonIntent.primary} onClick={onDismiss}>
                    {window.i18next.t('SuccessModal-JSXText-1873-get-started')}
                  </Button>
                </div>
              </DialogContent>
            );
          }}
        </Query>
      </Dialog>
    </Modal>
  );
};
