import React, { useCallback } from 'react';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { showWhatsappImportUsersDialog } from './component/WhatsappImportUsersDialog/showWhatsappImportUsersDialog';
import { sendEvent } from '@utils/Analytics';
import { ReactComponent as ImportIcon } from './images/import-icon.svg';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';

export interface WhatsappImportUserProps {
  onDone: VoidFunction;
}

export const WhatsappImportUser: React.FC<WhatsappImportUserProps> = ({
  onDone,
}) => {
  const { t } = useSafeTranslation();

  const showImportDialogClickHandle = useCallback(() => {
    showWhatsappImportUsersDialog(onDone);
    sendEvent({
      category: 'people tab',
      label: 'whatsapp user import',
      action: 'show dialog button click',
    });
  }, [onDone]);

  return (
    <Button
      intent="primary"
      onClick={showImportDialogClickHandle}
      data-testid="people-tab_whatsapp-user-import_show-dialog-button"
    >
      <Flex gapFactor={1} alignItems="center">
        <ImportIcon />
        <Type color="white">
          {t('modernComponents.WhatsAppImportUser.buttonTitle')}
        </Type>
      </Flex>
    </Button>
  );
};
