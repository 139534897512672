import React from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { Modal } from '../../modern-ui/Modal';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogCloseButton,
  DialogFooter,
} from '../../modern-ui/Dialog';
import { OneBlockSelector } from '../OneBlockSelector';
import client from '../../common/services/ApolloService';
import { PreventAngularGlobalClickEvent } from '../../modern-ui/PreventAngularGlobalClickEvent';
import diagram from './images/diagram.png';
import * as css from './SelectBlockPopup.css';

export interface ISelectBlockPopupProps {
  botId: string;
  currentBlockId: string;
  onRequestClose: () => void;
  onBlockSelected: (blockId: string) => void;
}

export class SelectBlockPopup extends React.Component<
  ISelectBlockPopupProps,
  {}
> {
  state = {
    blockId: null,
  };

  handleSelectBlock = (blockId: string) => {
    this.setState({ blockId });
  };

  handleSubmit = () => {
    const { onBlockSelected, onRequestClose } = this.props;
    const { blockId } = this.state;

    if (blockId) {
      onBlockSelected(blockId!);
    }

    onRequestClose();
  };

  render() {
    const { botId, currentBlockId, onRequestClose } = this.props;
    const { blockId } = this.state;
    const selectedBlockId = blockId || currentBlockId;
    return (
      <ApolloProvider client={client}>
        <PreventAngularGlobalClickEvent>
          <Modal onDismiss={onRequestClose}>
            <Dialog className={css.dialog}>
              <DialogContent>
                <DialogHeading>
                  <span>
                    {window.i18next.t(
                      'SelectBlockPopup-JSXText--729-select-a-block-a-person-will-receive',
                    )}
                  </span>
                  <br />
                  <span>
                    {window.i18next.t(
                      'SelectBlockPopup-JSXText-5307-after-clicking-the-button',
                    )}
                  </span>
                </DialogHeading>
                <div className={css.content}>
                  <img
                    src={diagram}
                    className={css.diagram}
                    alt={window.i18next.t(
                      'SelectBlockPopup-string--600-use-this-option-to-add-connections-between-blocks',
                    )}
                  />
                  <div className={css.text}>
                    {window.i18next.t(
                      'SelectBlockPopup-JSXText--431-buttons-are-like-web-links-when-users-click-on-a-button-they-receive-a-block-connected-to-that-button',
                    )}
                  </div>
                  <div>
                    <OneBlockSelector
                      botId={botId}
                      blockId={selectedBlockId}
                      onBlockSelected={this.handleSelectBlock}
                      placeholder={window.i18next.t(
                        'SelectBlockPopup-string--658-enter-block-name-you-want-to-send',
                      )}
                    />
                  </div>
                </div>
              </DialogContent>
              <DialogFooter>
                <Button
                  intent={ButtonIntent.primary}
                  onClick={this.handleSubmit}
                  wide
                >
                  {window.i18next.t('SelectBlockPopup-JSXText-6057-done')}
                </Button>
              </DialogFooter>
              <DialogCloseButton
                className={css.closeButton}
                onClick={onRequestClose}
              />
            </Dialog>
          </Modal>
        </PreventAngularGlobalClickEvent>
      </ApolloProvider>
    );
  }
}
