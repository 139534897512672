import React from 'react';
import { TransferPlanDialogPagesList } from './components/TransferPlanDialogPagesList';
import { useTransferPlanDialog } from './hooks/useTransferPlanDialog';
import { CenteredLoader } from '@ui/Loader';
import * as css from './TransferPlanDialog.css';
import { TransferPlanDialogEmpty } from './components/TransferPlanDialogEmpty';

interface TransferPlanDialogProps {
  onDismiss: () => void;
}

export const TransferPlanDialog: React.FC<TransferPlanDialogProps> = ({
  onDismiss,
}) => {
  const { loading, pages, refetchPages, transferPlan, error } =
    useTransferPlanDialog();

  if (error) {
    onDismiss();
  }

  if (loading) {
    return <CenteredLoader className={css.loader} />;
  }

  return pages?.length ? (
    <TransferPlanDialogPagesList
      pages={pages}
      onDismiss={onDismiss}
      onRefetchPagesRequest={refetchPages}
      onTranferRequest={(pageToId) => {
        onDismiss();
        transferPlan(pageToId);
      }}
    />
  ) : (
    <TransferPlanDialogEmpty
      onDismiss={onDismiss}
      onRefetchPagesRequest={refetchPages}
    />
  );
};
