import React, { useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { sendEvent } from '@utils/Analytics';
import {
  useConnectInstagram,
  useInstagramAccount,
} from '@utils/Data/Instagram/BotAccount';
import { useCurrentBotId } from '@utils/Routing';

import { Avatar } from '@ui/Avatar';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';

import accountPlaceholder from './images/account-placeholder.svg';

import * as css from './ConnectInstagramAccount.css';

const getFollowersNumberString = (num: number) => {
  if (num / 1000000 > 1) return `${(num / 1000000).toFixed(1)}M`;
  if (num / 1000 > 1) return `${(num / 1000).toFixed(1)}K`;
  return num;
};

interface ConnectInstagramAccountProps {
  autoConnect?: boolean;
  onConnect(error?: any): void;
  onConnectAnotherAccount(): void;
}
export const ConnectInstagramAccount: React.FC<ConnectInstagramAccountProps> =
  ({ autoConnect, onConnect, onConnectAnotherAccount }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId() ?? '';
    const { instagramConnectedAccount } = useInstagramAccount(botId);
    const { connectInstagram, instagramIsConnecting } = useConnectInstagram(
      botId,
      onConnect,
      onConnect,
    );

    const [isAutoConnectionStarted, setIsAutoConnectionStarted] =
      useState(false);
    useEffect(() => {
      if (autoConnect && !isAutoConnectionStarted) {
        sendEvent({
          category: 'instagram connect popup',
          action: 'autoconnect',
          label: 'account',
          propertyBag: { botId },
        });
        setIsAutoConnectionStarted(true);
        connectInstagram();
      }
    }, [autoConnect, botId, connectInstagram, isAutoConnectionStarted]);

    const handleConnectAnotherAccount = () => {
      onConnectAnotherAccount();
      sendEvent({
        category: 'instagram connect popup',
        action: 'click connect another account',
        label: 'account',
        propertyBag: { botId },
      });
    };

    const handleConnectInstagram = () => {
      connectInstagram();
      sendEvent({
        category: 'instagram connect popup',
        action: 'click connect account',
        label: 'account',
        propertyBag: { botId },
      });
    };

    return (
      <ConnectInstagramDialogContent
        loading={autoConnect || instagramIsConnecting}
        header={t('common.Instagram.dialog.account.header')}
        footerRight={
          <Button
            data-testid="instagram-connect-dialog__account__connect-another-account-button"
            intent="secondary"
            icon={<Icon icon="plus" size="14px" />}
            onClick={handleConnectAnotherAccount}
          >
            {t('common.Instagram.dialog.account.connectAnotherAccountButton')}
          </Button>
        }
        data-testid="instagram-connect-dialog__account"
      >
        <Flex justifyContent="space-between" className={css.container}>
          <Flex>
            <Avatar
              src={instagramConnectedAccount.picture ?? accountPlaceholder}
              size={40}
            />
            <Spacer horizontalFactor={2} />
            <Flex flexDirection="column">
              <Type size="15px" weight="semibold">
                {instagramConnectedAccount.handle}
              </Type>
              <Spacer factor={1} />
              <Type size="12px">
                {t('common.Instagram.dialog.account.followers', {
                  followersNumber: getFollowersNumberString(
                    instagramConnectedAccount.followers,
                  ),
                })}
              </Type>
            </Flex>
          </Flex>
          <Button
            intent="primary"
            onClick={handleConnectInstagram}
            data-testid="instagram-connect-dialog__account__next-button"
            loading={instagramIsConnecting}
          >
            {t('common.Instagram.dialog.account.nextButton')}
          </Button>
        </Flex>
      </ConnectInstagramDialogContent>
    );
  };
