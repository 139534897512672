import React, { useMemo } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getI18ZapierKey } from '../../FlowBuilder/views/components/Zapier/utils/getI18ZapierKey';
import image1Src from './images/TestZapierDialog_1.png';
import image2Src from './images/TestZapierDialog_2.png';
import image3Src from './images/TestZapierDialog_3.png';
import image4Src from './images/TestZapierDialog_4.png';
import { TestSteps } from './components';

export const TestZapierDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => {
  const { t } = useSafeTranslation();

  const TEST_STEPS = useMemo(
    () => [
      {
        text: <>1. {t(getI18ZapierKey('testDialog.step1'))}</>,
        src: image1Src,
      },
      {
        text: <>2. {t(getI18ZapierKey('testDialog.step2'))}</>,
        src: image2Src,
      },
      {
        text: <>3. {t(getI18ZapierKey('testDialog.step3'))}</>,
        src: image3Src,
      },
      {
        text: <>4. {t(getI18ZapierKey('testDialog.step4'))}</>,
        src: image4Src,
      },
    ],
    [t],
  );

  return (
    <DefaultDialog
      dialogStyle={{
        width: 572,
      }}
      header={t(getI18ZapierKey('testYourZapierEntryPoint'))}
      onDismiss={onRequestClose}
    >
      <TestSteps steps={TEST_STEPS} />
    </DefaultDialog>
  );
};
