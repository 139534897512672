import { useState } from 'react';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { usePaymentFlowState } from '@utils/Payment/hooks/usePaymentFlowState';
import { PaymentFlowState } from '@utils/Payment/constants';
import { usePremiumIntentSetup } from './usePremiumPaymentIntent';
import { usePaymentMethodSetup } from './usePremiumPaymentAttach';
import { useStripeCardConfirmation } from 'cf-common/src/utils/Stripe/hooks/useStripeCardConfirmation';
import { use3DSCallback } from 'cf-common/src/utils/Stripe/hooks/use3DSCallback';
import { PaymentFlowError, PaymentFlowErrorKind } from 'cf-common/src/utils/Stripe/errors';

/**
 *
 * @deprecated please use from @components/Payments/PaymentDialog/hooks/usePremiumPayment instead
 */
export const usePremiumPayment = () => {
  const { paymentFlowState, error, setFlowState, failWith } =
    usePaymentFlowState();

  const [errorMessage, setErrorMessage] = useState('');

  const { setupIntent, clientSecret } = usePremiumIntentSetup();

  const { setupCard, retryCard, link3DSecure, stripeIsReady } =
    useStripeCardConfirmation(true);

  const { attachPaymentMethod } = usePaymentMethodSetup();

  use3DSCallback(async () => {
    setFlowState(PaymentFlowState.confirming3DSecure);

    try {
      const { paymentMethodId } = await retryCard(clientSecret!);

      await attachPaymentMethod(paymentMethodId);

      setFlowState(PaymentFlowState.success);
    } catch (exception) {
      if (exception instanceof PaymentFlowError) {
        failWith(exception.errorData);
        setErrorMessage(exception.message);
      } else {
        failWith({ kind: PaymentFlowErrorKind.unhandledError });
        setErrorMessage(
          window.i18next.t(
            'usePremiumPayment-string-1033-something-went-wrong',
          ),
        );
        log.error({
          error: exception,
          msg: 'Unhandled payment error',
          data: {
            label: 'payments',
            requestId: getRequestIdFromApolloError(exception as any),
          },
        });
      }
    }
  });

  const submitPayment = async () => {
    const flowNotInIdleOrErrorState =
      paymentFlowState !== PaymentFlowState.idle &&
      paymentFlowState !== PaymentFlowState.error;

    if (flowNotInIdleOrErrorState || !stripeIsReady) return;

    setFlowState(PaymentFlowState.submitting);
    setErrorMessage('');

    try {
      const { clientSecret } = await setupIntent();
      const { redirectIsRequired, paymentMethodId } = await setupCard(
        clientSecret!,
      );

      if ((window as any).cfStop) {
        failWith({ kind: PaymentFlowErrorKind.unhandledError });
        setErrorMessage(
          window.i18next.t('usePremiumPayment-string-2422-testing'),
        );
        return;
      }

      if (redirectIsRequired) {
        setFlowState(PaymentFlowState.redirecting3DSecure);
      } else {
        await attachPaymentMethod(paymentMethodId!);
        setFlowState(PaymentFlowState.success);
      }
    } catch (exception) {
      if (exception instanceof PaymentFlowError) {
        failWith(exception.errorData);
        setErrorMessage(exception.message);
      } else {
        failWith({ kind: PaymentFlowErrorKind.unhandledError });
        setErrorMessage(
          window.i18next.t(
            'usePremiumPayment-string-1033-something-went-wrong',
          ),
        );
        log.error({
          error: exception,
          msg: 'Unhandled payment error',
          data: {
            label: 'payments',
            requestId: getRequestIdFromApolloError(exception as any),
          },
        });
      }
    }
  };

  return {
    stripeIsReady,
    paymentFlowState,
    link3DSecure,
    error,
    errorMessage,
    submitPayment,
  };
};
