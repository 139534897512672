import { ApolloError } from 'apollo-client';
import { useEffect } from 'react';
import { ComposeTabId } from '../consts';

interface ItemLoadOnTabChangeParams {
  activeTabId: string;
  loading: boolean;
  loadMore(): Promise<any>;
  items?: any[]; // cause we need only array iterator change
  cursors?: any[] | null;
  searchString?: string;
  error?: ApolloError;
}

export const useItemLoadOnTabChange = ({
  activeTabId,
  loading,
  loadMore,
  items,
  cursors,
  searchString,
  error,
}: ItemLoadOnTabChangeParams) => {
  useEffect(() => {
    if (
      (!!searchString?.trim() || !items?.length) &&
      !loading &&
      activeTabId !== ComposeTabId.postUrl &&
      !error
    ) {
      loadMore();
    }
  }, [searchString, loading, cursors, activeTabId, loadMore, items, error]);
};
