import debounce from 'lodash-es/debounce';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import { sendEvent } from '@utils/Analytics';
import React, { useCallback } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { commentsAutoreplyEntryPointFragment_config_posts as Post } from '../../Plugins/CommentsAutoreplyEntryPoint/@types/commentsAutoreplyEntryPointFragment';
import { PluginType } from '../../Plugins/common/PluginTypes';
import {
  ChooseItemFromListContainerDialog,
  ComposeTabId,
} from '../ChooseItemFromListDialog';
import { MESSENGER_TABS_ITEMS } from './consts';
import { FacebookCommentAutoreplyPostsList } from './FacebookCommentAutoreplyPostsList';
import { validateFacebookPostUrl } from './helpers';
import { useGetPostByUrl } from './hooks/useGetPostByUrl';
import { PostSelectorDialog } from '../types';

export interface ChooseFacebookCommentAutoreplyPostsDialogProps
  extends PostSelectorDialog<Post[], Post> {}

export const ChooseFacebookCommentAutoreplyPostsDialog: React.FC<ChooseFacebookCommentAutoreplyPostsDialogProps> =
  ({ onDismiss, onSubmit, onPostRemove, selectedPosts }) => {
    const { t } = useSafeTranslation();

    const { getPostByUrl, postByUrl, postByUrlLoading } = useGetPostByUrl();
    const debouncedGetPostByUrl = useCallback(
      debounce(
        (value: string) => {
          getPostByUrl(value);
          sendEvent({
            category: 'flows',
            action: 'Find post by url',
            label: PluginType.comments_autoreply_entry_point,
          });
        },
        300,
        { leading: true },
      ),
      [getPostByUrl],
    );

    return (
      <ChooseItemFromListContainerDialog
        header={t(
          'modernComponents.ChooseFacebookCommentAutoreplyPostsDialog.header',
        )}
        tabs={MESSENGER_TABS_ITEMS()}
        onDismiss={onDismiss}
        onTabChange={(tabId) => {
          sendEvent({
            category: 'flows',
            action: 'Tab change',
            label: PluginType.comments_autoreply_entry_point,
            propertyBag: {
              tabId,
            },
          });
        }}
        renderInput={({
          activeTabId,
          searchString,
          onChange,
          setActiveTabId,
        }) => (
          <Input
            error={
              activeTabId === ComposeTabId.postUrl &&
              !!searchString &&
              !validateFacebookPostUrl(searchString)
            }
            renderIcon={() => <Icon icon="search" size="28px" color="grey" />}
            placeholder={
              activeTabId === ComposeTabId.postUrl
                ? t(
                    'modernComponents.ChooseFacebookCommentAutoreplyPostsDialog.searchInputPostUrlTabPlaceholderText',
                  )
                : t(
                    'modernComponents.ChooseFacebookCommentAutoreplyPostsDialog.searchInputPlaceholderText',
                  )
            }
            value={searchString}
            onChange={({ currentTarget: { value } }) => {
              onChange(value);
              if (validateFacebookPostUrl(value)) {
                setActiveTabId(ComposeTabId.postUrl);
                debouncedGetPostByUrl(value);
              }
            }}
          />
        )}
      >
        {({ activeTabId, searchString }) => (
          <FacebookCommentAutoreplyPostsList
            addedPosts={selectedPosts}
            activeTabId={activeTabId}
            searchString={searchString}
            onSubmit={onSubmit}
            // @ts-expect-error fix types
            onRemovedPost={onPostRemove}
            postByUrl={postByUrl}
            postByUrlLoading={postByUrlLoading}
          />
        )}
      </ChooseItemFromListContainerDialog>
    );
  };
