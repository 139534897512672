import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from '@utils/Analytics';
import { removeTypename } from '@utils/GQL/utils';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Loader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import {
  BillingDetailsForm,
  BillingDetailsFormProps,
  FormState,
} from '../../../Payments/BillingDetails';

import { BillingDetailsModalProps } from './types';

import * as css from './BillingDetailsModal.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useBillingDetailsData, useUpdateBotBillingDetails } from './hooks';
import { toaster } from '@services/MessageService';

const DEFAULT_BILLING_DETAILS = {
  city: '',
  company_name: '',
  country: '',
  email: '',
  postcode: '',
  street: '',
  state: '',
  tax_id: '',
};

export const BillingDetailsDialog: React.FC<BillingDetailsModalProps> = ({
  botId,
  onRequestClose,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const { billingDetailsData, billingDetailsLoading, billingDetailsError } =
    useBillingDetailsData(botId);
  const [updateBotBillingDetails, { loading }] = useUpdateBotBillingDetails();

  const billingDetails = removeTypename(
    billingDetailsData?.billingDetails ?? DEFAULT_BILLING_DETAILS,
  );

  const handleSubmit: BillingDetailsFormProps['onSubmit'] = (values) => {
    sendEvent({
      category: 'pro plan payments',
      action: 'update billing address',
      label: 'confirm',
    });

    if (!billingDetailsData?.billingDetails) {
      return;
    }

    updateBotBillingDetails({
      botId: billingDetailsData.id,
      details: values,
    })
      .then(() =>
        toaster.default({
          payload: {
            message: t('BillingDetailsForm-JSXText--183-changes-saved'),
          },
        }),
      )
      .catch(() =>
        toaster.error({
          payload: {
            message: t(
              'BillingDetailsForm-JSXText-1677-error-please-try-later',
            ),
          },
        }),
      );
  };

  const getState = () => {
    if (loading) return FormState.loading;
    return FormState.pending;
  };

  return (
    <DefaultDialog
      className={css.billingDetailsDialog}
      mobileAdaptive={isSmallScreenSize}
      header={t('BillingDetailsModal-JSXText-2023-billing-address')}
      onDismiss={onRequestClose}
    >
      <Type size="15px">
        {t(
          'BillingDetailsModal-JSXText-3576-these-billing-details-appear-in-invoices',
        )}
      </Type>
      <Spacer factor={2} />
      {billingDetailsLoading && (
        <Flex justifyContent="center" style={{ width: '100%' }}>
          <Loader />
        </Flex>
      )}
      {(billingDetailsError || !billingDetailsData?.billingDetails) && (
        <Type size="15px">
          {t('BillingDetailsModal-JSXText--199-error-please-try-later')}
        </Type>
      )}
      {billingDetailsData?.billingDetails && (
        <BillingDetailsForm
          initialValues={billingDetails}
          onSubmit={handleSubmit}
          state={getState()}
          withoutShadow
        />
      )}
    </DefaultDialog>
  );
};
