import { Button } from '@ui/Button';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Translation } from '@translations';

import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';

import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';

import * as css from './ConnectInstagramRequirements.css';

interface ConnectInstagramRequirementsProps {
  onNext(): void;
}
export const ConnectInstagramRequirements: React.FC<ConnectInstagramRequirementsProps> =
  ({ onNext }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId();
    const handleNext = () => {
      onNext();
      sendEvent({
        category: 'instagram connect popup',
        action: 'click next',
        label: 'requirements',
        propertyBag: { botId },
      });
    };
    return (
      <ConnectInstagramDialogContent
        header={t('common.Instagram.dialog.requirements.header')}
        footerRight={
          <Button
            intent="primary"
            onClick={handleNext}
            data-testid="instagram-connect-dialog__permissions__requirements__next-button"
          >
            {t('common.Instagram.dialog.requirements.nextButton')}
          </Button>
        }
        data-testid="instagram-connect-dialog__permissions__requirements"
      >
        <Flex flexDirection="column">
          {[1, 2, 3, 4].map((reason) => (
            <Flex key={reason} className={css.reason} alignItems="center">
              <Icon icon="check" size="20px" />
              <Spacer horizontalFactor={3} />
              <Type size="15px">
                {t(
                  `common.Instagram.dialog.requirements.reason${reason}` as Translation,
                )}
              </Type>
            </Flex>
          ))}
        </Flex>
      </ConnectInstagramDialogContent>
    );
  };
