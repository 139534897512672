import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { sendEvent } from '@utils/Analytics';
import { useGoToGetPermissions } from '@utils/FacebookPages/useGoToGetPermissions';
import { useCurrentBotId } from '@utils/Routing';

import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';

interface ConnectInstagramGivePermissionsProps {
  onBeforeGivePermission(): void;
  onAfterGivePermission(): void;
}
export const ConnectInstagramGivePermissions: React.FC<ConnectInstagramGivePermissionsProps> =
  ({ onBeforeGivePermission, onAfterGivePermission }) => {
    const { t } = useSafeTranslation();
    const { goToGetInstagramPermissions } = useGoToGetPermissions();
    const botId = useCurrentBotId();
    const handleGivePermissions = () => {
      sendEvent({
        category: 'instagram connect popup',
        action: 'click give permissions',
        label: 'give permissions',
        propertyBag: { botId },
      });
      onBeforeGivePermission();
      goToGetInstagramPermissions();
      onAfterGivePermission();
    };
    return (
      <ConnectInstagramDialogContent
        header={t('common.Instagram.dialog.givePermissions.header')}
        footerRight={
          <Button
            intent="secondary"
            data-testid="instagram-connect-dialog__give-permissions__create-instagram-account-button"
            icon={<Icon icon="plus" size="14px" />}
            onClick={() => {
              sendEvent({
                category: 'instagram connect popup',
                action: 'click create instagram account',
                label: 'give permissions',
                propertyBag: { botId },
              });
              window.open(
                'https://help.instagram.com/155940534568753',
                '_blank',
                'noopener noreferrer',
              );
            }}
          >
            {t('common.Instagram.dialog.givePermissions.createIg')}
          </Button>
        }
        data-testid="instagram-connect-dialog__give-permissions"
      >
        <Flex justifyContent="space-between">
          <Type size="15px">
            {t('common.Instagram.dialog.givePermissions.content')}
          </Type>
          <Spacer horizontalFactor={2} />
          <Button
            intent="primary"
            onClick={handleGivePermissions}
            data-testid="instagram-connect-dialog__give-permissions__give-permissions-button"
            fitToText
          >
            {t('common.Instagram.dialog.givePermissions.givePermissionButton')}
          </Button>
        </Flex>
      </ConnectInstagramDialogContent>
    );
  };
