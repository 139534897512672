import { useCallback, useEffect, useState, useRef } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { START_BOT_TESTING } from '@utils/Data/BotTesting/queries';
import { isMongoObjectId } from 'cf-common/src/utils/mongo/isMongoObjectId';
import { Platform } from '@globals';
import {
  StartBotTestingMutation,
  StartBotTestingMutationVariables,
} from '@utils/Data/BotTesting/@types/StartBotTestingMutation';
import {
  testThisBlockEventEmitter,
  TEST_THIS_BLOCK_START_TEST_REQUEST,
  TestThisBlockPayload,
  TEST_THIS_BLOCK_DISMISS_REQUEST,
} from './events';
import { useCurrentBotId } from '@utils/Routing';

interface UseTestThisActionsArgs {
  tabId: string | undefined;
  platform: Platform;
  chatIsShown: boolean;
  botTesting: boolean;
  toggleChatShown?: (shown: boolean) => void;
}

export const useTestThisActions = ({
  tabId,
  platform,
  chatIsShown,
  botTesting,
  toggleChatShown,
}: UseTestThisActionsArgs) => {
  const blockIdRef = useRef<string>();
  const [currentTabId, setCurrentTabId] = useState<string>();
  const botId = useCurrentBotId();

  const refId = botTesting ? undefined : (blockIdRef.current || tabId) ?? '';

  const [startTesting, { loading, error }] = useMutation<
    StartBotTestingMutation,
    StartBotTestingMutationVariables
  >(START_BOT_TESTING);

  const handleTestThisFlowClick = useCallback(
    (nextBlockId?: string, targetPlatform?: Platform) => {
      blockIdRef.current = nextBlockId;
      const nextRefId = nextBlockId || tabId;
      if ((nextRefId && isMongoObjectId(nextRefId)) || botTesting) {
        startTesting({
          variables: {
            botTestingOptions: {
              refId: nextRefId,
              platform: targetPlatform || platform,
              botId,
            },
          },
        });
        toggleChatShown?.(true);
      }
    },
    [tabId, botTesting, startTesting, platform, botId, toggleChatShown],
  );

  const handleRefresh = useCallback(() => {
    handleTestThisFlowClick(blockIdRef.current);
  }, [handleTestThisFlowClick, blockIdRef]);

  const handleDismiss = useCallback(() => {
    toggleChatShown?.(false);
    blockIdRef.current = undefined;
  }, [toggleChatShown, blockIdRef]);

  useEffect(() => {
    return testThisBlockEventEmitter.on<TestThisBlockPayload>(
      TEST_THIS_BLOCK_START_TEST_REQUEST,
      ({ blockId: nextBlockId, platform }) => {
        handleTestThisFlowClick(nextBlockId, platform);
      },
    );
  }, [handleTestThisFlowClick]);

  useEffect(() => {
    return testThisBlockEventEmitter.on<TestThisBlockPayload>(
      TEST_THIS_BLOCK_DISMISS_REQUEST,
      handleDismiss,
    );
  }, [handleDismiss]);

  useEffect(() => {
    if (tabId === currentTabId) return;
    blockIdRef.current = undefined;
    setCurrentTabId(tabId);
    if (chatIsShown) {
      handleDismiss();
    }
  }, [
    tabId,
    currentTabId,
    chatIsShown,
    setCurrentTabId,
    blockIdRef,
    handleTestThisFlowClick,
    handleDismiss,
  ]);

  return {
    refId,
    ready: Boolean(refId) || botTesting,
    loading,
    hasError: !!error,
    handleTestThisFlowClick,
    handleRefresh,
    handleDismiss,
  };
};
