import { useWhatsappImportUsersSessionStatus } from './useWhatsappImportUsersSessionStatus';
import { useStartWhatsappImportUsersSession } from './useStartWhatsappImportUsersSession';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { WhatsappImportUsersSessionStatus } from '@globals';
import { WhatsappImportUsersSessionStatusQuery_whatsappImportUsersSessionStatus_stats } from './@types/WhatsappImportUsersSessionStatusQuery';
import Maybe from 'graphql/tsutils/Maybe';
import { AttributesErrors, ImportUsersAttribute } from './types';
import { useWhatsappImportUsersAttributes } from './useWhatsappImportUsersAttributes';
import { useWhatsappImportLimits } from './useWhatsappImportLimits';
import { WhatsappImportLimitsQuery_whatsappImportLimits } from './@types/WhatsappImportLimitsQuery';

interface WhatsappImportUsersSessionContextPayload {
  limits: WhatsappImportLimitsQuery_whatsappImportLimits | null;
  limitsLoading: boolean;
  startWhatsappImportUsersSession: () => Promise<string | undefined>;
  csvUploadSessionId: string | undefined;
  status: WhatsappImportUsersSessionStatus;
  stats: Maybe<WhatsappImportUsersSessionStatusQuery_whatsappImportUsersSessionStatus_stats>;
  setCurrentStatus: (status: WhatsappImportUsersSessionStatus) => void;
  attributes: ImportUsersAttribute[];
  attributesErrors: AttributesErrors;
  addAttribute: VoidFunction;
  removeAttribute: (id: string) => void;
  updateAttribute: (attribute: ImportUsersAttribute) => void;
  canDownloadDeclinedUsersFile: boolean;
}

const WhatsappImportUsersSessionContext =
  React.createContext<WhatsappImportUsersSessionContextPayload>({
    limits: null,
    limitsLoading: false,
    startWhatsappImportUsersSession: () => Promise.resolve(undefined),
    csvUploadSessionId: undefined,
    status: WhatsappImportUsersSessionStatus.idle,
    stats: undefined,
    setCurrentStatus: () => undefined,
    attributes: [],
    attributesErrors: {},
    addAttribute: () => undefined,
    removeAttribute: () => undefined,
    updateAttribute: () => undefined,
    canDownloadDeclinedUsersFile: false,
  });

export const WhatsappImportUsersSessionContextProvider: React.FC = ({
  children,
}) => {
  const [currentStatus, setCurrentStatus] =
    useState<WhatsappImportUsersSessionStatus>(
      WhatsappImportUsersSessionStatus.none,
    );
  const { attributes, ...attributesRest } = useWhatsappImportUsersAttributes();
  const {
    csvUploadSessionId,
    startWhatsappImportUsersSession: startWhatsappImportUsersSessionMutation,
  } = useStartWhatsappImportUsersSession({
    onError: () => {
      setCurrentStatus(WhatsappImportUsersSessionStatus.failed_init);
    },
  });

  const { status, stats, canDownloadDeclinedUsersFile } =
    useWhatsappImportUsersSessionStatus({
      csvUploadSessionId,
      pooling: [
        WhatsappImportUsersSessionStatus.running,
        WhatsappImportUsersSessionStatus.idle,
      ].includes(currentStatus),
    });

  const { data, loading } = useWhatsappImportLimits();

  const startWhatsappImportUsersSession = useCallback(() => {
    setCurrentStatus(WhatsappImportUsersSessionStatus.running);
    return startWhatsappImportUsersSessionMutation(
      attributes
        .map(({ id, ...attribute }) => attribute)
        .filter(({ name, value }) => name.trim() && value.trim()),
    );
  }, [attributes, startWhatsappImportUsersSessionMutation]);

  useEffect(() => {
    if (!status) {
      return;
    }
    setCurrentStatus(status);
  }, [status]);

  return (
    <WhatsappImportUsersSessionContext.Provider
      value={{
        limits: data?.whatsappImportLimits || null,
        limitsLoading: loading,
        startWhatsappImportUsersSession,
        csvUploadSessionId,
        status: currentStatus,
        stats,
        setCurrentStatus,
        attributes,
        canDownloadDeclinedUsersFile: canDownloadDeclinedUsersFile || false,
        ...attributesRest,
      }}
    >
      {children}
    </WhatsappImportUsersSessionContext.Provider>
  );
};

export const useWhatsappImportUsersSession = () =>
  useContext(WhatsappImportUsersSessionContext);
