import React from 'react';

import { CenteredLoader } from '@ui/Loader';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { IconButton } from '@ui/IconButton';
import { sendEvent } from '@utils/Analytics';

import {
  OnboardingTourEventType,
  OnboardingTourHTMLElementId,
  Platform,
} from '@globals';

import { ChatContainer } from '../ChatContainer/ChatContainer';
import { ChatMoreMenu } from '../ChatMoreMenu/ChatMoreMenu';
import { ChatError } from '../ChatError/ChatError';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

import { ReactComponent as ChatfuelLogo } from './chatfuel-status.svg';

import * as css from './ChatDialog.css';

interface ChatDialogProps {
  refId: string | undefined;
  platform?: Platform | null;
  loading: boolean;
  hasError: boolean;
  onDismiss(): void;
  onRefreshRequest(): void;
  showEmptyState?: boolean;
  showEmptyMessage?: boolean;
  hideMoreMenuButton?: boolean;
}

export const ChatDialog: React.FC<ChatDialogProps> = ({
  platform,
  refId,
  loading,
  hasError,
  showEmptyState,
  showEmptyMessage,
  onDismiss,
  onRefreshRequest,
  hideMoreMenuButton,
}) => {
  return (
    <div
      data-testid="test-this_dialog"
      className={css.dialog}
      id={OnboardingTourHTMLElementId.TestThisFlowDialog}
    >
      <Flex justifyContent="space-between" className={css.header}>
        <Flex alignItems="center">
          <ChatfuelLogo />
        </Flex>
        <Flex alignItems="center">
          <IconButton
            data-testid="test-this_refresh"
            onClick={() => {
              sendEvent({
                category: 'test in chatfuel',
                action: 'refresh icon clicked',
                propertyBag: { refId },
              });
              onRefreshRequest();
            }}
            size="24px"
            icon="sync"
            color="white"
          />
          <Spacer horizontalFactor={1} />
          {platform !== Platform.instagram && !hideMoreMenuButton && (
            <ChatMoreMenu refId={refId} />
          )}
          <Spacer horizontalFactor={1} />
          <IconButton
            id={OnboardingTourHTMLElementId.TestThisFlowChatCloseButton}
            data-testid="test-this_close"
            onClick={() => {
              sendEvent({
                category: 'test in chatfuel',
                action: 'close icon clicked',
                propertyBag: { refId },
              });
              OnboardingEmitter.emit(OnboardingTourEventType.click, {
                element:
                  OnboardingTourHTMLElementId.TestThisFlowChatCloseButton,
                value: null,
              });
              onDismiss();
            }}
            size="24px"
            icon="close"
            color="white"
          />
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        id={OnboardingTourHTMLElementId.TestThisFlowChatContent}
        className={css.contentWrapper}
      >
        {loading && <CenteredLoader />}
        {!loading && hasError && (
          <ChatError onTryAgainClick={onRefreshRequest} />
        )}
        {!loading && !hasError && (
          <ChatContainer
            showEmptyState={showEmptyState}
            showEmptyMessage={showEmptyMessage}
          />
        )}
      </Flex>
    </div>
  );
};
