import gql from 'graphql-tag';

export const CREATE_PREMIUM_PAYMENT_INTENT = gql`
  mutation CreatePremiumPaymentIntent {
    createPremiumPaymentIntent {
      client_secret
    }
  }
`;

export const ATTACH_PREMIUM_PAYMENT_METHOD = gql`
  mutation AttachPremiumPaymentMethod($paymentMethod: String!) {
    attachPremiumPaymentMethod(paymentMethod: $paymentMethod) {
      id
      subscription_info {
        id
        status
        current_period_end
      }
    }
  }
`;

export const PREMIUM_PAYMENT_METHOD_QUERY = gql`
  query PremiumPaymentMethodQuery {
    me {
      id
      premiumAccount {
        id
        paymentMethod {
          last4
        }
      }
    }
  }
`;
