import * as React from 'react';
import { PlanDisplayPrice } from '@components/Payments';
import { PaymentInterval } from '@utils/Payment/paymentInterval';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { formatPrice } from '@utils/Format/priceFormatter';

interface ProPlanDisplayPriceProps {
  className?: string;
  price?: number;
  currency?: string;
  discountIsApplicable?: boolean;
  discountFactor?: number;
}

export const ProPlanDisplayPrice: React.FC<ProPlanDisplayPriceProps> = ({
  className,
  currency,
  price = 0,
  discountIsApplicable,
  discountFactor = 1,
}) => {
  return (
    <Flex className={className} flexDirection="row" alignItems="baseline">
      <PlanDisplayPrice
        displayAmount={formatPrice(price, { currency, decimals: 0 })}
        intervalName={PaymentInterval.month}
      />
      {discountIsApplicable && <Spacer horizontalFactor={2} />}
      {discountIsApplicable && (
        <PlanDisplayPrice
          style={{
            borderColor: 'var(--greyDark)',
            color: 'var(--greyDark)',
          }}
          displayAmountFontSize={24}
          displayAmount={`${formatPrice(price / discountFactor, {
            currency,
            decimals: 0,
          })}`}
          strikethrough
          intervalName={PaymentInterval.month}
        />
      )}
    </Flex>
  );
};
