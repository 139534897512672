import i18next from 'i18next';
import { memoize } from 'lodash-es';
import { Translation } from '@translations';
import { Color } from '@ui/_common/colors';
import Maybe from 'graphql/tsutils/Maybe';
import { PaymentDetailsStatus } from '@globals';

export type WorkspaceStatus = Readonly<{
  color: Color;
  title: string;
}>;

export type WorkspaceStatusesType = Record<
  PaymentDetailsStatus,
  WorkspaceStatus
>;

const workspaceStatuses: WorkspaceStatusesType = {
  [PaymentDetailsStatus.active]: {
    color: Color.green,
    get title() {
      return i18next.t<string, Translation>('BotList.workspaceStatus.paid');
    },
  },
  [PaymentDetailsStatus.cancelled]: {
    color: Color.red,
    get title() {
      return i18next.t<string, Translation>('BotList.workspaceStatus.cancel');
    },
  },
  [PaymentDetailsStatus.to_be_cancelled]: {
    color: Color.greyDark,
    get title() {
      return i18next.t<string, Translation>('BotList.workspaceStatus.cancel');
    },
  },
  [PaymentDetailsStatus.trial]: {
    color: Color.blue,
    get title() {
      return i18next.t<string, Translation>('BotList.workspaceStatus.trial');
    },
  },
};

export const getWorkspaceDataByStatus = memoize(
  (status: Maybe<PaymentDetailsStatus>) => status && workspaceStatuses[status],
);
