import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Type } from '../../../modern-ui/Type';
import { CenteredLoader } from '../../../modern-ui/Loader';
import { PaymentHistory } from '../../Payments/PaymentHistory';
import { downloadInvoice } from '../../../utils/downloadInvoice';
import {
  PremiumPaymentHistoryQuery,
  PremiumPaymentHistoryQuery_premiumPaymentHistory_items as Payment,
} from './@types/PremiumPaymentHistoryQuery';
import {
  PremiumInvoiceQuery,
  PremiumInvoiceQueryVariables,
} from './@types/PremiumInvoiceQuery';

const PREMIUM_PAYMENT_HISTORY_QUERY = gql`
  query PremiumPaymentHistoryQuery {
    premiumPaymentHistory {
      items {
        id
        amount_paid
        created
        last4
        charge_id
        currency
      }
    }
  }
`;

const PREMIUM_INVOICE_QUERY = gql`
  query PremiumInvoiceQuery($id: String!) {
    premiumInvoice(id: $id) {
      id
      html
    }
  }
`;

const { Card, Download, PaymentDate, Price } = PaymentHistory;

export const PremiumPaymentHistory: React.FC = () => {
  const { data, loading, error, client } = useQuery<PremiumPaymentHistoryQuery>(
    PREMIUM_PAYMENT_HISTORY_QUERY,
    { fetchPolicy: 'no-cache' },
  );

  if (error) {
    return (
      <Type color="red" size="15px_DEPRECATED">
        {window.i18next.t(
          'PremiumPaymentHistory-JSXText--131-could-not-fetch-payment-history-please-try-again',
        )}
      </Type>
    );
  }

  if (loading || !data?.premiumPaymentHistory) {
    return <CenteredLoader />;
  }

  const payments = data.premiumPaymentHistory.items;
  return payments.length ? (
    <div
      style={{
        flexGrow: 1,
        overflowY: 'auto',
        height: '328px',
        padding: '0 5px',
      }}
    >
      <PaymentHistory<Payment>
        listHeight={328}
        itemHeight={48}
        items={payments}
        getItemId={(item) => item.id}
        columns={[
          {
            columnId: 'date',
            render: (item) => <PaymentDate date={item.created} />,
          },
          {
            columnId: 'card',
            render: (item) => <Card last4={item.last4} />,
          },
          {
            columnId: 'price',
            render: (item) => (
              <Price amount={item.amount_paid / 100} currency={item.currency} />
            ),
          },
          {
            columnId: 'download',
            render: (item) =>
              item.charge_id ? (
                <Download
                  onDownload={async () => {
                    const res = await client.query<
                      PremiumInvoiceQuery,
                      PremiumInvoiceQueryVariables
                    >({
                      query: PREMIUM_INVOICE_QUERY,
                      variables: { id: item.charge_id! },
                      fetchPolicy: 'no-cache',
                    });
                    if (res.data.premiumInvoice) {
                      downloadInvoice(
                        item.created,
                        res.data.premiumInvoice.html,
                      );
                    }
                  }}
                />
              ) : null,
          },
        ]}
      />
    </div>
  ) : null;
};
