import React, { useState } from 'react';
import cn from 'classnames';
import { sendEvent } from '@utils/Analytics';
import { useRouteMatch } from 'react-router-dom';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import { DiscountMonthOnTierMessage } from './FreeMonthOnTierMessage/FreeMonthOnTierMessage';
import { InfoButton } from './InfoButton/InfoButton';
import { seporator } from './seporator/seporator';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useAssignBotPromoCodeMutation } from '@utils/Data/PromoCode/useAssignBotPromoCodeMutation';
import { useBotPromoCodeQuery } from '@utils/Data/PromoCode/useBotPromoCodeQuery';
import { CenteredLoader } from '@ui/Loader';
import { ErrorMessage } from './ErrorMessage/ErrorMessage';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { extractGQLErrorData } from '@utils/GQL/utils';
import { BOT_PROMO_CODE_QUERY } from '@utils/Data/PromoCode/queries';
import { PRICING_PLAN_TIERS_QUERY } from '@utils/Data/Pricing';
import Maybe from 'graphql/tsutils/Maybe';
import css from './PromoCode.css';

interface PromoCodeProps {
  botId: string;
  pricingPlanId: Maybe<string>;
  currency: Maybe<string>;
}

export const PromoCode: React.FC<PromoCodeProps> = ({
  botId,
  pricingPlanId,
  currency,
}) => {
  const [promoCode, setPromoCode] = useState('');
  const [errorPromoCode, setErrorPromoCode] = useState<string>();
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const matchPage =
    useRouteMatch<{ botId?: string; page?: string }>('/bot/:botId/:page');
  const { data: botPromoCodeData, loading: botPromoCodeDataLoading } =
    useBotPromoCodeQuery(botId);
  const { assignBotPromoCode, errorMessage, loading } =
    useAssignBotPromoCodeMutation({
      onCompleted: () =>
        sendEvent({
          category: 'promocode',
          action: 'assign promocode',
          propertyBag: {
            botId,
            promoCode,
            location: matchPage?.params.page,
          },
        }),
      onError: (error) => {
        sendEvent({
          category: 'promocode',
          action: 'error promocode',
          propertyBag: {
            botId,
            promoCode,
            location: matchPage?.params.page,
            errorMessage: extractGQLErrorData(error)?.message,
          },
        });
        setErrorPromoCode(promoCode);
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: BOT_PROMO_CODE_QUERY,
          variables: { botId },
        },
        {
          query: PRICING_PLAN_TIERS_QUERY,
          variables: {
            botId,
            pricingPlanId,
            currency,
          },
        },
      ],
    });

  const appliedPromoCode =
    botPromoCodeData?.bot.assignedPromoCode?.promoCode.id;
  const codeApplied = Boolean(appliedPromoCode);

  if (botPromoCodeDataLoading)
    return (
      <div className={css.container}>
        <CenteredLoader className={css.loader} />
      </div>
    );

  const isError = errorMessage && errorPromoCode === promoCode;
  const applyButton = (
    <Button
      className={css.applyPromocodeAction}
      textClassName={css.text}
      disabled={!promoCode.trim() || isError}
      loading={loading}
      onClick={() => {
        sendEvent({
          category: 'promocode',
          action: 'apply promocode button click',
          propertyBag: {
            botId,
            promoCode,
            location: matchPage?.params.page,
          },
        });
        assignBotPromoCode({
          variables: {
            botId,
            promoCodeId: promoCode.trim(),
          },
        });
      }}
      data-testid="promocode-info-button"
    >
      {t('PromoCode.apply')}
    </Button>
  );
  const getContent = () => {
    if (codeApplied)
      return (
        <>
          {seporator}
          <DiscountMonthOnTierMessage
            discount={
              botPromoCodeData?.bot.assignedPromoCode?.promoCode
                .discountPercents
            }
            className={css.freeMonthMessage}
            isWhatsappEnabled={isWhatsappEnabled}
          />
          <InfoButton
            className={css.infoButton}
            isWhatsappEnabled={isWhatsappEnabled}
          />
        </>
      );

    if (isError)
      return (
        <>
          {seporator}
          {isSmallScreenSize && applyButton}
          <ErrorMessage
            errorMessage={errorMessage}
            className={css.errorMessage}
          />
        </>
      );

    return applyButton;
  };

  return (
    <div className={css.container}>
      <Input
        containerClassName={css.inputContainer}
        className={cn(css.input, codeApplied && css.codeApplied)}
        value={appliedPromoCode ?? promoCode}
        readOnly={codeApplied}
        placeholder={t('PromoCode.promocode')}
        error={isError}
        onChange={(e) => setPromoCode(e.target.value)}
        data-testid="promocode-input"
      />
      {getContent()}
    </div>
  );
};
