import { Item } from '@ui/SimpleCombobox';
import { ChatfuelUseCase, Industry, SourceAboutUs } from '@globals';
import { Translation } from '@translations';
import i18next from 'i18next';

export const getIndustryItems = (): Item<{ id: Industry; title: string }>[] => [
  {
    id: Industry.e_commerce,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.industry.e_commerce',
    ),
  },
  {
    id: Industry.education,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.industry.education',
    ),
  },
  {
    id: Industry.health_and_beauty,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.industry.health_and_beauty',
    ),
  },
  {
    id: Industry.marketing,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.industry.marketing',
    ),
  },
  {
    id: Industry.other,
    title: i18next.t<string, Translation>('Premium.requestDemo.industry.other'),
  },
];

export const getChatfuelUseCaseItems = (): Item<{
  id: ChatfuelUseCase;
  title: string;
}>[] => [
  {
    id: ChatfuelUseCase.answer_questions,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.use_case.answer_questions',
    ),
  },
  {
    id: ChatfuelUseCase.generate_leads,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.use_case.generate_leads',
    ),
  },
  {
    id: ChatfuelUseCase.react_to_comments,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.use_case.react_to_comments',
    ),
  },
  {
    id: ChatfuelUseCase.sell_roducts,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.use_case.sell_roducts',
    ),
  },
  {
    id: ChatfuelUseCase.other,
    title: i18next.t<string, Translation>('Premium.requestDemo.use_case.other'),
  },
];

export const getSourceAboutUsItems = (): Item<{
  id: SourceAboutUs;
  title: string;
}>[] => [
  {
    id: SourceAboutUs.articles,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.source.articles',
    ),
  },
  {
    id: SourceAboutUs.from_someone_else,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.source.from_someone_else',
    ),
  },
  {
    id: SourceAboutUs.youtube_videos,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.source.youtube_videos',
    ),
  },
  {
    id: SourceAboutUs.advertisement,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.source.advertisement',
    ),
  },
  {
    id: SourceAboutUs.online_courses,
    title: i18next.t<string, Translation>(
      'Premium.requestDemo.source.online_courses',
    ),
  },
  {
    id: SourceAboutUs.other,
    title: i18next.t<string, Translation>('Premium.requestDemo.source.other'),
  },
];

export const OTHER:
  | Industry.other
  | ChatfuelUseCase.other
  | SourceAboutUs.other = Industry.other;

export const initialRequestForm = {
  email: '',
  name: '',
  industry: null,
  industry_other: null,
  company: null,
  phone: null,
  use_case: null,
  use_case_other: null,
  source: null,
  source_other: null,
};

export enum RequestDemoStatus {
  success = 'success',
  error = 'error',
}
