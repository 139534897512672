import gql from 'graphql-tag';

const FACEBOOK_PAGE_POST_FRAGMENT = gql`
  fragment facebookPagePostFragment on FacebookPagePost {
    id
    type
    full_picture
    icon
    created_time
    updated_time
    message
    permalink_url
    page_id
    flow_id
    flow_title
  }
`;

export const FACEBOOK_PAGE_POSTS_QUERY = gql`
  query FacebookPagePostsQuery(
    $pageId: String!
    $cursors: [FacebookPagePostCursorInput!]!
    $limit: Int
    $postIds: [String!]
  ) {
    page(pageId: $pageId) {
      id
      morePosts(cursors: $cursors, limit: $limit, postIds: $postIds)
        @connection(key: "facebookPagePosts") {
        posts {
          ...facebookPagePostFragment
        }
        cursors {
          type
          after
        }
      }
    }
  }
  ${FACEBOOK_PAGE_POST_FRAGMENT}
`;

export const REMOVE_FACEBOOK_PAGE_POST_FROM_ENTRY_POINTS_MUTATION = gql`
  mutation RemoveFacebookPagePostFromEntryPointsMutation(
    $pageId: String!
    $postId: String!
  ) {
    removePostFromEntryPoints(pageId: $pageId, postId: $postId) {
      ...facebookPagePostFragment
    }
  }
  ${FACEBOOK_PAGE_POST_FRAGMENT}
`;

export const FACEBOOK_PAGE_POST_BY_URL = gql`
  query FacebookPagePostByUrl($pageId: String!, $url: String!) {
    page(pageId: $pageId) {
      id
      post(url: $url) {
        ...facebookPagePostFragment
      }
    }
  }
  ${FACEBOOK_PAGE_POST_FRAGMENT}
`;
