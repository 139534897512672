import React, { useState, useRef, useCallback } from 'react';
import YouTube from 'react-youtube';
import cn from 'classnames';
import throttle from 'lodash-es/throttle';

import { MousemoveTracker } from '@utils/MousemoveTracker';
import { Icon } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { setPageCursor } from '@utils/DOM/setPageCursor';
import { TextEllipsis } from '@ui/TextEllipsis';
import {
  PLAYER_OPTIONS,
  VIDEO_BIG_WIDTH,
  VIDEO_DEFAULT_WIDTH,
  VIDEO_MAX_WIDTH,
  VIDEO_MIN_WIDTH,
  VIDEO_RATE,
} from './constants';
import {
  disableSelection,
  enableSelection,
  getMinLeftCoordinate,
  getMinRightCoordinate,
  getMinTopCoordinate,
  getMinBottomCoordinate,
} from './utils';

import * as css from './YouTubeFramePlayer.css';

interface YouTubeFramePlayerProps {
  videoId: string; // example XBTOqyUtX-0
  onChangeSize(): void;
  onMoveFinished?(): void;
  initialPosition?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  videoContainerClassName?: string;
  onClose(): void;
  onPlay?: () => void;
  autoplay?: boolean;
  title?: string;
}

export const YouTubeFramePlayer = React.memo<YouTubeFramePlayerProps>(
  ({
    videoId,
    onChangeSize,
    onMoveFinished,
    initialPosition,
    videoContainerClassName,
    onClose,
    onPlay,
    autoplay = true,
    title = '',
  }) => {
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    const onReady = () => {
      setIsPlayerReady(true);
    };

    const [position, setPosition] = useState(
      initialPosition ?? { top: 0, right: 0 },
    );

    const [width, setWidth] = useState<number>(VIDEO_DEFAULT_WIDTH);

    const movableContainerRef = useRef<HTMLDivElement>(null);
    const mousemoveTrackerRef = useRef(new MousemoveTracker());
    const resizeTrackerRef = useRef(new MousemoveTracker());
    const playerRef = useRef<YouTube>(null);

    const setPlayerInteractiveMode = useCallback(async (enable: boolean) => {
      if (!playerRef.current) {
        return;
      }
      const player = await playerRef.current.getInternalPlayer();
      const iframe = await player.getIframe();
      iframe.style.pointerEvents = enable ? 'auto' : 'none';
    }, []);

    const startTrackMousemove = useCallback(
      (event: React.MouseEvent) => {
        disableSelection();
        setPlayerInteractiveMode(false);
        setPageCursor('grab');
        const prevTop = position.top ?? 0;
        const prevRight = position.right ?? 0;
        mousemoveTrackerRef.current.startTrack(
          throttle(({ deltaX, deltaY }) => {
            setPosition({
              top: prevTop + deltaY,
              right: prevRight - deltaX,
            });
          }, 5),
          () => {
            enableSelection();
            setPlayerInteractiveMode(true);
            setPageCursor('default');
            onMoveFinished!();
          },
          {
            top: getMinTopCoordinate(movableContainerRef, event),
            bottom: getMinBottomCoordinate(movableContainerRef, event),
            right: getMinRightCoordinate(movableContainerRef, event),
            left: getMinLeftCoordinate(movableContainerRef, event),
          },
        );
      },
      [onMoveFinished, position.right, position.top, setPlayerInteractiveMode],
    );

    const startTrackResize = useCallback(() => {
      disableSelection();
      setPlayerInteractiveMode(false);
      setPageCursor('nesw-resize');
      const prevWidth = width ?? VIDEO_MIN_WIDTH;
      resizeTrackerRef.current.startTrack(
        throttle(({ deltaX, deltaY }) => {
          setWidth(
            Math.max(
              Math.min(
                Math.max(prevWidth - deltaX, prevWidth + deltaY / VIDEO_RATE),
                VIDEO_MAX_WIDTH,
              ),
              VIDEO_MIN_WIDTH,
            ),
          );
        }, 5),
        () => {
          enableSelection();
          setPlayerInteractiveMode(true);
          setPageCursor('default');
          onChangeSize();
        },
        {
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        },
      );
    }, [onChangeSize, setPlayerInteractiveMode, width]);

    const isBig =
      width >
      (VIDEO_BIG_WIDTH - VIDEO_DEFAULT_WIDTH) * 0.5 + VIDEO_DEFAULT_WIDTH;

    return (
      <div className={css.ytPlayer}>
        <div
          className={css.ytPlayerMovable}
          ref={movableContainerRef}
          style={position}
        >
          {isPlayerReady && (
            <>
              <div className={css.ytPlayerControls}>
                <ButtonUnstyled
                  className={css.ytPlayerIconWrapper}
                  onClick={onClose}
                >
                  <Icon icon="close" size="28px" />
                </ButtonUnstyled>
                <ButtonUnstyled
                  className={css.ytPlayerIconWrapper}
                  onClick={() => {
                    setWidth(isBig ? VIDEO_DEFAULT_WIDTH : VIDEO_BIG_WIDTH);
                    onChangeSize();
                  }}
                >
                  <Icon icon={isBig ? 'expand' : 'unexpand'} size="24px" />
                </ButtonUnstyled>
                {onMoveFinished && (
                  <>
                    <ButtonUnstyled
                      className={cn(
                        css.ytPlayerIconWrapper,
                        css.ytPlayerDragable,
                      )}
                      onMouseDown={startTrackMousemove}
                    >
                      <Icon icon="drag" size="28px" />
                    </ButtonUnstyled>
                    <Flex
                      className={cn(
                        css.ytPlayerEmptyControlSpace,
                        css.ytPlayerDragable,
                      )}
                      onMouseDown={startTrackMousemove}
                      alignItems="center"
                    >
                      <Spacer factor={0} horizontalFactor={4} />
                      <TextEllipsis width={110}>
                        <Type size="15px" weight="medium">
                          {title}
                        </Type>
                      </TextEllipsis>
                    </Flex>
                  </>
                )}
              </div>
              <ButtonUnstyled
                className={css.resizeTool}
                onMouseDown={startTrackResize}
              />
            </>
          )}
          <div
            className={cn(css.ytPlayerVideoWrapper, videoContainerClassName)}
            style={{
              width,
              height: width * VIDEO_RATE,
            }}
          >
            <YouTube
              videoId={videoId}
              onReady={onReady}
              onPlay={onPlay}
              opts={{
                ...PLAYER_OPTIONS,
                playerVars: {
                  ...PLAYER_OPTIONS.playerVars,
                  autoplay: autoplay ? 1 : 0,
                },
              }}
              ref={playerRef}
            />
          </div>
        </div>
      </div>
    );
  },
);
