import React from 'react';
import { Item, SimpleCombobox, SimpleComboboxProps } from '@ui/SimpleCombobox';
import { OTHER } from '../consts';
import { LoadingInput, LoadingInputProps } from '../LoadingInput/LoadingInput';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import css from './RequestDemoCombobox.css';
import { Input } from '@ui/Input';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';

export interface RequestDemoComboboxProps
  extends Omit<SimpleComboboxProps<Item>, 'error' | 'onChange' | 'renderInput'>,
    Pick<LoadingInputProps, 'loading' | 'labelText' | 'name' | 'readOnly'> {
  error?: string;
  onChange(payload: { value: unknown; otherValue?: unknown }): void;
  otherValue?: string | null;
}

export const RequestDemoCombobox: React.FC<RequestDemoComboboxProps> = ({
  items,
  onChange,
  loading,
  error,
  labelText,
  name,
  readOnly,
  otherValue,
  ...props
}) => {
  const { t } = useSafeTranslation();

  const handleChange: SimpleComboboxProps<Item>['onChange'] = (item) => {
    onChange({ value: item?.id });
  };

  const handleOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ value: OTHER, otherValue: e.target.value });
  };

  const renderInput: SimpleComboboxProps<Item>['renderInput'] = ({
    getToggleButtonProps,
    selectedItem,
  }) => (
    <Button
      intent="text"
      {...getToggleButtonProps()}
      iconRight={<Icon icon="triangle" />}
      className={css.selectButton}
      disabled={readOnly}
      readOnly={readOnly}
    >
      {selectedItem ? (
        selectedItem.title
      ) : (
        <Type size="15px" className={css.selectPlaceholder}>
          {t('Premium.requestDemo.select')}
        </Type>
      )}
    </Button>
  );

  const { selectedItem } = props;

  return (
    <>
      <LoadingInput
        labelText={labelText}
        loading={loading}
        error={!!error}
        input={
          <Input
            error={!!error}
            renderErrorText={error ? () => error : undefined}
            render={() => (
              <SimpleCombobox
                {...props}
                disableFixedMode
                items={items}
                menuBoxClassName={css.menuBox}
                onChange={handleChange}
                renderInput={renderInput}
              />
            )}
          />
        }
      />
      {selectedItem?.id === OTHER && (
        <Input
          containerClassName={css.otherInput}
          value={otherValue ?? ''}
          onChange={handleOtherChange}
          placeholder={t('Premium.requestDemo.type')}
          disabled={readOnly}
          readOnly={readOnly}
        />
      )}
    </>
  );
};
