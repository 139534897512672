import React from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { Type } from '@ui/Type';

export interface WorkspaceStatisticsProps {
  maxCount?: Maybe<number>;
  count: number;
}

export const WorkspaceStats: React.FC<WorkspaceStatisticsProps> = ({
  maxCount,
  count,
}) => {
  const isMaxCount = typeof maxCount === 'number';

  return (
    <div>
      <Type
        size="18px"
        weight="medium"
        color={isMaxCount && count >= maxCount ? 'red' : 'black'}
        data-testid="workspace_stats__current_count"
      >
        {count}
      </Type>
      {isMaxCount && (
        <Type
          size="12px"
          weight="semibold"
          data-testid="workspace_stats__max_count"
        >
          {' '}
          / {maxCount}
        </Type>
      )}
    </div>
  );
};
