import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import image1Src from './images/TestInstagramPersistentMenuDialog_1.png';
import image2Src from './images/TestInstagramPersistentMenuDialog_2.png';
import image3Src from './images/TestInstagramPersistentMenuDialog_3.png';
import { TestSteps } from './components';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';

const TEST_STEPS = memoize(() => [
  {
    text: (
      <>
        {i18next.t(
          'modernComponents.TestEntryPoints.TestInstagramPersistentMenuDialog.text1',
        )}
      </>
    ),
    src: image1Src,
  },
  {
    text: (
      <>
        {i18next.t(
          'modernComponents.TestEntryPoints.TestInstagramPersistentMenuDialog.text2',
        )}
      </>
    ),
    src: image2Src,
  },
  {
    text: (
      <>
        {i18next.t(
          'modernComponents.TestEntryPoints.TestInstagramPersistentMenuDialog.text3',
        )}
      </>
    ),
    src: image3Src,
  },
]);

export const TestInstagramPersistentMenuDialog: React.FC<TestEntryPointsDialogProps> =
  ({ onRequestClose }) => (
    <DefaultDialog
      dialogStyle={{
        width: 572,
      }}
      header={i18next.t(
        'TestPersistentMenuDialog-string-2027-test-your-persistent-menu-entry-point',
      )}
      onDismiss={onRequestClose}
    >
      <TestSteps steps={TEST_STEPS()} />
    </DefaultDialog>
  );
