import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeading,
  DialogCloseButton,
  DialogFooter,
} from '../../../modern-ui/Dialog';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';
import * as css from './AfterAddSkillDialog.css';
import s1 from './images/s1.svg';

export interface IAfterAddSkillDialogProps {
  onRequestClose: () => void;
}

export const AfterAddSkillDialog: React.SFC<IAfterAddSkillDialogProps> = ({
  onRequestClose,
}) => (
  <Dialog className={css.dialog}>
    <DialogContent>
      <DialogHeading>
        <span>
          {window.i18next.t(
            'AfterAddSkillDialog-JSXText--314-weve-modified-your-bot',
          )}
        </span>
        <br />
        <span>
          {window.i18next.t(
            'AfterAddSkillDialog-JSXText-7064-follow-the-to-do-list-to-customize-it-for-you',
          )}
        </span>
      </DialogHeading>
      <div className={css.content}>
        <img src={s1} className={css.img1} alt="how to" />
      </div>
    </DialogContent>
    <DialogFooter>
      <Button intent={ButtonIntent.primary} onClick={onRequestClose}>
        {window.i18next.t(
          'AfterAddSkillDialog-JSXText--303-got-it-take-me-to-to-do',
        )}
      </Button>
    </DialogFooter>
    <DialogCloseButton className={css.closeButton} onClick={onRequestClose} />
  </Dialog>
);
