import { InstagramConnectionProblem } from '@globals';
import { ConnectInstagramTroubleshootingState } from './types';

export const getConnectInstagramTroubleshootingState = (
  connectionProblem: InstagramConnectionProblem,
) => {
  if (connectionProblem === InstagramConnectionProblem.not_a_business_account) {
    return ConnectInstagramTroubleshootingState.isProfessional;
  }
  if (connectionProblem === InstagramConnectionProblem.not_an_admin) {
    return ConnectInstagramTroubleshootingState.isAdmin;
  }
  if (
    connectionProblem ===
    InstagramConnectionProblem.no_access_to_direct_messages
  ) {
    return ConnectInstagramTroubleshootingState.getPermissions;
  }
  if (
    connectionProblem ===
    InstagramConnectionProblem.no_access_to_direct_messages_toggle
  ) {
    return ConnectInstagramTroubleshootingState.messagesAccess;
  }
  if (connectionProblem === InstagramConnectionProblem.not_connected_to_page) {
    return ConnectInstagramTroubleshootingState.isConnectedToPage;
  }
  return ConnectInstagramTroubleshootingState.checkPermissions;
};
