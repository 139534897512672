import React, { useCallback } from 'react';
import { StepProps } from '../types';
import { WhatsappImportUserDialogFooter } from '../../components/WhatsappImportUserDialogFooter';
import { Callout } from '@ui/Callout';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Trans } from 'react-i18next';
import { WHATSAPP_PHONE_ATTRIBUTE_NAME } from '../../consts';
import { Spacer } from '@ui/Spacer';
import { ReactComponent as BoxSvg } from './images/box.svg';
import { Flex } from '@ui/Flex';
import { useUserDataCsvFile } from '../../hocks/useUserDataCsvFile';
import { Type } from '@ui/Type';
import { PREVENT_INTERPOLATE } from '@utils/I18next/consts';
import { useWhatsappImportUsersSession } from '../../hocks/useWhatsappImportUsersSession';
import { ImportUsersAttributes } from './components/ImportUsersAttributes';

export const ReviewChangesStep: React.FC<StepProps> = ({ onDone, onBack }) => {
  const { fields, fieldsMap, uploadCsv } = useUserDataCsvFile();
  const { t } = useSafeTranslation();
  const { startWhatsappImportUsersSession, attributesErrors } =
    useWhatsappImportUsersSession();

  const transferredFieldQty = [...fieldsMap.values()].filter(Boolean).length;

  const nextClickHandler = useCallback(async () => {
    onDone?.();
    const sId = await startWhatsappImportUsersSession();
    if (!sId) {
      return;
    }
    uploadCsv(sId);
  }, [onDone, startWhatsappImportUsersSession, uploadCsv]);

  return (
    <>
      <Callout
        text={
          <Trans
            i18nKey="modernComponents.WhatsAppImportUser.dialog.ReviewChanges.info"
            values={{ attributeName: WHATSAPP_PHONE_ATTRIBUTE_NAME }}
            tOptions={PREVENT_INTERPOLATE}
          />
        }
        type="info"
      />
      <Spacer factor={8} />
      <Flex alignItems="center" flexDirection="column">
        <BoxSvg />
        <Spacer factor={8} />
        <Type color="baseSecondary">
          {t(
            'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.column',
            { count: transferredFieldQty },
          )}{' '}
          {t(
            'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.transferred',
          )}
        </Type>
        <Spacer factor={2} />
        <Type color="baseSecondary">
          {t(
            'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.column',
            { count: fields ? fields?.length - transferredFieldQty : 0 },
          )}{' '}
          {t(
            'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.dismissed',
          )}
        </Type>
      </Flex>

      <ImportUsersAttributes />

      <WhatsappImportUserDialogFooter
        showBackButton
        onNextStepButtonClick={nextClickHandler}
        onBackButtonClick={onBack}
        disabled={Object.values(attributesErrors).length > 0}
        nextStepButtonTitle={t(
          'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.next',
        )}
      />
    </>
  );
};
