import gql from 'graphql-tag';

export const PAGES_WITH_SUBSCRIPTIONS_QUERY = gql`
  query PagesWithSubscriptionsQuery {
    pages {
      id
      title
      picture
      subscription {
        id
        status
        paywallStatus
      }
    }
  }
`;

export const TRANSFER_PLAN_MUTATION = gql`
  mutation TransferPlanMutation($from: String!, $to: String!) {
    transferPlan(from: $from, to: $to)
  }
`;
