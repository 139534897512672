import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import image1Src from './images/TestCustomerChatDialog_1.png';
import image2Src from './images/TestCustomerChatDialog_2.png';
import image3Src from './images/TestCustomerChatDialog_3.png';
import { TestSteps } from './components';

const TEST_STEPS = () => [
  {
    text: (
      <>
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--168-1-insert-generated-code-on-your',
        )}
        <br />
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText-1371-website-open-your-web-page-on',
        )}
        <br />
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--141-which-you-want-to-place-the-html',
        )}
        <br />
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--108-code-in-the-pages-editor',
        )}
      </>
    ),
    src: image1Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--869-2-toggle-the-entry-point-on',
        )}
        <br />
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--718-click-the-messenger-icon-once-it',
        )}
        <br />
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--610-appears-in-the-corner-of-the-page',
        )}
        <br />
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--490-click-to-continue-as-a-logged-in-user',
        )}
        <br />
        {window.i18next.t('TestCustomerChatDialog-JSXText--152-or-a-guest')}
      </>
    ),
    src: image2Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText--606-3-review-the-response-from-your',
        )}
        <br />
        {window.i18next.t(
          'TestCustomerChatDialog-JSXText-1535-bot-in-the-chat-window',
        )}
      </>
    ),
    src: image3Src,
  },
];

export const TestCustomerChatDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => (
  <DefaultDialog
    dialogStyle={{
      width: 572,
    }}
    header={window.i18next.t(
      'TestCustomerChatDialog-string--171-test-your-customer-chat-entry-point',
    )}
    onDismiss={onRequestClose}
  >
    <TestSteps steps={TEST_STEPS()} />
  </DefaultDialog>
);
