import { useMutation } from 'react-apollo';
import {
  TransferPlanMutation,
  TransferPlanMutationVariables,
} from './@types/TransferPlanMutation';
import {
  PAGES_WITH_SUBSCRIPTIONS_QUERY,
  TRANSFER_PLAN_MUTATION,
} from './queries';
import { useCallback } from 'react';
import { removeEntity } from '../../FlowBuilder/components';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { showTransferPlanDialogError } from '../components/TransferPlanDialogError/showTransferPlanDialogError';
import { showInfoToaster } from '@services/MessageService';
import { LIGHT_BOTS_QUERY } from '@utils/Data/Bot/queries';

export interface Page {
  id: string;
  title: string;
}

export const useTransferPlan = () => {
  const [transferPlanMutation, mutationState] = useMutation<
    TransferPlanMutation,
    TransferPlanMutationVariables
  >(TRANSFER_PLAN_MUTATION, {
    refetchQueries: [
      {
        query: PAGES_WITH_SUBSCRIPTIONS_QUERY,
      },
      {
        query: LIGHT_BOTS_QUERY,
      },
    ],
    onError: () => {
      showTransferPlanDialogError();
    },
  });
  const { t } = useSafeTranslation();

  const transferPlan = useCallback(
    (fromPage: Page, toPage: Page) => {
      removeEntity({
        intent: 'primary',
        onSubmit: async () => {
          if (
            await transferPlanMutation({
              variables: {
                from: fromPage.id,
                to: toPage.id,
              },
            })
          ) {
            showInfoToaster(
              'modernComponents.TransferPlanDialog.ConfirmTransfer.doneToaster',
              false,
              {
                pageName: toPage.title,
              },
            );
          }
        },
        renderHeading: () =>
          t('modernComponents.TransferPlanDialog.ConfirmTransfer.title'),
        renderActionText: () =>
          t('modernComponents.TransferPlanDialog.ConfirmTransfer.button'),
        renderNoteText: () => null,
      });
    },
    [t, transferPlanMutation],
  );

  return {
    transferPlan,
    ...mutationState,
  };
};
