import { useQuery } from 'react-apollo';
import {
  WhatsappImportUsersSessionStatusQuery,
  WhatsappImportUsersSessionStatusQueryVariables,
} from './@types/WhatsappImportUsersSessionStatusQuery';
import { WHATSAPP_IMPORT_USERS_SESSION_STATUS_QUERY } from './queries';
import { WHATSAPP_IMPORT_USERS_SESSION_STATUS_POOL_INTERVAL } from './consts';
import { useCurrentBotId } from '@utils/Routing';

interface UseWhatsappImportUsersSessionStatusArgs {
  csvUploadSessionId: string | undefined;
  pooling?: boolean;
}

export const useWhatsappImportUsersSessionStatus = ({
  csvUploadSessionId,
  pooling,
}: UseWhatsappImportUsersSessionStatusArgs) => {
  const botId = useCurrentBotId();
  const {
    data,
    error: queryError,
    ...queryState
  } = useQuery<
    WhatsappImportUsersSessionStatusQuery,
    WhatsappImportUsersSessionStatusQueryVariables
  >(WHATSAPP_IMPORT_USERS_SESSION_STATUS_QUERY, {
    skip: !csvUploadSessionId || !botId,
    pollInterval: pooling
      ? WHATSAPP_IMPORT_USERS_SESSION_STATUS_POOL_INTERVAL
      : 0,
    variables: {
      csvUploadSessionId: csvUploadSessionId || '',
      botId: botId || '',
    },
  });

  return {
    ...(data?.whatsappImportUsersSessionStatus || {}),
    queryError,
    ...queryState,
  };
};
