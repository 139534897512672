import ng from 'angular';

import { ngAddSkill } from './Skills/AddSkill/ngAddSkill';
import { ngTodoPluginBox } from './Skills/TodoPlugin/ngTodoPlugin';
import { ngSelectBlockPopup } from './SelectBlockPopup';
import { ngDashboardTokenAPIDialog } from './DashboardTokenAPI';
import { ngBroadcastingTags } from './BroadcastingTags';
import { ngBroadcastHeader } from './BroadcastHeader';
import { ngUpgradeToProServiceMessage } from './UpgradeToProServiceMessage';
import { ngClickVeil } from './UpgradeToProPopup';
import { ngPlugins } from './Plugins';
import { ngHandoverPluginPopup } from './HandoverPluginPopup';
import { ngKnowNothingPermissionsContent } from '@pages/Permissions/ngPermissions';
import { ngPeopleTabNoPage } from './PeopleTabNoPage';
import { ngProPlanSection } from './ProPlanSection';
import { ngButtonPopupBlocksTab } from './ButtonEditorPopup/BlocksTab';
import { ngButtonPopupURLTab } from './ButtonEditorPopup/URLTab';
import { ngButtonPopupTabs } from './ButtonEditorPopup/Tabs';
import { ngButtonPopupTitle } from './ButtonEditorPopup/common/ngButtonPopupTitle';
import { ngButtonTabBlockSelector } from './ButtonEditorPopup/common/ngButtonTabBlockSelector';
import { ngButtonPopupPhoneCallTab } from './ButtonEditorPopup/PhoneCallTab';
import { ngBlockLinks } from './BlockLinks';
import { ngUserFilterForReactWrapper } from './UserFilter';
import { ngSponsoredMessageBanner } from './SponsoredMessageBanner/ngSponsoredMessageBanner';
import { ngAudienceSyncBanner } from './AudienceSync/ngAudienceSyncBanner';
import { ngWhatsappImportUsers } from './WhatsappImportUsers';
import { ngImageResizeDialog } from './Plugins/ImagePlugin/components/ImageResize/ngImageResizeDialog';
import { ngImageSizeAlert } from './Plugins/ImagePlugin/components/ImageSizeAlert/ngImageSizeAlert';
import { ngGalleryAspectRatioPrompt } from './Plugins/ImagePlugin/components/GalleryAspectRatioPrompt/ngGalleryAspectRatioPrompt';
import { ngManageMessagesButton } from './LivechatAutomatedMessages/ManageMessagesButton/ngManageMessagesButton';

export default ng.module('app.modernComponents', [
  ngAddSkill,
  ngTodoPluginBox,
  ngSelectBlockPopup,
  ngDashboardTokenAPIDialog,
  ngBroadcastingTags,
  ngBroadcastHeader,
  ngUpgradeToProServiceMessage,
  ngClickVeil,
  ngPlugins,
  ngHandoverPluginPopup,
  ngPeopleTabNoPage,
  ngProPlanSection,
  ngKnowNothingPermissionsContent,
  ngButtonPopupURLTab,
  ngButtonPopupBlocksTab,
  ngButtonPopupTabs,
  ngButtonPopupTitle,
  ngButtonPopupPhoneCallTab,
  ngUserFilterForReactWrapper,
  ngSponsoredMessageBanner,
  ngAudienceSyncBanner,
  ngBlockLinks,
  ngImageResizeDialog,
  ngImageSizeAlert,
  ngGalleryAspectRatioPrompt,
  ngButtonTabBlockSelector,
  ngManageMessagesButton,
  ngWhatsappImportUsers,
]).name;
