import React from 'react';
import { sendEvent } from '@utils/Analytics';
import { Node } from '@components/FlowBuilder/Node';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import { TestCommentsAutoreplyDialog } from '@components/TestEntryPoints/dialogs/TestCommentsAutoreplyDialog';
import { TestCustomerChatDialog } from '@components/TestEntryPoints/dialogs/TestCustomerChatDialog';
import { TestFacebookPageDialog } from '@components/TestEntryPoints/dialogs/TestFacebookPageDialog';
import { TestSendToMessengerDialog } from '@components/TestEntryPoints/dialogs/TestSendToMessengerDialog';
import { TestPersistentMenuDialog } from '@components/TestEntryPoints/dialogs/TestPersistentMenuDialog';
import { TestInstagramPersistentMenuDialog } from '@components/TestEntryPoints/dialogs/TestInstagramPersistentMenuDialog';
import { TestRefLinkDialog } from '@components/TestEntryPoints/dialogs/TestRefLinkDialog';
import { TestFacebookMessengerAdsDialog } from '@components/TestEntryPoints/dialogs/TestFacebookMessengerAdsDialog';
import { TestFacebookShopsDialog } from '@components/TestEntryPoints/dialogs/TestFacebookShopsDialog';
import { PluginType } from '@components/FlowBuilder/types';
import { getEntryPointCard } from '@components/FlowBuilder/views/entry-points/common/utils';
import { getPluginData } from '@components/FlowBuilder/StatefulPlugin/ApolloState/utils';
import { Modal } from '@services/index';
import { Card } from '../../Plugins/common';
import { useTestThisWithOneTimeRef } from '../../TestInFacebook/utils/useTestThisWithOneTimeRef';
import imageSrc from './images/TestThisFlowDialog.png';
import { TestPopupDialog } from './TestPopupDialog';
import { TestZapierDialog } from './TestZapierDialog';

const DEFAULT = 'default';

export const TestThisFlowDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
  blockId,
}) => {
  const { testThis } = useTestThisWithOneTimeRef({ blockId });

  return (
    <DefaultDialog
      dialogStyle={{
        width: 572,
      }}
      header={window.i18next.t('TestThisFlowDialog-string--202-test-this-flow')}
      footer={
        <Flex
          justifyContent="space-between"
          alignItems="center"
          style={{
            width: 492,
          }}
        >
          <Button
            intent="secondary"
            onClick={() => {
              sendEvent({
                category: 'flow',
                action: 'click Open in messenger',
                label: 'test dialog',
              });
              testThis();
              onRequestClose();
            }}
          >
            {window.i18next.t(
              'TestThisFlowDialog-JSXText--149-open-in-messenger',
            )}
          </Button>
        </Flex>
      }
      onDismiss={onRequestClose}
    >
      <img
        src={imageSrc}
        alt=""
        style={{
          display: 'block',
          width: 492,
          height: 150,
        }}
      />
      <Spacer factor={4} />
      <Type size="15px_DEPRECATED">
        {window.i18next.t(
          'TestThisFlowDialog-JSXText-1496-to-test-this-flow-hover-over-the-block-you-want-to-start',
        )}
        <br />
        {window.i18next.t(
          'TestThisFlowDialog-JSXText-2997-from-and-click-the-messenger-icon',
        )}
      </Type>
    </DefaultDialog>
  );
};

const DIALOG_BY_PLUGIN_ID: Record<
  string,
  React.FC<TestEntryPointsDialogProps>
> = {
  [PluginType.persistent_menu_entry_point]: TestPersistentMenuDialog,
  [PluginType.instagram_persistent_menu_entry_point]:
    TestInstagramPersistentMenuDialog,
  [PluginType.customer_chat_entry_point]: TestCustomerChatDialog,
  [PluginType.comments_autoreply_entry_point]: TestCommentsAutoreplyDialog,
  [PluginType.ad_comments_autoreply_entry_point]: TestCommentsAutoreplyDialog,
  [PluginType.fb_page_entry_point]: TestFacebookPageDialog,
  [PluginType.send_to_messenger_entry_point]: TestSendToMessengerDialog,
  [PluginType.ref_link_entry_point]: TestRefLinkDialog,
  [PluginType.ads_manager_sm_entry_point]: TestFacebookMessengerAdsDialog,
  [PluginType.ads_manager_ctm_entry_point]: TestFacebookMessengerAdsDialog,
  [PluginType.ads_manager_ctm_json_entry_point]: TestFacebookMessengerAdsDialog,
  [PluginType.facebook_shops_entry_point]: TestFacebookShopsDialog,
  [PluginType.external_integration_entry_point]: TestZapierDialog,
  [PluginType.popup_entry_point]: TestPopupDialog,
  [DEFAULT]: TestThisFlowDialog,
};

export const startTestEntryPoint = (node: Node) => {
  const { block } = node;
  const entryPointCardCard = getEntryPointCard(block.cards);
  if (!entryPointCardCard) {
    return false;
  }

  const pluginId = entryPointCardCard?.plugin_id;
  const page = node.controller.flow.botStatus?.page_info;

  if (
    pluginId === PluginType.ref_link_entry_point &&
    entryPointCardCard.enabled &&
    page
  ) {
    const pluginData = getPluginData(entryPointCardCard.id);
    const ref = (pluginData?.card as Card).config.referral;
    window.open(
      `https://m.me/${page.username || page.id || ''}?ref=${ref}`,
      '_blank',
    );
    return true;
  }

  const Dialog = DIALOG_BY_PLUGIN_ID[pluginId || ''];
  if (Dialog) {
    Modal.show(({ close }) => (
      <Dialog
        onRequestClose={close}
        blockId={block.id}
        onSubmit={() => {
          if (
            pluginId === PluginType.ref_link_entry_point &&
            !entryPointCardCard.enabled
          ) {
            // @ts-expect-error
            node.blockView.updateEntryPointEnableState(true);
          }
        }}
      />
    ));
    sendEvent({
      category: 'flow',
      action: 'open test dialog',
      propertyBag: {
        pluginId,
      },
    });
  }
  return !!Dialog;
};
