import { useQuery } from '@apollo/react-hooks';
import { sendEvent } from '@utils/Analytics';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { removeTypename, WithoutGQLTypename } from '@utils/GQL/utils';
import { useCurrentBotId } from '@utils/Routing';
import { equals, prop, propEq, uniqBy } from 'ramda';
import { useCallback, useRef } from 'react';
import { PluginType } from '../../../Plugins/common/PluginTypes';
import {
  ComposeTabId,
  POST_LOADING_ATTEMPT_LIMIT,
  POST_LOADING_TOTAL_LIMIT,
} from '../../ChooseItemFromListDialog';
import {
  FacebookPagePostsQuery,
  FacebookPagePostsQuery_page_morePosts_cursors,
  FacebookPagePostsQueryVariables,
} from '../@types/FacebookPagePostsQuery';
import {
  getEmptyCursorByType,
  getPostTypesForQuery,
  mergeCursors,
} from '../helpers';
import { FACEBOOK_PAGE_POSTS_QUERY } from '../queries';

interface FacebookPostsFetchParams {
  activeTabId: string;
  postByUrlLoading: boolean;
}

export const useFacebookPostsFetch = ({
  activeTabId,
  postByUrlLoading,
}: FacebookPostsFetchParams) => {
  const botId = useCurrentBotId();
  const { pageId, loading: pageLoading } = usePageConnected(botId);
  const lastRequestCursors = useRef<
    FacebookPagePostsQuery_page_morePosts_cursors[] | undefined
  >();

  const currentTypes = getPostTypesForQuery(activeTabId);

  const {
    data,
    loading: postsLoading,
    fetchMore,
    refetch,
    networkStatus,
    error,
  } = useQuery<FacebookPagePostsQuery, FacebookPagePostsQueryVariables>(
    FACEBOOK_PAGE_POSTS_QUERY,
    {
      variables: {
        pageId: pageId || '',
        limit: POST_LOADING_ATTEMPT_LIMIT,
        cursors: currentTypes.map(getEmptyCursorByType),
      },
      skip: !pageId,
      notifyOnNetworkStatusChange: true,
    },
  );

  const loading = pageLoading || postsLoading || postByUrlLoading;

  const { posts, cursors } = data?.page?.morePosts || {};

  const loadMore = useCallback(() => {
    if (
      loading ||
      (posts || []).length > POST_LOADING_TOTAL_LIMIT ||
      (equals(lastRequestCursors.current, cursors) && posts?.length) ||
      activeTabId === ComposeTabId.postUrl
    ) {
      return Promise.resolve();
    }

    lastRequestCursors.current = cursors || undefined;

    const currentCursors = removeTypename(
      mergeCursors(
        cursors?.filter(({ type }) => currentTypes.includes(type)) || [],
        currentTypes.map(getEmptyCursorByType),
      ).filter(({ after }) => after !== null),
    );

    if (!currentCursors.length) {
      return Promise.resolve();
    }

    sendEvent({
      category: 'flows',
      action: 'Load more posts',
      label: PluginType.comments_autoreply_entry_point,
    });

    return fetchMore({
      query: FACEBOOK_PAGE_POSTS_QUERY,
      variables: {
        cursors: currentCursors,
        pageId: pageId || '',
        limit: POST_LOADING_ATTEMPT_LIMIT,
      },
      updateQuery: (
        previousResult: WithoutGQLTypename<FacebookPagePostsQuery> | undefined,
        { fetchMoreResult },
      ) => {
        const previousCursors = previousResult?.page?.morePosts.cursors || [];
        const fetchMoreCursors = fetchMoreResult?.page?.morePosts.cursors || [];
        const mergedCursors = mergeCursors(
          fetchMoreCursors,
          previousCursors,
        ) as FacebookPagePostsQuery_page_morePosts_cursors[];

        const previousPosts = previousResult?.page?.morePosts.posts || [];
        const nextPosts = fetchMoreResult?.page?.morePosts.posts || [];

        const mergedPosts = nextPosts.reduce((posts, post) => {
          const oldPostIndex = posts.findIndex(propEq('id', post.id));
          if (oldPostIndex !== -1) {
            // eslint-disable-next-line no-param-reassign
            posts[oldPostIndex] = post;
          } else {
            posts.push(post);
          }
          return posts;
        }, previousPosts);

        return {
          page: {
            id: previousResult?.page?.id || '',
            morePosts: {
              posts: uniqBy(prop('id'), mergedPosts),
              cursors: mergedCursors,
              __typename: 'MorePostsPayload',
            },
            __typename: 'Page',
          },
        };
      },
    });
  }, [activeTabId, loading, posts, cursors, currentTypes, fetchMore, pageId]);

  return {
    posts,
    cursors,
    postsLoading,
    loading,
    loadMore,
    refetch,
    error,
    networkStatus,
    currentTypes,
  };
};
