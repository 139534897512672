import { sendEvent } from '@utils/Analytics';

export const sendShopifyEvent = (
  action: string,
  propertyBag?: PureAnalyticsEvent['propertyBag'],
) =>
  sendEvent({
    category: 'payment',
    label: 'shopify v3',
    action,
    propertyBag,
  });
