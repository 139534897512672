import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as css from './FileSelectStep.css';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { ButtonUnstyled } from '@ui/Button';
import { UPLOAD_LIMIT_MB } from './consts';
import { Spacer } from '@ui/Spacer';
import { WhatsappImportUserDialogFooter } from '../../components/WhatsappImportUserDialogFooter';
import { CheckBox } from '@ui/CheckBox';
import { useUserDataCsvFile } from '../../hocks/useUserDataCsvFile';
import { StepProps } from '../types';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { sendEvent } from '@utils/Analytics';
import { CsvNote } from './components/CsvNote';
import { TextEllipsis } from '@ui/TextEllipsis';

export const FileSelectStep: React.FC<StepProps> = ({ onDone }) => {
  const [accept, setAccept] = useState<boolean>(false);
  const { t } = useSafeTranslation();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const {
    file,
    handleDragDropEvent,
    setFiles,
    parsingError: csvParsingError,
    csvReady,
  } = useUserDataCsvFile();

  const selectFileClickHandler = useCallback(() => {
    sendEvent({
      category: 'people tab',
      label: 'whatsapp user import',
      action: 'file update link click',
    });
    fileInputRef.current?.click();
  }, []);

  const fileName = file?.name;
  const isFileSizeLimitError =
    (file?.size || 0) > UPLOAD_LIMIT_MB * 1024 * 1024;

  const prevIsFileSizeLimitError = usePrevious(isFileSizeLimitError);
  useEffect(() => {
    if (isFileSizeLimitError && !prevIsFileSizeLimitError) {
      sendEvent({
        category: 'people tab',
        label: 'whatsapp user import',
        action: 'file size limit error',
      });
    }
  }, [isFileSizeLimitError, prevIsFileSizeLimitError]);

  const prevCsvParsingError = usePrevious(csvParsingError);
  useEffect(() => {
    if (csvParsingError && !prevCsvParsingError) {
      sendEvent({
        category: 'people tab',
        label: 'whatsapp user import',
        action: 'csv parse error',
      });
    }
  }, [csvParsingError, prevCsvParsingError]);

  const getErrorMessage = () => {
    if (isFileSizeLimitError) {
      return t(
        'modernComponents.WhatsAppImportUser.dialog.FileUploader.limitError',
        {
          uploadLimit: UPLOAD_LIMIT_MB,
        },
      );
    }
    if (csvParsingError) {
      if (typeof csvParsingError === 'boolean') {
        return t(
          'modernComponents.WhatsAppImportUser.dialog.FileUploader.csvError',
        );
      }
      return csvParsingError;
    }
    return t('common.ToasterMessages.somethingWentWrong');
  };

  return (
    <>
      <Flex
        className={css.box}
        alignItems="center"
        justifyContent="center"
        onDragEnter={handleDragDropEvent}
        onDragOver={handleDragDropEvent}
        onDrop={(event) => {
          handleDragDropEvent(event);
          setFiles(event);
        }}
      >
        <div>
          {!csvReady && (
            <>
              <ButtonUnstyled
                onClick={selectFileClickHandler}
                className={css.button}
                data-testid="whatsapp-import-users-dialog_upload-button"
              >
                <Type color="accent1Normal">
                  {t(
                    'modernComponents.WhatsAppImportUser.dialog.FileUploader.Upload',
                  )}
                </Type>
              </ButtonUnstyled>{' '}
              <Type>
                {t(
                  'modernComponents.WhatsAppImportUser.dialog.FileUploader.text',
                )}
              </Type>{' '}
              <Type color="baseTertiary">
                {t(
                  'modernComponents.WhatsAppImportUser.dialog.FileUploader.limit',
                  {
                    uploadLimit: UPLOAD_LIMIT_MB,
                  },
                )}
              </Type>
            </>
          )}
          {csvReady && (
            <Flex flexDirection="column" alignItems="center">
              <TextEllipsis title={fileName} width={480}>
                <Type size="18px" weight="semibold">
                  {fileName}
                </Type>
              </TextEllipsis>
              <Spacer factor={1} />
              <ButtonUnstyled
                onClick={selectFileClickHandler}
                className={css.button}
              >
                <Type color="accent1Normal">
                  {t(
                    'modernComponents.WhatsAppImportUser.dialog.FileUploader.Replace',
                  )}
                </Type>
              </ButtonUnstyled>
            </Flex>
          )}
        </div>
      </Flex>
      <input
        className={css.fileInput}
        ref={fileInputRef}
        data-testid="import-contacts__file-upload-input"
        onChange={(event) => {
          if (event.target.files?.length) {
            setFiles(event);
          }
        }}
        accept="text/*"
        type="file"
      />
      {(isFileSizeLimitError || csvParsingError) && (
        <>
          <Spacer factor={1} />
          <Type
            color="redNormal"
            size="12px"
            weight="semibold"
            data-testid="import-contacts__file-validation-error"
          >
            {getErrorMessage()}
          </Type>
        </>
      )}
      <Spacer factor={4} />
      <CsvNote />
      <Spacer factor={4} />
      <label>
        <Flex>
          <CheckBox
            checked={accept}
            data-testid="import-contacts__confirm-optin-checkbox"
            onChange={() => {
              setAccept((value) => !value);
            }}
          />
          <Spacer factor={0} horizontalFactor={2} />
          <Type>
            {t(
              'modernComponents.WhatsAppImportUser.dialog.FileUploader.dismiss',
            )}
          </Type>
        </Flex>
      </label>
      <WhatsappImportUserDialogFooter
        disabled={
          isFileSizeLimitError || !fileName || !accept || !!csvParsingError
        }
        onNextStepButtonClick={onDone}
      />
    </>
  );
};
