import { useMutation } from '@apollo/react-hooks';
import {
  ATTACH_PREMIUM_PAYMENT_METHOD,
  PREMIUM_PAYMENT_METHOD_QUERY,
} from '../../queries';

import {
  AttachPremiumPaymentMethod,
  AttachPremiumPaymentMethodVariables,
} from '../../@types/AttachPremiumPaymentMethod';

export const usePaymentMethodSetup = () => {
  const [invokeAttachPaymentMethod] = useMutation<
    AttachPremiumPaymentMethod,
    AttachPremiumPaymentMethodVariables
  >(ATTACH_PREMIUM_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: PREMIUM_PAYMENT_METHOD_QUERY,
      },
    ],
  });

  const attachPaymentMethod = async (paymentMethod: string) => {
    await invokeAttachPaymentMethod({
      variables: {
        paymentMethod,
      },
    });
  };

  return { attachPaymentMethod };
};
