import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Anchor } from '@ui/Links';
import { Button } from '@ui/Button';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import image1Src from './images/TestFacebookPageDialog_1.png';
import image2Src from './images/TestFacebookPageDialog_2.png';
import image3Src from './images/TestFacebookPageDialog_3.png';
import image4Src from './images/TestFacebookPageDialog_4.png';
import { TestSteps } from './components';

const getTestSteps = (pageId: string) => [
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText--122-1-toggle-the-entry-point-on',
        )}
        <br />
        <Anchor
          href={`https://m.me/${pageId}`}
          target="_blank"
          intent="external"
          hideArrow
        >
          {window.i18next.t('TestFacebookPageDialog-JSXText-9518-open')}
        </Anchor>
        {window.i18next.t('TestFacebookPageDialog-JSXText--160-and-delete-the')}
        <br />
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText-3829-conversation-with-the-bot-in',
        )}
        <br />
        {window.i18next.t('TestFacebookPageDialog-JSXText-1975-messenger')}
      </>
    ),
    src: image1Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText-1423-2-open-a-new-conversation-with',
        )}
        <br />
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText--132-your-facebook-page-in-messenger',
        )}
      </>
    ),
    src: image2Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText--188-3-click-on-an-icebreaker-question-or',
        )}
        <br />
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText-1200-the-get-started-button',
        )}
      </>
    ),
    src: image3Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText-4192-4-review-the-response-from-your',
        )}
        <br />
        {window.i18next.t(
          'TestFacebookPageDialog-JSXText--128-bot-in-messenger',
        )}
      </>
    ),
    src: image4Src,
  },
];

export const TestFacebookPageDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => {
  const botId = useCurrentBotId();
  const { pageId } = usePageConnected(botId);

  return (
    <DefaultDialog
      dialogStyle={{
        width: 572,
      }}
      header={window.i18next.t(
        'TestFacebookPageDialog-string-3774-test-your-facebook-page-entry-point',
      )}
      footer={
        <Button
          onClick={() => {
            sendEvent({
              category: 'flow',
              action: 'click Go to Messenger',
              label: 'test dialog',
            });
            window.open(`https://m.me/${pageId}`, '_blank');
            onRequestClose();
          }}
        >
          {window.i18next.t(
            'TestFacebookPageDialog-JSXText--868-go-to-messenger',
          )}
        </Button>
      }
      onDismiss={onRequestClose}
    >
      <TestSteps steps={getTestSteps(pageId || '')} />
    </DefaultDialog>
  );
};
