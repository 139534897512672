import React from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Translation } from '@translations';

import { sendEvent } from '@utils/Analytics';
import { useConnectInstagram } from '@utils/Data/Instagram/BotAccount';
import { useCurrentBotId } from '@utils/Routing';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';

import { ConnectInstagramPermissionsType } from './types';

import * as css from './ConnectInstagramPermissions.css';

interface ConnectInstagramPermissionsProps {
  onConnected(error?: any): void;
  type: ConnectInstagramPermissionsType;
}
export const ConnectInstagramPermissions: React.FC<ConnectInstagramPermissionsProps> =
  ({ type, onConnected }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId()!;
    const { connectInstagram, instagramIsConnecting } = useConnectInstagram(
      botId,
      onConnected,
      onConnected,
    );
    const handleAction = () => {
      sendEvent({
        category: 'instagram connect popup',
        action: 'click update',
        label: 'troubleshooting',
        propertyBag: { reason: 'permissions' },
      });
      connectInstagram();
    };
    return (
      <ConnectInstagramDialogContent
        header={t(`common.Instagram.dialog.permissions.${type}.header`)}
        footerRight={
          <Button
            intent="primary"
            onClick={handleAction}
            data-testid={`instagram-connect-dialog__permissions__${type}-button`}
            loading={instagramIsConnecting}
          >
            {t(`common.Instagram.dialog.permissions.${type}.action`)}
          </Button>
        }
        data-testid="instagram-connect-dialog__permissions"
      >
        <Flex flexDirection="column">
          {type !== ConnectInstagramPermissionsType.get && (
            <>
              <Trans
                i18nKey="common.Instagram.dialog.permissions.subheader"
                t={t}
              >
                <Type size="15px">
                  Go to your{' '}
                  <a
                    className={css.link}
                    href="https://www.facebook.com/settings?tab=business_tools"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Business Integrations
                  </a>{' '}
                  → <strong>Chatfuel</strong> → make sure your Instagram account
                  is checked for the following permissions:
                </Type>
              </Trans>
              <Spacer factor={6} />
            </>
          )}
          {[1, 2, 3].map((check) => (
            <Flex key={check} className={css.check} alignItems="center">
              <Icon icon="check" size="20px" />
              <Spacer horizontalFactor={3} />
              <Type size="15px">
                {t(
                  `common.Instagram.dialog.permissions.check${check}` as Translation,
                )}
              </Type>
            </Flex>
          ))}
        </Flex>
      </ConnectInstagramDialogContent>
    );
  };
