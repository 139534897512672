import ng from 'angular';
import { react2angular } from 'react2angular';
import { SelectBlockPopup } from './SelectBlockPopup';

export const ngSelectBlockPopup = ng
  .module('app.modernComponents.selectBlockPopup', [])
  .component(
    'selectBlockPopup',
    react2angular(
      SelectBlockPopup,
      ['botId', 'currentBlockId', 'onBlockSelected', 'onRequestClose'],
      [],
    ),
  ).name;
