interface SubscriptionInfo {
  status: string | null;
}

export enum SubscriptionStatus {
  incomplete = 'incomplete',
  incomplete_expired = 'incomplete_expired',
  trialing = 'trialing',
  active = 'active',
  past_due = 'past_due',
  // У Stripe status называется "canceled", а у нас "cancelled"
  canceled = 'canceled',
  cancelled = 'cancelled',
  unpaid = 'unpaid',
}

const warningStatuses = new Set([
  SubscriptionStatus.incomplete,
  SubscriptionStatus.incomplete_expired,
  SubscriptionStatus.trialing,
  SubscriptionStatus.past_due,
  SubscriptionStatus.canceled,
  SubscriptionStatus.cancelled,
  SubscriptionStatus.unpaid,
]);

export function isWarning(subscription: SubscriptionInfo) {
  return (
    subscription.status !== null &&
    warningStatuses.has(subscription.status as unknown as SubscriptionStatus)
  );
}
