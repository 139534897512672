import React from 'react';
import { CheckboxTree } from '@ui/Tree/CheckboxTree';
import {
  TreeItemData,
  TreeItem,
} from '@components/FlowBuilder/views/entry-points/common/components/ChooseAdsPopup/components/TreeItem';
import { PluginType } from '@components/FlowBuilder/types';
import Maybe from 'graphql/tsutils/Maybe';
import {
  adValidator,
  ValidatorAd,
} from '@components/FlowBuilder/views/entry-points/common/components/ChooseAdsPopup/components/utils';
import {
  ItemLoadingPlaceholder,
  PostsListProps,
} from '../ChooseItemFromListDialog';

export type AdPost = TreeItemData & ValidatorAd;

export interface ChooseAdPostsListProps {
  posts: Array<AdPost>;
  postsLoading: boolean;
  loadMore: PostsListProps<TreeItemData>['loadMore'];
  pageId: string;
  pluginId: PluginType;
  checked: Map<string, boolean>;
  currentEntryPointId: string;
  setChecked: (value: Map<string, boolean>) => void;
  resolveAdConflict: (adId: string, entryPointBotId: Maybe<string>) => void;
}

export const ChooseAdPostsList: React.FC<ChooseAdPostsListProps> = ({
  posts,
  postsLoading,
  loadMore,
  pageId,
  checked,
  pluginId,
  setChecked,
  resolveAdConflict,
  currentEntryPointId,
}) => {
  const checkedIds = Array.from(checked.keys());

  return (
    <CheckboxTree
      height={322}
      treeData={posts}
      checked={[...checked.keys()]}
      itemSize={() => 52}
      onCheckedChange={() => null}
      onExpandedChange={() => null}
      filterTree={() => false}
      expanded={[]}
      threshold={20}
      isItemLoaded={(index, treeLength) => index < treeLength}
      loadMoreItems={loadMore}
      renderItem={({ data }) => {
        const state = Boolean(checked.get(data.id));
        const { error, reason } = adValidator(
          data,
          checkedIds,
          pageId,
          currentEntryPointId,
        );

        return (
          <div>
            <TreeItem
              key={data.id}
              data={data}
              onResolveConflictClick={(entryPointBotId) => {
                resolveAdConflict(data.id, entryPointBotId);
              }}
              onCheckedChange={() => {
                setChecked(new Map(checked.set(data.id, !state)));
              }}
              pluginId={pluginId}
              validationError={error}
              reason={reason}
              checked={state}
            />
          </div>
        );
      }}
      renderLoader={() => (postsLoading ? <ItemLoadingPlaceholder /> : null)}
    />
  );
};
