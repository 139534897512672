import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { getComponentWithRefAndName } from '@utils/withRef';
import { useTestThisMarked } from './useTestThisMarked';

interface TestThisButtonProps {
  id?: string;
  buttonLabel?: string;
  icon?: React.ReactNode;
  onClick(): void;
  onMouseEnter?(): void;
}

export const TestThisButton = getComponentWithRefAndName<
  HTMLButtonElement,
  TestThisButtonProps
>('TestThisButton', (props, ref) => {
  const { t } = useSafeTranslation();
  const marked = useTestThisMarked();

  const baseStyle: React.CSSProperties = { minWidth: 145 };
  const markedStyle: React.CSSProperties = {
    boxShadow: '0 0 0 3px var(--blue)',
  };

  const style = marked ? { ...baseStyle, ...markedStyle } : baseStyle;
  const { buttonLabel, ...buttonProps } = props;

  return (
    <Button {...buttonProps} ref={ref} intent="secondary" style={style}>
      {buttonLabel || t('modernComponents.TestThis.testThisFlow')}
    </Button>
  );
});
