export enum ConnectInstagramStep {
  account = 'account',
  error = 'error',
  success = 'success',
  facebookPageError = 'facebookPageError',
  givePermissions = 'givePermissions',
  permissions = 'permissions',
  troubleshooting = 'troubleshooting',
  requirements = 'requirements',
  done = 'done',
  givingPermissions = 'givingPermissions',
}

export enum ConnectInstagramVariant {
  refresh = 'refresh',
  known = 'known',
  full = 'full',
  none = 'none',
}

export enum ConnectInstagramQueryField {
  step = 'cigStep',
}
export type ConnectInstagramQueryParams = {
  [ConnectInstagramQueryField.step]: ConnectInstagramStep;
};

export enum ConnectInstagramRestorationQueryField {
  blockId = 'cigBlockId',
  cardId = 'cigCardId',
  flowId = 'cigFlowId',
  entryPointId = 'cigEntryPointId',
  name = 'cigName',
}
export type ConnectInstagramRestorationQueryParams = Partial<
  Record<ConnectInstagramRestorationQueryField, string>
>;
