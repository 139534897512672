import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  BotBillingDetailsQuery,
  BotBillingDetailsQueryVariables,
} from './@types/BotBillingDetailsQuery';
import {
  UpdateBotBillingDetailsMutation,
  UpdateBotBillingDetailsMutationVariables,
} from './@types/UpdateBotBillingDetailsMutation';
import { BOT_BILLING_DETAILS, BOT_BILLING_DETAILS_MUTATION } from './queries';

export const useBillingDetailsData = (botId: string) => {
  const { data, loading, error } = useQuery<
    BotBillingDetailsQuery,
    BotBillingDetailsQueryVariables
  >(BOT_BILLING_DETAILS, {
    variables: { botId },
  });

  return {
    billingDetailsData: data?.bot,
    billingDetailsLoading: loading,
    billingDetailsError: error,
  };
};

export const useUpdateBotBillingDetails = () => {
  const [mutation, queryResult] = useMutation<
    UpdateBotBillingDetailsMutation,
    UpdateBotBillingDetailsMutationVariables
  >(BOT_BILLING_DETAILS_MUTATION);

  const updateBotBillingDetails = (
    variables: UpdateBotBillingDetailsMutationVariables,
  ) => {
    return mutation({ variables });
  };

  return [updateBotBillingDetails, queryResult] as const;
};
