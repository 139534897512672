import React from 'react';
import { Callout, CalloutVariantKey } from '@ui/Callout';
import { DeviceMediaProps, useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { RoleConsumer } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './ShopifyCallout.css';

export interface ShopifyCalloutProps extends TestLocator, DeviceMediaProps {
  type: CalloutVariantKey;
  text: React.ReactNode;
  onClick: VoidFunction;
  buttonText?: string;
}

export const ShopifyCallout: React.FC<ShopifyCalloutProps> = ({
  type,
  text,
  onClick,
  buttonText,
  'data-testid': dataTestId,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const { getValueDueToSize } = useDeviceMedia(props);

  return (
    <Callout
      {...props}
      type={type}
      text={text}
      flexDirection={getValueDueToSize('column', 'row')}
      alignItems={getValueDueToSize('flex-start', 'center')}
      data-testid={dataTestId}
      icon="shopifyBag"
      button={
        <RoleConsumer domain="pro" can={Permission.EDIT}>
          {({ allowed }) => (
            <Button
              onClick={onClick}
              intent="secondary"
              disabled={!allowed}
              className={css.button}
              data-testid="shopify-payment-callout__button"
            >
              {buttonText || t('ShopifyOnboarding.payment.button')}
            </Button>
          )}
        </RoleConsumer>
      }
    />
  );
};
