import React, {
  SyntheticEvent,
  KeyboardEvent,
  useState,
  ChangeEvent,
} from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import TextArea from 'react-textarea-autosize';
import some from 'lodash-es/some';
import * as css from './MessageInput.css';

const MAX_INPUT_LENGTH = 640;

interface MessageInputProps {
  onSubmit(message: string): void;
  autoFocus: boolean;
}

export const MessageInput: React.FC<MessageInputProps> = ({
  onSubmit,
  autoFocus,
}) => {
  const [text, setText] = useState('');
  const { t } = useSafeTranslation();

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const trimmedText = text.trim();
    if (!trimmedText) return;
    onSubmit(trimmedText);
    setText('');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    const { target } = event;
    const enterIsPressed = event.key === 'Enter';
    // allow line break
    const ctrlOrMetaOrShiftIsPressed =
      event.metaKey || event.ctrlKey || event.shiftKey;

    if (!enterIsPressed || ctrlOrMetaOrShiftIsPressed) return;

    const targetIsFormControl = some(
      form.elements,
      (control) => control === target,
    );

    if (targetIsFormControl) {
      handleSubmit(event);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const nextText = event.target.value;
    setText(nextText.substring(0, MAX_INPUT_LENGTH));
  };

  return (
    <form
      className={css.form}
      onSubmit={handleSubmit}
      onKeyDown={handleKeyDown}
    >
      <TextArea
        minRows={1}
        maxRows={5}
        className={css.textarea}
        value={text}
        onChange={handleChange}
        autoFocus={autoFocus}
        placeholder={t('modernComponents.TestThis.MessageInput.placeholder')}
        data-testid="test-this_message-send-input"
      />
      <Button
        className={css.button}
        type="submit"
        intent="text"
        data-testid="test-this_message-send-button"
      >
        {t('modernComponents.TestThis.MessageInput.send')}
      </Button>
    </form>
  );
};
