import React from 'react';
import { DefaultDialog, DialogHeading } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './ConnectStripeDialog.css';
import { ConnectStripeForm } from '../common/ConnectStripeForm';

interface ConnectStripeDialogProps {
  onDismiss: VoidFunction;
}

const DIALOG_STYLE = {
  width: 500,
};

export const ConnectStripeDialog: React.FC<ConnectStripeDialogProps> = ({
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  return (
    <DefaultDialog onDismiss={onDismiss} dialogStyle={DIALOG_STYLE}>
      <div data-testid="connect-stripe-dialog">
        <DialogHeading>
          <div className={css.title}>
            {t('modernComponents.Stripe.ConnectStripeDialog.title')}
          </div>
        </DialogHeading>
        <ConnectStripeForm onConnected={onDismiss} />
      </div>
    </DefaultDialog>
  );
};
