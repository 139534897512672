import React from 'react';
import * as css from './ImportUsersAttributes.css';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon } from '@ui/Icon';
import { Tooltip2 } from '@ui/Tooltip2';
import imageSrc from './images/import-attirbutes.png';
import { useWhatsappImportUsersSession } from '../../../../hocks/useWhatsappImportUsersSession';
import { ImportUsersAttributeItem } from './components/ImportUsersAttributeItem';
import { ButtonUnstyled } from '@ui/Button';
import { sendEvent } from '@utils/Analytics';

const ATTRIBUTES_LIMIT = 20;

export const ImportUsersAttributes: React.FC = () => {
  const { t } = useSafeTranslation();
  const {
    attributes,
    attributesErrors,
    addAttribute,
    updateAttribute,
    removeAttribute,
  } = useWhatsappImportUsersSession();
  return (
    <>
      <Spacer factor={8} />
      <div className={css.box}>
        <Spacer factor={3} />
        <Flex alignItems="center" gapFactor={2}>
          <Type weight="medium">
            {t(
              'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.attributes.title',
            )}
          </Type>
          <Tooltip2
            content={
              <>
                <img
                  src={imageSrc}
                  alt=""
                  width={214}
                  height={147}
                  className={css.image}
                />
                <Spacer factor={4} />
                <div className={css.text}>
                  {t(
                    'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.attributes.tooltip',
                  )}
                </div>
              </>
            }
          >
            {(ref, bind) => (
              <Icon color="black" icon="info" size="20px" ref={ref} {...bind} />
            )}
          </Tooltip2>
        </Flex>
        <Spacer factor={2} />
        <Flex
          gapFactor={3}
          flexDirection="column"
          className={css.list}
          data-testid="import-contacts__common-attributes-mapping-table"
        >
          {attributes.map((attribute) => (
            <ImportUsersAttributeItem
              key={attribute.id}
              attribute={attribute}
              showRemoveButton={attributes.length > 1}
              error={attributesErrors[attribute.id]}
              onChange={updateAttribute}
              onRemoveRequest={removeAttribute}
            />
          ))}
        </Flex>
        {attributes.length < ATTRIBUTES_LIMIT && (
          <>
            <Spacer factor={3} />
            <ButtonUnstyled
              data-testid="import-contacts__add-attribute-button"
              onClick={() => {
                sendEvent({
                  category: 'people tab',
                  label: 'whatsapp user import',
                  action: 'add user attribute click',
                });
                addAttribute();
              }}
            >
              <Type color="blue" weight="medium">
                {t(
                  'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.attributes.add',
                )}
              </Type>
            </ButtonUnstyled>
          </>
        )}
      </div>
    </>
  );
};
