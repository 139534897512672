import React from 'react';
import { DIALOG_STYLE } from '../../consts';
import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Type } from '@ui/Type';
import { useFacebookPageCreate } from '@utils/FacebookPages/useFacebookPageCreate';
import { sendEvent } from '@utils/Analytics';

interface TransferPlanDialogEmptyProps {
  onDismiss: () => void;
  onRefetchPagesRequest: () => void;
}

export const TransferPlanDialogEmpty: React.FC<TransferPlanDialogEmptyProps> =
  ({ onDismiss, onRefetchPagesRequest }) => {
    const { t } = useSafeTranslation();
    const { facebookPageCreate } = useFacebookPageCreate(onRefetchPagesRequest);

    return (
      <DefaultDialog
        header={t(
          'modernComponents.TransferPlanDialog.TransferPlanDialogEmpty.title',
        )}
        onDismiss={onDismiss}
        dialogStyle={DIALOG_STYLE}
        footer={
          <Button
            data-testid="transfer-plan_create-page-item"
            onClick={() => {
              sendEvent({
                category: 'transfer plan',
                label: 'empty pages list',
                action: 'create page click',
              });
              facebookPageCreate();
            }}
          >
            {t(
              'modernComponents.TransferPlanDialog.TransferPlanDialogEmpty.createPageButton',
            )}
          </Button>
        }
      >
        <Type size="18px">
          {t(
            'modernComponents.TransferPlanDialog.TransferPlanDialogEmpty.text',
          )}
        </Type>
      </DefaultDialog>
    );
  };
