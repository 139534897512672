import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';
import { Toggle } from 'react-powerplug';
import moment from 'moment';
import OutsideClick from 'react-outsideclick';

import { Button, ButtonUnstyled } from '@ui/Button';
import { CheckBox } from '@ui/CheckBox';
import { DatePicker } from '@ui/DatePicker';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import { MenuItem } from '@ui/Menu';
import { RadioButton } from '@ui/Radio';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';

import { Translation } from '@translations';
import {
  ShopifyDiscountsCodeType,
  ShopifyDiscountsCodeValueType,
} from '@globals';

import { ShopifyDiscountsCodesQuery_shopifyDiscountsCodes as ShopifyDiscountsCode } from '../../@types/ShopifyDiscountsCodesQuery';

import * as css from './ShopifyDiscountsCodeEditor.css';

const shopifyDiscountsCodeValueTypes: Array<{
  id: ShopifyDiscountsCodeValueType;
  title: Translation;
}> = [
  {
    id: ShopifyDiscountsCodeValueType.fixed_amount,
    title: 'shopify.openShopifyStore.editor.form.valueType.fixedAmount',
  },
  {
    id: ShopifyDiscountsCodeValueType.percentage,
    title: 'shopify.openShopifyStore.editor.form.valueType.percentage',
  },
];

type ShopifyDiscountsCodeEditorForm = Omit<
  Partial<ShopifyDiscountsCode>,
  'start_date' | 'end_date'
> & {
  limitPerCode: boolean;
  periodType: 'limited' | 'unlimited';
  start_date: number;
  end_date?: number | null;
};

export interface ShopifyDiscountsCodeEditorProps {
  discountCode?: ShopifyDiscountsCode | null;
  onSubmit(
    discountCode: Omit<ShopifyDiscountsCode, '__typename' | 'shop_id'>,
  ): void;
  onClose(): void;
}

const ALLOWED_CONTROL_KEYS = [
  'Enter',
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
];

export const ShopifyDiscountsCodeEditor: React.FC<ShopifyDiscountsCodeEditorProps> =
  ({ discountCode, onSubmit, onClose }) => {
    const { t } = useSafeTranslation();

    const formik = useFormik<ShopifyDiscountsCodeEditorForm>({
      initialValues: {
        ...discountCode,

        periodType:
          discountCode?.start_date && discountCode?.end_date
            ? 'limited'
            : 'unlimited',
        start_date: discountCode?.start_date
          ? parseInt(discountCode?.start_date, 10)
          : Date.now(),
        end_date: discountCode?.end_date
          ? parseInt(discountCode?.end_date, 10)
          : null,

        limitPerCode: !!discountCode?.usage_limit,
        once_per_customer: !!discountCode?.once_per_customer,
      },
      validationSchema: Yup.object().shape({
        code: Yup.string()
          .required(t('shopify.openShopifyStore.editor.form.code.error'))
          .min(1),
        discount_type: Yup.string().oneOf([
          ShopifyDiscountsCodeType.common,
          ShopifyDiscountsCodeType.unique,
        ]),

        value_type: Yup.string().oneOf([
          ShopifyDiscountsCodeValueType.fixed_amount,
          ShopifyDiscountsCodeValueType.percentage,
        ]),
        value: Yup.number().when('value_type', {
          is: ShopifyDiscountsCodeValueType.percentage,
          then: Yup.number()
            .round('round')
            .lessThan(
              100.01,
              t(
                'shopify.openShopifyStore.editor.form.value.error.percent.more',
              ),
            )
            .moreThan(
              -0.01,
              t(
                'shopify.openShopifyStore.editor.form.value.error.percent.less',
              ),
            )
            .required(
              t('shopify.openShopifyStore.editor.form.value.error.required'),
            ),
          otherwise: Yup.number()
            .moreThan(
              -0.01,
              t('shopify.openShopifyStore.editor.form.value.error.amount'),
            )
            .required(
              t('shopify.openShopifyStore.editor.form.value.error.required'),
            ),
        }),

        periodType: Yup.string().oneOf(['limited', 'unlimited']),
        start_date: Yup.number().when('periodType', {
          is: 'limited',
          then: Yup.number()
            .typeError(
              t('shopify.openShopifyStore.editor.form.start.error.required'),
            )
            .required(
              t('shopify.openShopifyStore.editor.form.start.error.required'),
            ),
          otherwise: Yup.number().nullable(true),
        }),
        end_date: Yup.number()
          .nullable(true)
          .when('periodType', {
            is: 'limited',
            then: Yup.number().moreThan(
              Yup.ref('start_date'),
              t('shopify.openShopifyStore.editor.form.end.error.greater'),
            ),
            otherwise: Yup.number().nullable(true),
          }),

        limitPerCode: Yup.boolean(),
        usage_limit: Yup.number().when('limitPerCode', {
          is: true,
          then: Yup.number()
            .typeError(
              t('shopify.openShopifyStore.editor.form.limit.error.required'),
            )
            .moreThan(
              0,
              t('shopify.openShopifyStore.editor.form.limit.error.moreThan'),
            )
            .required(
              t('shopify.openShopifyStore.editor.form.limit.error.required'),
            ),
          otherwise: Yup.number().nullable(true),
        }),
        once_per_customer: Yup.boolean(),
      }),
      onSubmit(values) {
        onSubmit({
          id: values.id!,
          price_rule_id: values.price_rule_id!,
          code: (values.code || '').trim().toUpperCase(),
          discount_type: values.discount_type!,
          value: values.value!,
          value_type: values.value_type!,
          usage_limit: values.limitPerCode ? values.usage_limit! : null,
          once_per_customer: values.once_per_customer!,
          start_date: (values.start_date || Date.now()).toString(10),
          end_date:
            values.periodType === 'limited' &&
            values.start_date &&
            values.end_date
              ? values.end_date.toString(10)
              : null,
        });
      },
    });

    const isEditing = Boolean(discountCode?.id);

    return (
      <DefaultDialog
        className={css.dialog}
        onDismiss={onClose}
        header={
          <Flex alignItems="center">
            <Type size="24px">
              {isEditing
                ? t('shopify.openShopifyStore.editor.headerEdit')
                : t('shopify.openShopifyStore.editor.headerCreate')}
            </Type>
            <Spacer horizontalFactor={1} />
            <Tooltip2
              content={
                <Type size="12px" color="white">
                  {isEditing
                    ? t('shopify.openShopifyStore.editor.headerEditTooltip')
                    : t('shopify.openShopifyStore.editor.headerCreateTooltip')}
                </Type>
              }
            >
              {(ref, bind) => (
                <Icon icon="info" color="grey" ref={ref} {...bind} />
              )}
            </Tooltip2>
          </Flex>
        }
        footer={
          <Flex className={css.footer}>
            <Button
              intent="primary"
              onClick={formik.submitForm}
              disabled={!formik.isValid}
              style={{ marginBottom: -10 }}
            >
              {isEditing
                ? t('shopify.openShopifyStore.editor.submitButtonEdit')
                : t('shopify.openShopifyStore.editor.submitButtonCreate')}
            </Button>
          </Flex>
        }
      >
        <Flex flexDirection="column">
          <Type size="15px_DEPRECATED" weight="semibold">
            {t('shopify.openShopifyStore.editor.form.type.label')}
          </Type>
          <Spacer factor={2} />
          <RadioButton
            name="discount_type"
            id="common"
            disabled={!!formik.values.price_rule_id}
            label={t('shopify.openShopifyStore.editor.form.type.common')}
            value={formik.values.discount_type}
            onChange={formik.handleChange}
          />
          <Spacer factor={2} />
          <RadioButton
            name="discount_type"
            id="unique"
            disabled={!!formik.values.price_rule_id}
            label={t('shopify.openShopifyStore.editor.form.type.unique')}
            value={formik.values.discount_type}
            onChange={formik.handleChange}
          />
        </Flex>

        <Spacer factor={5} />

        <Flex justifyContent="space-between">
          <Flex flexDirection="column" className={css.column}>
            {formik.values.discount_type === 'common' ? (
              <Type size="15px_DEPRECATED" weight="semibold">
                {t('shopify.openShopifyStore.editor.form.code.label')}
              </Type>
            ) : (
              <Flex className={css.label}>
                <Type size="15px_DEPRECATED" weight="semibold">
                  {t('shopify.openShopifyStore.editor.form.prefix.label')}
                </Type>
                <Spacer horizontalFactor={1} />
                <Tooltip2
                  content={
                    <Type size="12px" color="white">
                      {t('shopify.openShopifyStore.editor.form.prefix.tooltip')}
                    </Type>
                  }
                >
                  {(ref, bind) => (
                    <Icon icon="info" color="grey" ref={ref} {...bind} />
                  )}
                </Tooltip2>
              </Flex>
            )}
            <Spacer factor={2} />
            <Input
              containerClassName={css.input}
              name="code"
              value={(formik.values.code || '').toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t(
                'shopify.openShopifyStore.editor.form.code.placeholder',
              )}
              error={!!formik.errors.code && formik.touched.code}
              // На v1 решили убрать возможность редактирования дискаунт кода. Из-за тех. сложностей, планируется вернуть в v2
              disabled={isEditing}
              renderErrorText={
                !!formik.errors.code && formik.touched.code
                  ? () => (
                      <Flex className={css.column}>{formik.errors.code}</Flex>
                    )
                  : undefined
              }
            />
          </Flex>
          <Flex flexDirection="column" className={css.column}>
            <Type size="15px_DEPRECATED" weight="semibold">
              {t('shopify.openShopifyStore.editor.form.codeExample.label')}
            </Type>
            <Spacer factor={2} />
            <Flex
              className={css.codeExample}
              alignItems="center"
              justifyContent="center"
            >
              <Type size="15px_DEPRECATED" color="greyDark">
                {t(
                  'shopify.openShopifyStore.editor.form.codeExample.placeholder',
                )}
              </Type>
            </Flex>
          </Flex>
        </Flex>

        <Spacer factor={5} />

        <Flex justifyContent="space-between">
          <Flex flexDirection="column" className={css.column}>
            <Type size="15px_DEPRECATED" weight="semibold">
              {t('shopify.openShopifyStore.editor.form.valueType.label')}
            </Type>
            <Spacer factor={2} />
            <SimpleCombobox<{
              id: ShopifyDiscountsCodeValueType;
              title: Translation;
            }>
              renderInput={({ getToggleButtonProps, isOpen, selectedItem }) => (
                <Button
                  {...getToggleButtonProps()}
                  style={{ width: 227 }}
                  intent="secondary"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    {t(selectedItem.title)}
                    <Icon
                      className={cn({
                        [css.selectInputIconReversed]: isOpen,
                      })}
                      icon="triangle"
                    />
                  </Flex>
                </Button>
              )}
              renderItem={({ item, index, highlightedIndex, getItemProps }) => (
                <MenuItem
                  className={css.input}
                  key={item.id}
                  {...getItemProps({ item })}
                  title={t(item.title)}
                  active={index === highlightedIndex}
                />
              )}
              menuBoxClassName={css.column}
              items={shopifyDiscountsCodeValueTypes}
              itemToString={(item) => (item ? t(item.title) : '')}
              initialSelectedItem={
                formik.values.value_type === 'fixed_amount'
                  ? shopifyDiscountsCodeValueTypes[0]
                  : shopifyDiscountsCodeValueTypes[1]
              }
              onChange={(item) => {
                if (item) {
                  formik.setFieldValue('value_type', item.id);
                  formik.setTouched({ ...formik.touched, value: true }, false);
                }
              }}
            />
          </Flex>
          <Flex flexDirection="column" className={css.column}>
            <Type size="15px_DEPRECATED" weight="semibold">
              {t('shopify.openShopifyStore.editor.form.value.label')}
            </Type>
            <Spacer factor={2} />
            <Input
              containerClassName={css.input}
              name="value"
              type="number"
              value={formik.values.value}
              onChange={(event) => {
                if (
                  formik.values.value_type !== 'fixed_amount' &&
                  parseInt(event.currentTarget.value, 10) > 100
                ) {
                  // eslint-disable-next-line no-param-reassign
                  event.currentTarget.value = '100';
                }
                formik.handleChange(event);
              }}
              onKeyDown={(event) => {
                if (
                  Number.isNaN(Number.parseInt(event.key, 10)) &&
                  ![
                    ...ALLOWED_CONTROL_KEYS,
                    ...(formik.values.value_type === 'fixed_amount'
                      ? [',', '.']
                      : []),
                  ].includes(event.key)
                ) {
                  event.preventDefault();
                }
              }}
              onBlur={formik.handleBlur}
              error={!!formik.errors.value && formik.touched.value}
              renderErrorText={
                !!formik.errors.value && formik.touched.value
                  ? () => (
                      <Flex className={css.column}>{formik.errors.value}</Flex>
                    )
                  : undefined
              }
              placeholder={t(
                'shopify.openShopifyStore.editor.form.value.placeholder',
              )}
            />
          </Flex>
        </Flex>

        <Spacer factor={5} />

        <Flex flexDirection="column">
          <Type size="15px_DEPRECATED" weight="semibold">
            {t('shopify.openShopifyStore.editor.form.period.label')}
          </Type>
          <Spacer factor={2} />
          <RadioButton
            name="periodType"
            id="limited"
            label={t('shopify.openShopifyStore.editor.form.period.limited')}
            value={formik.values.periodType}
            onChange={formik.handleChange}
          />
          <Spacer factor={2} />
          <RadioButton
            name="periodType"
            id="unlimited"
            label={t('shopify.openShopifyStore.editor.form.period.unlimited')}
            value={formik.values.periodType}
            onChange={formik.handleChange}
          />
        </Flex>

        {formik.values.periodType === 'limited' && (
          <>
            <Spacer factor={5} />
            <Flex justifyContent="space-between">
              <Toggle>
                {({
                  on: isDatePickerOpened,
                  toggle: toggleIsDatePickerOpened,
                  set: setIsDatePickerOpened,
                }) => (
                  <OutsideClick
                    onClickOutside={() => setIsDatePickerOpened(false)}
                  >
                    <Flex flexDirection="column">
                      <Type size="15px_DEPRECATED" weight="semibold">
                        {t('shopify.openShopifyStore.editor.form.start.label')}
                      </Type>
                      <Spacer factor={2} />
                      <span className={css.selectInputContainer}>
                        <Input
                          containerClassName={css.input}
                          name="start_date"
                          value={
                            formik.values.start_date
                              ? moment
                                  .utc(formik.values.start_date)
                                  .format('YYYY-MM-DD')
                              : ''
                          }
                          onClick={() => {
                            formik.touched.start_date = true;
                            toggleIsDatePickerOpened();
                          }}
                          readOnly
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            !!formik.errors.start_date &&
                            formik.touched.start_date &&
                            formik.touched.end_date
                          }
                          renderErrorText={
                            !!formik.errors.start_date &&
                            formik.touched.start_date &&
                            formik.touched.end_date
                              ? () => (
                                  <Flex className={css.column}>
                                    {formik.errors.start_date}
                                  </Flex>
                                )
                              : undefined
                          }
                          placeholder={t(
                            'shopify.openShopifyStore.editor.form.start.placeholder',
                          )}
                          renderIconEnd={() =>
                            formik.values.start_date && (
                              <ButtonUnstyled
                                onClick={() => {
                                  formik.setFieldValue('start_date', undefined);
                                  setIsDatePickerOpened(false);
                                }}
                              >
                                <Icon icon="close" size="20px" />
                              </ButtonUnstyled>
                            )
                          }
                        />
                        {isDatePickerOpened && (
                          <DatePicker
                            className={css.selectInputDatePicker}
                            onChange={(value) => {
                              if (value) {
                                formik.setFieldValue(
                                  'start_date',
                                  value.unix() * 1000,
                                );
                                setIsDatePickerOpened(false);
                              }
                            }}
                            value={
                              formik.values.start_date
                                ? moment.utc(formik.values.start_date)
                                : undefined
                            }
                          />
                        )}
                      </span>
                    </Flex>
                  </OutsideClick>
                )}
              </Toggle>
              <Toggle>
                {({
                  on: isDatePickerOpened,
                  toggle: toggleIsDatePickerOpened,
                  set: setIsDatePickerOpened,
                }) => (
                  <OutsideClick
                    onClickOutside={() => setIsDatePickerOpened(false)}
                  >
                    <Flex flexDirection="column">
                      <Type size="15px_DEPRECATED" weight="semibold">
                        {t('shopify.openShopifyStore.editor.form.end.label')}
                      </Type>
                      <Spacer factor={2} />
                      <div className={css.selectInputContainer}>
                        <Input
                          containerClassName={css.input}
                          name="end_date"
                          value={
                            formik.values.end_date
                              ? moment
                                  .utc(formik.values.end_date)
                                  .format('YYYY-MM-DD')
                              : ''
                          }
                          onClick={() => {
                            formik.touched.end_date = true;
                            toggleIsDatePickerOpened();
                          }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            !!formik.errors.end_date &&
                            (formik.touched.start_date ||
                              formik.touched.end_date)
                          }
                          renderErrorText={
                            !!formik.errors.end_date &&
                            (formik.touched.start_date ||
                              formik.touched.end_date)
                              ? () => (
                                  <Flex className={css.column}>
                                    {formik.errors.end_date}
                                  </Flex>
                                )
                              : undefined
                          }
                          placeholder={t(
                            'shopify.openShopifyStore.editor.form.end.placeholder',
                          )}
                          readOnly
                          renderIconEnd={() =>
                            formik.values.end_date && (
                              <ButtonUnstyled
                                onClick={() => {
                                  formik.setFieldValue('end_date', undefined);
                                  setIsDatePickerOpened(false);
                                }}
                              >
                                <Icon icon="close" size="20px" />
                              </ButtonUnstyled>
                            )
                          }
                        />
                        {isDatePickerOpened && (
                          <DatePicker
                            className={css.selectInputDatePicker}
                            onChange={(value) => {
                              if (value) {
                                formik.setFieldValue(
                                  'end_date',
                                  value.unix() * 1000,
                                );
                                setIsDatePickerOpened(false);
                              }
                            }}
                            value={
                              formik.values.end_date
                                ? moment.utc(formik.values.end_date)
                                : undefined
                            }
                          />
                        )}
                      </div>
                    </Flex>
                  </OutsideClick>
                )}
              </Toggle>
            </Flex>
          </>
        )}

        <Spacer factor={5} />

        <Flex flexDirection="column">
          <Type size="15px_DEPRECATED" weight="semibold">
            {t('shopify.openShopifyStore.editor.form.limit.label')}
          </Type>
          <Spacer factor={2} />
          <Flex alignItems="center" justifyContent="space-between">
            <Flex
              className={cn(css.column, css.checkbox)}
              alignItems="center"
              onClick={() => {
                formik.setFieldValue(
                  'limitPerCode',
                  !formik.values.limitPerCode,
                );
              }}
            >
              <CheckBox
                name="limitPerCode"
                checked={formik.values.limitPerCode}
                onChange={formik.handleChange}
              />
              <Spacer horizontalFactor={2} />
              <Type size="15px_DEPRECATED">
                {t('shopify.openShopifyStore.editor.form.limit.perCode')}
              </Type>
            </Flex>
            <Flex
              style={{
                flexDirection: 'column',
                alignSelf: 'flex-start',
                marginBottom:
                  !!formik.errors.usage_limit && formik.touched.usage_limit
                    ? -24
                    : undefined,
              }}
              className={css.column}
            >
              <Input
                containerClassName={css.input}
                type="number"
                name="usage_limit"
                disabled={!formik.values.limitPerCode}
                value={formik.values.usage_limit ?? ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                onKeyDown={(event) => {
                  if (
                    Number.isNaN(Number.parseInt(event.key, 10)) &&
                    !ALLOWED_CONTROL_KEYS.includes(event.key)
                  ) {
                    event.preventDefault();
                  }
                }}
                placeholder={t(
                  'shopify.openShopifyStore.editor.form.limit.placeholder',
                )}
                error={
                  !!formik.errors.usage_limit && formik.touched.usage_limit
                }
                renderErrorText={
                  !!formik.errors.usage_limit && formik.touched.usage_limit
                    ? () => (
                        <Flex className={css.column}>
                          {formik.errors.usage_limit}
                        </Flex>
                      )
                    : undefined
                }
              />
            </Flex>
          </Flex>
          <Spacer factor={2} />
          <Flex
            className={cn(css.column, css.checkbox)}
            alignItems="center"
            onClick={() => {
              formik.setFieldValue(
                'once_per_customer',
                !formik.values.once_per_customer,
              );
            }}
          >
            <CheckBox
              name="once_per_customer"
              checked={!!formik.values.once_per_customer}
              onChange={formik.handleChange}
            />
            <Spacer horizontalFactor={2} />
            <Type size="15px_DEPRECATED">
              {t('shopify.openShopifyStore.editor.form.limit.perCustomer')}
            </Type>
          </Flex>
        </Flex>
      </DefaultDialog>
    );
  };
