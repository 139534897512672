import React from 'react';
import { DIALOG_STYLE } from '../../consts';
import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Button } from '@ui/Button';
import { Type } from '@ui/Type';
import { showIntercom } from '@utils/Intercom';
import { sendEvent } from '@utils/Analytics';

interface TransferPlanDialogEmptyProps {
  onDismiss: () => void;
}

export const TransferPlanDialogError: React.FC<TransferPlanDialogEmptyProps> =
  ({ onDismiss }) => {
    const { t } = useSafeTranslation();

    return (
      <DefaultDialog
        header={t(
          'modernComponents.TransferPlanDialog.TransferPlanDialogError.title',
        )}
        onDismiss={onDismiss}
        dialogStyle={DIALOG_STYLE}
        footer={
          <Button
            data-testid="transfer-plan_contact-us-button"
            onClick={() => {
              showIntercom();
              onDismiss();
              sendEvent({
                category: 'transfer plan',
                label: 'error dialog',
                action: 'contact us click',
              });
            }}
          >
            {t(
              'modernComponents.TransferPlanDialog.TransferPlanDialogError.button',
            )}
          </Button>
        }
      >
        <Type size="18px">
          {t(
            'modernComponents.TransferPlanDialog.TransferPlanDialogError.text',
          )}
        </Type>
      </DefaultDialog>
    );
  };
