import React, { useState } from 'react';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';
import { getBotPrice } from '@utils/Bot';
import { plurals } from '@utils/Plurals';

import { Button } from '@ui/Button';
import { Anchor } from '@ui/Links';

import { PricingPopup } from '@components/PricingPopup';
import { getConfigureTabBotData_bot as Bot } from '@utils/Data/Pricing/@types/getConfigureTabBotData';

import { Modal } from '../../../../services';

import { Tos } from '../Tos';
import { ProPlanDisplayPrice } from '../ProPlanDisplayPrice';
import { DEFAULT_TERMS_CHECKED_VALUE } from '../../constants';

import * as css from './ProPlanManagementLite.css';

interface ProPlanManagementLiteProps {
  bot: Pick<Bot, 'status' | 'pricingData' | 'user_limit_reached'>;
  onGoPro: (botHasPage: boolean) => void;
  discountIsApplicable?: boolean;
  discountFactor?: number;
  discountPercentage?: number;
  discountExpiresAt?: number;
}

export const ProPlanManagementLite: React.FC<ProPlanManagementLiteProps> = ({
  bot,
  onGoPro,
  discountIsApplicable = false,
  discountFactor,
  discountPercentage,
  discountExpiresAt,
}) => {
  const [termsChecked, setTermsChecked] = useState(DEFAULT_TERMS_CHECKED_VALUE);
  const { currentUsers = 0, currency } = bot.pricingData || {};
  const price = getBotPrice(bot);

  const discount = discountIsApplicable
    ? {
        factor: discountFactor!,
        percentage: discountPercentage!,
        expiresAt: discountExpiresAt!,
      }
    : undefined;

  return (
    <div className={css.leftColumn}>
      <div className={css.price}>
        <div>
          {window.i18next.t('ProPlanManagementLite-JSXText-3148-for')}{' '}
          {plurals(
            currentUsers,
            window.i18next.t('ProPlanManagementLite-string-7736-subscriber'),
            window.i18next.t('ProPlanManagementLite-string--178-subscribers'),
          )}
        </div>
        <ProPlanDisplayPrice
          price={price}
          currency={currency ?? undefined}
          discountFactor={discountFactor}
          discountIsApplicable={discountIsApplicable}
        />
      </div>

      <div className={css.disclaimer}>
        {window.i18next.t(
          'ProPlanManagementLite-JSXText--180-price-adjusts-according-to',
        )}
        <br />
        {window.i18next.t('ProPlanManagementLite-JSXText-1030-the')}{' '}
        <Anchor
          intent="subtitle"
          size="regular"
          onClick={() => {
            Modal.show(({ close }) => (
              <PricingPopup
                pricingData={bot.pricingData!}
                currency={currency}
                discount={discount}
                onDismiss={close}
              />
            ));
          }}
        >
          {window.i18next.t(
            'ProPlanManagementLite-JSXText-1201-number-of-subscribers',
          )}
        </Anchor>
      </div>

      <Button
        onClick={() => {
          onGoPro(!!bot.status?.page_info?.id);
        }}
        intent={bot.user_limit_reached ? 'red' : 'primary'}
        className={cn(css.upgradeButton, 'test-upgrade-to-pro-button')}
        disabled={!termsChecked}
        data-testid="upgrade-to-pro-button"
      >
        {discountIsApplicable
          ? `${window.i18next.t(
              'ProPlanManagementLite-Template--110-upgrade-to-pro-with',
            )}${discountPercentage}${window.i18next.t(
              'ProPlanManagementLite-Template-3523-off',
            )}`
          : window.i18next.t(
              'ProPlanManagementLite-string--165-upgrade-to-pro',
            )}
      </Button>

      <div className={css.tos}>
        <Tos
          termsChecked={termsChecked}
          setTermsChecked={setTermsChecked}
          onChange={() => {
            sendEvent({
              category: 'pro plan payments',
              action: !termsChecked ? 'enable' : 'disable',
              label: 'terms of service',
            });
          }}
        />
      </div>
    </div>
  );
};
