import React from 'react';
import ng from 'angular';
import { react2angular } from 'react2angular';
import { equals } from 'ramda';
import debounce from 'lodash-es/debounce';
import UiUserAttributeEditor, {
  IUiUserAttributeEditorProps,
} from './UiUserAttributeEditor';
import { UserAttribute } from './UserAttributeTypes';

export interface IUiUserAttributeEditorFUState {
  attributes: UserAttribute[];
  lastChangesAttributes: UserAttribute[] | undefined;
}

/**
 * For use in angular (debounce events)
 */

export class UiUserAttributeEditorFU extends React.Component<
  IUiUserAttributeEditorProps,
  IUiUserAttributeEditorFUState
> {
  static getDerivedStateFromProps(
    props: IUiUserAttributeEditorProps,
    state: IUiUserAttributeEditorFUState,
  ) {
    const { attributes } = props;
    const { lastChangesAttributes } = state;

    if (!lastChangesAttributes || !equals(lastChangesAttributes, attributes)) {
      return {
        attributes,
        lastChangesAttributes: attributes,
      };
    }

    return state;
  }

  state = {
    attributes: this.props.attributes,
    lastChangesAttributes: undefined,
  };

  lastAttributes: UserAttribute[] | undefined;

  fireOnChange = debounce((attributes) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(attributes);
    }
  }, 300);

  componentWillUnmount() {
    this.fireOnChange.cancel();
  }

  handleOnChange = (attributes: UserAttribute[]) => {
    this.setState({
      attributes,
    });
    this.fireOnChange(attributes);
  };

  handleOnSaveRequest = () => {
    setImmediate(() => {
      const { onSaveRequest } = this.props;
      const { attributes } = this.state;

      if (onSaveRequest) {
        onSaveRequest(attributes);
      }
    });
  };

  render() {
    return (
      <UiUserAttributeEditor
        {...this.props}
        attributes={this.state.attributes}
        onChange={this.handleOnChange}
        onSaveRequest={this.handleOnSaveRequest}
        onDismissModal={this.props.onDismissModal}
        isSearchBoxNarrow={this.props.isSearchBoxNarrow}
      />
    );
  }
}

export const ngUiUserAttributeEditor = ng
  .module('app.modernUi.uiUserAttributeEditor', [])
  .component(
    'uiUserAttributeEditor',
    react2angular(
      UiUserAttributeEditorFU,
      [
        'attributes',
        'onChange',
        'attributesSuggestsName',
        'attributesSuggestsValues',
        'userName',
        'onRequestAttributeValuesLoad',
        'readOnly',
        'onSaveRequest',
        'upgradeTitle',
        'onUpgradeToProClick',
      ],
      [],
    ),
  ).name;
