import ng from 'angular';
import { react2angular } from 'react2angular';
import { UpgradeToProServiceMessage } from './UpgradeToProServiceMessage';

export const ngUpgradeToProServiceMessage = ng
  .module('app.components.upgradeToProServiceMessage', [])
  .component(
    'upgradeToProServiceMessage',
    react2angular(UpgradeToProServiceMessage, ['onClick']),
  ).name;
