import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import { sendEvent } from '@utils/Analytics';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  ChooseItemFromListContainerDialog,
  ComposeTabId,
} from '../ChooseItemFromListDialog';
import { instagramPostFragment as Post } from './@types/instagramPostFragment';
import { INSTAGRAM_TABS_ITEMS } from './consts';
import { validateInstagramPostUrl } from './helpers';
import { InstagramPostList } from './InstagramPostsList';
import { PostSelectorDialog } from '../types';

export interface ChooseInstagramPostDialogProps
  extends PostSelectorDialog<Post[], Post> {
  onPostSelect?(post: Post, selected: boolean): void;
  disableAnotherFlowPost?: boolean;
  multiple?: boolean;
}

export const ChooseInstagramPostDialog: React.FC<ChooseInstagramPostDialogProps> =
  ({
    selectedPosts,
    onSubmit,
    onPostSelect,
    onPostRemove,
    onDismiss,
    disableAnotherFlowPost,
    multiple,
  }) => {
    const { t } = useSafeTranslation();

    return (
      <ChooseItemFromListContainerDialog
        header={t('modernComponents.ChooseInstagramPostDialog.header')}
        tabs={INSTAGRAM_TABS_ITEMS()}
        onDismiss={onDismiss}
        onTabChange={(tabId) => {
          sendEvent({
            category: 'flows',
            action: 'instagram post selector tab change',
            propertyBag: {
              tabId,
            },
          });
        }}
        renderInput={({ activeTabId, searchString, onChange }) => (
          <Input
            error={
              activeTabId === ComposeTabId.postUrl &&
              !!searchString &&
              !validateInstagramPostUrl(searchString)
            }
            renderIcon={() => <Icon icon="search" size="28px" color="grey" />}
            placeholder={t(
              'modernComponents.ChooseInstagramPostDialog.searchInputPlaceholderText',
            )}
            value={searchString}
            onChange={({ currentTarget: { value } }) => {
              onChange(value);
            }}
          />
        )}
      >
        {({ activeTabId, searchString }) => (
          <InstagramPostList
            chosenPosts={selectedPosts}
            onSubmit={onSubmit}
            onPostSelect={onPostSelect}
            onPostRemove={onPostRemove}
            activeTabId={activeTabId}
            searchString={searchString}
            multiple={multiple}
            disableAnotherFlowPost={disableAnotherFlowPost}
          />
        )}
      </ChooseItemFromListContainerDialog>
    );
  };
