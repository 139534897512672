import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as PercentSvg } from './percent.svg';
import { Spacer } from '@ui/Spacer';
import css from './FreeMonthOnTierMessage.css';

interface DiscountMonthOnTierMessageProps {
  className?: string;
  isWhatsappEnabled: boolean;
  discount?: string | null;
}

export const DiscountMonthOnTierMessage: React.FC<DiscountMonthOnTierMessageProps> =
  ({ className, isWhatsappEnabled, discount }) => {
    const { t } = useSafeTranslation();

    return (
      <Flex alignItems="center" className={cn(css.container, className)}>
        <PercentSvg className={css.image} />
        <Spacer factor={0} horizontalFactor={3} />
        <Type size="18px" weight="medium" className={css.text}>
          {discount
            ? t(
                isWhatsappEnabled
                  ? 'PromoCode.discountMonthOnBusiness'
                  : 'PromoCode.discountMonthOnEntrepreneurAndStartup',
                { discount },
              )
            : t(
                isWhatsappEnabled
                  ? 'PromoCode.freeMonthOnBusiness'
                  : 'PromoCode.freeMonthOnEntrepreneurAndStartup',
              )}
        </Type>
      </Flex>
    );
  };
