import React, { useState } from 'react';
import { PaymentsModal } from './PaymentsModal';
import { SuccessModal } from './SuccessModal';
import { PaymentInterval } from '../../../pages/UpgradePage/components/paymentInterval';

/**
 * Kinda like a state machine. For more complex flows consider
 * an actual state machine
 */
enum Steps {
  createPaymentMethod,
  done,
}

interface PaymentFlowProps {
  amount: number;
  currency?: string;
  planName: string;
  intervalName: PaymentInterval;
  onDismiss: () => void;
}
export const PaymentFlow: React.FC<PaymentFlowProps> = ({
  onDismiss,
  amount,
  currency,
  intervalName,
  planName,
}) => {
  const [step, setStep] = useState<Steps>(Steps.createPaymentMethod);
  if (step === Steps.createPaymentMethod) {
    return (
      <PaymentsModal
        amount={amount}
        currency={currency}
        planName={planName}
        intervalName={intervalName}
        onDismiss={onDismiss}
        onSuccess={() => {
          setStep(Steps.done);
        }}
      />
    );
  }
  if (step === Steps.done) {
    return <SuccessModal onDismiss={onDismiss} />;
  }
  return null;
};
