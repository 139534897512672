import React, { useRef } from 'react';
import ng from 'angular';
import { react2angular } from 'react2angular';
import { Modal } from '@ui/Modal';
import { Dialog, DialogCloseButton, DialogContent } from '@ui/Dialog';
import { RoleConsumer, RoleProvider } from '@utils/Roles';
import { Permission } from '../../common/services/RoleService';
import { UiUserAttributeEditorFU } from './UiUserAttributeEditorFU';
import { IUiUserAttributeEditorProps } from './UiUserAttributeEditor';
import * as css from './UiUserAttributeEditorModal.css';

interface UiUserAttributeEditorModalProps extends IUiUserAttributeEditorProps {
  botId: string;
  onSave: () => void;
}

const UiUserAttributeEditorModal = (props: UiUserAttributeEditorModalProps) => {
  const { onSave, botId, ...editorProps } = props;
  const dialogRef = useRef();

  return (
    <RoleProvider botId={botId}>
      <Modal onDismiss={onSave} initialFocusRef={dialogRef}>
        <Dialog className={css.dialog} ref={dialogRef} tabIndex={-1}>
          <DialogContent className={css.content}>
            <RoleConsumer domain="people" can={Permission.EDIT}>
              {({ allowed }) => (
                <React.Fragment>
                  <UiUserAttributeEditorFU
                    fixedAddButton
                    withSearch
                    isSearchBoxNarrow
                    readOnly={!allowed}
                    scrollBoxStyle={{
                      position: 'static',
                      overflowX: 'initial', // otherwise scroll hardly works for some reason
                      paddingBottom: 32, // Make space for buttons
                    }}
                    onDismissModal={onSave}
                    {...editorProps}
                  />
                </React.Fragment>
              )}
            </RoleConsumer>
          </DialogContent>
          <DialogCloseButton onClick={onSave} />
        </Dialog>
      </Modal>
    </RoleProvider>
  );
};

export const ngUiUserAttributeEditorModal = ng
  .module('app.modernUi.ngUiUserAttributeEditorModal', [])
  .component(
    'uiUserAttributeEditorModal',
    react2angular(
      UiUserAttributeEditorModal,
      [
        'botId',
        'attributes',
        'onChange',
        'attributesSuggestsName',
        'attributesSuggestsValues',
        'userName',
        'onRequestAttributeValuesLoad',
        'onSave',
        'upgradeTitle',
        'onUpgradeToProClick',
      ],
      [],
    ),
  ).name;
