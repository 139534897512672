import { useMutation } from '@apollo/react-hooks';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { useCallback } from 'react';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { facebookPagePostFragment } from '../@types/facebookPagePostFragment';
import {
  RemoveFacebookPagePostFromEntryPointsMutation,
  RemoveFacebookPagePostFromEntryPointsMutationVariables,
} from '../@types/RemoveFacebookPagePostFromEntryPointsMutation';
import { REMOVE_FACEBOOK_PAGE_POST_FROM_ENTRY_POINTS_MUTATION } from '../queries';

interface RemovePostParams {
  onRemovedPost?(post: facebookPagePostFragment): void;
}

export const useRemoveFacebookPagePost = ({
  onRemovedPost,
}: RemovePostParams) => {
  const botId = useCurrentBotId();
  const { pageId } = usePageConnected(botId);

  const [removePostFromEntryPointsMutation] = useMutation<
    RemoveFacebookPagePostFromEntryPointsMutation,
    RemoveFacebookPagePostFromEntryPointsMutationVariables
  >(REMOVE_FACEBOOK_PAGE_POST_FROM_ENTRY_POINTS_MUTATION, {
    onError: (error) => {
      log.error({
        error,
        msg: 'Remove Facebook Page post from other Entry Point error',
        data: {
          label: 'fb_comments_autoreply',
          requestId: getRequestIdFromApolloError(error),
        },
      });
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: Messages.somethingWentWrong,
        },
      });
    },
  });

  const removePost = useCallback(
    async (post: facebookPagePostFragment) => {
      if (!pageId) {
        return;
      }
      await removePostFromEntryPointsMutation({
        variables: {
          pageId,
          postId: post.id,
        },
        optimisticResponse: {
          removePostFromEntryPoints: {
            ...post,
            flow_id: null,
            flow_title: null,
          },
        },
      });
      onRemovedPost?.(post);
    },
    [onRemovedPost, pageId, removePostFromEntryPointsMutation],
  );

  return {
    removePost,
  };
};
