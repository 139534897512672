import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { sendEvent } from '@utils/Analytics';

import {
  OnboardingTourEventType,
  OnboardingTourHTMLElementId,
  Platform,
} from '@globals';

import { ChatDialog } from './ChatDialog/ChatDialog';
import { TestThisButton } from '../TestThisButton';
import { useTestThisActions } from './useTestThisActions';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

import * as css from './TestThis.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useIsFirstSessionTab } from '@utils/Routing';

interface TestThisChatfuelProps {
  tabId?: string;
  insideFlow: boolean;
  botTesting: boolean;
  hideButton: boolean;
  hideMoreMenuButton: boolean;
  platform?: Platform | null;
}

export const TestThisChatfuel: React.FC<TestThisChatfuelProps> = ({
  tabId,
  insideFlow,
  platform,
  botTesting,
  hideButton,
  hideMoreMenuButton,
}) => {
  const { t } = useSafeTranslation();
  const [chatIsShown, setChatShown] = useState(false);
  const { isSmallScreenSize } = useDeviceMedia();
  const isFirstSessionTab = useIsFirstSessionTab();

  const {
    refId,
    ready,
    loading,
    hasError,
    handleTestThisFlowClick,
    handleRefresh,
    handleDismiss,
  } = useTestThisActions({
    tabId,
    platform: platform ?? Platform.facebook,
    chatIsShown,
    toggleChatShown: setChatShown,
    botTesting,
  });

  if (!ready || (insideFlow && !platform)) {
    return null;
  }

  return (
    <>
      {chatIsShown && (
        <ChatDialog
          platform={platform}
          refId={refId}
          loading={loading}
          hasError={hasError}
          onDismiss={handleDismiss}
          onRefreshRequest={handleRefresh}
          showEmptyState={botTesting && !isFirstSessionTab}
          showEmptyMessage={botTesting && isFirstSessionTab}
          hideMoreMenuButton={hideMoreMenuButton}
        />
      )}
      {!hideButton && (
        <div className={css.box}>
          {!chatIsShown ? (
            <TestThisButton
              id={OnboardingTourHTMLElementId.TestThisFlowButton}
              data-testid="test-this_button"
              icon={<Icon icon="chatSimple" color="blue" />}
              onClick={() => {
                sendEvent({
                  category: 'test in chatfuel',
                  action: 'test this button clicked',
                  propertyBag: { refId },
                });
                OnboardingEmitter.emit(OnboardingTourEventType.click, {
                  element: OnboardingTourHTMLElementId.TestThisFlowButton,
                  value: null,
                });
                handleTestThisFlowClick();
              }}
            />
          ) : isSmallScreenSize ? null : (
            <Button
              intent="secondary"
              data-testid="test-this_close-chat"
              style={{
                marginLeft: 12,
              }}
              onClick={() => {
                sendEvent({
                  category: 'test in chatfuel',
                  action: 'close button clicked',
                  propertyBag: { refId },
                });
                handleDismiss();
              }}
            >
              {t('modernComponents.TestThis.closeChat')}
            </Button>
          )}
        </div>
      )}
    </>
  );
};
