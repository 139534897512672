import React, { useCallback, useState } from 'react';
import {
  AttributeErrorType,
  ImportUsersAttribute,
} from '../../../../../hocks/types';
import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './ImportUsersAttributeItem.css';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { itemToString } from '@ui/TextWithAttributesEditor/Attribute';
import { AttributeInput } from '../../../../../../../../AttributeInput';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useCurrentBotId } from '@utils/Routing';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { Platform } from '@globals';

interface ImportUsersAttributeItemProps {
  attribute: ImportUsersAttribute;
  showRemoveButton: boolean;
  error: AttributeErrorType | undefined;
  onChange: (attribute: ImportUsersAttribute) => void;
  onRemoveRequest: (id: string) => void;
}

const ATTRIBUTE_NAME_ERRORS = [
  AttributeErrorType.emptyName,
  AttributeErrorType.duplicate,
  AttributeErrorType.system,
  AttributeErrorType.useInMapping,
];
const VALUE_ERRORS = [AttributeErrorType.emptyValue];
const ERRORS_WITH_TEXTS = [
  AttributeErrorType.duplicate,
  AttributeErrorType.system,
  AttributeErrorType.useInMapping,
];

export const ImportUsersAttributeItem: React.FC<ImportUsersAttributeItemProps> =
  ({ attribute, showRemoveButton, error, onChange, onRemoveRequest }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId();
    const { attributes } = useAttributes(
      botId,
      VariableSuggestType.template,
      Platform.whatsapp,
    );
    const [touched, setTouched] = useState<boolean>(false);

    const changeHandler = useCallback(
      (name: string) => {
        onChange({
          ...attribute,
          name,
        });
      },
      [attribute, onChange],
    );
    return (
      <div>
        <Flex
          gapFactor={4}
          className={css.box}
          data-testid="import-contacts__common-attributes-mapping-table-row"
        >
          <AttributeInput
            error={touched && error && ATTRIBUTE_NAME_ERRORS.includes(error)}
            onBlur={() => {
              setTouched(true);
            }}
            value={attribute.name}
            platform={Platform.whatsapp}
            attributes={attributes}
            customAttributesOnly
            onSelect={(item) => {
              changeHandler(itemToString(item));
            }}
            onChange={changeHandler}
            placeholder={t(
              'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.attributes.attribute',
            )}
            data-testid="import-contacts__attribute-name-input"
            containerClassName={css.input}
          />

          <Input
            error={touched && error && VALUE_ERRORS.includes(error)}
            data-testid="import-contacts__attribute-value-input"
            value={attribute.value}
            maxLength={600}
            containerClassName={css.input}
            onBlur={() => {
              setTouched(true);
            }}
            onChange={({ currentTarget: { value } }) => {
              onChange({
                ...attribute,
                value,
              });
            }}
            placeholder={t(
              'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.attributes.value',
            )}
          />

          {showRemoveButton && (
            <ButtonUnstyled
              data-testid="import-contacts__remove-attribute-button"
              onClick={() => {
                sendEvent({
                  category: 'people tab',
                  label: 'whatsapp user import',
                  action: 'remove user attribute click',
                });
                onRemoveRequest(attribute.id);
              }}
              className={css.deleteButton}
            >
              <Icon icon="delete" />
            </ButtonUnstyled>
          )}
        </Flex>
        {error && ERRORS_WITH_TEXTS.includes(error) && (
          <>
            <Spacer factor={1} />
            <Type
              as="div"
              color="red"
              size="12px"
              data-testid="import-contacts__common-attribute-error"
            >
              {error === AttributeErrorType.system &&
                t(
                  'modernComponents.WhatsAppImportUser.dialog.MapFields.systemNotUse',
                )}
              {error === AttributeErrorType.duplicate &&
                t(
                  'modernComponents.WhatsAppImportUser.dialog.MapFields.alreadyInUse',
                )}
              {error === AttributeErrorType.useInMapping &&
                t(
                  'modernComponents.WhatsAppImportUser.dialog.MapFields.useInMapping',
                )}
            </Type>
          </>
        )}
      </div>
    );
  };
