import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Flex } from '@ui/Flex';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getClid } from 'cf-common/src/analytics';
import { getUserId } from 'cf-common/src/userId';
import { useCurrentBotId } from '@utils/Routing';
import { Type } from '@ui/Type';
import { Input } from '@ui/Input';
import { InputErrorBox } from '@ui/Input/InputErrorBox';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import {
  BaseComboboxItem,
  ComboboxWithTriangleButton,
} from '@ui/SimpleCombobox';
import { useHideIntercom } from '@utils/Intercom/hooks/useHideIntercom';
import { ComboboxIndustry, CUSTOM_INDUSTRY, getIndustries } from '../../consts';
import { ReferralFormValue } from '../../api';
import * as css from './ReferralContactForm.css';

interface IndustryBaseComboboxItem extends BaseComboboxItem {
  industry: ComboboxIndustry;
}

const mapIndustry = (industry: ComboboxIndustry): IndustryBaseComboboxItem => ({
  id: industry.id,
  title: industry.label,
  industry,
});

const referralFormValidationSchema = Yup.object().shape({
  email: Yup.string().trim().email().required(),
  name: Yup.string().trim().min(2).required(),
  industry: Yup.string().min(2).required(),
  otherIndustry: Yup.string().when('industry', (industry) =>
    industry === CUSTOM_INDUSTRY
      ? Yup.string().trim().min(2).required()
      : Yup.string(),
  ),
});

const InputLabel = ({
  label,
  required,
}: {
  label: string;
  required?: boolean;
}) => (
  <Type size="15px" as="div" weight="semibold" className={css.label}>
    {label}
    {required ? '*' : ''}
  </Type>
);

const renderInputLabel = (label: string, required?: boolean) => () =>
  <InputLabel label={label} required={required} />;

export const ReferralContactForm = ({
  onSubmit,
}: {
  onSubmit: (formData: ReferralFormValue) => Promise<any>;
}) => {
  useHideIntercom();
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, values, errors, setFieldValue, handleChange, isValid } =
    useFormik<Omit<ReferralFormValue, 'clid' | 'botId' | 'userId'>>({
      initialValues: {
        industry: '',
        email: '',
        name: '',
        company: '',
        otherIndustry: '',
      },
      validationSchema: referralFormValidationSchema,
      validateOnChange: true,
      onSubmit: ({ industry, otherIndustry, ...form }) => {
        const formData = {
          email: form.email.trim(),
          name: form.name.trim(),
          company: form.company.trim(),
          industry: (industry === CUSTOM_INDUSTRY
            ? otherIndustry?.trim()
            : industry)!,
          clid: getClid()!,
          botId: botId!,
          userId: getUserId()!,
        };
        setIsLoading(true);
        onSubmit(formData).finally(() => setIsLoading(false));
      },
    });

  const comboboxIndustries = getIndustries(t);
  const selectedIndustry = values.industry
    ? comboboxIndustries.find((i: ComboboxIndustry) => i.id === values.industry)
    : null;

  return (
    <form className={css.rightSide} onSubmit={handleSubmit}>
      <Type as="div" size="32px" weight="bold">
        {t('modernComponents.ReferralProgram.form.participate')}
      </Type>

      <Flex flexWrap justifyContent="space-between">
        <div className={css.halfInput}>
          <Input
            renderLabel={renderInputLabel(
              t('modernComponents.ReferralProgram.form.email'),
              true,
            )}
            placeholder={t('modernComponents.ReferralProgram.form.email')}
            name="email"
            value={values.email}
            error={
              errors.email
                ? t('modernComponents.ReferralProgram.form.error.email')
                : null
            }
            onChange={handleChange}
          />
        </div>
        <div className={css.halfInput}>
          <Input
            renderLabel={renderInputLabel(
              t('modernComponents.ReferralProgram.form.name'),
              true,
            )}
            placeholder={t('modernComponents.ReferralProgram.form.name')}
            name="name"
            value={values.name}
            error={
              errors.name
                ? t('modernComponents.ReferralProgram.form.error.name')
                : null
            }
            onChange={handleChange}
          />
        </div>

        <div className={css.halfInput}>
          <InputLabel
            label={t('modernComponents.ReferralProgram.form.industry')}
            required
          />
          <Spacer factor={3} />

          <ComboboxWithTriangleButton<IndustryBaseComboboxItem>
            onChange={({ id }) => setFieldValue('industry', id)}
            items={comboboxIndustries.map(mapIndustry)}
            className={css.selectButton}
            itemToString={(industry) => industry?.id || ''}
            buttonChildren={() => (
              <Type size="15px" color={selectedIndustry ? 'black' : 'grey'}>
                {selectedIndustry?.label ||
                  t('modernComponents.ReferralProgram.form.select')}
              </Type>
            )}
            intent="secondary"
            selectedItem={selectedIndustry && mapIndustry(selectedIndustry)}
            fullWidth
          />

          {selectedIndustry?.id === CUSTOM_INDUSTRY && (
            <>
              <Spacer factor={3} />
              <Input
                placeholder={t(
                  'modernComponents.ReferralProgram.form.industry',
                )}
                name="otherIndustry"
                value={values.otherIndustry}
                onChange={handleChange}
              />
            </>
          )}

          {(errors.otherIndustry || errors.otherIndustry) && (
            <InputErrorBox
              errorText={t(
                'modernComponents.ReferralProgram.form.error.industry',
              )}
            />
          )}
        </div>

        <div className={css.halfInput}>
          <Input
            renderLabel={renderInputLabel(
              t('modernComponents.ReferralProgram.form.company'),
            )}
            placeholder={t('modernComponents.ReferralProgram.form.company')}
            name="company"
            value={values.company}
            onChange={handleChange}
          />
        </div>
      </Flex>

      <Spacer factor={5} />
      <Flex justifyContent="flex-end">
        <Button
          className={css.submit}
          type="submit"
          loading={isLoading}
          disabled={!isValid}
        >
          {t('modernComponents.ReferralProgram.form.submit')}
        </Button>
      </Flex>
    </form>
  );
};
