import React, { useCallback } from 'react';
import { StepProps } from '../types';
import { WhatsappImportUserDialogFooter } from '../../components/WhatsappImportUserDialogFooter';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import * as css from './ErrorStep.css';
import { useUserDataCsvFile } from '../../hocks/useUserDataCsvFile';
import { useWhatsappImportUsersSession } from '../../hocks/useWhatsappImportUsersSession';

export const ErrorStep: React.FC<StepProps> = ({ onBack }) => {
  const { t } = useSafeTranslation();
  const { uploadCsv } = useUserDataCsvFile();
  const { startWhatsappImportUsersSession } = useWhatsappImportUsersSession();

  const tryAgainClickHandler = useCallback(async () => {
    const sId = await startWhatsappImportUsersSession();
    if (!sId) {
      return;
    }
    uploadCsv(sId);
  }, [startWhatsappImportUsersSession, uploadCsv]);

  return (
    <>
      <Flex alignItems="center" justifyContent="center" className={css.box}>
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          className={css.text}
        >
          <Icon icon="bad" color="accent4Secondary" size="120px" />
          <Spacer factor={2} />
          <Type color="baseSecondary" as="div">
            {t('modernComponents.WhatsAppImportUser.dialog.Error.text1')}
          </Type>
          <Type color="baseSecondary" as="div" align="center">
            {t('modernComponents.WhatsAppImportUser.dialog.Error.text2')}
          </Type>
        </Flex>
      </Flex>
      <WhatsappImportUserDialogFooter
        showBackButton
        onBackButtonClick={onBack}
        onNextStepButtonClick={tryAgainClickHandler}
        nextStepButtonTitle={t(
          'modernComponents.WhatsAppImportUser.dialog.Error.TryAgain',
        )}
      />
    </>
  );
};
