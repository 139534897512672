import React from 'react';
import { Router } from 'react-router-dom';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { globalHistory } from '@utils/Routing';
import { ServiceMessage } from '@ui/ServiceMessage';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import * as Toaster from '../../services/MessageService';
import {
  AnimateEnter,
  ServiceMessage as ServiceMessage2,
} from '../../modern-ui/ServiceMessage2';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../modern-ui/_deprecated/Button';
import * as css from './ToasterContainer.css';

interface IToasterContainerProps {
  toaster: Toaster.IToaster;
  renderToast?: (renderArgs: IRenderToastArguments) => React.ReactNode;
}

interface IRenderToastArguments {
  toast: Toaster.IToast;
  handleClose: (toast: Toaster.IToast) => void;
}

interface IToasterContainerState {
  toasts: ToastsDictionary;
}

type TimerDictionary = { [id: string]: number };
type ToastsDictionary = { [id: string]: Toaster.IToast };

export class ToasterContainer extends React.Component<
  IToasterContainerProps,
  IToasterContainerState
> {
  timers: TimerDictionary = {};

  state = {
    toasts: {} as ToastsDictionary,
  };

  unsubscribe: (() => void) | null = null;

  componentDidMount() {
    this.unsubscribe = this.props.toaster.subscribe(this.listener);
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    Object.keys(this.timers).forEach((key: string) =>
      clearTimeout(this.timers[key]),
    );
  }

  listener = (toast: Toaster.IToast) => {
    this.setState((prevState) => ({
      ...prevState,
      toasts: {
        ...prevState.toasts,
        [toast.id]: toast,
      },
    }));
    clearTimeout(this.timers[toast.id]);
    delete this.timers[toast.id];

    const hideToast = () => {
      const { toasts } = this.state;
      delete toasts[toast.id];
      delete this.timers[toast.id];
      this.setState({
        toasts: {
          ...toasts,
        },
      });
    };

    const timerId = setTimeout(hideToast, toast.payload.timeout || 5000);
    this.timers[toast.id] = Number(timerId);

    return hideToast;
  };

  defaultRenderToast = ({ toast, handleClose }: IRenderToastArguments) => {
    return <ServiceMessage {...toast} onClose={() => handleClose(toast)} />;
  };

  handleCloseToast = (toast: Toaster.IToast) => {
    const { toasts } = this.state;
    delete toasts[toast.id];
    delete this.timers[toast.id];
    this.setState({
      toasts: {
        ...toasts,
      },
    });
    delete this.timers[toast.id];
  };

  render() {
    const {
      renderToast = this.defaultRenderToast,
      toaster,
      ...restProps
    } = this.props;
    const { toasts } = this.state;

    const displayToasts = Object.keys(toasts)
      .map((key) => toasts[key])
      .sort((a, b) => b.timestamp - a.timestamp);
    return (
      <div {...restProps} className={css.toasterContainer}>
        <div>
          <div>
            {displayToasts.map((toast) => (
              <div key={toast.id}>
                {renderToast({
                  toast,
                  handleClose: this.handleCloseToast,
                })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export const NotificationContainer = () => (
  <Router history={globalHistory}>
    <ToasterContainer
      toaster={Toaster.toaster}
      renderToast={({ toast, handleClose }) => (
        <AnimateEnter>
          <ServiceMessage2
            renderContentEnd={() =>
              !toast.payload.showCloseButton ? (
                <Flex>
                  <Spacer factor={1} horizontalFactor={2} />
                  <Button
                    intent={ButtonIntent.primary}
                    size={ButtonSize.s}
                    colorWay={ButtonColorWay.white}
                    onClick={() => {
                      toast.payload.onButtonClick?.();
                      handleClose(toast);
                    }}
                  >
                    {toast.payload.buttonLabel || 'OK'}
                  </Button>
                </Flex>
              ) : (
                <ButtonUnstyled
                  onClick={() => {
                    handleClose(toast);
                  }}
                  style={{ outline: 'none', alignSelf: 'flex-start' }}
                >
                  <Icon icon="close" color="white" />
                </ButtonUnstyled>
              )
            }
            type={toast.type}
          >
            {toast.payload.message}
          </ServiceMessage2>
        </AnimateEnter>
      )}
    />
  </Router>
);
