import { useEffect, useState } from 'react';

import {
  deleteQueryParam,
  deleteQueryParams,
  isParamsMatchesUrlParams,
  setQueryParam,
  setQueryParams,
  useQueryParams,
} from '@utils/queryParams';

import {
  ConnectInstagramVariant,
  ConnectInstagramQueryField,
  ConnectInstagramQueryParams,
  ConnectInstagramRestorationQueryParams,
  ConnectInstagramStep,
} from './types';

export const useConnectInstagramQueryParams = (
  restorationParams?: ConnectInstagramRestorationQueryParams,
  onParamsMatches?: (urlParams: Partial<ConnectInstagramQueryParams>) => void,
) => {
  const queryParams = useQueryParams<
    ConnectInstagramQueryParams & ConnectInstagramRestorationQueryParams
  >();

  const [isOnParamMatchesCalled, setIsOnParamMatchesCalled] = useState(false);
  useEffect(
    function checkPassedAndUrlParamsMatch() {
      if (
        isParamsMatchesUrlParams(restorationParams, queryParams) &&
        !isOnParamMatchesCalled
      ) {
        setIsOnParamMatchesCalled(true);
        onParamsMatches?.(queryParams);
      }
    },
    [isOnParamMatchesCalled, onParamsMatches, restorationParams, queryParams],
  );

  const cleanupParams = () => {
    if (restorationParams) {
      deleteQueryParams(restorationParams);
    }
    deleteQueryParam(ConnectInstagramQueryField.step);
  };

  const setupRestorationParams = () => {
    if (restorationParams) {
      setQueryParams(restorationParams);
    }
  };

  const setStepQueryParam = (value: ConnectInstagramStep) => {
    setQueryParam(ConnectInstagramQueryField.step, value);
  };

  return {
    stepQueryParam: queryParams[ConnectInstagramQueryField.step],
    setStepQueryParam,
    cleanupParams,
    setupRestorationParams,
  };
};

export const getInstagramConnectionVariant = (
  isInstagramAccountConnected: boolean,
  isInstagramAccountKnown: boolean,
  hasInstagramPermissions: boolean,
): ConnectInstagramVariant => {
  if (isInstagramAccountConnected && !hasInstagramPermissions) {
    return ConnectInstagramVariant.refresh;
  }

  if (!isInstagramAccountConnected) {
    return isInstagramAccountKnown && hasInstagramPermissions
      ? ConnectInstagramVariant.known
      : ConnectInstagramVariant.full;
  }
  return ConnectInstagramVariant.none;
};
