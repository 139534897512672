import React from 'react';

import { Flex } from '@ui/Flex';
import { CenteredLoader } from '@ui/Loader';
import { Type } from '@ui/Type';

import * as css from './ConnectInstagramDialogContent.css';

interface ConnectInstagramDialogContentProps {
  loading?: boolean;
  header: React.ReactNode;
  footerLeft?: React.ReactNode;
  footerRight?: React.ReactNode;
  'data-testid': string;
}
export const ConnectInstagramDialogContent: React.FC<ConnectInstagramDialogContentProps> =
  ({
    children,
    header,
    loading,
    footerLeft = <div />,
    footerRight = <div />,
    ...props
  }) => {
    return (
      <Flex
        className={css.container}
        flexDirection="column"
        justifyContent="space-between"
        data-testid={props['data-testid']}
      >
        <Type size="24px" weight="semibold">
          {header}
        </Type>
        <Flex className={css.content} alignItems="center">
          {loading ? (
            <Flex justifyContent="center" className={css.loader}>
              <CenteredLoader />
            </Flex>
          ) : (
            children
          )}
        </Flex>
        <Flex className={css.footer} justifyContent="space-between">
          {footerLeft}
          {footerRight}
        </Flex>
      </Flex>
    );
  };
