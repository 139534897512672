import React from 'react';
import cn from 'classnames';
import Maybe from 'graphql/tsutils/Maybe';
import { StepProps } from '../types';
import { WhatsappImportUserDialogFooter } from '../../components/WhatsappImportUserDialogFooter';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import * as css from './CompletedStep.css';
import { useWhatsappImportUsersSession } from '../../hocks/useWhatsappImportUsersSession';
import { WhatsappImportUserDialogFooterErrorsList } from '../../components/WhatsappImportUserDialogFooterErrorsList';
import { WhatsappImportUsersSessionStatus } from '@globals';
import { WhatsappImportUsersSessionStatusQuery_whatsappImportUsersSessionStatus_stats as Stats } from '../../hocks/@types/WhatsappImportUsersSessionStatusQuery';

export interface TextSummaryProps {
  stats: Maybe<Stats>;
}

const TextSummary: React.FC<TextSummaryProps> = ({ stats }) => {
  const { t } = useSafeTranslation();
  let contactsShown = false;

  const addContacts = (
    x: Maybe<number>,
    tFn: (x: { count: number }) => string,
  ) => {
    if (x) {
      if (!contactsShown) {
        contactsShown = true;

        const contact = t(
          'modernComponents.WhatsAppImportUser.dialog.Done.contact',
          { count: x },
        );

        return `${x} ${contact} ${tFn({ count: x })}`;
      }

      return `${x} ${tFn({ count: x })}`;
    }

    return null;
  };

  const text = [
    addContacts(stats?.imported, (opt) =>
      t('modernComponents.WhatsAppImportUser.dialog.Done.imported', opt),
    ),
    addContacts(stats?.updated, (opt) =>
      t('modernComponents.WhatsAppImportUser.dialog.Done.updated', opt),
    ),
    addContacts(stats?.declined, (opt) =>
      t('modernComponents.WhatsAppImportUser.dialog.Done.declined', opt),
    ),
  ]
    .filter(Boolean)
    .join(', ');

  return (
    <Type color="baseSecondary" as="div">
      {text}
    </Type>
  );
};

export const CompletedStep: React.FC<StepProps> = ({ onDone }) => {
  const { t } = useSafeTranslation();
  const { stats, status } = useWhatsappImportUsersSession();

  const isAllFailed = stats?.imported === 0 && stats?.updated === 0;
  const isAllSuccess =
    stats?.declined === 0 &&
    status === WhatsappImportUsersSessionStatus.success;

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        className={cn(css.box, isAllSuccess && css.bigBox)}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {isAllFailed ? (
            <Icon icon="bad" color="accent4Secondary" size="120px" />
          ) : (
            <Icon
              icon="check_in_circle2"
              color="accent2Secondary"
              size="120px"
            />
          )}

          <Spacer factor={2} />
          <Type color="baseSecondary" as="div">
            {t(
              isAllFailed
                ? 'modernComponents.WhatsAppImportUser.dialog.Done.text2'
                : 'modernComponents.WhatsAppImportUser.dialog.Done.text',
            )}
          </Type>
          {!isAllFailed && <TextSummary stats={stats} />}
        </Flex>
      </Flex>
      <WhatsappImportUserDialogFooterErrorsList />
      <WhatsappImportUserDialogFooter
        onNextStepButtonClick={onDone}
        nextStepButtonTitle={t(
          'modernComponents.WhatsAppImportUser.dialog.Done.next',
        )}
      />
    </>
  );
};
