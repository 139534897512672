import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import Downshift from 'downshift';
import { Manager, Reference, Popper } from 'react-popper';
import { IconButton } from '@ui/IconButton';
import { Menubox, MenuItem } from '@ui/Menu';
import { Icon } from '@ui/Icon';
import { sendEvent } from '@utils/Analytics';
import { useShowConnectPageForTestThisModalIfNeed } from '@components/ConnectPageDialog';
import { useTestThisWithOneTimeRef } from '@components/TestInFacebook/utils/useTestThisWithOneTimeRef';

interface ChatMoreMenuProps {
  refId: string | undefined;
}

export const ChatMoreMenu: React.FC<ChatMoreMenuProps> = ({ refId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useSafeTranslation();
  const testInMessenger = t(
    'modernComponents.TestThis.ChatMoreMenu.testInMessenger',
  );

  const showConnectPageDialogIfNeed =
    useShowConnectPageForTestThisModalIfNeed();

  const { testThis } = useTestThisWithOneTimeRef({ blockId: refId });

  const handleTestThisFlowClick = () => {
    sendEvent({
      category: 'test in chatfuel',
      action: 'test in messenger clicked',
      propertyBag: { refId },
    });

    if (!showConnectPageDialogIfNeed()) {
      testThis();
    }
  };

  return (
    <Downshift
      isOpen={isOpen}
      itemToString={(item) => item}
      onOuterClick={() => setIsOpen(false)}
      onSelect={() => {
        setIsOpen(false);
        handleTestThisFlowClick();
      }}
    >
      {({ getItemProps, highlightedIndex, setHighlightedIndex }) => (
        <div>
          <Manager>
            <Reference>
              {({ ref }) => (
                <IconButton
                  ref={ref}
                  data-testid="test-this_more"
                  onClick={() => setIsOpen((prev) => !prev)}
                  size="24px"
                  icon="more_small"
                  color="white"
                />
              )}
            </Reference>
            {isOpen && (
              <Popper placement="bottom-end">
                {({ ref, style }) => (
                  <Menubox
                    style={style}
                    ref={ref}
                    onMouseLeave={() => {
                      // @ts-ignore
                      setHighlightedIndex(null);
                    }}
                  >
                    <MenuItem
                      {...getItemProps({ item: testInMessenger })}
                      title={testInMessenger}
                      active={highlightedIndex === 0}
                      leftElement={
                        <Icon
                          icon="messenger"
                          color={highlightedIndex === 0 ? 'white' : 'blue'}
                          style={{
                            marginLeft: -8,
                          }}
                        />
                      }
                    />
                  </Menubox>
                )}
              </Popper>
            )}
          </Manager>
        </div>
      )}
    </Downshift>
  );
};
