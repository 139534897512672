import { Input, InputProps } from '@ui/Input';
import { InputLoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import React from 'react';
import css from './LoadingInput.css';

export interface LoadingInputProps
  extends Pick<
    InputProps,
    | 'disabled'
    | 'placeholder'
    | 'value'
    | 'error'
    | 'renderErrorText'
    | 'onChange'
    | 'name'
    | 'readOnly'
  > {
  labelText?: string;
  loading?: boolean;
  input?: React.ReactNode;
}

export const LoadingInput: React.FC<LoadingInputProps> = ({
  labelText,
  loading,
  input,
  error,
  ...props
}) => {
  const label = labelText ? (
    <Type size="15px" weight="semibold" className={css.label}>
      {labelText}
    </Type>
  ) : null;

  if (loading)
    return (
      <div>
        {label}
        <InputLoadingPlaceholder />
        <Spacer factor={6} />
      </div>
    );

  if (input)
    return (
      <div>
        {label}
        {input}
        {!error && <Spacer factor={6} />}
      </div>
    );

  return <Input renderLabel={() => label} error={error} {...props} />;
};
