import React from 'react';
import { Type } from '../../../modern-ui/Type';
import { Spacer } from '../../../modern-ui/Spacer';
import * as s from './PremiumPromo.css';

export const PremiumPromo: React.FC = () => (
  <div>
    <Type as="p" size="15px_DEPRECATED">
      {window.i18next.t(
        'PremiumPromo-JSXText--279-supercharge-your-chatbot-with-the-chatfuel-premium-plan',
      )}
    </Type>
    <Type as="p" size="15px_DEPRECATED">
      {window.i18next.t(
        'PremiumPromo-JSXText-1866-youll-get-access-to-incredible-features-including',
      )}
    </Type>

    <Spacer factor={8} />
    <div className={s.featureItem}>
      <div className={s.emojiItem}>
        <span role="img" aria-label="rocket">
          🚀
        </span>
      </div>
      <div>
        <Type as="p" weight="medium" size="15px_DEPRECATED">
          {window.i18next.t('PremiumPromo-JSXText--902-quick-start-training')}
        </Type>
        <Type as="p" size="15px_DEPRECATED">
          {window.i18next.t(
            'PremiumPromo-JSXText-1383-personalized-training-to-get-you-up-and-running',
          )}
        </Type>
        <Type size="15px_DEPRECATED">
          {window.i18next.t('PremiumPromo-JSXText-1638-in-no-time')}
        </Type>
      </div>
    </div>
    <Spacer />
    <div className={s.featureItem}>
      <div className={s.emojiItem}>
        <span role="img" aria-label="cheering megaphone">
          📣
        </span>
      </div>
      <div>
        <Type as="p" weight="medium" size="15px_DEPRECATED">
          {window.i18next.t('PremiumPromo-JSXText-6582-premium-support')}
        </Type>
        <Type as="p" size="15px_DEPRECATED">
          {window.i18next.t(
            'PremiumPromo-JSXText--187-enjoy-white-glove-service-which-includes-lightning',
          )}
        </Type>
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'PremiumPromo-JSXText--174-fast-responses-to-your-questions-and-help-requests',
          )}
        </Type>
      </div>
    </div>
    <Spacer />
    <div className={s.featureItem}>
      <div className={s.emojiItem}>
        <span role="img" aria-label="smiling face with sunglasses">
          😎
        </span>
      </div>
      <div>
        <Type as="p" weight="medium" size="15px_DEPRECATED">
          {window.i18next.t(
            'PremiumPromo-JSXText-1555-advanced-functionalities',
          )}
        </Type>
        <Type as="p" size="15px_DEPRECATED">
          {window.i18next.t(
            'PremiumPromo-JSXText--166-access-to-powerful-ap-is-and-additional-premium-only',
          )}
        </Type>
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'PremiumPromo-JSXText-6460-features-for-power-users-and-agencies',
          )}
        </Type>
      </div>
    </div>
  </div>
);
