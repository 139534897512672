import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import image1Src from './images/TestPersistentMenuDialog_1.png';
import image2Src from './images/TestPersistentMenuDialog_2.png';
import image3Src from './images/TestPersistentMenuDialog_3.png';
import { TestSteps } from './components';

const TEST_STEPS = () => [
  {
    text: (
      <>
        {window.i18next.t(
          'TestPersistentMenuDialog-JSXText--122-1-toggle-the-entry-point-on',
        )}
        <br />
        {window.i18next.t(
          'TestPersistentMenuDialog-JSXText-8961-open-a-new-conversation-with-your',
        )}
        <br />
        {window.i18next.t(
          'TestPersistentMenuDialog-JSXText-4390-facebook-page-in-messenger',
        )}
      </>
    ),
    src: image1Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestPersistentMenuDialog-JSXText--227-2-open-the-persistent-menu-and-click-one',
        )}
        <br />
        {window.i18next.t(
          'TestPersistentMenuDialog-JSXText--190-of-the-menu-buttons',
        )}
      </>
    ),
    src: image2Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestPersistentMenuDialog-JSXText--606-3-review-the-response-from-your',
        )}
        <br />
        {window.i18next.t(
          'TestPersistentMenuDialog-JSXText--128-bot-in-messenger',
        )}
      </>
    ),
    src: image3Src,
  },
];

export const TestPersistentMenuDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => (
  <DefaultDialog
    dialogStyle={{
      width: 572,
    }}
    header={window.i18next.t(
      'TestPersistentMenuDialog-string-2027-test-your-persistent-menu-entry-point',
    )}
    onDismiss={onRequestClose}
  >
    <TestSteps steps={TEST_STEPS()} />
  </DefaultDialog>
);
