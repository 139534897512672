import { propEq, clone } from 'ramda';
import { Button, ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { Icon } from '@ui/Icon';
import { Anchor } from '@ui/Links';
import { sendEvent } from '@utils/Analytics';
import { CheckBox } from '@ui/CheckBox';
import { NetworkStatus } from 'apollo-client';
import cn from 'classnames';
import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from '../ChooseItemFromListDialog/ChooseItemFromListDialog.css';
import * as instagramPostListCss from './InstagramPostList.css';
import {
  ItemList,
  ComposeTabId,
  getCurrentItems,
  useItemLoadOnTabChange,
  useActionOnDialogOpen,
} from '../ChooseItemFromListDialog';
import { instagramPostFragment as Post } from './@types/instagramPostFragment';
import {
  filterPostsBySearchString,
  getInstagramPostPreview,
  validateInstagramPostUrl,
} from './helpers';
import { useInstagramPostFetch } from './hooks/useInstagramPostsFetch';
import { useRemoveInstagramPost } from './hooks/useRemoveInstagramPost';
import { Translation } from '@translations';
import { DateFormat } from '@utils/DateTime';

const getI18nKey = (key: string) =>
  `modernComponents.ChooseInstagramPostDialog.postList.${key}` as Translation;

interface InstagramPostListProps {
  chosenPosts: Post[];
  onSubmit(chosenPosts: Post[]): void;
  onPostSelect?(post: Post, selected: boolean): void;
  onPostRemove?(post: Post): void;
  activeTabId: string;
  searchString: string;
  disableAnotherFlowPost?: boolean;
  multiple?: boolean;
}

export const InstagramPostList: React.FC<InstagramPostListProps> = ({
  chosenPosts,
  onSubmit,
  onPostSelect,
  onPostRemove,
  activeTabId,
  searchString,
  disableAnotherFlowPost,
  multiple,
}) => {
  const { t } = useSafeTranslation();

  const [checkedPosts, setCheckedPosts] = useState(chosenPosts);

  const {
    posts,
    postsLoading,
    loadMore,
    loading,
    refetch,
    error,
    networkStatus,
  } = useInstagramPostFetch({ activeTabId });

  useItemLoadOnTabChange({
    items: posts,
    searchString,
    loading,
    activeTabId,
    loadMore,
    error,
  });

  useActionOnDialogOpen({
    itemsLoading: postsLoading,
    action: refetch,
  });

  const { removePost } = useRemoveInstagramPost({
    onPostRemove,
  });

  const isRefetch = networkStatus === NetworkStatus.refetch;
  const isValidPostUrl = validateInstagramPostUrl(searchString);

  const currentPosts = getCurrentItems<Post>({
    activeTabId,
    items: posts,
    searchString,
    loading,
    isRefetch,
    isValidItemUrl: isValidPostUrl,
    filterFunc: (item) => filterPostsBySearchString(searchString, item),
  });

  const handleSubmit = (posts: Post[]) => {
    onSubmit(
      posts.map((post) => ({
        ...post,
        media_url: getInstagramPostPreview(post),
      })),
    );
    sendEvent({
      category: 'flows',
      action: 'choose instagram post click',
      propertyBag: {
        postIds: posts.map((v) => v.id),
      },
    });
  };

  return (
    <>
      <ItemList<Post>
        loading={postsLoading}
        loadMore={loadMore}
        currentPosts={currentPosts}
        hideCheckboxLoader={!multiple}
        notFoundState={
          <div>
            {activeTabId === ComposeTabId.postUrl && (
              <Type size="15px_DEPRECATED">
                {t(getI18nKey('postNotFoundPostUrlTabPlaceholderText'))}&nbsp;
              </Type>
            )}
            {activeTabId !== ComposeTabId.postUrl && !!searchString && (
              <Type size="15px_DEPRECATED">
                {t(getI18nKey('postNotFoundPlaceholderText'))}
              </Type>
            )}
          </div>
        }
        invalidUrlState={
          <div>
            <Type size="15px_DEPRECATED">
              {t(getI18nKey('invalidPostUrlText'))}&nbsp;
            </Type>
          </div>
        }
        renderPost={({ post, style }) => {
          const isPostChecked = chosenPosts.some((v) => v.id === post.id);
          const postAlreadyUsed = !!post.flow_id;
          return (
            <Tooltip2
              boundariesElement="viewport"
              type="small"
              content={
                <div className={instagramPostListCss.postPreview}>
                  {getInstagramPostPreview(post) && (
                    <img
                      src={getInstagramPostPreview(post) ?? ''}
                      className={css.tooltipPicture}
                      alt={post.permalink ?? ''}
                    />
                  )}
                  <div style={{ padding: 8 }}>
                    {post.caption && (
                      <>
                        <div className={css.tooltipTitle}>
                          <Type size="12px">{post.caption}</Type>
                        </div>
                        <Spacer factor={2} />
                      </>
                    )}
                    {post.timestamp && (
                      <Type size="12px" color="greyDark">
                        {DateFormat.DDMMMMHHmm(post.timestamp)}
                      </Type>
                    )}
                  </div>
                </div>
              }
            >
              {(ref, bind) => (
                <div style={style}>
                  <ButtonUnstyled
                    className={cn(css.postItem, {
                      [css.selectedPost]: isPostChecked,
                    })}
                    onClick={() => {
                      if (postAlreadyUsed && disableAnotherFlowPost) {
                        return;
                      }
                      if (!multiple) {
                        const posts = [post];
                        setCheckedPosts(posts);
                        handleSubmit(posts);
                      } else {
                        setCheckedPosts((prevPosts) => {
                          const existingPost = prevPosts.some(
                            (v) => v.id === post.id,
                          );
                          if (existingPost) {
                            return prevPosts.filter((v) => v.id !== post.id);
                          }
                          return prevPosts.concat(post);
                        });
                      }
                    }}
                  >
                    <Flex inline style={{ height: 40 }} alignItems="center">
                      {multiple &&
                        (postAlreadyUsed && !isPostChecked ? (
                          <Tooltip2
                            content={
                              <>
                                {t(getI18nKey('postAlreadyUsedInFlowText'), {
                                  flowTitle: post.flow_title,
                                })}
                                <br />
                                <br />
                                <Anchor
                                  intent="tooltip"
                                  onClick={() => {
                                    removePost(post);
                                  }}
                                >
                                  {t(
                                    getI18nKey('removePostFromFlowAnchorText'),
                                    {
                                      flowTitle: post.flow_title,
                                    },
                                  )}
                                </Anchor>
                              </>
                            }
                            placement="right"
                            hoverable
                            type="small"
                          >
                            {(ref, bind) => (
                              <div className={css.checkBox} ref={ref} {...bind}>
                                <Icon
                                  icon="warning"
                                  size="28px"
                                  color="red"
                                  style={{
                                    marginLeft: -5,
                                  }}
                                />
                              </div>
                            )}
                          </Tooltip2>
                        ) : (
                          <CheckBox
                            className={css.checkBox}
                            checked={checkedPosts.some(propEq('id', post.id))}
                            onChange={() => {
                              const updatedPosts = clone(checkedPosts);
                              const postIndex = checkedPosts.findIndex(
                                propEq('id', post.id),
                              );
                              const selected = postIndex === -1;
                              if (selected) {
                                updatedPosts.push({
                                  ...post,
                                  __typename: 'InstagramPost',
                                });
                              } else {
                                updatedPosts.splice(postIndex, 1);
                              }
                              setCheckedPosts(updatedPosts);
                              onPostSelect?.(post, selected);
                            }}
                          />
                        ))}
                      <Flex alignItems="center" ref={ref} {...bind}>
                        <div
                          className={css.postPicture}
                          style={{
                            backgroundImage: getInstagramPostPreview(post)
                              ? `url(${getInstagramPostPreview(post)})`
                              : undefined,
                          }}
                        />
                        <div>
                          <div
                            className={css.title}
                            title={post.caption ?? undefined}
                          >
                            {post.caption ? (
                              <Type size="15px_DEPRECATED">{post.caption}</Type>
                            ) : (
                              <Type color="grey" size="15px_DEPRECATED">
                                {t(getI18nKey('noPostTitlePlaceholderText'))}
                              </Type>
                            )}
                          </div>
                          {post.timestamp && (
                            <Type size="12px" color="greyDark">
                              {DateFormat.DDMMMMHHmm(post.timestamp)}
                            </Type>
                          )}
                        </div>
                      </Flex>
                    </Flex>
                  </ButtonUnstyled>
                </div>
              )}
            </Tooltip2>
          );
        }}
      />
      {multiple && (
        <Flex
          className={css.bottom}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            data-testid="choose-posts__submit"
            className={css.bottomButton}
            onClick={() => {
              handleSubmit(checkedPosts);
            }}
            disabled={loading}
          >
            {t(getI18nKey('addPostButtonText'))}
          </Button>
        </Flex>
      )}
    </>
  );
};
