import memoizeOne from 'memoize-one';
import { InstagramPostType } from '@globals';
import { instagramPostFragment } from './@types/instagramPostFragment';

export const validateInstagramPostUrl = (urlStr: string) => {
  try {
    const url = new URL(urlStr);
    return url.host.indexOf('instagram.com') !== -1;
  } catch {
    return false;
  }
};

export const filterPostsBySearchString = memoizeOne(
  (searchString: string, { caption }: instagramPostFragment) => {
    const clearSearchString = searchString.trim().toLowerCase();
    if (clearSearchString) {
      return !!caption?.toLowerCase().includes(clearSearchString);
    }
    return true;
  },
);

export const getInstagramPostPreview = (post: instagramPostFragment) => {
  if (post.media_type === InstagramPostType.VIDEO) {
    return post.thumbnail_url;
  }
  return post.media_url;
};
