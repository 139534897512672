import React from 'react';
import { useHistory } from 'react-router-dom';
import { sendEvent } from 'cf-common/src/analytics';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useCurrentBotId } from '@utils/Routing';
import { TabButton } from '@components/LeftNavigationPanel/components/TabLink/TabLink';
import { useBotProStatus } from '@utils/Data/Bot';
import { useBotWorkspaceId } from '@utils/Data/Bot/useBotWorkspaceId';
import { ReactComponent as PresentIcon } from '../images/present.svg';
import * as css from './ReferralProgramButton.css';
import { useIsLiveChatWebView } from '@utils/useIsLiveChatWebView';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';

export const ReferralProgramButton = ({
  onOpenReferralPage,
}: {
  onOpenReferralPage: () => void;
}) => {
  const isWhiteLabel = isWhiteLabelDomain();
  const botId = useCurrentBotId();
  const { isLiveChatWebView } = useIsLiveChatWebView();
  const { t } = useSafeTranslation();
  const { push } = useHistory();
  const { botIsPro, botIsManualPro } = useBotProStatus();
  const [botWorkspaceId] = useBotWorkspaceId(botId);
  const { account: shopifyAccount, loading: shopifyAccountLoading } =
    useShopifyAccount();

  const handleButtonClick = () => {
    onOpenReferralPage();
    push(`/bot/${botId}/referral`);
    sendEvent({
      action: 'button-click',
      category: 'referral',
    });
  };

  if (
    shopifyAccount ||
    shopifyAccountLoading ||
    isWhiteLabel ||
    botWorkspaceId ||
    !(botIsPro || botIsManualPro) ||
    isLiveChatWebView
  ) {
    return null;
  }

  return (
    <TabButton
      tabEvent="referral"
      data-testid="referral-program-button"
      onClick={handleButtonClick}
    >
      {/* some nested div to implement universal gradient borders */}
      <div className={css.container}>
        <div className={css.border}>
          <div className={css.background}>
            <div className={css.button}>
              <div className={css.label}>
                <span className={css.highlight}>
                  {t('modernComponents.ReferralProgram.freeMonth')}
                </span>
                <span>{t('modernComponents.ReferralProgram.forFriends')}</span>
              </div>

              <PresentIcon className={css.icon} />
            </div>
          </div>
        </div>
      </div>
    </TabButton>
  );
};
