import React from 'react';
import { sendEvent } from '@utils/Analytics';
import { Icon } from '@ui/Icon';
import { useTestThisWithOneTimeRef } from '../../TestInFacebook/utils/useTestThisWithOneTimeRef';
import { TestThisButton } from './TestThisButton';

export interface TestThisSimpleProps {
  blockId?: string;
  buttonLabel?: string;
  onClick?: () => void;
}

export const TestThisSimple: React.FC<TestThisSimpleProps> = ({
  blockId,
  buttonLabel,
  onClick,
}) => {
  const { testThis } = useTestThisWithOneTimeRef({ blockId });

  return (
    <TestThisButton
      buttonLabel={buttonLabel}
      icon={<Icon icon="messenger" color="blue" />}
      onClick={() => {
        testThis();
        onClick?.();
        sendEvent({
          category: 'test this 3',
          action: 'click Test This flow',
          label: 'Test This',
        });
      }}
    />
  );
};
