import React from 'react';
import { useIsShopifyV3 } from '@utils/Integrations/Shopify/useIsShopifyV3';
import { useWhatsappEnabled } from '@utils/Whatsapp';

export const ShopifyIntegrationContainer: React.FC = ({ children }) => {
  const { isWhatsappEnabled } = useWhatsappEnabled();
  const { isShopifyV3 } = useIsShopifyV3();

  if (isWhatsappEnabled && isShopifyV3) {
    return <>{children}</>;
  }

  return null;
};
