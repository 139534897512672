import { NAV_PANEL_COLLAPSE_BUTTON_ID } from '../../pages/BotPage/FlowTab/FlowList/constants';
import { PAGE_HEADER_CONTAINER_ID } from '@components/PageHeader/constants';
import * as css from './YouTubeFramePlayer.css';

export function disableSelection(): void {
  document.body.classList.add(css.disableSelection);
}

export function enableSelection(): void {
  document.body.classList.remove(css.disableSelection);
}

/**
 * Вычисляет координату, дальше которой нельзя двигать влево
 * Нужно, чтобы окно не заезжало под левую панель FlowList
 */
export function getMinLeftCoordinate(
  containerRef: React.RefObject<HTMLDivElement>,
  event: { clientX: number },
): number {
  const navPanelElement = document.getElementById(NAV_PANEL_COLLAPSE_BUTTON_ID);
  if (!navPanelElement) {
    return 0;
  }
  const navPanelRects = navPanelElement.getBoundingClientRect();
  let left = navPanelRects.x + navPanelRects.width;
  if (containerRef.current) {
    left += event.clientX - containerRef.current.getBoundingClientRect().x;
  }
  return left;
}

/**
 * Вычисляет координату, дальше которой нельзя двигать вправо
 * Нужно, чтобы окно не заезжало за экран справа
 */
export function getMinRightCoordinate(
  containerRef: React.RefObject<HTMLDivElement>,
  event: { clientX: number },
): number {
  if (!containerRef.current) {
    return 0;
  }
  const containerRects = containerRef.current.getBoundingClientRect();
  return containerRects.width - (event.clientX - containerRects.x);
}

/**
 * Вычисляет координату, дальше которой нельзя двигать наверх
 * Нужно, чтобы окно не заезжало под PageHeader
 */
export function getMinTopCoordinate(
  containerRef: React.RefObject<HTMLDivElement>,
  event: { clientY: number },
): number {
  const pageHeaderElement = document.getElementById(PAGE_HEADER_CONTAINER_ID);
  if (!pageHeaderElement) {
    return 0;
  }
  const pageHeaderRects = pageHeaderElement.getBoundingClientRect();
  let top = pageHeaderRects.y + pageHeaderRects.height;
  if (containerRef.current) {
    top += event.clientY - containerRef.current.getBoundingClientRect().y;
  }
  return top;
}

/**
 * Вычисляет координату, дальше которой нельзя двигать вниз
 * Нужно, чтобы окно не заезжало за экран снизу
 */
export function getMinBottomCoordinate(
  containerRef: React.RefObject<HTMLDivElement>,
  event: { clientY: number },
): number {
  if (!containerRef.current) {
    return 0;
  }
  const containerRects = containerRef.current.getBoundingClientRect();
  return containerRects.height - (event.clientY - containerRects.y);
}
