import React, { useState } from 'react';
import { TFunction } from 'i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';

import { BotStatus } from '@utils/Bot';
import { formatPrice } from '@utils/Format/priceFormatter';

import { Anchor } from '@ui/Links';
import { ButtonUnstyled } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import { Tooltip, TooltipMode } from '@ui/Tooltip';
import { Type } from '@ui/Type';
import { sendEvent } from 'cf-common/src/analytics';

import { getFeaturesSet, IFeature } from '../../../../constants/premiumData';

import {
  CryingFace,
  FaceWithSunglasses,
  MonocleFace,
  MouthAndSweatFace,
  WorriedFace,
} from '../../../../modern-ui/_deprecated/Icon/icons';

import FbAdsTargetingIcon from './images/icon_Targeting.png';
import PrioritySupportIcon from './images/icon_Priority_Support.png';
import TeamAccessIcon from './images/icon_Teammates.png';
import NoBrandingIcon from './images/icon_No_Branding.png';
import FullFeaturedIcon from './images/icon_Full_Featured.png';
import NoLimitIcon from './images/icon_No_limit.png';

import * as css from './ProPlanMessage.css';

interface ProPlanMessageProps extends ProPlanMessageViewProps {
  status: BotStatus;
}

interface ProPlanMessageViewProps {
  botId: string;
  termsChecked: boolean;
  onGoPro?: () => void;
  onPageConnect?: () => void;
  onUpdatePayment?: () => void;
  debtAmount?: number;
  daysLeft?: number;
  subscribersLeft?: number;
  subscribersLimit?: number;
  currency?: string | null;
}

interface SimpleMessageViewProps {
  renderEmoji: () => React.ReactNode;
  headerFirstString: string;
  infoText: React.ReactNode;
  headerSecondString?: string;
  accented?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  headerClassName?: string;
}

interface ConfigureTabFeature {
  icon: string;
  feature: IFeature;
}

const formatter = new Intl.NumberFormat('en');

const getConfigureFeatures = (t: TFunction): ConfigureTabFeature[] => {
  const featuresSet = getFeaturesSet(0, t);

  return [
    {
      icon: FullFeaturedIcon,
      feature: featuresSet.fullFeatured,
    },
    {
      icon: NoLimitIcon,
      feature: featuresSet.noLimit,
    },
    {
      icon: NoBrandingIcon,
      feature: featuresSet.noBranding,
    },
    {
      icon: FbAdsTargetingIcon,
      feature: featuresSet.targeting,
    },
    {
      icon: TeamAccessIcon,
      feature: featuresSet.teammates,
    },
    {
      icon: PrioritySupportIcon,
      feature: featuresSet.prioritySupport,
    },
  ];
};

const SimpleMessageView: React.FC<SimpleMessageViewProps> = ({
  renderEmoji,
  headerFirstString,
  headerSecondString,
  infoText,
  accented,
  disabled,
  onClick,
  headerClassName,
}) => (
  <ButtonUnstyled
    className={cn(css.messageView, {
      [css.messageViewAccented]: accented,
      [css.messageViewDisabled]: disabled,
    })}
    onClick={disabled ? undefined : onClick}
  >
    <div className={css.emojiColumn}>{renderEmoji()}</div>
    <div>
      <div className={cn(css.header, headerClassName)}>
        {headerFirstString}
        <br />
        {headerSecondString}
      </div>
      <div className={css.text}>{infoText}</div>
    </div>
  </ButtonUnstyled>
);

const DebtMessage: React.FC<ProPlanMessageViewProps> = ({
  daysLeft = 0,
  debtAmount = 0,
  onUpdatePayment,
  currency,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <WorriedFace />;
  const headerFirstString = t(
    `modernComponents.ProPlanSection.ProPlanMessage.debtDaysLeft`,
    {
      count: daysLeft,
    },
  );
  const headerSecondString = t(
    `modernComponents.ProPlanSection.ProPlanMessage.debtAmount`,
    {
      amount: formatPrice(debtAmount, { currency }),
    },
  );
  const infoText = t(
    `modernComponents.ProPlanSection.ProPlanMessage.debtInfo`,
    { count: daysLeft },
  );

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      headerSecondString={headerSecondString}
      infoText={infoText}
      onClick={onUpdatePayment}
      accented
    />
  );
};

const ExpiredDebtMessage: React.FC<ProPlanMessageViewProps> = ({
  debtAmount = 0,
  onUpdatePayment,
  currency,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <WorriedFace />;
  const headerFirstString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.debtHeader',
  );

  const headerSecondString = t(
    `modernComponents.ProPlanSection.ProPlanMessage.debtAmount`,
    {
      amount: formatPrice(debtAmount, { currency }),
    },
  );
  const infoText =
    window.i18next.t(
      'ProPlanMessage-string--118-new-subscribers-cant-communicate-with-your-bot-at-this-time',
    ) +
    window.i18next.t(
      'ProPlanMessage-string-5861-please-update-your-payment-method-to-pay-the-outstanding-amount',
    );

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      headerSecondString={headerSecondString}
      infoText={infoText}
      onClick={onUpdatePayment}
      accented
    />
  );
};

const ElapsingMessage: React.FC<ProPlanMessageViewProps> = ({
  subscribersLeft = 0,
  subscribersLimit = 0,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <MouthAndSweatFace />;
  const headerFirstString = t(
    `modernComponents.ProPlanSection.ProPlanMessage.usersLeft`,
    {
      count: subscribersLeft,
    },
  );

  const headerSecondString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.leftOnFreePlan',
  );
  const infoText = `${t(
    'ProPlanMessage-Template-3709-when-your-bot-reaches',
  )}${formatter.format(subscribersLimit)}${t(
    'ProPlanMessage-Template--211-users',
  )}${t(
    'ProPlanMessage-string-9976-it-will-stop-working-for-new-people-upgrade-to-the-pro-plan-to-continue',
  )}${t(
    'ProPlanMessage-string--180-adding-subscribers-and-get-access-to-all-pro-features',
  )}`;

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      headerSecondString={headerSecondString}
      infoText={infoText}
    />
  );
};

const ElapsedMessage: React.FC<ProPlanMessageViewProps> = ({
  termsChecked,
  subscribersLimit = 0,
  onGoPro,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <CryingFace />;

  const headerFirstString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.elapsedHeader',
  );
  const headerSecondString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.elapsedSubHeader',
  );
  const infoText = `${t(
    'ProPlanMessage-Template-1555-you-are-over-the',
  )}${formatter.format(subscribersLimit)}${t(
    'ProPlanMessage-Template-1251-subscriber-limit-on-the-free-plan',
  )}${t(
    'ProPlanMessage-string-2008-upgrade-to-the-pro-plan-to-continue-adding-subscribers-and-get-access-to-all-pro-features',
  )}`;

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      headerSecondString={headerSecondString}
      infoText={infoText}
      onClick={onGoPro}
      disabled={!termsChecked}
      accented
    />
  );
};

const ProMessage: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <div className={cn(css.messageView)}>
      <div className={css.emojiColumn}>
        <Emoji emoji="🤩" size={100} />
      </div>
      <div>
        <div className={css.header}>
          {t('ProPlanMessage-JSXText-1191-woo-hoo')}
          <br />
          {t('ProPlanMessage-JSXText--153-youre-on-the-pro-plan')}
        </div>
        <div className={css.text}>
          <span>
            {t(
              'ProPlanMessage-string--180-with-the-pro-plan-you-have-access-to-chatfuels-most-powerful-features',
            ) +
              t(
                'ProPlanMessage-string--178-if-you-are-interested-in-our-premium-service-with-vip-support-and-a-dedicated',
              ) +
              t('ProPlanMessage-string-2985-account-manager')}
          </span>{' '}
          <a
            className={css.mailtoLink}
            target="_blank"
            rel="noreferrer noopener"
            onClick={() => {
              sendEvent({
                category: 'request-demo',
                action: 'dash_talk_to_us_pro',
              });
            }}
            href="https://tiny.cc/chatfueldemo"
          >
            {t('ProPlanMessage-JSXText-1168-talk-to-us')}
          </a>
          <span>.</span>
        </div>
      </div>
    </div>
  );
};

const PremiumMessage: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <div className={cn(css.messageView)}>
      <div className={css.emojiColumn}>
        <FaceWithSunglasses />
      </div>
      <div>
        <div className={css.header}>
          {t('ProPlanMessage-JSXText-1647-booyah')}
          <br />
          {t('ProPlanMessage-JSXText-2551-custom-pro-plan-heh')}
        </div>
        <div className={css.text}>
          <span>
            {t(
              'ProPlanMessage-JSXText-1914-the-pro-plan-for-this-page-was-activated-via-a-custom-request',
            )}{' '}
          </span>
          <br />
          <span>
            {t(
              'ProPlanMessage-JSXText-5658-if-you-have-any-questions-or-concerns-please-contact-your',
            )}
          </span>
          <br />
          <span>
            {t(
              'ProPlanMessage-JSXText-2001-chatfuel-representative-or-reach-out-to',
            )}
          </span>{' '}
          <a className={css.mailtoLink} href="mailto:team@chatfuel.com">
            team@chatfuel.com
          </a>
          <span>.</span>
        </div>
      </div>
    </div>
  );
};

const Feature: React.FC<ConfigureTabFeature> = ({
  icon,
  feature: { title, tooltip },
}) => {
  const [showTooltip, toggleTooltip] = useState(false);

  return (
    <div key={title} className={cn(css.feature)}>
      <img src={icon} alt={title} className={css.featureIcon} />
      <div className={css.featureText}>
        {!tooltip ? (
          <span>{title}</span>
        ) : (
          <Tooltip
            mode={TooltipMode.classic}
            content={tooltip}
            show={showTooltip}
            placement="top-start"
          >
            {(ref) => {
              const onMouseEnter = () => toggleTooltip(true);
              const onMouseLeave = () => toggleTooltip(false);

              return (
                <span
                  className={css.withTooltip}
                  ref={ref}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  {title}
                </span>
              );
            }}
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const AmateurMessage: React.FC = () => {
  const { t } = useSafeTranslation();
  const configureTabFeatures = getConfigureFeatures(t);
  return (
    <div className={cn(css.messageView)}>
      <div>
        <div className={css.header}>
          {t('ProPlanMessage-JSXText--207-upgrade-to-pro-to-get-more')}
        </div>
        <div className={css.featuresList}>
          <div className={css.featuresColumn}>
            {configureTabFeatures.slice(0, 3).map(({ icon, feature }) => (
              <Feature key={feature.title} icon={icon} feature={feature} />
            ))}
          </div>
          <div className={css.featuresColumn}>
            {configureTabFeatures.slice(3).map(({ icon, feature }) => (
              <Feature key={feature.title} icon={icon} feature={feature} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const TrialMessageRegular: React.FC<ProPlanMessageViewProps> = ({
  termsChecked,
  onGoPro,
  subscribersLimit,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <FaceWithSunglasses />;
  const headerFirstString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.trialHeader',
  );
  const infoText = t(
    'modernComponents.ProPlanSection.ProPlanMessage.trialInfo',
    { subscribersLimit },
  );

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      infoText={infoText}
      onClick={onGoPro}
      disabled={!termsChecked}
    />
  );
};

const TrialMessageElapsed: React.FC<ProPlanMessageViewProps> = ({
  termsChecked,
  subscribersLimit = 0,
  onGoPro,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <CryingFace />;

  const headerFirstString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.elapsedTrialHeader',
  );
  const headerSecondString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.elapsedTrialSubHeader',
  );
  const infoText = t(
    'modernComponents.ProPlanSection.ProPlanMessage.elapsedInfo',
    { subscribersLimit },
  );

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      headerSecondString={headerSecondString}
      infoText={infoText}
      onClick={onGoPro}
      disabled={!termsChecked}
      accented
    />
  );
};

const TrialMessageElapsing: React.FC<ProPlanMessageViewProps> = ({
  termsChecked,
  onGoPro,
  subscribersLeft = 0,
  subscribersLimit = 0,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <MouthAndSweatFace />;
  const headerFirstString = t(
    `modernComponents.ProPlanSection.ProPlanMessage.usersLeft`,
    {
      count: subscribersLeft,
    },
  );
  const headerSecondString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.leftOnTrial',
  );
  const infoText = t(
    'modernComponents.ProPlanSection.ProPlanMessage.elapsingInfo',
    {
      subscribersLimit,
    },
  );
  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      headerSecondString={headerSecondString}
      infoText={infoText}
      onClick={onGoPro}
      headerClassName={css.messageViewHeaderTrialHighUsage}
      disabled={!termsChecked}
    />
  );
};

const TrialMessage: React.FC<ProPlanMessageViewProps> = (props) => {
  const { subscribersLimit, subscribersLeft } = props;
  if (subscribersLimit !== undefined && subscribersLeft !== undefined) {
    const noTrialSubscribersLeft = subscribersLeft <= 0;
    if (noTrialSubscribersLeft) {
      return TrialMessageElapsed(props);
    }
    const lessThan20PercentTrialSubscribersLeft =
      subscribersLeft / subscribersLimit <= 0.2;
    if (lessThan20PercentTrialSubscribersLeft) {
      return TrialMessageElapsing(props);
    }
  }

  return TrialMessageRegular(props);
};

const PausedMessage: React.FC = () => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <Emoji emoji="😴" label="😴" size={100} />;
  const headerFirstString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.pausedHeader',
  );
  const infoText = (
    <Type size="15px_DEPRECATED">
      {t(
        'ProPlanMessage-JSXText-1748-your-data-is-safe-but-your-bot-wont-send-or-respond-to',
      )}
      <br />
      {t(
        'ProPlanMessage-JSXText--861-messages-when-youre-ready-to-keep-messaging-leads-and',
      )}
      <br />
      {t('ProPlanMessage-JSXText--163-customers-click-unpause')}
    </Type>
  );

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      infoText={infoText}
    />
  );
};

const PausedAndExpiredDebtMessage: React.FC<ProPlanMessageViewProps> = ({
  debtAmount = 0,
  onUpdatePayment,
  currency,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <WorriedFace />;
  const headerFirstString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.debtHeader',
  );

  const headerSecondString = t(
    'modernComponents.ProPlanSection.ProPlanMessage.debtAmount',
    {
      amount: formatPrice(debtAmount, {
        currency,
      }),
    },
  );
  const infoText = t(
    'modernComponents.ProPlanSection.ProPlanMessage.pauseAndExpiredInfo',
  );

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={headerFirstString}
      headerSecondString={headerSecondString}
      infoText={infoText}
      onClick={onUpdatePayment}
      accented
    />
  );
};

const NoPageMessage: React.FC<ProPlanMessageViewProps> = ({
  termsChecked,
  onPageConnect,
}) => {
  const { t } = useSafeTranslation();
  const renderEmoji = () => <MonocleFace />;

  return (
    <SimpleMessageView
      renderEmoji={renderEmoji}
      headerFirstString={t('ProPlanMessage-string-9314-connect-your-bot')}
      headerSecondString={t('ProPlanMessage-string-2112-to-a-page')}
      disabled={!termsChecked}
      infoText={
        <>
          <Anchor intent="external" hideArrow onClick={onPageConnect}>
            {t('ProPlanMessage-JSXText-4889-click-here-to-connect-page')}
          </Anchor>
          <span>
            {t(
              'ProPlanMessage-JSXText-1104-our-bot-cant-communicate-with-people-if-its-not-connected-to-a-page',
            )}
          </span>
        </>
      }
    />
  );
};

const getMessageView = (status: BotStatus): React.FC<ProPlanMessageViewProps> =>
  ({
    [BotStatus.paused]: PausedMessage,
    [BotStatus.pausedAndExpiredDebt]: PausedAndExpiredDebtMessage,
    [BotStatus.trial]: TrialMessage,
    [BotStatus.debt]: DebtMessage,
    [BotStatus.expiredDebt]: ExpiredDebtMessage,
    [BotStatus.elapsing]: ElapsingMessage,
    [BotStatus.elapsed]: ElapsedMessage,
    [BotStatus.premium]: PremiumMessage,
    [BotStatus.amateur]: AmateurMessage,
    [BotStatus.noPage]: NoPageMessage,
    [BotStatus.draft]: NoPageMessage,
    [BotStatus.pro]: ProMessage,
  }[status]);

export const ProPlanMessage: React.FC<ProPlanMessageProps> = ({
  status,
  ...props
}) => {
  const ProPlanMessageView = getMessageView(status);
  return <ProPlanMessageView {...props} />;
};
