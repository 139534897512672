import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import image1Src from './images/TestFacebookShopsDialog_1.png';
import image2Src from './images/TestFacebookShopsDialog_2.png';
import image3Src from './images/TestFacebookShopsDialog_3.png';
import image4Src from './images/TestFacebookShopsDialog_4.png';
import { TestSteps } from './components';

const TEST_STEPS = () => [
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookShopsDialog-JSXText--173-1-add-products-in-the-facebook-shops',
        )}
        <br />
        {window.i18next.t('TestFacebookShopsDialog-JSXText-1366-entry-point')}
      </>
    ),
    src: image1Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookShopsDialog-JSXText-1999-2-toggle-the-entry-point-on',
        )}
      </>
    ),
    src: image2Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookShopsDialog-JSXText--468-3-go-to-the-product-page-in',
        )}
        <br />
        {window.i18next.t(
          'TestFacebookShopsDialog-JSXText-2063-facebook-page-and-send-a-message',
        )}
        <br />
        {window.i18next.t(
          'TestFacebookShopsDialog-JSXText--787-to-the-bot-by-clicking-the-message',
        )}
        <br />
        {window.i18next.t('TestFacebookShopsDialog-JSXText-1523-button')}
      </>
    ),
    src: image3Src,
  },
  {
    text: (
      <>
        {window.i18next.t(
          'TestFacebookShopsDialog-JSXText-9833-4-review-the-response-from-your',
        )}
        <br />
        {window.i18next.t(
          'TestFacebookShopsDialog-JSXText--128-bot-in-messenger',
        )}
      </>
    ),
    src: image4Src,
  },
];

export const TestFacebookShopsDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => (
  <DefaultDialog
    dialogStyle={{
      width: 572,
    }}
    header={window.i18next.t(
      'TestFacebookShopsDialog-string-1628-test-your-facebook-shops-entry-point',
    )}
    onDismiss={onRequestClose}
  >
    <TestSteps steps={TEST_STEPS()} />
  </DefaultDialog>
);
