import React from 'react';
import cn from 'classnames';
import { CollapsibleSection } from '@ui/CollapsibleSection';
import css from './Workspace.css';
import {
  WorkspaceAnchor,
  WorkspaceAnchorProps,
} from './WorkspaceAnchor/WorkspaceAnchor';
import { NestedMenuItem } from '@ui/NestedMenu/types';

export interface WorkspaceProps<MenuItemType extends NestedMenuItem>
  extends Omit<
    WorkspaceAnchorProps<MenuItemType>,
    'changeExtended' | 'extended'
  > {
  className?: string;
  readOnly: boolean;
  children?: React.ReactNode;
  defaultExtended: boolean;
  onChangeExtends(extended: boolean): void;
}

export const Workspace = <MenuItemType extends NestedMenuItem>({
  className,
  title,
  status,
  limitation,
  children,
  titleEditing,
  onTitleChange,
  menuItems,
  readOnly,
  onMenuItemSelect,
  onBillingClick,
  pages,
  isUsers,
  defaultExtended,
  onChangeExtends,
}: WorkspaceProps<MenuItemType>) => {
  return (
    <div
      className={cn(css.workspace, className)}
      data-testid="workspace_bots_table"
    >
      <CollapsibleSection
        defaultExtended={defaultExtended}
        anchor={({ changeExtended, extended }) => (
          <WorkspaceAnchor
            isUsers={isUsers}
            extended={extended}
            changeExtended={() => {
              changeExtended();
              onChangeExtends(!extended);
            }}
            title={title}
            status={status}
            limitation={limitation}
            pages={pages}
            titleEditing={titleEditing}
            onTitleChange={onTitleChange}
            menuItems={menuItems}
            onMenuItemSelect={onMenuItemSelect}
            onBillingClick={onBillingClick}
            readOnly={readOnly}
          />
        )}
      >
        {({ bind }) => {
          return (
            <div {...bind} className={css.botList}>
              {children}
            </div>
          );
        }}
      </CollapsibleSection>
    </div>
  );
};
