import React from 'react';
import * as css from './QuickReply.css';

interface QuickReplyProps {
  quickReplay: string;
  onClick(): void;
}

export const QuickReply: React.FC<QuickReplyProps> = ({
  quickReplay,
  onClick,
}) => {
  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
    <div className={css.quickReply} onClick={onClick}>
      {quickReplay}
    </div>
  );
};
