import { useCallback, useEffect, useState } from 'react';
import { WhatsappImportUserDialogSteps } from '../consts';
import { useWhatsappImportUsersSession } from './useWhatsappImportUsersSession';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { WhatsappImportUsersSessionStatus } from '@globals';
import { useUserDataCsvFile } from './useUserDataCsvFile';
import { sendEvent } from '@utils/Analytics';

const LIVE_STATUSES = [
  WhatsappImportUsersSessionStatus.idle,
  WhatsappImportUsersSessionStatus.running,
];

const ERROR_STATUSES = [
  WhatsappImportUsersSessionStatus.failed_init,
  WhatsappImportUsersSessionStatus.error,
  WhatsappImportUsersSessionStatus.failed_completely,
];

export const useWhatsappImportUserDialogStep = () => {
  const [step, setStepState] = useState<WhatsappImportUserDialogSteps>(
    WhatsappImportUserDialogSteps.selectFile,
  );
  const { status, setCurrentStatus } = useWhatsappImportUsersSession();
  const { networkError } = useUserDataCsvFile();

  const prevStatus = usePrevious(status);
  const prevNetworkError = usePrevious(networkError);

  const setStep = useCallback((step: WhatsappImportUserDialogSteps) => {
    setStepState(step);
    sendEvent({
      category: 'people tab',
      label: 'whatsapp user import',
      action: 'set step',
      propertyBag: {
        step,
      },
    });
  }, []);

  useEffect(() => {
    if (prevStatus !== status && LIVE_STATUSES.includes(status)) {
      setStep(WhatsappImportUserDialogSteps.process);
      return;
    }

    if (
      prevStatus &&
      LIVE_STATUSES.includes(prevStatus) &&
      status === WhatsappImportUsersSessionStatus.success
    ) {
      setStep(WhatsappImportUserDialogSteps.success);
      return;
    }

    if (
      prevStatus &&
      LIVE_STATUSES.includes(prevStatus) &&
      ERROR_STATUSES.includes(status)
    ) {
      setStep(WhatsappImportUserDialogSteps.error);
    }
  }, [prevStatus, setStep, status]);

  useEffect(() => {
    if (!prevNetworkError && networkError) {
      setStep(WhatsappImportUserDialogSteps.error);
      setCurrentStatus(WhatsappImportUsersSessionStatus.error);
    }
  }, [networkError, prevNetworkError, setCurrentStatus, setStep]);

  return {
    step,
    setStep,
  };
};
