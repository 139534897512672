import React, { useState } from 'react';
import { ButtonUnstyled } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Trans } from 'react-i18next';

export const CsvNote: React.FC = () => {
  const { t } = useSafeTranslation();
  const [expand, setExpand] = useState<boolean>(false);

  return (
    <>
      <ButtonUnstyled
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <Type color="accent1Normal" weight="semibold">
          <Flex alignItems="center">
            {t(
              'modernComponents.WhatsAppImportUser.dialog.FileUploader.csvNote.title',
            )}
            <Icon icon={expand ? 'chevronUp' : 'chevronDown'} noColor />
          </Flex>
        </Type>
      </ButtonUnstyled>
      {expand && (
        <>
          <Spacer factor={1} />
          <Type>
            <Trans
              t={t}
              i18nKey="modernComponents.WhatsAppImportUser.dialog.FileUploader.csvNote.text"
            />
          </Type>
        </>
      )}
    </>
  );
};
