import { Button } from '@ui/Button';
import { CheckBox } from '@ui/CheckBox';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Anchor } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { NetworkStatus } from 'apollo-client';
import { clone, prop, propEq } from 'ramda';
import React, { useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { FacebookPagePostType } from '@globals';
import { commentsAutoreplyEntryPointFragment_config_posts as Post } from '../../Plugins/CommentsAutoreplyEntryPoint/@types/commentsAutoreplyEntryPointFragment';
import { PluginType } from '../../Plugins/common/PluginTypes';
import * as css from '../ChooseItemFromListDialog/ChooseItemFromListDialog.css';
import {
  ItemList,
  ComposeTabId,
  getCurrentItems,
  useActionOnDialogOpen,
  useItemLoadOnTabChange,
} from '../ChooseItemFromListDialog';
import { ReactComponent as PostPreviewImg } from '../ChooseItemFromListDialog/images/post-preview.svg';
import { filterPostsBySearchString, validateFacebookPostUrl } from './helpers';
import { useFacebookPostsFetch } from './hooks/useFacebookPostsFetch';
import { useRemoveFacebookPagePost } from './hooks/useRemovePost';
import { FacebookPagePostsQuery_page_morePosts_posts as FacebookPost } from './@types/FacebookPagePostsQuery';
import { FacebookPagePostByUrl_page_post as FacebookPagePost } from './@types/FacebookPagePostByUrl';
import { Translation } from '@translations';
import { DateFormat } from '@utils/DateTime';
import { PagePostsAnchor } from '@components/FlowBuilder/views/entry-points/common/components/ChooseAdsPopup/components/NoAdsView';

const getI18nKey = (key: string) =>
  `modernComponents.ChooseFacebookCommentAutoreplyPostsDialog.postList.${key}` as Translation;

interface FacebookCommentAutoreplyPostsListProps {
  addedPosts: Post[];
  onSubmit: (checkedPosts: Post[]) => void;
  onRemovedPost?: (post: FacebookPagePost) => void;
  activeTabId: string;
  searchString: string;
  postByUrl: FacebookPagePost | undefined;
  postByUrlLoading: boolean;
}

export const FacebookCommentAutoreplyPostsList: React.FC<FacebookCommentAutoreplyPostsListProps> =
  ({
    addedPosts,
    onRemovedPost,
    onSubmit,
    activeTabId,
    searchString,
    postByUrl,
    postByUrlLoading,
  }) => {
    const { t } = useSafeTranslation();

    const [checkedPosts, setCheckedPosts] = useState(addedPosts);

    const { removePost } = useRemoveFacebookPagePost({ onRemovedPost });

    const {
      posts,
      cursors,
      postsLoading,
      loading,
      loadMore,
      refetch,
      networkStatus,
      currentTypes,
      error,
    } = useFacebookPostsFetch({ activeTabId, postByUrlLoading });

    useItemLoadOnTabChange({
      items: posts,
      searchString,
      loading,
      activeTabId,
      cursors,
      loadMore,
      error,
    });

    useActionOnDialogOpen({
      itemsLoading: postsLoading,
      action: refetch,
    });

    const isRefetch = networkStatus === NetworkStatus.refetch;
    const isValidPostUrl = validateFacebookPostUrl(searchString);

    const currentPosts = getCurrentItems<FacebookPost>({
      activeTabId,
      itemByUrl: postByUrl,
      items: posts,
      searchString,
      loading,
      isRefetch,
      isValidItemUrl: isValidPostUrl,
      filterFunc: (item) =>
        currentTypes.includes(item.type) &&
        filterPostsBySearchString(searchString, item),
    });

    const isFoundPostsByTypes = posts?.some(({ type }) =>
      currentTypes.includes(type),
    );

    return (
      <>
        {activeTabId === FacebookPagePostType.scheduled && isFoundPostsByTypes && (
          <>
            <Flex className={css.availabilityMessage}>
              <Type size="15px">
                {t(getI18nKey('availabilityMessage.part1'))} <PagePostsAnchor />{' '}
                {t(getI18nKey('availabilityMessage.part2'))}
              </Type>
            </Flex>
            <Spacer factor={6} />
          </>
        )}
        <ItemList<FacebookPost>
          height={360}
          loading={postsLoading}
          loadMore={loadMore}
          currentPosts={currentPosts}
          notFoundState={
            <div>
              {activeTabId === ComposeTabId.postUrl && (
                <Type size="15px_DEPRECATED">
                  {t(getI18nKey('postNotFoundPostUrlTabPlaceholderText'))}&nbsp;
                </Type>
              )}
              {activeTabId === FacebookPagePostType.scheduled &&
                !isFoundPostsByTypes && (
                  <>
                    <Type size="15px">
                      {t(
                        getI18nKey(
                          'scheduledPostNotFoundPlaceholderText.part1',
                        ),
                      )}{' '}
                      <PagePostsAnchor />{' '}
                      {t(
                        getI18nKey(
                          'scheduledPostNotFoundPlaceholderText.part2',
                        ),
                      )}
                    </Type>
                  </>
                )}
              {activeTabId === FacebookPagePostType.promoted &&
                !isFoundPostsByTypes && (
                  <Type size="15px_DEPRECATED">
                    {t(getI18nKey('promotedPostNotFoundPlaceholderText'))}
                  </Type>
                )}
              {[FacebookPagePostType.published, ComposeTabId.all].includes(
                activeTabId as FacebookPagePostType | ComposeTabId,
              ) &&
                !isFoundPostsByTypes && (
                  <Type size="15px_DEPRECATED">
                    {t(getI18nKey('noPostsOnFacebookPagePlaceholderText'))}
                  </Type>
                )}
              {activeTabId !== ComposeTabId.postUrl &&
                !!searchString &&
                isFoundPostsByTypes && (
                  <Type size="15px_DEPRECATED">
                    {t(getI18nKey('postNotFoundPlaceholderText'))}
                  </Type>
                )}
            </div>
          }
          invalidUrlState={
            <div>
              <Type size="15px_DEPRECATED">
                {t(getI18nKey('invalidPostUrlText'))}&nbsp;
              </Type>
            </div>
          }
          renderPost={({ post, style }) => {
            const isPostExcluded = addedPosts.map(prop('id')).includes(post.id);

            return (
              <Tooltip2
                boundariesElement="viewport"
                type="small"
                content={
                  <div className={css.postPreview}>
                    {post.full_picture && (
                      <img
                        src={post.full_picture}
                        className={css.tooltipPicture}
                        alt={post.message ?? ''}
                      />
                    )}
                    <div style={{ padding: 8 }}>
                      {post.message && (
                        <>
                          <div className={css.tooltipTitle}>
                            <Type size="12px">{post.message}</Type>
                          </div>
                          <Spacer factor={2} />
                        </>
                      )}
                      <PostPreviewImg />
                    </div>
                  </div>
                }
              >
                {(ref, bind) => (
                  <div style={style}>
                    <Flex inline style={{ height: 40 }} alignItems="center">
                      {post.flow_id && !isPostExcluded ? (
                        <Tooltip2
                          content={
                            <>
                              {post.flow_id && (
                                <>
                                  {t(getI18nKey('postAlreadyUsedInFlowText'), {
                                    flowTitle: post.flow_title,
                                  })}
                                  <br />
                                  <br />
                                  <Anchor
                                    intent="tooltip"
                                    onClick={() => {
                                      removePost(post);
                                      sendEvent({
                                        category: 'flows',
                                        action: 'Remove post click (tooltip)',
                                        label:
                                          PluginType.comments_autoreply_entry_point,
                                        propertyBag: {
                                          postId: post.id,
                                          flowId: post.flow_id,
                                        },
                                      });
                                    }}
                                  >
                                    {t(
                                      getI18nKey(
                                        'removePostFromFlowAnchorText',
                                      ),
                                      {
                                        flowTitle: post.flow_title,
                                      },
                                    )}
                                  </Anchor>
                                </>
                              )}
                            </>
                          }
                          placement="right"
                          hoverable
                          type="small"
                        >
                          {(ref, bind) => (
                            <div className={css.checkBox} ref={ref} {...bind}>
                              <Icon
                                icon="warning"
                                size="28px"
                                color="red"
                                style={{
                                  marginLeft: -5,
                                }}
                              />
                            </div>
                          )}
                        </Tooltip2>
                      ) : (
                        <CheckBox
                          className={css.checkBox}
                          checked={checkedPosts.some(propEq('id', post.id))}
                          onChange={() => {
                            const updatedPosts = clone(checkedPosts);
                            const postIndex = checkedPosts.findIndex(
                              propEq('id', post.id),
                            );
                            if (postIndex === -1) {
                              updatedPosts.push({
                                ...post,
                                __typename: 'PostIdWithStatus',
                              });
                            } else {
                              updatedPosts.splice(postIndex, 1);
                            }
                            setCheckedPosts(updatedPosts);
                            sendEvent({
                              category: 'flows',
                              action: 'Post select change',
                              label: PluginType.comments_autoreply_entry_point,
                              propertyBag: {
                                postId: post.id,
                                selected: postIndex === -1,
                              },
                            });
                          }}
                        />
                      )}
                      <Flex alignItems="center" ref={ref} {...bind}>
                        <div
                          className={css.postPicture}
                          style={{
                            backgroundImage: post.full_picture
                              ? `url(${post.full_picture})`
                              : undefined,
                          }}
                        />
                        <div>
                          <div
                            className={css.title}
                            title={post.message || undefined}
                          >
                            {post.message ? (
                              <Type size="15px_DEPRECATED">{post.message}</Type>
                            ) : (
                              <Type color="grey" size="15px_DEPRECATED">
                                {t(getI18nKey('noPostTitlePlaceholderText'))}
                              </Type>
                            )}
                          </div>
                          {post.created_time && (
                            <Type size="12px" color="greyDark">
                              {DateFormat.DDMMMMHHmm(post.created_time)}
                            </Type>
                          )}
                        </div>
                      </Flex>
                    </Flex>
                  </div>
                )}
              </Tooltip2>
            );
          }}
        />
        <Flex
          className={css.bottom}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            data-testid="choose-posts__submit"
            className={css.bottomButton}
            onClick={() => {
              onSubmit(checkedPosts);
              sendEvent({
                category: 'flows',
                action: 'Add posts click',
                label: PluginType.comments_autoreply_entry_point,
                propertyBag: {
                  postIds: checkedPosts.map(({ id }) => id),
                },
              });
            }}
            disabled={
              activeTabId === ComposeTabId.postUrl ? postByUrlLoading : loading
            }
          >
            {t(getI18nKey('addPostButtonText'))}
          </Button>
        </Flex>
      </>
    );
  };
