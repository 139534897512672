import { log } from 'cf-common/src/logger';
import { isProductionDomain } from 'cf-common/src/whitelabel';

export type ReferralFormValue = {
  industry: string;
  email: string;
  name: string;
  company: string;
  clid: string;
  botId: string;
  userId: string;
  otherIndustry?: string;
};

export const sendReferralDataToSheet = (form: ReferralFormValue) => {
  // array structure should be persist to add to google sheet
  const columnStructuredValues: (string | number)[] = [
    form.name || '',
    form.email || '',
    form.industry || '',
    form.company || '',
    form.botId || '',
    form.userId || '',
    form.clid || '',
  ];

  if (!isProductionDomain()) {
    // eslint-disable-next-line no-console
    console.log('[referral-data]', JSON.stringify(columnStructuredValues));
    return Promise.resolve();
  }

  // Temporary solution
  // Will be send to https://docs.google.com/spreadsheets/d/1m0_l2UHawrr2EvCaxXTNvCHT6-7ifqWXJyh_4XDWIco/edit#gid=0
  // ToDo: remove after PoC movement to own backend
  return fetch('https://chatfuel.com/api/referralForm', {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(columnStructuredValues),
  })
    .catch((error) => {
      log.error({ error });
    })
    .then((response) =>
      response?.status === 200 ? response.json() : Promise.reject(),
    );
};
