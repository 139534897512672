import React from 'react';
import cn from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { inc } from 'ramda';
import { AddSkillMutation_addSkill_block_cards_ToDoPlugin_config_todo as ITodo } from '../AddSkill/@types/AddSkillMutation';
import { getDoneQty } from '../skillUtils';
import { CheckBox, CheckboxTheme } from '../../../modern-ui/CheckBox';
import { ButtonUnstyled } from '../../../modern-ui/Button';
import * as css from './TodoPlugin.css';

export interface ITodoPluginProps {
  config: { title: string; items: ITodo[] };
  onTodoChecked: (todoId: string) => void;
  onBlockLinkClick: (blockId: string) => void;
}

interface ITodoPluginState {}

const MAX_SHOW_DONE_TODO = 2;

export class TodoPlugin extends React.Component<
  ITodoPluginProps,
  ITodoPluginState
> {
  state = {};

  renderDescription = (description: string) => {
    const { onBlockLinkClick } = this.props;
    const linksEx = /\[([^\[\]|]+)\|([^\[\]|]+)\]/g; // eslint-disable-line no-useless-escape
    const textsEx = /\[[^\[\]|]+\|[^\[\]|]+\]/; // eslint-disable-line no-useless-escape
    const links: string[][] = [];

    let match;
    do {
      match = linksEx.exec(description);

      if (match) {
        links.push(match);
      }
    } while (match);

    const texts = description.split(textsEx);

    return texts.map((text, index) => {
      const link = links[index];
      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          <span>{text}</span>
          {link && (
            <ButtonUnstyled
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                onBlockLinkClick(link[2]);
              }}
              className={css.link}
            >
              {link[1]}
            </ButtonUnstyled>
          )}
        </React.Fragment>
      );
    });
  };

  render() {
    const {
      config: { items, title },
      onTodoChecked,
    } = this.props;

    const doneQty = getDoneQty(items);
    let currentShowDoneQty = 0;

    return (
      <div
        className={cn(css.todoPlugin, {
          [css.show]: items.length,
        })}
      >
        {items.length ? (
          <React.Fragment>
            {title && <div className={css.todoPluginTitle}>{title}</div>}
            <div>
              <TransitionGroup>
                {items.map(({ id, description, done }) => {
                  if (done) {
                    currentShowDoneQty = inc(currentShowDoneQty);
                  }

                  return !done ||
                    MAX_SHOW_DONE_TODO > doneQty - currentShowDoneQty ? (
                    <CSSTransition
                      key={id}
                      timeout={200}
                      classNames={{
                        enter: css.itemEnter,
                        enterActive: css.itemEnterActive,
                        exit: css.itemExit,
                        exitActive: css.itemExitActive,
                      }}
                    >
                      <ButtonUnstyled
                        className={cn(css.todoItem, { [css.checked]: done })}
                        onClick={() => onTodoChecked(id)}
                      >
                        <CheckBox
                          className={css.checkBox}
                          checked={!!done}
                          readOnly
                          disabled={!!done}
                          theme={CheckboxTheme.dark}
                        />
                        {this.renderDescription(description)}
                      </ButtonUnstyled>
                    </CSSTransition>
                  ) : (
                    undefined
                  );
                })}
              </TransitionGroup>
            </div>
          </React.Fragment>
        ) : (
          undefined
        )}
      </div>
    );
  }
}
