import React from 'react';
import { Flex } from '@ui/Flex';
import { Type, TypeProps } from '@ui/Type';
import { Badge, Kind } from '@ui/Badge';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './ProBadge.css';

export interface ProBadgeProps extends TypeProps<HTMLDivElement>, TestLocator {}

export const ProBadge: React.FC<ProBadgeProps> = (props) => {
  const { t } = useSafeTranslation();

  return (
    <Badge
      size="s"
      className={css.badge}
      kind={Kind.alert}
      data-testid={props['data-testid']}
    >
      <Flex alignItems="center" justifyContent="center">
        <Type color="white" weight="bold" {...props}>
          {t('ProInformer-JSXText-8052-pro')}
        </Type>
      </Flex>
    </Badge>
  );
};
