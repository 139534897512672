import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import { useCurrentBotId } from '@utils/Routing';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { TestSteps } from './components';
import image1Src from './images/TestRefLinkDialog_1.png';
import image2Src from './images/TestRefLinkDialog_2.png';
import image3Src from './images/TestRefLinkDialog_3.png';

const TEST_STEPS = () => [
  {
    text: window.i18next.t(
      'TestRefLinkDialog-string-1306-1-toggle-the-entry-point-on',
    ),
    src: image2Src,
  },
  {
    text: window.i18next.t(
      'TestRefLinkDialog-string--574-2-test-the-entry-point-again',
    ),
    src: image3Src,
  },
];

const TEST_STEPS_NO_PAGE_CONNECTED = () => [
  {
    text: window.i18next.t(
      'TestRefLinkDialog-string--201-1-connect-your-facebook-page',
    ),
    src: image1Src,
  },
  {
    text: window.i18next.t(
      'TestRefLinkDialog-string-1999-2-toggle-the-entry-point-on',
    ),
    src: image2Src,
  },
  {
    text: window.i18next.t(
      'TestRefLinkDialog-string--563-3-test-the-entry-point-again',
    ),
    src: image3Src,
  },
];

export const TestRefLinkDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => {
  const botId = useCurrentBotId();
  const { isConnected } = usePageConnected(botId);

  return (
    <DefaultDialog
      dialogStyle={{
        width: 572,
      }}
      header={window.i18next.t(
        'TestRefLinkDialog-string-3442-test-your-bot-links-entry-point',
      )}
      onDismiss={onRequestClose}
    >
      <TestSteps
        steps={isConnected ? TEST_STEPS() : TEST_STEPS_NO_PAGE_CONNECTED()}
      />
    </DefaultDialog>
  );
};
