import gql from 'graphql-tag';

export const START_WHATSAPP_IMPORT_USERS_SESSION_MUTATION = gql`
  mutation StartWhatsappImportUsersSessionMutation(
    $botId: String!
    $payload: WhatsappImportUsersSessionPayload!
  ) {
    startWhatsappImportUsersSession(botId: $botId, payload: $payload) {
      id
      status
      error
    }
  }
`;

export const WHATSAPP_IMPORT_USERS_SESSION_STATUS_QUERY = gql`
  query WhatsappImportUsersSessionStatusQuery(
    $botId: String!
    $csvUploadSessionId: String!
  ) {
    whatsappImportUsersSessionStatus(
      botId: $botId
      csvUploadSessionId: $csvUploadSessionId
    ) {
      id
      status
      error
      stats {
        imported
        updated
        declined
        declinedErrorsStats {
          fileImportContactError
          errorsCount
          position
        }
      }
      canDownloadDeclinedUsersFile
    }
  }
`;

export const WHATSAPP_IMPORT_LIMITS_QUERY = gql`
  query WhatsappImportLimitsQuery($botId: String!) {
    whatsappImportLimits(botId: $botId) {
      perFileLimit
      perMonthLimit
    }
  }
`;
