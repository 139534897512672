import { angular2react } from 'angular2react';
import React from 'react';
import ng from 'angular';
import { assoc } from 'ramda';
import { PluginDataQuery_card_AbtPlugin_config_user_filter as UserFilterData } from '../Plugins/common/@types/PluginDataQuery';
import { lazyInjector } from '../../utils/angularLazyInjector';

export enum UserFilterColorWay {
  white = 'white',
  lightGray = 'light-gray',
}

interface UserFilterWrapperProps {
  filter: UserFilterData;
  showGlobalPreloader?: boolean;
  onChange?: (filter: UserFilterData) => void;
  onReady?: () => void;
  onFilterItemRemove?: (data: any) => void;
  colorWay?: UserFilterColorWay;
  includeOnlyParameters?: string[];
}

interface UserFilterProps extends UserFilterWrapperProps {
  onBlur?: () => void;
}

const addParameterTypename = assoc('__typename', 'SegmentationParameter');

const userFilterForReactWrapper = {
  template: () => `
    <user-filter-component-data-loader
          type="{{vm.colorWay}}"
          filter="vm.filter"
          show-global-preloader="vm.showGlobalPreloader"
          on-change="vm.onChangeRaw($filter)"
          on-ready="vm.onReady()"
          on-filter-item-remove="vm.onFilterItemRemove()"
          include-only-parameters="vm.includeOnlyParameters"
        ></user-filter-component-data-loader>
  `,
  controllerAs: 'vm',
  controller: class implements ng.IComponentController {
    onChange: ((filter: UserFilterData) => any) | undefined;

    onChangeRaw = (filter: UserFilterData) => {
      if (this.onChange) {
        this.onChange({
          ...filter,
          parameters: filter.parameters.map(addParameterTypename),
        });
      }
    };
  },
  bindings: {
    filter: '<',
    showGlobalPreloader: '<',
    onChange: '<',
    onReady: '<',
    onFilterItemRemove: '<',
    colorWay: '<',
    includeOnlyParameters: '<',
  },
};

export const ngUserFilterForReactWrapper = ng
  .module('app.components.users.userFilterForReactWrapper', [])
  .component('userFilterForReactWrapper', userFilterForReactWrapper).name;

const UserFilterWrapper = angular2react<UserFilterWrapperProps>(
  'userFilterForReactWrapper',
  userFilterForReactWrapper,
  lazyInjector.$injector as any,
);

export const UserFilter: React.FC<UserFilterProps> = ({ onBlur, ...props }) => (
  <div onBlur={onBlur}>
    <UserFilterWrapper {...props} />
  </div>
);
