import { Translation } from '../../../@types/translations';

export type ComboboxIndustry = {
  id: string;
  label: string;
};

export const CUSTOM_INDUSTRY = 'other';

export const getIndustries = (
  t: (key: Translation) => string,
): ComboboxIndustry[] => [
  {
    label: t('modernComponents.ReferralProgram.form.industries.e-commerce'),
    id: 'e-commerce',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.education'),
    id: 'education',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.entertainment'),
    id: 'entertainment',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.health-beauty'),
    id: 'health-beauty',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.real-estate'),
    id: 'real-estate',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.restaurants'),
    id: 'restaurants',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.retail'),
    id: 'retail',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.just-looking'),
    id: 'just-looking',
  },
  {
    label: t('modernComponents.ReferralProgram.form.industries.other'),
    id: CUSTOM_INDUSTRY,
  },
];
