import { Modal } from '@services/index';
import React from 'react';
import { WhatsappImportUsersDialog } from './WhatsappImportUsersDialog';
import { WhatsappImportUsersSessionContextProvider } from './hocks/useWhatsappImportUsersSession';
import { UserDataCsvFileContextProvider } from './hocks/useUserDataCsvFile';

export const showWhatsappImportUsersDialog = (onDone: VoidFunction) => {
  Modal.show(({ close }) => (
    <UserDataCsvFileContextProvider>
      <WhatsappImportUsersSessionContextProvider>
        <WhatsappImportUsersDialog
          onDismiss={() => {
            close();
          }}
          onDone={() => {
            close();
            onDone();
          }}
        />
      </WhatsappImportUsersSessionContextProvider>
    </UserDataCsvFileContextProvider>
  ));
};
