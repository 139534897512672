import React from 'react';
import { Query } from '@apollo/react-components';

import { showIntercom } from '@utils/Intercom';

import { Modal } from '../../../../modern-ui/Modal';
import { DefaultDialog } from '../../../../modern-ui/Dialog/DefaultDialog';
import { Type } from '../../../../modern-ui/Type';
import { Anchor } from '../../../../modern-ui/Links';
import { Spacer } from '../../../../modern-ui/Spacer';
import { Flex } from '../../../../modern-ui/Flex';

import { PremiumPaymentHistory } from '../PremiumPaymentHistory';
import { CUSTOM_PRICING_URL } from '../../../Payments/constants/customPricing';
import { ExistingCard, PaymentStatus } from '../../../Payments';
import { PREMIUM_PAYMENT_METHOD_QUERY } from '../queries';
import { PaymentInterval } from '../../../../pages/UpgradePage/components/paymentInterval';

import { isWarning } from './subscriptionStatus';

import { PremiumPaymentMethodQuery as PremiumPaymentMethod } from '../@types/PremiumPaymentMethodQuery';

interface SubscriptionInfo {
  id: string;
  status: string | null;
  current_period_end: number | null;
}

export const PaymentStatusModal: React.FC<{
  onDismiss: () => void;
  onUpdateCard: () => void;
  planName: string;
  amount: number;
  currency?: string;
  intervalName: PaymentInterval;
  subscriptionInfo: null | SubscriptionInfo;
}> = ({
  onDismiss,
  planName,
  amount,
  currency,
  onUpdateCard,
  intervalName,
  subscriptionInfo,
}) => {
  if (!subscriptionInfo) {
    throw new Error('View requires subscriptionInfo');
  }
  const isWarningState = isWarning(subscriptionInfo);

  return (
    <Modal onDismiss={onDismiss}>
      <DefaultDialog
        header={`${window.i18next.t(
          'PaymentStatusModal-Template-1383-status',
        )}${planName}`}
        onDismiss={onDismiss}
        contentStyle={{
          maxHeight: 'calc(100vh - 20px)',
          width: '520px',
          display: 'flex',
          flexDirection: 'column',
        }}
        dialogStyle={{ width: '520px' }}
      >
        <Query<PremiumPaymentMethod> query={PREMIUM_PAYMENT_METHOD_QUERY}>
          {({ data, loading }) => (
            <>
              <Flex flexDirection="column">
                <Flex alignItems="baseline">
                  <Type size="15px_DEPRECATED">
                    {window.i18next.t(
                      'PaymentStatusModal-JSXText--396-your-current-plan-is',
                    )}
                    {planName}
                    {window.i18next.t(
                      'PaymentStatusModal-JSXText-1722-any-questions',
                    )}
                  </Type>
                  <Anchor
                    intent="internal"
                    onClick={() => {
                      showIntercom();
                    }}
                  >
                    {window.i18next.t(
                      'PaymentStatusModal-JSXText--634-lets-chat',
                    )}
                  </Anchor>
                </Flex>
                <Spacer />
                {subscriptionInfo && subscriptionInfo.current_period_end && (
                  <PaymentStatus
                    amount={amount / 100}
                    currency={currency}
                    intervalName={intervalName}
                    nextPaymentDate={
                      // UNIX timestamp to js timestamp
                      new Date(subscriptionInfo.current_period_end * 1000)
                    }
                  />
                )}
                <Spacer />
                {!loading &&
                  data &&
                  data.me &&
                  data.me.premiumAccount &&
                  data.me.premiumAccount.paymentMethod && (
                    <ExistingCard
                      onUpdate={onUpdateCard}
                      error={isWarningState}
                      cardLastNumbers={
                        data.me.premiumAccount.paymentMethod.last4
                      }
                    />
                  )}
                {subscriptionInfo.status == null ? (
                  <>
                    <Spacer factor={3} />
                    <i>
                      <Type size="15px_DEPRECATED">
                        {window.i18next.t(
                          'PaymentStatusModal-JSXText--148-unexpected-subscription-status-please',
                        )}{' '}
                      </Type>
                      <Anchor
                        hideArrow
                        intent="external"
                        href={CUSTOM_PRICING_URL}
                        target="_blank"
                      >
                        {window.i18next.t(
                          'PaymentStatusModal-JSXText--339-contact-us',
                        )}
                      </Anchor>
                      .
                    </i>
                  </>
                ) : null}
                {isWarningState ? (
                  <>
                    <Spacer factor={3} />
                    <Type color="red" size="15px_DEPRECATED">
                      {window.i18next.t(
                        'PaymentStatusModal-JSXText-2383-we-were-unable-to-process-your-payment',
                      )}
                    </Type>
                  </>
                ) : null}
                <Spacer factor={3} />
              </Flex>
              <PremiumPaymentHistory />
            </>
          )}
        </Query>
      </DefaultDialog>
    </Modal>
  );
};
