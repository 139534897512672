import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  OneTimeRefForFlowBlockQuery,
  OneTimeRefForFlowBlockQueryVariables,
} from '@components/TestInFacebook/utils/@types/OneTimeRefForFlowBlockQuery';
import { useOneTimeRef } from '@components/TestInFacebook/utils/useOneTimeRef';
import { FlowPlaceholder } from '@utils/Data/Flow';

const ONE_TIME_REF_FOR_FLOW_BLOCK_QUERY = gql`
  query OneTimeRefForFlowBlockQuery($blockId: ID!) {
    flowBlock(blockId: $blockId) {
      id
      oneTimeRef
    }
  }
`;

export const useOneTimeRefPreLoaded = ({
  blockId,
  skip = false,
}: {
  blockId: string | undefined;
  skip: boolean | undefined;
}) => {
  const { oneTimeRef, updateOneTimeRef } = useOneTimeRef(blockId);

  const { data, ...state } = useQuery<
    OneTimeRefForFlowBlockQuery,
    OneTimeRefForFlowBlockQueryVariables
  >(ONE_TIME_REF_FOR_FLOW_BLOCK_QUERY, {
    skip:
      !blockId ||
      !!oneTimeRef ||
      [FlowPlaceholder.removed || skip, FlowPlaceholder.new].includes(
        blockId as FlowPlaceholder,
      ),
    variables: {
      blockId: blockId || '',
    },
  });

  return {
    oneTimeRef: oneTimeRef || data?.flowBlock?.oneTimeRef,
    updateOneTimeRef,
    ...state,
  };
};
