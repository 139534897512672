import {
  differenceWith,
  eqBy,
  find,
  flip,
  pathOr,
  propEq,
  propOr,
  pipe,
  anyPass,
  not,
  defaultTo,
  lt,
  last,
  findIndex,
  map,
  all,
  lens,
  over,
  replace,
  assoc,
  countBy,
  nth,
} from 'ramda';
import { getBlocksTitlesArchiveObservable } from '../Aside/BlocksGroupsQueries';
import {
  AddSkillMutation_addSkill_block as IBlock,
  AddSkillMutation_addSkill_block_cards as ICard,
  AddSkillMutation_addSkill_block_cards_ToDoPlugin_config_todo as ITodo,
} from './AddSkill/@types/AddSkillMutation';

type BlockToCards = (block: IBlock) => ICard[];
export type TodoConfig = { items: ITodo[]; title: string };

const isTodoCard = propEq('plugin_id', 'todo');
const getTodoCard = find(isTodoCard);
const getCards: BlockToCards = propOr([] as ICard[], 'cards');
const getDifferenceById = flip(differenceWith(eqBy(propOr('', 'id')) as any));
const todoPath = pathOr([] as ITodo[], ['config', 'todo']);
const getTodoItems = pipe(
  getCards,
  getTodoCard,
  todoPath,
);
const getTodoCardTitle = (block: IBlock, index: number) =>
  pipe(
    getCards,
    nth(index),
    pathOr('', ['config', 'title']),
  )(block);

const getTodoItemsFromBlock = (block: IBlock, index: number) =>
  pipe(
    getCards,
    nth(index),
    todoPath,
  )(block);

export const getTodoCardIndexFromBlock = pipe(
  getCards,
  findIndex(isTodoCard),
);

export const isAddSkillButtonEnabled = pipe(
  getCards,
  last as any,
  defaultTo({}),
  anyPass([
    pipe(
      propEq('plugin_id', 'go_to_block_plugin'),
      not,
    ),
    pipe(
      pathOr(0, ['config', 'user_filter', 'parameters', 'length']),
      lt(0),
    ),
  ]),
);

export const getNewTodoItems = (currentBlock: IBlock, skillBlock: IBlock) =>
  pipe(
    getTodoItems,
    getDifferenceById(getTodoItems(currentBlock)) as any,
  )(skillBlock) as ITodo[];

export const getNewSkillCards = (currentBlock: IBlock, skillBlock: IBlock) =>
  getDifferenceById(getCards(currentBlock), getCards(skillBlock));

export const getTodoCardConfig = (block: IBlock, index: number) => ({
  title: getTodoCardTitle(block, index),
  items: getTodoItemsFromBlock(block, index),
});

export const isAllTodoCheckedInCard = pipe(
  todoPath,
  all(propOr(false, 'done')),
);

export const insertBlockTitleToBlockLinkIfNeed = async (
  config: TodoConfig,
  botId: string,
) => {
  const blocks = (await getBlocksTitlesArchiveObservable(botId)
    .take(1)
    .toPromise()) as IBlock[];
  const itemsLens = lens(propOr([], 'items'), assoc('items'));
  const descriptionLens = lens(propOr('', 'description'), assoc('description'));
  const getTitleById = pipe(
    propEq('id'),
    flip(find)(blocks) as any,
    propOr('Block', 'title'),
  );
  const prepareLink = (_: string, blockId: string) =>
    `[${getTitleById(blockId) || 'Block'}|${blockId}]`;
  const replaceLinks = (replace as any)(/\[([a-f0-9]+)\]/g, prepareLink);
  return over(itemsLens, map(over(descriptionLens, replaceLinks)))(config);
};

export const getDoneQty: ((items: ITodo[]) => number) = pipe(
  countBy(propOr(false, 'done')),
  propOr(0, 'true'),
  Number,
);
