import gql from 'graphql-tag';

export const INSTAGRAM_POST_FRAGMENT = gql`
  fragment instagramPostFragment on InstagramPost {
    id
    ig_id
    media_type
    media_url
    permalink
    caption
    timestamp
    thumbnail_url
    flow_id
    flow_title
  }
`;

export const INSTAGRAM_POSTS_QUERY = gql`
  query InstagramPostsQuery($pageId: String!, $after: String) {
    page(pageId: $pageId) {
      id
      instagramPosts(after: $after) {
        data {
          ...instagramPostFragment
        }
        after
      }
    }
  }
  ${INSTAGRAM_POST_FRAGMENT}
`;

export const REMOVE_INSTAGRAM_POST_FROM_ENTRY_POINTS_MUTATION = gql`
  mutation RemoveInstagramPostFromEntryPointsMutation(
    $botId: String!
    $postId: String!
  ) {
    removeInstagramPostFromEntryPoints(botId: $botId, postId: $postId) {
      ...instagramPostFragment
    }
  }
  ${INSTAGRAM_POST_FRAGMENT}
`;
