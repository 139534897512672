import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  OneTimeRefQuery,
  OneTimeRefQueryVariables,
} from '@components/TestInFacebook/utils/@types/OneTimeRefQuery';
import { FlowPlaceholder } from '@utils/Data/Flow';
import client from '../../../common/services/ApolloService';

const ONE_TIME_REF_QUERY = gql`
  query OneTimeRefQuery($blockId: String!) {
    oneTimeRef(blockId: $blockId)
  }
`;

export const updateOneTimeRef = (
  blockId: string | undefined,
  skip?: boolean,
) => {
  if (
    !blockId ||
    [FlowPlaceholder.removed, FlowPlaceholder.new].includes(
      blockId as FlowPlaceholder,
    ) ||
    skip
  ) {
    return undefined;
  }
  // update GQL cache outside React lifecycle
  return client.query<OneTimeRefQuery, OneTimeRefQueryVariables>({
    query: ONE_TIME_REF_QUERY,
    variables: {
      blockId,
    },
    fetchPolicy: 'network-only',
  });
};

export const useOneTimeRef = (blockId: string | undefined, skip?: boolean) => {
  const { data, ...state } = useQuery<
    OneTimeRefQuery,
    OneTimeRefQueryVariables
  >(ONE_TIME_REF_QUERY, {
    skip:
      !blockId ||
      skip ||
      [FlowPlaceholder.removed, FlowPlaceholder.new].includes(
        blockId as FlowPlaceholder,
      ),
    variables: {
      blockId: blockId || '',
    },
    fetchPolicy: 'cache-only',
  });

  useEffect(() => {
    updateOneTimeRef(blockId);
  }, [blockId]);

  return {
    oneTimeRef: data?.oneTimeRef,
    updateOneTimeRef,
    ...state,
  };
};
