import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { useInstagramAccount } from '@utils/Data/Instagram/BotAccount';
import { useCurrentBotId } from '@utils/Routing';

import { Button } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';

interface ConnectInstagramSuccessProps {
  onDone(): void;
}
export const ConnectInstagramSuccess: React.FC<ConnectInstagramSuccessProps> =
  ({ onDone }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId() ?? '';
    const { instagramConnectedAccount } = useInstagramAccount(botId);
    return (
      <ConnectInstagramDialogContent
        header={
          <Flex>
            <Type size="24px" weight="semibold">
              {t('common.Instagram.dialog.success.header', {
                accountName: truncateWithEllipses(
                  instagramConnectedAccount.name,
                  26,
                ),
              })}
            </Type>
            <Spacer horizontalFactor={1} />
            <Emoji emoji="🎉" size={24} />
          </Flex>
        }
        footerRight={
          <Button
            intent="primary"
            onClick={onDone}
            data-testid="instagram-connect-dialog__permissions__success__done-button"
          >
            {t('common.Instagram.dialog.success.done')}
          </Button>
        }
        data-testid="instagram-connect-dialog__permissions__success"
      >
        <Type size="15px">
          {t('common.Instagram.dialog.success.description')}
        </Type>
      </ConnectInstagramDialogContent>
    );
  };
