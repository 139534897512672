import React from 'react';
import { useTestThisMessages } from '../useTestThisMessages';
import { ChatContent } from '../ChatContent/ChatContent';

interface ChatContainerProps {
  showEmptyState?: boolean;
  showEmptyMessage?: boolean;
}

export const ChatContainer: React.FC<ChatContainerProps> = ({
  showEmptyState,
  showEmptyMessage,
}) => {
  const {
    messages,
    postMessage,
    handleQrClick,
    handleButtonClick,
    handleGalleryButtonClick,
    handleWhatsappListReply,
  } = useTestThisMessages();

  return (
    <ChatContent
      messages={messages}
      showEmptyState={showEmptyState}
      showEmptyMessage={showEmptyMessage}
      onPostMessage={postMessage}
      onQrClick={handleQrClick}
      onButtonClick={handleButtonClick}
      onGalleryButtonClick={handleGalleryButtonClick}
      onWhatsappListReplyClick={handleWhatsappListReply}
    />
  );
};
