import React from 'react';
import cn from 'classnames';
import { Flex } from '@ui/Flex';
import css from './WorkspaceConversationWrapper.css';
import {
  WorkspaceStatisticsProps,
  WorkspaceStats,
} from '../WorkspaceStats/WorkspaceStats';

export interface WorkspaceConversationWrapperProps
  extends WorkspaceStatisticsProps {
  className?: string;
}

export const WorkspaceConversationWrapper: React.FC<WorkspaceConversationWrapperProps> =
  ({ maxCount, count, className, children }) => (
    <Flex
      flexDirection="column"
      className={cn(css.workspaceConversation, className)}
      data-testid="bot-list__list-item__conversation_stats_column"
    >
      <WorkspaceStats count={count} maxCount={maxCount} />

      {children}
    </Flex>
  );
