import React, { useCallback, useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router-dom';
import { log } from 'cf-common/src/logger';

import { sendEvent } from '@utils/Analytics';
import { getPaymentStatus } from '@components/Payments/utils/getPaymentStatus';
import { BotStatus, getBotStatus } from '@utils/Bot';
import { usePaymentUpdateSubscriber } from '@utils/Payment/PaymentUpdateHandler/usePaymentUpdateSubscriber';

import {
  getConfigureTabBotData as BotData,
  getConfigureTabBotData_bot as Bot,
} from '@utils/Data/Pricing/@types/getConfigureTabBotData';

import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import {
  useConnectPage,
  ConnectPageField,
  ConnectPageOrigin,
} from '@components/ConnectPageDialog';
import { PaymentDialog } from '@components/Payments';
import { GET_CONFIGURE_TAB_BOT_DATA } from '@utils/Data/Pricing/queries';

import { Modal } from '../../services';

import {
  DeepLinksMode,
  DeepLinksQueryParam,
} from '../../pages/DeepLinks/types';
import { useDeepLinkCleanup } from '../../pages/DeepLinks/helpers';

import { ProPlanMessage } from './components/ProPlanMessage';
import { ProPlanManagement } from './components/ProPlanManagement';

import { ProPlanSectionLoader } from './ProPlanSectionLoader';

import { DEFAULT_TERMS_CHECKED_VALUE } from './constants';

import * as css from './ProPlanSection.css';
import { getBotFeatures } from '@utils/Data/Bot';
import { getPageToUpgrade } from '@utils/Routing';

enum ShowPayment {
  default = 'default',
  update = 'update',
}

interface ProPlanSectionProps {
  botId: string;
}

export const ProPlanSection: React.FC<ProPlanSectionProps> = ({ botId }) => {
  const { t } = useSafeTranslation();
  const [proPlanSectionLoading, setProPlanSectionLoading] = useState(false);
  const {
    loading: botDataLoading,
    error,
    data,
    refetch,
  } = useQuery<BotData>(GET_CONFIGURE_TAB_BOT_DATA, {
    variables: { botId },
    fetchPolicy: 'network-only',
  });
  const location = useLocation();
  const history = useHistory();
  const { deepLinkCleanup } = useDeepLinkCleanup();
  const [termsChecked, setTermsChecked] = useState(DEFAULT_TERMS_CHECKED_VALUE);

  const [showPayment, setShowPayment] = useState<ShowPayment | null>(null);
  const showPaymentDialog = useCallback(() => {
    Modal.show(
      ({ close }) => <PaymentDialog initialPayment onClose={close} />,
      {
        onOverlayDismiss: deepLinkCleanup,
        id: 'ShowPaymentDialog',
      },
    )?.onClose(deepLinkCleanup);
  }, [deepLinkCleanup]);
  const showPaymentUpdateDialog = useCallback(() => {
    Modal.show(({ close }) => <PaymentDialog onClose={close} />, {
      id: 'ShowPaymentUpdateDialog',
    });
  }, []);
  useEffect(() => {
    if (showPayment === ShowPayment.default) {
      showPaymentDialog();
    } else if (showPayment === ShowPayment.update) {
      showPaymentUpdateDialog();
    }
    setShowPayment(null);
  }, [showPayment, showPaymentDialog, showPaymentUpdateDialog]);

  const { connectPage } = useConnectPage({
    botId,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.upgrade,
    },
    onClose: deepLinkCleanup,
    onPageConnected: async ({ status: botStatus, close }) => {
      refetch();
      close?.();
      try {
        /**
         * HACK: after connecting the page, we focus on the
         * pricing plan of the page. so dialogs_pricing_enabled can update
         */
        const botFeatures = await getBotFeatures(botId, 'network-only');

        if (botFeatures.dialogs_pricing_enabled) {
          history.push(
            getPageToUpgrade(botId, botFeatures.dialogs_pricing_enabled),
          );
          return;
        }

        const { needUpdatePayment } = getPaymentStatus(botStatus);
        if (botStatus !== BotStatus.pro && botStatus !== BotStatus.premium) {
          if (needUpdatePayment) {
            setShowPayment(ShowPayment.update);
          } else {
            setShowPayment(ShowPayment.default);
          }
        }
      } catch (error) {
        log.warn({
          error,
          msg: 'Bot features request error after connecting fb page',
        });
      }
    },
  });

  const goProAfterPageConnection = useCallback(
    (bot: Bot) => {
      const status = getBotStatus(bot);
      const { needUpdatePayment } = getPaymentStatus(status);
      if (status === BotStatus.noPage) {
        connectPage();
      } else {
        refetch();
        if (needUpdatePayment) {
          showPaymentUpdateDialog();
        } else {
          showPaymentDialog();
        }
      }
    },
    [connectPage, refetch, showPaymentUpdateDialog, showPaymentDialog],
  );

  const [deepLinkProcessed, setDeepLinkProcessed] = useState(false);
  useEffect(() => {
    if (data?.bot && !deepLinkProcessed) {
      setDeepLinkProcessed(true);
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.get(DeepLinksQueryParam.mode) === DeepLinksMode.upgrade) {
        goProAfterPageConnection(data.bot);
      }
    }
  }, [data, deepLinkProcessed, goProAfterPageConnection, location.search]);

  usePaymentUpdateSubscriber(showPaymentUpdateDialog);

  if (error || data?.bot.workspace_id) {
    return null;
  }

  if (botDataLoading || !data) {
    return <ProPlanSectionLoader />;
  }

  const { bot } = data;
  const botStatus = getBotStatus(bot);

  return (
    <div className={css.section}>
      {proPlanSectionLoading && <div className={css.loading} />}
      <>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={css.sectionHeader}
        >
          <Type size="24px">{t('ProPlanSection-JSXText--100-pro-plan')}</Type>
          <div className={css.linkWrapper}>
            <a
              href={t('common.helpDocLinks.pricing')}
              target="_blank"
              rel="noopener noreferrer"
              className={css.faqLink}
            >
              ?
            </a>
          </div>
        </Flex>
        <div className={css.content}>
          <ProPlanManagement
            bot={bot}
            termsChecked={termsChecked}
            setTermsChecked={setTermsChecked}
            onGoPro={() => {
              goProAfterPageConnection(bot);
            }}
            onUpdatePayment={showPaymentUpdateDialog}
            onCancelProPlan={refetch}
            setLoading={setProPlanSectionLoading}
          />
          <ProPlanMessage
            status={botStatus}
            daysLeft={bot.pro?.days_before_cancel || 0}
            debtAmount={bot.pro?.debt || 0}
            subscribersLeft={
              bot.limits.usersLimit - (bot.pricingData?.currentUsers || 0)
            }
            subscribersLimit={bot.limits.usersLimit}
            onUpdatePayment={showPaymentUpdateDialog}
            onPageConnect={() => {
              sendEvent({
                category: 'pro plan payments',
                action: 'click banner',
                propertyBag: {
                  banner: 'connect your bot',
                  'new flow': true,
                },
              });
              connectPage();
            }}
            onGoPro={showPaymentDialog}
            botId={bot.id}
            termsChecked={termsChecked}
            currency={bot.pricingData?.currency}
          />
        </div>
      </>
    </div>
  );
};
