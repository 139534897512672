import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';
import {
  BotTestingQuery_botTesting,
  BotTestingQuery_botTesting as MessageItem,
} from '@utils/Data/BotTesting/@types/BotTestingQuery';

import { ProductGalleryMessage } from '@pages/LiveChat/components/Messages/Message/ProductGalleryMessage';
import { GalleryMessage } from '@pages/LiveChat/components/Messages/Message/GalleryMessage';

import { MessageRow } from '@pages/LiveChat/components/Messages/Message/MessageRow';
import { TextMessage } from '@pages/LiveChat/components/Messages/Message/TextMessage';
import { Avatar } from '@pages/LiveChat/components/Avatar';
import { UnsupportedMessage } from '@pages/LiveChat/components/Messages/InstagramMessage/UnsupportedMessage';
import { ImageMessage } from '@pages/LiveChat/components/Messages/InstagramMessage/ImageMessage';
import { VideoMessage } from '@pages/LiveChat/components/Messages/InstagramMessage/VideoMessage/VideoMessage';
import { StoryMessage } from '@pages/LiveChat/components/Messages/InstagramMessage/StoryMessage';
import { SentBlockMessage } from '@pages/LiveChat/components/Messages/Message/SentBlockMessage';
import {
  MessagePosition,
  MessageSourceType,
} from '@pages/LiveChat/components/Messages/InstagramMessage/types';

import { MessageInput } from '../MessageInput/MessageInput';
import { QuickReply } from '../QuickReplies/QuickReply';
import { ReactComponent as Typing } from './typing.svg';
import {
  ButtonClickHandler,
  GalleryButtonClickHandler,
  PostMessageHandler,
  QrClickHandler,
  TestThisMessageItem,
  TestThisMessageItems,
  WhatsappListReplyHandler,
} from '../types';

import chatfuelAvatar from './images/chatfuel-avatar.svg';
import * as css from './ChatContent.css';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as EmptyPic } from './images/empty.svg';
import { Spacer } from '@ui/Spacer';
import { createMessage } from '../utils';
import { WhatsappStatusEnum } from '@globals';
import { WhatsappLocationMessage } from '@pages/LiveChat/components/Messages/Message/WhatsappLocationMessage/WhatsappLocationMessage';
import { WhatsappListOverlay } from '@pages/LiveChat/components/Messages/Message/WhatsappListMessage/WhatsappList';
import { WhatsappListMessage } from '@pages/LiveChat/components/Messages/Message/WhatsappListMessage';
import { WhatsappListReplyMessage } from '@pages/LiveChat/components/Messages/Message/WhatsappListReplyMessage';

const avatarSize = 32;
const avatarMargin = 10;
const rowPadding = avatarSize + avatarMargin * 2;

const oppositePadding = 40;

const isTypingItem = (item: MessageItem): boolean => {
  return (item.message.typing_timeout_milliseconds ?? 0) > 0;
};

interface ChatProps {
  messages?: TestThisMessageItems;
  onPostMessage: PostMessageHandler;
  onQrClick: QrClickHandler;
  onButtonClick: ButtonClickHandler;
  onGalleryButtonClick: GalleryButtonClickHandler;
  onWhatsappListReplyClick: WhatsappListReplyHandler;
  showEmptyState?: boolean;
  showEmptyMessage?: boolean;
  hideMessageInput?: boolean;
}

const useChatContentOverlay = () => {
  const [overlayData, setOverlayData] = useState<TestThisMessageItem | null>(
    null,
  );

  return {
    overlayData,
    showOverlay: (x: TestThisMessageItem) => setOverlayData(x),
    hideOverlay: () => setOverlayData(null),
  };
};

export const ChatContent: React.FC<ChatProps> = ({
  messages,
  onPostMessage,
  onQrClick,
  onButtonClick,
  onGalleryButtonClick,
  onWhatsappListReplyClick,
  showEmptyState,
  showEmptyMessage,
  hideMessageInput,
}) => {
  const { t } = useSafeTranslation();
  const stubRef = useRef<HTMLDivElement | null>(null);
  const { overlayData, hideOverlay, showOverlay } = useChatContentOverlay();

  useEffect(() => {
    if (!stubRef.current) {
      return;
    }
    scrollIntoView(stubRef.current, {
      scrollMode: 'if-needed',
      behavior: 'auto',
      block: 'end',
      boundary: stubRef.current?.parentElement,
    });
  }, [messages]);

  const renderItem = (item: MessageItem): React.ReactNode => {
    const type = item.from.type === 'user' ? 'outgoing' : 'incoming';

    if (item.message.gallery?.type === 'products') {
      return (
        <div className={css.galleryWrapper}>
          <ProductGalleryMessage
            status={WhatsappStatusEnum.delivered}
            date={item.date}
            type={type}
            gallery={item.message.gallery}
            testing
          />
        </div>
      );
    }

    if (item.message.gallery) {
      return (
        <div className={css.galleryWrapper}>
          <GalleryMessage
            date={item.date}
            status={WhatsappStatusEnum.delivered}
            type={type}
            gallery={item.message.gallery}
            testing
            onButtonClick={(galleryIndex, buttonIndex, text) =>
              onGalleryButtonClick(item.id, galleryIndex, buttonIndex, text)
            }
          />
        </div>
      );
    }

    if (item.message.attachment?.type === 'image') {
      return (
        <ImageMessage message={item.message} positionClassName={css.image} />
      );
    }
    if (item.message.attachment?.type === 'video') {
      return (
        <VideoMessage message={item.message} positionClassName={css.video} />
      );
    }
    if (
      item.message.attachment?.type === 'story_reply' ||
      item.message.attachment?.type === 'story_mention'
    ) {
      return (
        <StoryMessage
          date={item.date}
          type={type}
          status={WhatsappStatusEnum.delivered}
          sourceType={MessageSourceType.incoming}
          message={item.message}
          position={MessagePosition.single}
          messageId={item.id}
          positionClassName={css.story}
        />
      );
    }

    if (item.message.send_to) {
      return <SentBlockMessage {...item.message.send_to} />;
    }

    if (item.message.whatsapp_location) {
      return (
        <WhatsappLocationMessage
          date={item.date}
          type={type}
          status={WhatsappStatusEnum.delivered}
          config={item.message.whatsapp_location}
        />
      );
    }

    if (item.message.whatsapp_list) {
      return (
        <WhatsappListMessage
          date={item.date}
          type={type}
          status={WhatsappStatusEnum.delivered}
          config={item.message.whatsapp_list}
          onButtonClick={() => showOverlay(item)}
        />
      );
    }

    if (item.message.whatsapp_list_reply) {
      return (
        <WhatsappListReplyMessage
          date={item.date}
          type={type}
          status={WhatsappStatusEnum.delivered}
          config={item.message.whatsapp_list_reply}
        />
      );
    }

    if (isTypingItem(item)) {
      return <Typing />;
    }

    if (item.message.text) {
      return (
        <TextMessage
          testing
          buttons={item.message.buttons}
          onButtonClick={(index, text) => onButtonClick(item.id!, index, text)}
          type={type}
        >
          <div className={css.text}>
            <span
              dangerouslySetInnerHTML={{ __html: filterXSS(item.message.text) }}
            />
          </div>
        </TextMessage>
      );
    }

    return <UnsupportedMessage />;
  };

  const renderOverlayItem = (
    item: BotTestingQuery_botTesting,
  ): React.ReactNode => {
    if (item.message.whatsapp_list) {
      return (
        <WhatsappListOverlay
          onClose={hideOverlay}
          config={item.message.whatsapp_list}
          onSubmit={(selectedItem) => {
            hideOverlay();
            onWhatsappListReplyClick(
              item.id,
              selectedItem.id,
              selectedItem.title,
              selectedItem.description,
            );
          }}
        />
      );
    }

    return null;
  };

  const filteredMessages = messages?.filter((item, index) => {
    const itemIsLast = index === messages?.length - 1;
    return !isTypingItem(item) || itemIsLast;
  });

  return (
    <div className={css.wrapper}>
      {overlayData && renderOverlayItem(overlayData)}

      <div className={css.container}>
        {!filteredMessages?.length && showEmptyState && (
          <Flex
            className={css.empty}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <EmptyPic />
            <Spacer factor={4} />
            <Type size="15px" align="center" as="div" className={css.emptyText}>
              {t('ChatDialog-JSXText-5699-empty')}
            </Type>
          </Flex>
        )}
        {!filteredMessages?.length && showEmptyMessage && (
          <div className={css.incoming}>
            <MessageRow padStart={0} padEnd={oppositePadding} type="incoming">
              <Avatar className={css.avatar} src={chatfuelAvatar} size={24} />
              {renderItem(
                createMessage(
                  '',
                  t('ChatDialog-JSXText-5699-empty-message'),
                  'bot',
                ),
              )}
            </MessageRow>
          </div>
        )}
        {filteredMessages?.map((item, index) => {
          const itemIsIncoming = item.from.type !== 'user';
          const itemIsLast = index === filteredMessages?.length - 1;

          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              className={cn({
                [css.incoming]: itemIsIncoming,
                [css.outgoing]: !itemIsIncoming,
              })}
            >
              <MessageRow
                padStart={itemIsIncoming ? 0 : rowPadding}
                padEnd={itemIsIncoming ? oppositePadding : 0}
                type={itemIsIncoming ? 'incoming' : 'outgoing'}
              >
                {itemIsIncoming && (
                  <Avatar
                    className={css.avatar}
                    src={chatfuelAvatar}
                    size={24}
                  />
                )}
                {renderItem(item)}
              </MessageRow>
              {item.message.quick_replies && itemIsLast && (
                <div className={css.quickReplies}>
                  {item.message.quick_replies.map((qr, index) => {
                    return (
                      <QuickReply
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        quickReplay={qr ?? ''}
                        onClick={() => onQrClick(item.id!, index, qr ?? '')}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
        <div ref={stubRef} />
      </div>
      {!hideMessageInput && (
        <MessageInput
          onSubmit={(message) => {
            onPostMessage({ text: message ?? '' });
          }}
          autoFocus={!!showEmptyState || !!showEmptyMessage}
        />
      )}
    </div>
  );
};
