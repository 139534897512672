import { sendEvent } from '@utils/Analytics';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { useCallback, useRef } from 'react';
import { useQuery } from 'react-apollo';
import {
  ComposeTabId,
  POST_LOADING_TOTAL_LIMIT,
} from '../../ChooseItemFromListDialog';
import {
  InstagramPostsQuery,
  InstagramPostsQueryVariables,
} from '../@types/InstagramPostsQuery';
import { INSTAGRAM_POSTS_QUERY } from '../queries';

interface InstagramPostFetchParams {
  activeTabId: string;
}

export const useInstagramPostFetch = ({
  activeTabId,
}: InstagramPostFetchParams) => {
  const botId = useCurrentBotId();
  const { pageId, loading: pageLoading } = usePageConnected(botId);
  const loadedLastPostRef = useRef<boolean>(false);

  const {
    data,
    loading: postsLoading,
    fetchMore,
    refetch,
    networkStatus,
    error,
  } = useQuery<InstagramPostsQuery, InstagramPostsQueryVariables>(
    INSTAGRAM_POSTS_QUERY,
    {
      variables: {
        pageId: pageId ?? '',
      },
      skip: !pageId,
      notifyOnNetworkStatusChange: true,
    },
  );

  const loading = pageLoading || postsLoading;
  const { data: posts, after } = data?.page?.instagramPosts || {};

  const loadMore = useCallback(() => {
    if (
      loading ||
      (posts || []).length > POST_LOADING_TOTAL_LIMIT ||
      loadedLastPostRef.current ||
      activeTabId === ComposeTabId.postUrl ||
      after === null
    ) {
      return Promise.resolve();
    }

    sendEvent({
      category: 'flows',
      action: 'Load more instagram posts',
    });

    return fetchMore({
      query: INSTAGRAM_POSTS_QUERY,
      variables: {
        pageId: pageId ?? '',
        after,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.page?.instagramPosts) {
          return prev;
        }

        loadedLastPostRef.current =
          fetchMoreResult.page.instagramPosts.after === null;

        // eslint-disable-next-line no-param-reassign
        fetchMoreResult.page.instagramPosts.data = [
          ...(prev.page?.instagramPosts.data || []),
          ...(fetchMoreResult.page?.instagramPosts.data || []),
        ];

        return fetchMoreResult;
      },
    });
  }, [activeTabId, loading, posts, after, fetchMore, pageId]);

  return {
    posts: posts ?? [],
    postsLoading,
    loading,
    refetch,
    networkStatus,
    loadMore,
    error,
  };
};
