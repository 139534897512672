import React from 'react';
import { Modal } from '@services/.';

import { ConnectInstagramDialog } from './components/ConnectInstagramDialog';
import { useConnectInstagramQueryParams } from './helpers';
import {
  ConnectInstagramVariant,
  ConnectInstagramQueryField,
  ConnectInstagramRestorationQueryParams,
  ConnectInstagramStep,
} from './types';

export const showConnectInstagramDialog = (
  connectVariant: ConnectInstagramVariant,
  step: ConnectInstagramStep | undefined | null,
  onClose: () => void,
  onConnected?: () => void,
) => {
  Modal.show(
    ({ close }) => (
      <ConnectInstagramDialog
        initialStep={step}
        connectVariant={connectVariant}
        onClose={close}
        onConnected={onConnected}
      />
    ),
    {
      mobileAdaptive: true,
      onOverlayDismiss: onClose,
      id: 'connectInstagramDialog',
    },
  )?.onClose(onClose);
};
export const useConnectInstagramDialog = (
  connectVariant: ConnectInstagramVariant,
  restorationParams: ConnectInstagramRestorationQueryParams,
  onConnected?: () => void,
) => {
  const { stepQueryParam, cleanupParams, setupRestorationParams } =
    useConnectInstagramQueryParams(restorationParams, (urlParams) => {
      showConnectInstagramDialog(
        connectVariant,
        urlParams[ConnectInstagramQueryField.step],
        cleanupParams,
        onConnected,
      );
    });
  return {
    connectInstagram: () => {
      setupRestorationParams();
      showConnectInstagramDialog(
        connectVariant,
        stepQueryParam,
        cleanupParams,
        onConnected,
      );
    },
  };
};
