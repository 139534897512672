import React from 'react';
import { Dialog, DialogHeading } from '../../modern-ui/Dialog';
import {
  ConfirmRow,
  CloseButton,
  FlexDialogContent,
} from '../../modern-ui/Dialog/ActionDialogParts';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { Autofocus } from '../../modern-ui/Autofocus';
import { Flex } from '../../modern-ui/Flex';
import * as css from './UpgradeToProPopup.css';

interface IUpgradeToProPopupProps {
  onSubmit: () => void;
  onDismiss: () => void;
}

export const UpgradeToProPopup: React.SFC<IUpgradeToProPopupProps> = ({
  onSubmit,
  onDismiss,
}) => (
  <Dialog className={css.dialog}>
    <FlexDialogContent>
      <DialogHeading>
        <Flex alignItems="baseline" justifyContent="space-between">
          <div
            data-testid="upgrade-to-pro-popup__title"
            style={{ paddingRight: 8 }}
          >
            {window.i18next.t(
              'UpgradeToProPopup-JSXText-1050-upgrade-to-pro-plan-to-use-this-feature',
            )}
          </div>
          {onDismiss ? (
            <CloseButton
              data-testid="upgrade-to-pro-popup__close-button"
              aria-label="close"
              onClick={onDismiss}
            />
          ) : null}
        </Flex>
      </DialogHeading>
      <span data-testid="upgrade-to-pro-popup__text" className={css.dialogText}>
        {window.i18next.t(
          'UpgradeToProPopup-JSXText-1304-you-can-see-all-your-users-and-their-attributes-on-the-pro-plan-you-will-also-be-able-to-export-and-delete-user-data-create-user-segments-subscribe-them-to-sequences-and-more',
        )}
      </span>
      <ConfirmRow>
        <Autofocus
          shouldFocus
          render={({ bind }) => (
            <Button
              data-testid="upgrade-to-pro-popup__upgrade-button"
              intent={ButtonIntent.primary}
              onClick={onSubmit}
              innerRef={bind.ref}
            >
              {window.i18next.t(
                'UpgradeToProPopup-JSXText-1227-upgrade-to-pro',
              )}
            </Button>
          )}
        />
      </ConfirmRow>
    </FlexDialogContent>
  </Dialog>
);
