import React from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Dialog, DialogCloseButton, DialogHeading } from '@ui/Dialog';
import { ConfirmRow, FlexDialogContent } from '@ui/Dialog/ActionDialogParts';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Icon } from '@ui/Icon';
import { Button } from '@ui/Button';
import { getCurrencySymbolByLocale } from '@utils/Format/priceFormatter';
import { sendEvent } from 'cf-common/src/analytics';
import { PricingData, Discount } from './types';
import { useVisiblePrices } from './useVisiblePrices';
import { CHATFUEL_DEMO_URL } from '../../pages/Plans/constants';

import * as css from './PricingPopup.css';

interface PricingPopupProps {
  pricingData: PricingData;
  currency?: string | null;
  discount?: Discount;
  onDismiss: () => void;
}

const formatter = new Intl.NumberFormat('en');
const I18N_NS = 'modernComponents.PricingPopup';

export const PricingPopup: React.FC<PricingPopupProps> = ({
  pricingData,
  currency,
  discount,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  const visiblePrices = useVisiblePrices(pricingData);

  const priceCount = visiblePrices.length;
  const gridColumnsStyle = {
    gridTemplateColumns: `repeat(${priceCount + 1}, min-content)`,
  };

  return (
    <Dialog style={{ width: 'auto' }}>
      <FlexDialogContent>
        <DialogHeading>
          <span data-testid="pricing-popup__title">
            {t(`${I18N_NS}.title`)}
          </span>
          <DialogCloseButton onClick={onDismiss} />
        </DialogHeading>
        <Type size="15px_DEPRECATED">
          {t(`${I18N_NS}.descriptionL1`)}
          <br />
          {t(`${I18N_NS}.descriptionL2`)}
        </Type>
        <Spacer factor={0} />
        <div className={css.pricingGrid} style={gridColumnsStyle}>
          <Type size="15px_DEPRECATED">
            {t(`${I18N_NS}.table.usersHeaderL1`)}
            <br />
            {t(`${I18N_NS}.table.usersHeaderL2`)}
          </Type>
          {visiblePrices.map(([botUsers]) => (
            <div className={css.usersCell} key={botUsers}>
              <Type size="15px_DEPRECATED">{formatter.format(botUsers)}</Type>
            </div>
          ))}

          <div className={css.line} />

          <Flex flexDirection="column" justifyContent="flex-start">
            <Spacer factor={5} />
            <Type size="15px_DEPRECATED">
              {t(`${I18N_NS}.table.priceHeader`, {
                currency: getCurrencySymbolByLocale(currency ?? undefined),
              })}
            </Type>
          </Flex>
          {visiblePrices.map(([botUsers, price]) => {
            const priceIsCurrent = price === pricingData.currentPrice;
            return (
              <Flex
                key={botUsers}
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
                className={cn(css.priceCell, {
                  [css.currentCell]: priceIsCurrent,
                })}
              >
                <div>
                  <Type size="30px" weight="medium">
                    {price}
                  </Type>
                  {discount && (
                    <span className={css.strikethrough}>
                      <Type color="greyDark" size="15px_DEPRECATED">
                        {price / discount.factor}
                      </Type>
                    </span>
                  )}
                </div>
                {priceIsCurrent && (
                  <div className={css.currentTier}>
                    <Type size="12px">{t(`${I18N_NS}.table.currentTier`)}</Type>
                  </div>
                )}
              </Flex>
            );
          })}
        </div>

        <ConfirmRow>
          <Type size="15px_DEPRECATED">{t(`${I18N_NS}.footer.needMore`)}</Type>
          <Button
            intent="secondary"
            icon={<Icon icon="messenger" />}
            className={css.contactUs}
            data-testid="pricing-popup__contact-us"
            onClick={() => {
              sendEvent({
                category: 'request-demo',
                action: 'dash_contact_us_pricing',
              });
              window.open(CHATFUEL_DEMO_URL, '_blank');
              onDismiss();
            }}
          >
            {t(`${I18N_NS}.footer.contactUs`)}
          </Button>
        </ConfirmRow>
      </FlexDialogContent>
    </Dialog>
  );
};
