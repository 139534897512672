import React from 'react';
import { sendEvent } from 'cf-common/src/analytics';
import { PremiumPromo } from './PremiumPromo';
import { Modal } from '../../../modern-ui/Modal';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';
import { Spacer } from '../../../modern-ui/Spacer';
import { DefaultDialog } from '../../../modern-ui/Dialog/DefaultDialog';

export const PremiumPromoModal: React.FC<{ onDismiss: () => void }> = ({
  onDismiss,
}) => {
  const chatfuelDemoUrl = 'https://tiny.cc/chatfueldemo';
  function goToChatfuelBot() {
    sendEvent({
      category: 'request-demo',
      action: 'dash_upgrade_to_premium',
    });
    window.open(chatfuelDemoUrl, '_blank');
  }
  const HrefButton = Button as any; // <Button intent={} /> in case your search for it
  return (
    <Modal onDismiss={onDismiss}>
      <DefaultDialog
        onDismiss={onDismiss}
        header={window.i18next.t(
          'PremiumPromoModal-string-2318-upgrade-to-premium',
        )}
        dialogStyle={{ width: '495px' }}
      >
        <PremiumPromo />
        <Spacer />
        <HrefButton
          intent={ButtonIntent.primary}
          role="link"
          href={chatfuelDemoUrl}
          onClick={goToChatfuelBot}
        >
          {window.i18next.t(
            'PremiumPromoModal-JSXText-6953-upgrade-to-premium',
          )}
        </HrefButton>
      </DefaultDialog>
    </Modal>
  );
};
