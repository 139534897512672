import { useMutation } from '@apollo/react-hooks';
import {
  Messages,
  ServiceMessageType,
  toaster,
} from '@services/MessageService';
import { useCurrentBotId } from '@utils/Routing';
import { useCallback } from 'react';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { instagramPostFragment as InstagramPost } from '../@types/instagramPostFragment';
import {
  RemoveInstagramPostFromEntryPointsMutation,
  RemoveInstagramPostFromEntryPointsMutationVariables,
} from '../@types/RemoveInstagramPostFromEntryPointsMutation';
import { REMOVE_INSTAGRAM_POST_FROM_ENTRY_POINTS_MUTATION } from '../queries';

interface RemovePostParams {
  onPostRemove?(post: InstagramPost): void;
}

export const useRemoveInstagramPost = ({ onPostRemove }: RemovePostParams) => {
  const botId = useCurrentBotId()!;

  const [removePostFromEntryPointsMutation] = useMutation<
    RemoveInstagramPostFromEntryPointsMutation,
    RemoveInstagramPostFromEntryPointsMutationVariables
  >(REMOVE_INSTAGRAM_POST_FROM_ENTRY_POINTS_MUTATION, {
    onError: (error) => {
      log.error({
        error,
        msg: 'Remove Instagram post from other Entry Point error',
        data: {
          label: 'instagram_post',
          requestId: getRequestIdFromApolloError(error),
        },
      });
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: Messages.somethingWentWrong,
        },
      });
    },
  });

  const removePost = useCallback(
    async (post: InstagramPost) => {
      await removePostFromEntryPointsMutation({
        variables: {
          botId,
          postId: post.id,
        },
        optimisticResponse: {
          removeInstagramPostFromEntryPoints: {
            ...post,
            flow_id: null,
            flow_title: null,
          },
        },
      });
      onPostRemove?.(post);
    },
    [onPostRemove, botId, removePostFromEntryPointsMutation],
  );

  return {
    removePost,
  };
};
