import React, { useCallback, useEffect } from 'react';
import { T } from 'ramda';

import { Modal } from '@services/.';

import { useAdminData, useAdminFeatures } from '@utils/Data/Admin';
import { useBotDefaultFlowGroup } from '@utils/Data/Bot';
import { BotTabs, useCurrentBotId, useCurrentTab } from '@utils/Routing';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';

import { DefaultAnswerToAiDialog } from './DefaultAnswerToAiDialog';

export const useDefaultAnswerToAiDialog = (properTabsToShow: BotTabs[]) => {
  const [
    defaultAnswerToAiDialogClosed,
    setDefaultAnswerToAiDialogClosed,
    { loading: defaultAnswerToAiDialogClosedLoading },
  ] = useServerStorage<boolean>(
    ServerStorageItemKeys.defaultAnswerToAiDialogClosed,
  );
  const { adminData, adminDataLoading } = useAdminData();
  const { adminFeaturesLoading, adminFeatures } = useAdminFeatures();
  const currentTab = useCurrentTab();
  const currentBotId = useCurrentBotId();
  const { botDefaultFlowGroupId, botDefaultFlowGroupIdLoading } =
    useBotDefaultFlowGroup(currentBotId);

  const handleCloseDialog = useCallback(() => {
    setDefaultAnswerToAiDialogClosed(true);
  }, [setDefaultAnswerToAiDialogClosed]);

  useEffect(() => {
    if (
      properTabsToShow.includes(currentTab! as BotTabs) &&
      !adminFeaturesLoading &&
      !adminFeatures?.disable_automate_tab &&
      !adminDataLoading &&
      adminData?.me?.terms_accepted &&
      parseInt(adminData?.me?.terms_accepted ?? '0', 10) <
        parseInt(adminFeatures?.disable_automate_tab_started_at ?? '0', 10) &&
      !defaultAnswerToAiDialogClosedLoading &&
      !defaultAnswerToAiDialogClosed &&
      // To prevent overloading templates modal, we check for default group existence
      // (templates modal is shown after those group init)
      !(botDefaultFlowGroupIdLoading || !botDefaultFlowGroupId)
    ) {
      Modal.show(({ close }) => <DefaultAnswerToAiDialog onClose={close} />, {
        onOverlayDismiss: handleCloseDialog,
        skipOverloadActive: T,
      })?.onClose(handleCloseDialog);
    }
  }, [
    adminFeatures,
    adminFeaturesLoading,
    currentTab,
    adminData,
    adminDataLoading,
    defaultAnswerToAiDialogClosed,
    handleCloseDialog,
    properTabsToShow,
    botDefaultFlowGroupIdLoading,
    botDefaultFlowGroupId,
    defaultAnswerToAiDialogClosedLoading,
  ]);
};
