import React, { MouseEventHandler } from 'react';
import Downshift from 'downshift';

import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../../modern-ui/_deprecated/Button';
import { Menubox, MenuItem } from '../../../../modern-ui/Menu';
import { ReactComponent as MenuIcon } from '../../../../modern-ui/_deprecated/Icon/icons/menu.svg';

import * as css from './DismissButton.css';

interface DismissButtonProps {
  onClick: MouseEventHandler;
  className: string;
}

const item = () => ({
  title: window.i18next.t('DismissButton-string--958-dismiss'),
  index: 0,
});

export const DismissButton: React.FC<DismissButtonProps> = ({
  onClick,
  className,
}) => (
  <Downshift onChange={onClick} selectedItem={null}>
    {({ isOpen, getToggleButtonProps, getItemProps, highlightedIndex }) => (
      <div className={className}>
        <Button
          {...getToggleButtonProps()}
          className={css.DismissButton}
          intent={ButtonIntent.default}
          size={ButtonSize.s}
          tall
          renderIcon={() => <MenuIcon />}
          iconClassName={css.DismissButtonIconContainer}
        />
        {isOpen ? (
          <Menubox className={css.DismissButtonMenu}>
            <MenuItem
              {...getItemProps({ item: item() })}
              active={item().index === highlightedIndex}
              title={item().title}
            />
          </Menubox>
        ) : null}
      </div>
    )}
  </Downshift>
);
