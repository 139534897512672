import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React from 'react';
import { ReactComponent as ImageSvg } from './image.svg';
import { ReactComponent as CFSVG } from './cf.svg';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { useAuthToken } from '@utils/Auth/useAuthToken';
import { Flex } from '@ui/Flex';
import css from './RedirectToLiveChatDialog.css';

interface RedirectToLiveChatDialogProps {
  close: VoidFunction;
  getAppLink(token: string): string;
}

export const RedirectToLiveChatDialog: React.FC<RedirectToLiveChatDialogProps> =
  ({ close, getAppLink }) => {
    const { t } = useSafeTranslation();
    const token = useAuthToken();

    return (
      <DefaultDialog onDismiss={close} className={css.dialog}>
        <Flex flexDirection="column" alignItems="center">
          <ImageSvg />
          <Spacer factor={8} />
          <Button
            onClick={() => {
              if (token) window.open(getAppLink(token), '_blank');
            }}
            fullWidth
          >
            <Flex alignItems="center" justifyContent="center">
              <CFSVG />
              <Spacer factor={0} horizontalFactor={1.5} />
              {t('continueWithChatfuelApp')}
            </Flex>
          </Button>
        </Flex>
      </DefaultDialog>
    );
  };
