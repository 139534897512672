import ng from 'angular';
import { react2angular } from 'react2angular';
import {
  getNewSkillCards,
  getNewTodoItems,
  isAddSkillButtonEnabled,
  getTodoCardIndexFromBlock,
} from '../skillUtils';
import { updateBlockValidStatusInGqlCache } from '../../Aside/BlocksGroupsQueries';
import { AddSkill } from './AddSkill';
import { AddSkillMutation_addSkill as ISkillData } from './@types/AddSkillMutation';

const ngAddSkillButton = ng
  .module('app.modernComponents.addSkill.addSkillButton', [])
  .component(
    'addSkillButton',
    react2angular(
      AddSkill,
      ['enabled', 'botId', 'blockId', 'onSkillAdded'],
      [],
    ),
  ).name;

export const ngAddSkill = ng
  .module('app.modernComponents.addSkill', [ngAddSkillButton])
  .component('addSkill', {
    template: () =>
      `<add-skill-button
  on-skill-added="onSkillAdded" 
  enabled="isEnabledAddSkill()" 
  block-id="$ctrl.block.id"
  bot-id="$stateParams.botId"
 ></add-skill-button>`,
    controller: ($scope, $rootScope) => {
      'ngInject';

      $scope.$stateParams = $rootScope.stateParams;
      $scope.onSkillAdded = ({ block: skillBlock }: ISkillData) => {
        const { block } = $scope.$ctrl;
        const newSkillCards = getNewSkillCards(block, skillBlock);
        block.cards.push(...newSkillCards);
        const newTodoItems = getNewTodoItems(block, skillBlock);
        const todoCard = block.cards[getTodoCardIndexFromBlock(block)];
        if (todoCard && newTodoItems) {
          todoCard.config.todo.push(...newTodoItems);
        }
        updateBlockValidStatusInGqlCache({ block });
      };
      $scope.isEnabledAddSkill = () =>
        isAddSkillButtonEnabled($scope.$ctrl.block);
    },
    bindings: {
      block: '=',
    },
  }).name;
