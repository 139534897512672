import { usePagesWithSubscriptions } from './usePagesWithSubscriptions';
import { PaywallStatus, SubscriptionStatus } from '@globals';
import { useCallback, useEffect, useMemo } from 'react';
import { Page, useTransferPlan } from './useTransferPlan';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { showSomethingWentWrongToaster } from '@services/MessageService';

export const useTransferPlanDialog = () => {
  const {
    pages,
    refetch,
    loading: pagesLoading,
    error: pagesLoadingError,
  } = usePagesWithSubscriptions();
  const {
    page: fromPage,
    loading: currentPageLoading,
    error: currentPageLoadingError,
  } = usePageConnected();
  const { transferPlan, loading: transferring } = useTransferPlan();

  const transferPlanFromCurrentPage = useCallback(
    (toPage: Page) => fromPage && transferPlan(fromPage, toPage),
    [fromPage, transferPlan],
  );

  const trialPages = useMemo(
    () =>
      pages?.filter(
        ({ subscription }) =>
          subscription?.status === SubscriptionStatus.trial &&
          (!subscription?.paywallStatus ||
            subscription?.paywallStatus === PaywallStatus.none),
      ),
    [pages],
  );

  const error = currentPageLoadingError || pagesLoadingError;

  useEffect(() => {
    if (error) {
      showSomethingWentWrongToaster();
    }
  }, [error]);

  return {
    transferPlan: transferPlanFromCurrentPage,
    loading: pagesLoading || currentPageLoading,
    refetchPages: refetch,
    pages: trialPages,
    error,
    transferring,
  };
};
