import React, { useCallback } from 'react';
import { Flex } from '@ui/Flex';
import logoSrc from '../common/images/stripe_logo.png';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { useStripeAccount } from '@utils/Stripe/hooks/useStripeAccount';
import { CenteredLoader } from '@ui/Loader';
import { Button, ButtonUnstyled } from '@ui/Button';
import { showConnectStripeDialog } from '../ConnectStripeDialog';
import { useHistory } from 'react-router-dom';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';
import * as css from './StripeAccount.css';
import { sendEvent } from '@utils/Analytics';
import { Callout } from '@ui/Callout';

export const StripeAccount: React.FC = () => {
  const { t } = useSafeTranslation();
  const { isStripeConnected, stripeStatusLoading } = useStripeAccount();
  const history = useHistory();
  const botId = useCurrentBotId();

  const goToDisconnect = useCallback(() => {
    history.push({
      pathname: getTabLink(BotTabs.configure, botId),
      state: {
        scrollToPayments: true,
      },
    });
    sendEvent({
      category: 'stripe account',
      action: 'go to disconnect button click',
    });
  }, [botId, history]);

  const connectHandler = useCallback(() => {
    showConnectStripeDialog();
    sendEvent({
      category: 'stripe account',
      action: 'connect button click',
    });
  }, []);

  if (stripeStatusLoading) {
    return <CenteredLoader />;
  }

  if (isStripeConnected) {
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <img src={logoSrc} width={67} height={32} alt="Stripe" />
          <Icon icon="activeCircle2" color="accent2Secondary" size="16px" />
          <Spacer factor={1} horizontalFactor={1} />
          <Type
            size="12px"
            color="accent2Secondary"
            data-testid="stripe-account__connection-status-label"
          >
            {t('modernComponents.StripeAccount.enabled')}
          </Type>
        </Flex>
        <div>
          <Type size="12px">
            {t('modernComponents.StripeAccount.goToDisconnect.t1')}
          </Type>{' '}
          <ButtonUnstyled
            className={css.link}
            onClick={goToDisconnect}
            data-testid="stripe-account__disconnect-link"
          >
            <Type color="accent1Normal" size="12px">
              {t('modernComponents.StripeAccount.goToDisconnect.t2')}
            </Type>
          </ButtonUnstyled>{' '}
          <Type size="12px">
            {t('modernComponents.StripeAccount.goToDisconnect.t3')}
          </Type>
        </div>
      </Flex>
    );
  }

  return (
    <>
      <Callout
        text={
          <Type as="div" className={css.callout} color="accent3Text">
            {t('modernComponents.StripeAccount.info')}
          </Type>
        }
        type="warning"
        flexDirection="column"
        alignItems="flex-start"
        iconDisabled
      />
      <Spacer factor={6} />
      <Flex
        className={css.box}
        flexDirection="column"
        gapFactor={3}
        alignItems="center"
      >
        <img src={logoSrc} width={67} height={32} alt="Stripe" />
        <Button
          data-testid="stripe-account_connect-button"
          intent="primary"
          fullWidth
          onClick={connectHandler}
        >
          {t('modernComponents.StripeAccount.Connect')}
        </Button>
      </Flex>
    </>
  );
};
