import { DefaultDialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import { TabItem, Tabs2 } from '@ui/Tabs2';
import React, { useState } from 'react';
import { ComposeTabId } from '../consts';
import * as css from '../ChooseItemFromListDialog.css';

interface ChildrenProps {
  activeTabId: string;
  searchString: string;
}

interface InputProps extends ChildrenProps {
  onChange(value: string): void;
  setActiveTabId(tabId: string): void;
}

interface ChooseItemFromListContainerDialogProps {
  tabs: TabItem[];
  onDismiss?(): void;
  onTabChange?(tabId: string): void;
  header: string;
  renderInput(props: InputProps): React.ReactNode;
  children(props: ChildrenProps): React.ReactNode;
}

export const ChooseItemFromListContainerDialog: React.FC<ChooseItemFromListContainerDialogProps> = ({
  tabs,
  header,
  onDismiss,
  onTabChange,
  renderInput,
  children,
}) => {
  const [activeTabId, setActiveTabId] = useState<string>(ComposeTabId.all);
  const [searchString, setSearchString] = useState<string>('');

  return (
    <DefaultDialog
      header={header}
      onDismiss={() => {
        onDismiss?.();
      }}
      dialogStyle={{
        width: 700,
        height: 600,
      }}
    >
      {tabs.length > 1 && (
        <>
          <Tabs2
            items={tabs}
            onChangeTab={(tabId) => {
              onTabChange?.(tabId);
              return setActiveTabId(tabId);
            }}
            tabClassName={css.tab}
            active={activeTabId}
          />
          <Spacer factor={4} />
        </>
      )}
      {renderInput({
        activeTabId,
        searchString,
        onChange: setSearchString,
        setActiveTabId,
      })}
      <Spacer factor={6} />
      {children({ activeTabId, searchString })}
    </DefaultDialog>
  );
};
