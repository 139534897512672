import React from 'react';
import { useFlow } from '@utils/Data/Flow/useFlow';
import { useRouteMatch } from 'react-router-dom';
import { BotPageRouteParams, useIsFirstSessionTab } from '@utils/Routing';
import { TestThisChatfuel } from './TestThisChatfuel';
import { Platform } from '@globals';

export const TestThis: React.FC = () => {
  const automateTabMatch = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/structure/:blockId',
  );
  const flowTabMatch = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/flows/:flowId',
  );
  const keywordsTabMatch = useRouteMatch<BotPageRouteParams>(
    '/bot/:botId/keywords/:platform?/:page?',
  );
  const isFirstSessionTab = useIsFirstSessionTab();

  const keywordsTabPlatform = keywordsTabMatch?.params.platform as Platform;
  const tabId = automateTabMatch?.params.blockId || flowTabMatch?.params.flowId;
  const flowId = flowTabMatch?.params.flowId ?? '';
  const { flow, loading } = useFlow({ flowId });
  const flowPlatform = flow?.platform;
  const botTesting = isFirstSessionTab || !!keywordsTabMatch;
  const isFacebookPlatform =
    flowPlatform === Platform.facebook ||
    keywordsTabPlatform === Platform.facebook;

  return (
    <TestThisChatfuel
      botTesting={botTesting}
      hideMoreMenuButton={isFirstSessionTab || !isFacebookPlatform}
      tabId={tabId}
      insideFlow={!!flowId}
      platform={keywordsTabPlatform || flowPlatform}
      hideButton={isFirstSessionTab || loading}
    />
  );
};
