import { ComposeTabId, StateItemId } from './consts';

interface getCurrentItemsParams<T> {
  activeTabId: string;
  isValidItemUrl: boolean;
  isRefetch: boolean;
  loading: boolean;
  items?: T[];
  filterFunc(item: T): boolean;
  itemByUrl?: T;
  getItemFilterFunc?(searchString: string): (post: T) => boolean;
  searchString: string;
}

export function getCurrentItems<T>({
  activeTabId,
  isValidItemUrl,
  itemByUrl,
  isRefetch,
  items,
  searchString,
  loading,
  filterFunc,
}: getCurrentItemsParams<T>) {
  const currentItems =
    activeTabId === ComposeTabId.postUrl
      ? itemByUrl && isValidItemUrl
        ? [itemByUrl]
        : []
      : isRefetch
      ? []
      : items?.filter(filterFunc) || ([] as ({ id: string } | T)[]);

  if (
    !(loading || isRefetch) &&
    currentItems.length === 0 &&
    (activeTabId !== ComposeTabId.postUrl || isValidItemUrl)
  ) {
    currentItems.push({
      id: StateItemId.notFound,
    });
  }

  if (searchString && activeTabId === ComposeTabId.postUrl && !isValidItemUrl) {
    currentItems.push({
      id: StateItemId.invalidUrl,
    });
  }

  if (loading || isRefetch) {
    currentItems.push({
      id: StateItemId.loader,
    });
  }

  currentItems.push({
    id: StateItemId.spacer,
  });

  return currentItems;
}
