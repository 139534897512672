import React, { useEffect, useRef, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { clone, pipe, prop, sortBy } from 'ramda';
import cn from 'classnames';
import useOnClickOutside from 'use-onclickoutside';
import { Button, ButtonUnstyled } from '@ui/Button';
import { CircleLoader } from '@ui/Loader/CircleLoader';
import { Dialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { formatPriceEx } from '@utils/Format/priceFormatter';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { formatDateShort } from '@utils/DateTime/formatDate';
import { ScrollBox } from '@ui/ScrollBox';
import { TextEllipsis } from '@ui/TextEllipsis';
import { ShopifyAccountButton } from '@utils/Integrations/Shopify/ShopifyAccountButton';
import { getShopifyDiscountsCodeSelectStatus } from './helpers';
import {
  ShopifyDiscountsCodesQuery_shopifyDiscountsCodes,
  ShopifyDiscountsCodesQuery_shopifyDiscountsCodes as ShopifyDiscountsCode,
} from '../../@types/ShopifyDiscountsCodesQuery';
import { ShopifyDiscountsCodeSelectStatus } from './types';
import * as css from './ShopifyDiscountsCodeSelect.css';
import {
  ShopifyDiscountsCodeType,
  ShopifyDiscountsCodeValueType,
} from '@globals';

export interface ShopifyDiscountsCodeSelectProps {
  className?: string;
  onCreate(): void;
  onChange(code: ShopifyDiscountsCode): void;
  onDelete(code: ShopifyDiscountsCode): void;
  onSelect(code: ShopifyDiscountsCode): void;
  onPermissionRequest(): void;
  onClickOutside(): void;
  permissionRequestLoading: boolean;
  codes: ShopifyDiscountsCode[];
  error: boolean;
  loading: boolean;
  hasPermission: boolean;
  refetch(): void;
  discountTypes?: ShopifyDiscountsCodeType[];
  onDiscountListChange(): void;
  onClose(): void;
}

export const NONE_CODE_ID = 'none';

const NONE_CODE: ShopifyDiscountsCodesQuery_shopifyDiscountsCodes = {
  __typename: 'ShopifyDiscountsCode',
  id: NONE_CODE_ID,
  code: 'None',
  discount_type: ShopifyDiscountsCodeType.common,
  price_rule_id: '',
  value: 0,
  value_type: ShopifyDiscountsCodeValueType.percentage,
  usage_limit: null,
  once_per_customer: null,
  start_date: '',
  end_date: null,
  shop_id: null,
};

export const ShopifyDiscountsCodeSelect: React.FC<ShopifyDiscountsCodeSelectProps> =
  ({
    onCreate,
    onChange,
    onDelete,
    onSelect,
    onPermissionRequest,
    onClickOutside,
    permissionRequestLoading,
    className,
    codes,
    error,
    hasPermission,
    loading,
    refetch,
    discountTypes,
    onDiscountListChange,
    onClose,
  }) => {
    const { t } = useSafeTranslation();
    const [search, setSearch] = useState('');
    const { account } = useShopifyAccount();
    const currency = account?.currency || 'USD';

    useEffect(() => {
      onDiscountListChange();
    }, [codes, onDiscountListChange]);

    const filteredCodes = pipe<
      ShopifyDiscountsCode[],
      ShopifyDiscountsCode[],
      ShopifyDiscountsCode[]
    >(
      clone,
      sortBy(prop('id')),
    )(
      search || discountTypes
        ? codes.filter(
            ({ code, discount_type }) =>
              code?.toUpperCase().includes(search.trim().toUpperCase()) &&
              (!discountTypes?.length ||
                discountTypes?.includes(discount_type)),
          )
        : codes,
    );

    if (
      !discountTypes ||
      discountTypes?.includes(ShopifyDiscountsCodeType.unique)
    ) {
      filteredCodes.unshift(NONE_CODE);
    }

    const status = getShopifyDiscountsCodeSelectStatus(
      search,
      filteredCodes,
      error,
      loading,
      hasPermission,
      !!account,
    );

    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, onClickOutside);

    return (
      <Dialog className={cn(css.container, className)} ref={ref}>
        <Type size="15px_DEPRECATED" weight="semibold">
          {t('shopify.openShopifyStore.codeSelect.header')}
        </Type>
        <Spacer factor={4} />

        {status === ShopifyDiscountsCodeSelectStatus.zero && (
          <>
            <Spacer factor={10} />
            <Flex flexDirection="column" alignItems="center">
              <Type size="15px_DEPRECATED">
                {t('shopify.openShopifyStore.codeSelect.noDiscountCodes')}
              </Type>
              <Spacer factor={6} />
              <Button
                intent="primary"
                icon={<Icon icon="plus" color="white" />}
                onClick={onCreate}
              >
                {t('shopify.openShopifyStore.codeSelect.newCode')}
              </Button>
              <Spacer factor={12} />
            </Flex>
          </>
        )}

        {status === ShopifyDiscountsCodeSelectStatus.loading && (
          <>
            <Spacer factor={10} />
            <Flex justifyContent="center">
              <CircleLoader size={20} color="grey" />
            </Flex>
            <Spacer factor={30} />
          </>
        )}

        {status === ShopifyDiscountsCodeSelectStatus.empty && (
          <>
            <Spacer factor={4} />
            <Type size="15px_DEPRECATED">
              {t('shopify.openShopifyStore.codeSelect.noResults')}
            </Type>
          </>
        )}

        {status === ShopifyDiscountsCodeSelectStatus.error && (
          <>
            <Spacer factor={10} />
            <Flex flexDirection="column" alignItems="center">
              <Type size="15px_DEPRECATED">
                {t('shopify.openShopifyStore.codeSelect.error')}
              </Type>
              <Spacer factor={5} />
              <Button
                intent="secondary"
                onClick={() => refetch()}
                disabled={loading}
              >
                {t('shopify.openShopifyStore.codeSelect.tryAgain')}
              </Button>
            </Flex>
          </>
        )}

        {status === ShopifyDiscountsCodeSelectStatus.needAccount && (
          <>
            <Spacer factor={3} />
            <Flex flexDirection="column" alignItems="center">
              <Type size="15px_DEPRECATED" align="center">
                {t('shopify.openShopifyStore.codeSelect.noAccount')}
              </Type>
              <Spacer factor={6} />
              <ShopifyAccountButton onClose={onClose} />
              <Spacer factor={4} />
            </Flex>
          </>
        )}

        {status === ShopifyDiscountsCodeSelectStatus.needPermission && (
          <>
            <Spacer factor={3} />
            <Flex flexDirection="column" alignItems="center">
              <Type size="15px_DEPRECATED" align="center">
                {t('shopify.openShopifyStore.codeSelect.noPermission')}
              </Type>
              <Spacer factor={6} />
              {permissionRequestLoading ? (
                <LoadingPlaceholder width={150} height={36} />
              ) : (
                <Button
                  intent="secondary"
                  onClick={onPermissionRequest}
                  style={{
                    minWidth: 150,
                  }}
                >
                  {t('shopify.openShopifyStore.codeSelect.requestPermission')}
                </Button>
              )}
              <Spacer factor={4} />
            </Flex>
          </>
        )}

        {status === ShopifyDiscountsCodeSelectStatus.list && (
          <>
            <Input
              renderIcon={() => <Icon icon="search" color="greyDark" />}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              autoFocus
              placeholder={t('shopify.openShopifyStore.codeSelect.search')}
            />
            <Spacer factor={2} />
            <ScrollBox
              style={{
                maxHeight: 320,
                width: 326,
                marginLeft: -16,
              }}
            >
              {filteredCodes.map((discountCode) => {
                const {
                  code,
                  price_rule_id,
                  value,
                  end_date: endDate,
                  value_type,
                } = discountCode as ShopifyDiscountsCode;

                const formatedValue =
                  value_type === ShopifyDiscountsCodeValueType.percentage
                    ? `${value}%`
                    : formatPriceEx(value, currency);
                return (
                  <Flex
                    key={price_rule_id}
                    className={css.listItem}
                    alignItems="center"
                    onClick={() => {
                      onSelect(discountCode);
                    }}
                  >
                    {discountCode.id === NONE_CODE_ID ? (
                      <div className={css.code}>
                        <Type size="15px_DEPRECATED">{code}</Type>
                      </div>
                    ) : (
                      <>
                        <div className={css.code} title={code.toUpperCase()}>
                          <Type size="15px_DEPRECATED">
                            <TextEllipsis width={121}>
                              {code.toUpperCase()}
                            </TextEllipsis>
                          </Type>
                        </div>
                        <Flex
                          className={css.discount}
                          justifyContent="flex-end"
                          title={formatedValue}
                        >
                          <Type size="15px_DEPRECATED">
                            <TextEllipsis width={72}>
                              {formatedValue}
                            </TextEllipsis>
                          </Type>
                        </Flex>
                        <Flex
                          className={css.expireAt}
                          justifyContent="flex-end"
                        >
                          <Flex
                            justifyContent="flex-end"
                            className={css.itemButtons}
                          >
                            <ButtonUnstyled
                              className={css.actionButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                onChange(discountCode);
                              }}
                            >
                              <Icon icon="edit" color="white" />
                            </ButtonUnstyled>
                            <ButtonUnstyled
                              className={css.actionButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                onDelete(discountCode);
                              }}
                            >
                              <Icon icon="delete" color="white" />
                            </ButtonUnstyled>
                          </Flex>
                          <div className={css.itemDate}>
                            <Type size="15px_DEPRECATED">
                              {endDate
                                ? t(
                                    'shopify.openShopifyStore.codeSelect.till',
                                    {
                                      expireAt: formatDateShort(
                                        parseInt(endDate, 10),
                                      ),
                                    },
                                  )
                                : t(
                                    'shopify.openShopifyStore.codeSelect.unlimited',
                                  )}
                            </Type>
                          </div>
                        </Flex>
                      </>
                    )}
                  </Flex>
                );
              })}
            </ScrollBox>
            <Spacer factor={3} />
            <Flex justifyContent="flex-end">
              <Button
                onClick={onCreate}
                intent="primary"
                icon={<Icon icon="plus" color="white" />}
              >
                {t('shopify.openShopifyStore.codeSelect.newCode')}
              </Button>
            </Flex>
          </>
        )}
      </Dialog>
    );
  };
