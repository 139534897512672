import { PricingData } from './types';

const MAX_POSITIONS_IN_VIEW = 7;

export const useVisiblePrices = ({ currentPrice, pricing }: PricingData) => {
  const safePricing = pricing ?? [];
  const currentIndex = safePricing.findIndex(
    ([_, price]) => price === currentPrice,
  );

  const subsetStart =
    currentIndex > MAX_POSITIONS_IN_VIEW - 2
      ? currentIndex + 2 - MAX_POSITIONS_IN_VIEW
      : 0;

  return safePricing.slice(subsetStart, subsetStart + MAX_POSITIONS_IN_VIEW);
};
