import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Type } from '@ui/Type';
import { DefaultPaymentModal } from './DefaultPaymentModal';
import { getStripePromise } from 'cf-common/src/utils/Stripe/getStripePromise';

interface UpdatePaymentMethodModalProps {
  onDismiss: () => void;
  onSuccess: () => void;
}

export const UpdatePaymentMethodModal: React.FC<UpdatePaymentMethodModalProps> = ({
  onDismiss,
  onSuccess,
}) => {
  const stripe = getStripePromise(window.CHATFUEL_CONFIG.STRIPE_PUBLIC_KEY!);

  return (
    <Elements stripe={stripe}>
      <DefaultPaymentModal
        header={window.i18next.t(
          'UpdatePaymentMethodModal-string-1778-update-payment-method',
        )}
        submitText={window.i18next.t(
          'UpdatePaymentMethodModal-string--175-update',
        )}
        onDismiss={onDismiss}
        onSuccess={onSuccess}
      >
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'UpdatePaymentMethodModal-JSXText-5294-enter-your-new-card-information',
          )}
        </Type>
      </DefaultPaymentModal>
    </Elements>
  );
};
