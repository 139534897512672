import React, { useCallback, useEffect, useState } from 'react';
import { FormLabel } from '@ui/Form/FormLabel';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Button, ButtonUnstyled } from '@ui/Button';
import * as css from './ConnectStripeForm.css';
import { usePaymentsConfig } from '@utils/Payment/hooks/usePaymentsConfig';
import { CenteredLoader } from '@ui/Loader';
import { usePrevious } from 'cf-common/src/utils/hooks';
import { useConnectStripeFormik } from './hooks/useConnectStripeFormik';
import { getDefaultPolicy } from './consts';
import { useStripeAccount } from '@utils/Stripe/hooks/useStripeAccount';
import { sendEvent } from '@utils/Analytics';

interface ConnectStripeFormProps {
  onConnected?: VoidFunction;
}

export const ConnectStripeForm: React.FC<ConnectStripeFormProps> = ({
  onConnected,
}) => {
  const { t } = useSafeTranslation();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { isStripeConnected, stripeConnect } = useStripeAccount({
    onConnected,
  });
  const { paymentsConfig, loading, updatePaymentsConfig } = usePaymentsConfig();
  const {
    values,
    setValues,
    handleSubmit,
    submitForm,
    isValid,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    validateField,
  } = useConnectStripeFormik({
    paymentsConfig,
    onSubmit: (paymentsConfig) => {
      updatePaymentsConfig(paymentsConfig);
      setIsEdit(false);
      sendEvent({
        category: 'connect stripe form',
        action: 'submit',
      });
    },
  });

  const prevPaymentsConfig = usePrevious(paymentsConfig);
  useEffect(() => {
    if (!prevPaymentsConfig && paymentsConfig) {
      setValues(paymentsConfig);
    }
  }, [paymentsConfig, prevPaymentsConfig, setValues]);

  const setDefaultPolicy = useCallback(async () => {
    await setFieldValue('terms_of_service_url', getDefaultPolicy(), true);
    await validateField('terms_of_service_url')
    submitForm();
    sendEvent({
      category: 'connect stripe form',
      action: 'set default policy button click',
    });
  }, [setFieldValue, submitForm, validateField]);

  const cancelHandler = useCallback(() => {
    setIsEdit(false);
    if (paymentsConfig) {
      setValues(paymentsConfig);
    }
    sendEvent({
      category: 'connect stripe form',
      action: 'cancel button click',
    });
  }, [paymentsConfig, setValues]);

  const saveHandler = useCallback(() => {
    submitForm();
    sendEvent({
      category: 'connect stripe form',
      action: 'save button click',
    });
  }, [submitForm]);

  const connectHandler = useCallback(() => {
    stripeConnect();
    sendEvent({
      category: 'connect stripe form',
      action: 'connect button click',
    });
  }, [stripeConnect]);

  const editHandler = useCallback(() => {
    setIsEdit(true);
    sendEvent({
      category: 'connect stripe form',
      action: 'edit button click',
    });
  }, []);

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <form onSubmit={handleSubmit} data-testid="connect-stripe-form">
      <FormLabel
        required
        text={t('modernComponents.Stripe.ConnectStripeForm.field1.title')}
        action={
          isStripeConnected &&
          !isEdit && (
            <ButtonUnstyled
              data-testid="connect-stripe-form_edit-button"
              className={css.buttonEdit}
              onClick={editHandler}
            >
              <Type color="accent1Normal">
                {t('modernComponents.Stripe.ConnectStripeForm.edit')}
              </Type>
            </ButtonUnstyled>
          )
        }
      >
        {(bind) => (
          <Input
            data-testid="connect-stripe-form_terms-of-service-url-input"
            className={css.input}
            placeholder={t(
              'modernComponents.Stripe.ConnectStripeForm.field1.placeholder',
            )}
            name="terms_of_service_url"
            value={values.terms_of_service_url || ''}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={!(!isStripeConnected || isEdit)}
            error={
              !!(touched.terms_of_service_url && errors.terms_of_service_url)
            }
            {...bind}
          />
        )}
      </FormLabel>
      <Spacer factor={2} />
      <div>
        <Type size="12px" color="baseTertiary">
          {t('modernComponents.Stripe.ConnectStripeForm.field1.subtext')}{' '}
        </Type>
        <ButtonUnstyled
          data-testid="connect-stripe-form_set-default-policy-button"
          className={css.linkButton}
          onClick={setDefaultPolicy}
        >
          <Type size="12px" color="accent1Normal">
            {t('modernComponents.Stripe.ConnectStripeForm.field1.subtextLink')}
          </Type>
        </ButtonUnstyled>
      </div>
      {!isStripeConnected && (
        <>
          <Spacer factor={7} />
          <Button
            data-testid="connect-stripe-form_connect-button"
            onClick={connectHandler}
            intent="primary"
            fullWidth
            disabled={!isValid}
            type="submit"
          >
            {t('modernComponents.Stripe.ConnectStripeForm.buttonConnect')}
          </Button>
        </>
      )}
      {isStripeConnected && isEdit && (
        <>
          <Spacer factor={7} />
          <Button
            data-testid="connect-stripe-form_save-button"
            onClick={saveHandler}
            intent="primary"
            fullWidth
            disabled={!isValid}
            type="submit"
          >
            {t('modernComponents.Stripe.ConnectStripeForm.save')}
          </Button>
          <Spacer factor={3} />
          <Button
            data-testid="connect-stripe-form_cancel-button"
            onClick={cancelHandler}
            intent="secondary"
            fullWidth
          >
            {t('modernComponents.Stripe.ConnectStripeForm.cancel')}
          </Button>
        </>
      )}
    </form>
  );
};
