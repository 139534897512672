import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { isEmpty, trim, compose, complement, prop } from 'ramda';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import cn from 'classnames';
import PageVisibility from 'react-page-visibility';
import { Spacer } from '@ui/Spacer';
import { SuggestStrings } from '@ui/Suggest2';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { Tooltip2 } from '@ui/Tooltip2';
import { UndoSimple, INamed } from '@ui/Undo';
import { attributeValueToString as attributeToString } from '../../common/services/OmniboxService';
import {
  ButtonSize,
  ButtonIntent,
  Button,
  ButtonColorWay,
} from '../../modern-ui/_deprecated/Button';
import { ReactComponent as TrashIcon } from '../../modern-ui/_deprecated/Icon/icons/trash.svg';
import { UserAttribute, UserAttributeType } from './UserAttributeTypes';
import { FlowsBadge } from './FlowsBadge/FlowsBadge';
import * as css from './AttributeGroupEditor.module.less';
import { CheckBox } from '@ui/CheckBox';
import i18next from 'i18next';

interface IAttributeGroupEditorProps {
  attributes: UserAttribute[];
  attributesSuggestsName?: string[];
  attributesSuggestsValues?: string[];
  attributesType: UserAttributeType;
  isNewAttributeEdit?: boolean;
  readOnly?: boolean;
  onChange?: (attributes: UserAttribute[]) => void;
  onUndoLine?: (index: number) => void;
  onValueInputKeyDown?: React.KeyboardEventHandler;
  onRequestAttributeValuesLoad?: (attributeName: string, value: string) => void;
  onSaveRequest?: (attributes: UserAttribute[]) => void;
  onAttributesSelected?: (names: string[]) => void;
  showHeader?: boolean;
  lastAttributeRef?: (ref: HTMLDivElement | null) => void;
  attributeNamePlaceholder?: string;
  attributeValuePlaceholder?: string;
  attributesColumnName?: string;
  attributeValueColumnName?: string;
  undeletable?: boolean;
  isSmallScreenSize?: boolean;
  showBulkRemoving?: boolean;
}

interface IAttributeGroupEditorState {
  hoverAttributeIndex: number | null;
  focusedSuggestIndex: number | null;
  lastDeletedAttribute: UserAttribute | null;
  lastDeletedAttributeIndex: number | undefined;
  lastAttributesListLength: number;
  enabledAttributesAnimation: boolean;
  checkedAttributes: Set<string>;
}

const attrToString = attributeToString as (a: UserAttribute) => string;

const hasBrackets = (name: string) => {
  return RegExp('[{}]').test(name);
};

const hasDuplicateName = (name: string, attribute: UserAttribute[]) => {
  let nameCount = 0;

  for (let i = 0; i < attribute.length; i++) {
    if (attribute[i].name === name) {
      nameCount++;
    }

    if (nameCount > 1) {
      return true;
    }
  }

  return false;
};

const isEmptyString = compose(isEmpty, trim);
const isNotEmptyString = complement(isEmptyString);
const attributeNotEmpty: (attribute: UserAttribute) => boolean = compose(
  isNotEmptyString,
  String,
  prop('name'),
);
const isUsedAttribute = ({ blocksUsingIt, flowsUsingIt }: UserAttribute) =>
  !!(blocksUsingIt?.length || flowsUsingIt?.length);

const ATTRIBUTE_MAX_LENGTH = 256;
export const JS_ATTRIBUTES_BOX_CLASS = 'js-attributes-box';

class AttributeGroupEditorComponent extends React.Component<
  IAttributeGroupEditorProps & WithTranslation,
  IAttributeGroupEditorState
> {
  static getDerivedStateFromProps(
    props: IAttributeGroupEditorProps,
    state: IAttributeGroupEditorState,
  ) {
    const {
      attributes: { length: currentAttributesListLength },
    } = props;
    const deltaAttributesQty = Math.abs(
      state.lastAttributesListLength - currentAttributesListLength,
    );
    const enabledAttributesAnimation = deltaAttributesQty < 2;
    return {
      ...state,
      enabledAttributesAnimation,
      lastAttributesListLength: currentAttributesListLength,
    };
  }

  state = {
    hoverAttributeIndex: null,
    focusedSuggestIndex: null,
    lastDeletedAttribute: null,
    lastDeletedAttributeIndex: undefined,
    lastAttributesListLength: 0,
    enabledAttributesAnimation: true,
    checkedAttributes: new Set<string>(),
  };

  elsLines: (HTMLDivElement | null)[] = [];

  isBrowserTabActive: boolean = true;

  timeoutAnimation: number | undefined;

  componentWillUnmount() {
    window.clearTimeout(this.timeoutAnimation);
  }

  onAttributeChange(
    name: string,
    value: string,
    oldAttr: UserAttribute,
    duplicateName: boolean = false,
  ) {
    const {
      attributes: [...newAttributes],
      onChange,
    } = this.props;
    const index = newAttributes.indexOf(oldAttr);
    if (index !== -1) {
      newAttributes[index] = {
        ...oldAttr,
        name,
        values: [value],
        duplicateName,
      };
      if (onChange) {
        onChange(newAttributes);
      }
    }
  }

  onDeleteAttr(attr: UserAttribute) {
    const {
      attributes: [...newAttributes],
      onChange,
      onSaveRequest,
    } = this.props;
    const index = newAttributes.indexOf(attr);
    if (index !== -1) {
      const [lastDeletedAttribute] = newAttributes.splice(index, 1);
      if (attributeNotEmpty(lastDeletedAttribute)) {
        this.timeoutAnimation = window.setTimeout(
          () =>
            this.setState({
              lastDeletedAttribute,
              lastDeletedAttributeIndex: index,
            }),
          200,
        ); // wait delete attribute animation end
      }
      if (onChange) {
        onChange(newAttributes);
      }
      if (onSaveRequest) {
        onSaveRequest(newAttributes);
      }
    }
  }

  setHoverStateAttribute(index: number | null) {
    this.setState({
      hoverAttributeIndex: index,
    });
  }

  setFocusToValueInput(suggestIndex: number | null) {
    if (suggestIndex !== null) {
      if (!this.elsLines[suggestIndex]) {
        return;
      }
      const elInputs = this.elsLines[suggestIndex]!.querySelectorAll('input');
      const elValueInput = elInputs[elInputs.length - 1];
      if (elValueInput) {
        elValueInput.focus();
      }
    }
  }

  updateAttributeValuesSuggest = (attributeName: string, value: string) => {
    const { onRequestAttributeValuesLoad } = this.props;
    if (onRequestAttributeValuesLoad) {
      onRequestAttributeValuesLoad(attributeName, value);
    }
  };

  handleUndo = (data: INamed, index: number | void) => {
    const {
      attributes: [...newAttributes],
      onChange,
      onUndoLine,
      onSaveRequest,
    } = this.props;
    const undoUserAttribute = data as UserAttribute;
    this.setState({
      lastDeletedAttribute: null,
      lastDeletedAttributeIndex: undefined,
    });
    if (index !== undefined && onChange) {
      newAttributes.splice(index, 0, undoUserAttribute);
      onChange(newAttributes);
      if (onUndoLine) {
        onUndoLine(index);
      }
      if (onSaveRequest) {
        onSaveRequest(newAttributes);
      }
    }
  };

  handleVisibilityChange = (isVisible: boolean) => {
    this.isBrowserTabActive = isVisible;
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.handleSuggestFocusState(null);
    }
  };

  handleSuggestFocusState = (index: number | null) => {
    const { focusedSuggestIndex } = this.state;
    const { onSaveRequest, attributes } = this.props;
    setImmediate(() => {
      const { attributes } = this.props;
      // wait browser tab event
      if (this.isBrowserTabActive) {
        this.setState({ focusedSuggestIndex: index });
      }

      if (!index && attributes[focusedSuggestIndex || 0].name) {
        this.setFocusToValueInput(focusedSuggestIndex);
      }
    });

    if (index === null) {
      // on blur suggest
      if (onSaveRequest) {
        onSaveRequest(attributes);
      }
    }
  };

  handleAttributesSelected = (checkedAttributesUpdated: Set<string>) => {
    this.setState({
      checkedAttributes: checkedAttributesUpdated,
    });
    this.props.onAttributesSelected?.(
      Array.from(checkedAttributesUpdated).filter(complement(isUsedAttribute)),
    );
  };

  private createAttributesLinesBox() {
    const { attributes } = this.props;
    const { enabledAttributesAnimation } = this.state;

    return (
      <div className={JS_ATTRIBUTES_BOX_CLASS}>
        <TransitionGroup
          appear={enabledAttributesAnimation}
          enter={enabledAttributesAnimation}
          exit={enabledAttributesAnimation}
        >
          {attributes
            .filter((attribute) => !attribute.hidden)
            .map((attribute, index) => {
              return this.createAttributeLine(attribute, index);
            })}
        </TransitionGroup>
        {attributes && attributes.length === 0 && (
          <Type size="15px_DEPRECATED">
            {window.i18next.t(
              'AttributeGroupEditor-JSXText--122-no-attributes',
            )}
          </Type>
        )}
      </div>
    );
  }

  private createUndo() {
    const { lastDeletedAttribute, lastDeletedAttributeIndex } = this.state;
    return (
      <UndoSimple
        deletedData={lastDeletedAttribute}
        deletedDataIndex={lastDeletedAttributeIndex}
        onUndoRequest={this.handleUndo}
      />
    );
  }

  private createAttributeLine(attribute: UserAttribute, index: number) {
    const { readOnly } = this.props;
    return (
      <CSSTransition
        key={attribute.id}
        timeout={200}
        classNames={{
          enter: css.itemEnter,
          enterActive: css.itemEnterActive,
          exit: css.itemExit,
          exitActive: css.itemExitActive,
        }}
      >
        <div
          ref={(el) => {
            this.elsLines[index] = el;
          }}
        >
          {!readOnly
            ? this.createEditableAttributeLine(attribute, index)
            : this.createReadOnlyAttributeLine(attribute)}
        </div>
      </CSSTransition>
    );
  }

  private createReadOnlyAttributeLine(attribute: UserAttribute) {
    const { isSmallScreenSize } = this.props;
    return (
      <div className={cn(css.line, css.listItemLayout)}>
        <div
          className={cn(
            isSmallScreenSize ? css.lineItem_mobile : css.lineItem,
            isSmallScreenSize && css.lineItem_undeletableMobile,
          )}
        >
          <div
            className={css.ellipsis}
            title={attribute.name}
            data-testid="user-attributes__readonly-name"
          >
            <Type size="15px_DEPRECATED">{attribute.name}</Type>
          </div>
        </div>
        <div
          className={cn(
            isSmallScreenSize ? css.lineItem_mobile : css.lineItem,
            isSmallScreenSize && css.lineItem_undeletableMobile,
          )}
        >
          <div
            className={css.ellipsis}
            title={attrToString(attribute)}
            data-testid="user-attributes__readonly-value"
          >
            <Type size="15px_DEPRECATED">{attrToString(attribute)}</Type>
          </div>
        </div>
      </div>
    );
  }

  private createEditableAttributeLine(attribute: UserAttribute, index: number) {
    const { focusedSuggestIndex, checkedAttributes } = this.state;
    const { undeletable, showBulkRemoving } = this.props;
    const isNewLine =
      isEmptyString(attribute.name) || focusedSuggestIndex === index;
    const checked = checkedAttributes.has(attribute.name);
    const checkboxDisabled = isUsedAttribute(attribute);
    return (
      <div
        data-testid={`${
          isNewLine
            ? 'user-attributes__new-attribute-line'
            : 'user-attributes__attribute-line'
        }`}
        className={cn(css.line, css.listItemLayout, 'test-attribute-line', {
          'test-attribute-new-line': isNewLine,
        })}
        onMouseEnter={() => this.setHoverStateAttribute(index)}
        onMouseLeave={() => this.setHoverStateAttribute(null)}
      >
        {showBulkRemoving && (
          <>
            <Tooltip2
              content={i18next.t(
                'modernComponents.UiUserAttributeEditor.disabledAttributeCheckbox',
              )}
              disabled={!checkboxDisabled}
              positionFixed
            >
              {(ref, bind) => (
                <div ref={ref} {...bind}>
                  <CheckBox
                    checked={checked}
                    disabled={checkboxDisabled}
                    onChange={() => {
                      if (checked) {
                        checkedAttributes.delete(attribute.name);
                      } else {
                        checkedAttributes.add(attribute.name);
                      }
                      this.handleAttributesSelected(checkedAttributes);
                    }}
                  />
                </div>
              )}
            </Tooltip2>

            <Spacer factor={0} horizontalFactor={2} />
          </>
        )}
        {this.createAttributeNameField(attribute, index)}
        {this.createAttributeValueField(attribute)}
        {!undeletable && this.createRemoveAttributeButton(index, attribute)}
      </div>
    );
  }

  private createAttributeValueField(attribute: UserAttribute) {
    const {
      onValueInputKeyDown,
      attributesSuggestsValues,
      onSaveRequest,
      attributes,
      t,
      undeletable,
      isSmallScreenSize,
    } = this.props;
    const onAttributeValueChange = (newValue: string) => {
      this.onAttributeChange(attribute.name, newValue, attribute);
      this.updateAttributeValuesSuggest(attribute.name, newValue);
    };
    const attributeValue = attrToString(attribute);

    return (
      <div
        data-testid="user-attributes__value-container"
        className={cn(
          isSmallScreenSize ? css.lineItem_mobile : css.lineItem,
          'test-attribute-value-box',
          undeletable && isSmallScreenSize && css.lineItem_undeletableMobile,
        )}
      >
        <SuggestStrings
          selectedItem={attributeValue}
          placeholder={
            this.props.attributeValuePlaceholder ??
            t('modernComponents.UiUserAttributeEditor.valuePlaceholder')
          }
          onChange={onAttributeValueChange}
          onInputChange={onAttributeValueChange}
          maxLength={ATTRIBUTE_MAX_LENGTH}
          onKeyDown={onValueInputKeyDown}
          disabled={attribute.duplicateName}
          onFocus={() =>
            this.updateAttributeValuesSuggest(attribute.name, attributeValue)
          }
          onBlur={() => {
            sendEvent({
              category: 'attributes editor',
              action: 'change value of existing attribute',
              propertyBag: {
                text: attributeValue,
              },
            });
            if (onSaveRequest) onSaveRequest(attributes);
          }}
          items={attributesSuggestsValues || []}
        />
      </div>
    );
  }

  private createAttributeNameField(attribute: UserAttribute, index: number) {
    const {
      attributesSuggestsName,
      attributes,
      lastAttributeRef,
      attributeNamePlaceholder,
      t,
      undeletable,
      isSmallScreenSize,
    } = this.props;
    const { focusedSuggestIndex } = this.state;
    const onAttributeNameChange = (newName: string) => {
      this.onAttributeChange(
        newName,
        attribute.values[0],
        attribute,
        attributes.some(({ name }) => name === newName),
      );
    };

    const duplicated = hasDuplicateName(attribute.name, attributes);
    const nameIsEditable =
      isEmptyString(attribute.name) ||
      attribute.duplicateName ||
      focusedSuggestIndex === index;

    return (
      <div
        data-testid="user-attributes__name-container"
        className={cn(
          isSmallScreenSize ? css.lineItem_mobile : css.lineItem,
          'test-attribute-name-box',
          undeletable && isSmallScreenSize && css.lineItem_undeletableMobile,
        )}
      >
        {nameIsEditable && (
          <>
            <SuggestStrings
              items={attributesSuggestsName || []}
              placeholder={
                attributeNamePlaceholder ??
                t('modernComponents.UiUserAttributeEditor.namePlaceholder')
              }
              selectedItem={attribute.name}
              onChange={onAttributeNameChange}
              onInputChange={onAttributeNameChange}
              onFocus={() => this.handleSuggestFocusState(index)}
              onBlur={() => {
                sendEvent({
                  category: 'attributes editor',
                  action: 'enter name of new attribute',
                  propertyBag: {
                    text: attribute.name,
                  },
                });
                this.handleSuggestFocusState(null);
              }}
              onKeyDown={this.handleKeyDown}
              maxLength={ATTRIBUTE_MAX_LENGTH}
              error={
                hasBrackets(attribute.name) ||
                hasDuplicateName(attribute.name, attributes)
              }
              inputRef={
                index === attributes.length - 1 ? lastAttributeRef : undefined
              }
            />
            {hasBrackets(attribute.name) && (
              <Type size="12px" color="red" as="p">
                {window.i18next.t(
                  'AttributeGroupEditor-JSXText-1408-dont-use-parentheses-here',
                )}
              </Type>
            )}
            {duplicated && (
              <>
                <Spacer factor={1} />
                <Type size="12px" color="red" as="p">
                  {window.i18next.t(
                    'AttributeGroupEditor-JSXText-1122-this-attribute-name-is-already-used-in-your-bot',
                  )}
                </Type>
              </>
            )}
          </>
        )}
        {!nameIsEditable && (
          <div className={css.ellipsis} data-testid="user-attributes__name">
            <span title={attribute.name}>
              <Type size="15px_DEPRECATED">{attribute.name} </Type>
            </span>
            <FlowsBadge flowsUsingIt={attribute.flowsUsingIt} />
          </div>
        )}
      </div>
    );
  }

  private createRemoveAttributeButton(index: number, attribute: UserAttribute) {
    const { isSmallScreenSize } = this.props;
    const { hoverAttributeIndex } = this.state;
    return (
      <div className={css.trashButtonPositioning}>
        {(index === hoverAttributeIndex || isSmallScreenSize) && (
          <Button
            data-testid="user-attributes__remove-attribute-button"
            onClick={() => {
              sendEvent({
                category: 'attributes editor',
                action: 'click delete attribute',
                propertyBag: {
                  name: attribute.name,
                  value: attribute.values[0],
                },
              });

              this.onDeleteAttr(attribute);
            }}
            renderIcon={() => (
              <Tooltip2
                content={window.i18next.t(
                  'AttributeGroupEditor-string-1141-deleting-an-attribute-will-remove-it-from-this-list-but-not-from-any-active-bot-s-and-users',
                )}
                placement="right"
                boundariesElement="viewport"
                hoverable
                disabled={isSmallScreenSize}
              >
                {(ref, bind) => (
                  <div ref={ref} {...bind}>
                    <TrashIcon style={{ opacity: 0.3 }} />
                  </div>
                )}
              </Tooltip2>
            )}
            intent={ButtonIntent.toggle}
            size={ButtonSize.s}
            colorWay={ButtonColorWay.white}
            tall
            className="test-delete-attribute-button"
          />
        )}
      </div>
    );
  }

  render() {
    const {
      attributes,
      attributesType,
      readOnly,
      showBulkRemoving,
      attributesColumnName,
      attributeValueColumnName,
      t,
      undeletable,
      isSmallScreenSize,
    } = this.props;
    const { checkedAttributes } = this.state;
    const unusedAttributes = attributes.filter(complement(isUsedAttribute));
    const checked =
      checkedAttributes.size > 0 &&
      checkedAttributes.size === unusedAttributes.length;

    return (
      <React.Fragment>
        <div>
          {this.props.showHeader && (
            <div className={cn(css.line, css.listHeaderSpacing)}>
              {showBulkRemoving && (
                <>
                  <CheckBox
                    checked={checked}
                    onChange={() => {
                      if (checked) {
                        checkedAttributes.clear();
                      } else {
                        unusedAttributes.forEach(({ name }) => {
                          checkedAttributes.add(name);
                        });
                      }
                      this.handleAttributesSelected(checkedAttributes);
                    }}
                    data-testid="user-attributes__all-attribute-checkbox"
                  />
                  <Spacer factor={0} horizontalFactor={2} />
                </>
              )}
              <div
                className={cn(
                  isSmallScreenSize
                    ? css.lineItemHeader_mobile
                    : css.lineItemHeader,
                  isSmallScreenSize &&
                    undeletable &&
                    css.lineItemHeader_undeletableMobile,
                )}
              >
                <Type weight="bold" size="15px_DEPRECATED">
                  {attributesColumnName ||
                    t('modernComponents.UiUserAttributeEditor.attributes', {
                      context: attributesType,
                    })}
                </Type>
              </div>
              <div
                className={cn(
                  isSmallScreenSize ? css.lineItem_mobile : css.lineItem,
                  isSmallScreenSize &&
                    undeletable &&
                    css.lineItem_undeletableMobile,
                )}
              >
                <Type weight="bold" size="15px_DEPRECATED">
                  {attributeValueColumnName ||
                    t('AttributeGroupEditor-string-8242-value')}
                </Type>
              </div>
            </div>
          )}
          {this.createAttributesLinesBox()}
        </div>
        {!readOnly && this.createUndo()}
        {!readOnly && <PageVisibility onChange={this.handleVisibilityChange} />}
      </React.Fragment>
    );
  }
}

export const AttributeGroupEditor = withTranslation()(
  AttributeGroupEditorComponent,
);
