import { useCallback, useMemo, useState } from 'react';
import {
  AttributeErrorType,
  AttributesErrors,
  ImportUsersAttribute,
} from './types';
import { createNewUserAttribute } from './helpers/createNewUserAttribute';
import { clone } from 'ramda';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useCurrentBotId } from '@utils/Routing';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { VariablesType } from '@globals';
import { useUserDataCsvFile } from './useUserDataCsvFile';

const normalizeString = (name: string) => name.trim().toLowerCase();

export const useWhatsappImportUsersAttributes = () => {
  const botId = useCurrentBotId();
  const { attributes: botAttributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    undefined,
    undefined,
    true,
  );
  const [attributes, setAttributes] = useState<ImportUsersAttribute[]>([
    createNewUserAttribute(),
  ]);
  const { fieldsMap } = useUserDataCsvFile();

  const attributesErrors = useMemo(() => {
    const systemAttributesNames = botAttributes
      .filter(({ type }) => type === VariablesType.system)
      .map(({ name }) => normalizeString(name));
    return attributes.reduce((errors, attribute) => {
      const attributeName = normalizeString(attribute.name);
      const attributeValue = normalizeString(attribute.value);
      if (!attributeName && attributeValue) {
        return {
          ...errors,
          [attribute.id]: AttributeErrorType.emptyName,
        };
      }
      if (
        [...fieldsMap.values()].map(normalizeString).includes(attributeName)
      ) {
        return {
          ...errors,
          [attribute.id]: AttributeErrorType.useInMapping,
        };
      }
      if (systemAttributesNames.includes(attributeName)) {
        return {
          ...errors,
          [attribute.id]: AttributeErrorType.system,
        };
      }
      if (
        attributes.filter(({ name }) => normalizeString(name) === attributeName)
          .length > 1
      ) {
        return {
          ...errors,
          [attribute.id]: AttributeErrorType.duplicate,
        };
      }
      if (!attributeValue && attributeName) {
        return {
          ...errors,
          [attribute.id]: AttributeErrorType.emptyValue,
        };
      }
      return errors;
    }, {} as AttributesErrors);
  }, [attributes, botAttributes, fieldsMap]);

  const addAttribute = useCallback(() => {
    setAttributes((attributes) => [...attributes, createNewUserAttribute()]);
  }, []);

  const removeAttribute = useCallback((id: string) => {
    setAttributes((attributes) =>
      attributes.filter((attribute) => attribute.id !== id),
    );
  }, []);

  const updateAttribute = useCallback(
    (updatedAttribute: ImportUsersAttribute) => {
      setAttributes((attributes) => {
        const updatedAttributes = clone(attributes);
        const index = updatedAttributes.findIndex(
          ({ id }) => id === updatedAttribute.id,
        );
        if (index !== -1) {
          updatedAttributes.splice(index, 1, updatedAttribute);
        }
        return updatedAttributes;
      });
    },
    [],
  );

  return {
    attributes,
    addAttribute,
    removeAttribute,
    updateAttribute,
    attributesErrors,
  };
};
