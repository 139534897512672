import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { useCurrentBotId } from '@utils/Routing';
import { useCallback } from 'react';
import { openMessengerPage } from '../../FBPlugins/FBPluginsUtils';
import { useOneTimeRefPreLoaded } from './useOneTimeRefPreLoaded';

interface UseTestThisWithOneTimeRefArgs {
  blockId: string | undefined;
  initPageId?: string;
  anonymous?: boolean;
}

export const useTestThisWithOneTimeRef = ({
  blockId,
  initPageId,
  anonymous,
}: UseTestThisWithOneTimeRefArgs) => {
  const botId = useCurrentBotId();
  const { updateOneTimeRef, oneTimeRef } = useOneTimeRefPreLoaded({
    blockId,
    skip: !botId,
  });
  const { pageId: currentPageId } = usePageConnected(botId);
  const pageId = initPageId || currentPageId;
  const testThis = useCallback(() => {
    if (pageId) {
      openMessengerPage({ pageId, ref: oneTimeRef, anonymous });
      updateOneTimeRef(blockId);
    }
    return !!(pageId && oneTimeRef);
  }, [pageId, oneTimeRef, blockId, anonymous, updateOneTimeRef]);

  return {
    testThis,
    oneTimeRef,
  };
};
