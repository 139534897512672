import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';

import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';

import { ConnectInstagramDialogContent } from '../ConnectInstagramDialogContent';

interface ConnectInstagramFacebookPageErrorProps {
  onGoBack(): void;
}
export const ConnectInstagramFacebookPageError: React.FC<ConnectInstagramFacebookPageErrorProps> =
  ({ onGoBack }) => {
    const { t } = useSafeTranslation();
    const botId = useCurrentBotId();

    const handleConnectOtherAccount = () => {
      sendEvent({
        category: 'instagram connect popup',
        action: 'click create account',
        label: 'connect other page',
        propertyBag: { botId },
      });
      window.open(
        'https://help.instagram.com/155940534568753',
        '_blank',
        'noopener noreferrer',
      );
    };

    const handleGoBack = () => {
      onGoBack();
      sendEvent({
        category: 'instagram connect popup',
        action: 'click back button',
        label: 'connect other page',
        propertyBag: { botId },
      });
    };

    return (
      <ConnectInstagramDialogContent
        header={t('common.Instagram.dialog.igFbError.header')}
        footerLeft={
          <Button
            intent="secondary"
            icon={<Icon icon="arrowBack" size="14px" />}
            onClick={handleGoBack}
            data-testid="instagram-connect-dialog__fb-page__back-button"
          >
            {t('common.Instagram.dialog.igFbError.backButton')}
          </Button>
        }
        footerRight={
          <Button
            intent="primary"
            onClick={handleConnectOtherAccount}
            data-testid="instagram-connect-dialog__fb-page__create-button"
          >
            {t('common.Instagram.dialog.igFbError.createButton')}
          </Button>
        }
        data-testid="instagram-connect-dialog__fb-page"
      >
        <Type size="15px">
          {t('common.Instagram.dialog.igFbError.description')}
        </Type>
      </ConnectInstagramDialogContent>
    );
  };
