import React from 'react';
import { Translation } from 'react-i18next';
import gql from 'graphql-tag';
import cn from 'classnames';
import { Level, log } from 'cf-common/src/logger';
import { globalHistory, BotTabs } from '@utils/Routing';
import { getAdminFeatures } from '@utils/Data/Admin';
import { Icon } from '@ui/Icon';
import client from '../../../common/services/ApolloService';
import { SKILLS_GROUPS, SKILLS_LIST } from '../SkillsData';
import { ChooseFromListPopup as SkillsDialog } from '../../../modern-ui/ChooseFromListPopup';
import { AfterAddSkillDialog } from '../AfterAddSkillDialog';
import { Modal } from '../../../modern-ui/Modal';
import { HoverDisclosure } from '../../../modern-ui/HoverDisclosure';
import {
  serverStorageItemGet,
  serverStorageItemSet,
  ServerStorageItemKeys,
} from '../../../utils/ServerStorage';
import {
  BLOCKS_GROUP_FRAGMENT,
  BLOCKS_TITLES_QUERY,
} from '../../Aside/Mutations/GQL';
import { ReactComponent as PreBildFlowIcon } from './images/pre-bild-flow.svg';
import {
  AddSkillMutation,
  AddSkillMutation_addSkill as ISkillData,
} from './@types/AddSkillMutation';
import * as css from './AddSkill.css';
import { BlocksGroupsFragment } from './@types/BlocksGroupsFragment';
import { sendEvent } from '../../../utils/Analytics';
import { PLUGINS_FRAGMENT } from '../../Plugins/common/PluginGQL';

export interface IAddSkillProps {
  botId: string;
  blockId: string;
  enabled: boolean;
  onSkillAdded: (skillData: ISkillData) => void;
}

interface IAddSkillState {
  isAddSkillPopupOpen: boolean;
  isAfterAddSkillPopupOpen: boolean;
  isFlowTemplatesFeature: boolean;
}

const ADD_SKILL_MUTATION = gql`
  mutation AddSkillMutation($blockId: String!, $skillId: String!) {
    addSkill(blockId: $blockId, skillId: $skillId) {
      block {
        id
        cards {
          ...pluginsFragment
        }
      }
      groups {
        ...blocksGroupFragment
      }
    }
  }
  ${PLUGINS_FRAGMENT}
  ${BLOCKS_GROUP_FRAGMENT}
`;

const BLOCKS_GROUPS_FRAGMENT = gql`
  fragment BlocksGroupsFragment on Bot {
    blocksGroups {
      ...blocksGroupFragment
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
`;

const LIMIT_COUNT_OF_SKILL_ADDED_POPUP = 1;

export class AddSkill extends React.Component<IAddSkillProps, IAddSkillState> {
  state = {
    isAddSkillPopupOpen: false,
    isAfterAddSkillPopupOpen: false,
    isFlowTemplatesFeature: false,
  };

  countOfSkillAddedPopup: number | undefined;

  componentDidMount() {
    this.loadCountOfSkillAddedPopup();
    this.loadFeatures();
  }

  addSkill = async (skillId: string, botId: string) => {
    const { blockId, onSkillAdded } = this.props;

    this.setState({
      isAddSkillPopupOpen: false,
    });

    if (
      this.countOfSkillAddedPopup !== undefined &&
      this.countOfSkillAddedPopup < LIMIT_COUNT_OF_SKILL_ADDED_POPUP
    ) {
      this.setState({
        isAfterAddSkillPopupOpen: true,
      });
      this.incCountOfSkillAddedPopup();
    }

    const { data } = await client.mutate<AddSkillMutation>({
      mutation: ADD_SKILL_MUTATION,
      variables: {
        skillId,
        blockId,
      },
      update: (cache, { data }) => {
        if (!data) {
          return;
        }
        let blocksGroupsFragment: BlocksGroupsFragment | null = null;

        try {
          // blocksGroups for BlocksGroupsFragment may be undefined (query is loading)
          blocksGroupsFragment = cache.readFragment({
            id: `Bot:${botId}`,
            fragment: BLOCKS_GROUPS_FRAGMENT,
            fragmentName: 'BlocksGroupsFragment',
          });
        } catch (e) {
          blocksGroupsFragment = null;
        }

        if (blocksGroupsFragment) {
          cache.writeFragment({
            id: `Bot:${botId}`,
            fragment: BLOCKS_GROUPS_FRAGMENT,
            fragmentName: 'BlocksGroupsFragment',
            data: {
              ...blocksGroupsFragment,
              blocksGroups: [
                ...blocksGroupsFragment.blocksGroups,
                ...data.addSkill.groups,
              ],
            },
          });
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: BLOCKS_TITLES_QUERY,
          variables: {
            botId,
          },
        },
      ],
    });

    if (!data || !data.addSkill) {
      log({ msg: 'No add skill', level: Level.warn });
      return;
    }

    onSkillAdded(data!.addSkill);
  };

  closeAddSkillPopup = () => {
    this.setState({
      isAddSkillPopupOpen: false,
    });
  };

  closeAfterAddSkillPopup = () => {
    this.setState({
      isAfterAddSkillPopupOpen: false,
    });
  };

  incCountOfSkillAddedPopup = () => {
    if (this.countOfSkillAddedPopup !== undefined) {
      this.countOfSkillAddedPopup = this.countOfSkillAddedPopup + 1;
      serverStorageItemSet(
        ServerStorageItemKeys.countOfSkillAddedPopup,
        this.countOfSkillAddedPopup,
      );
    }
  };

  loadCountOfSkillAddedPopup = async () => {
    this.countOfSkillAddedPopup = await serverStorageItemGet(
      ServerStorageItemKeys.countOfSkillAddedPopup,
    );
  };

  loadFeatures = async () => {
    const { flow_templates, flow_templates_modal } = await getAdminFeatures();

    this.setState({
      isFlowTemplatesFeature: flow_templates && flow_templates_modal,
    });
  };

  openAddSkillPopup = () => {
    if (this.state.isFlowTemplatesFeature) {
      sendEvent({
        category: 'prebuilt flow v2',
        action: 'click',
      });
      globalHistory.push(`/bot/${this.props.botId}/${BotTabs.flows}/new`);
      return;
    }

    this.setState({
      isAddSkillPopupOpen: true,
    });

    sendEvent({
      category: 'prebuilt flow',
      action: 'click',
    });
  };

  render() {
    const {
      isAddSkillPopupOpen,
      isAfterAddSkillPopupOpen,
      isFlowTemplatesFeature,
    } = this.state;
    const { enabled, botId } = this.props;

    return (
      <Translation>
        {(t) => (
          <React.Fragment>
            <HoverDisclosure
              render={({ isVisible, bind }) => (
                <div className={css.box}>
                  {!isFlowTemplatesFeature && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      {...bind}
                      onClick={this.openAddSkillPopup}
                      className={cn(css.addSkillButton, {
                        [css.disabled]: !enabled,
                      })}
                    >
                      <PreBildFlowIcon className={css.addSkillButtonIcon} />
                      <div className={css.prebuildLabel}>
                        {t('modernComponents.Skills.AddSkill.title')}
                      </div>
                    </div>
                  )}
                  {isFlowTemplatesFeature && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      {...bind}
                      onClick={this.openAddSkillPopup}
                      className={cn(css.addSkillButton2, {
                        [css.disabled]: !enabled,
                      })}
                    >
                      <Icon icon="template" color="white" />
                      <div className={cn(css.prebuildLabel2)}>
                        {t('modernComponents.Skills.AddSkill.title2')}
                      </div>
                    </div>
                  )}
                  {isVisible && (
                    <div className={css.tooltip}>
                      <span>
                        {isFlowTemplatesFeature
                          ? t(
                              'modernComponents.Skills.AddSkill.flowDescription2',
                            )
                          : t(
                              'modernComponents.Skills.AddSkill.flowDescription',
                            )}
                      </span>
                    </div>
                  )}
                </div>
              )}
            />

            {isAddSkillPopupOpen && (
              <Modal onDismiss={this.closeAddSkillPopup}>
                <SkillsDialog
                  onRequestClose={this.closeAddSkillPopup}
                  onConfirmSelection={(skillId?: string) => {
                    if (skillId) {
                      this.addSkill(skillId, botId);
                      sendEvent({
                        category: 'prebuilt flow',
                        action: 'use',
                        label: skillId,
                        propertyBag: {
                          skillId,
                        },
                      });
                    }
                  }}
                  items={SKILLS_LIST()}
                  groups={SKILLS_GROUPS()}
                  buttonText={window.i18next.t(
                    'AddSkill-string-2066-add-skill-to-the-bot',
                  )}
                  dialogHeading={window.i18next.t(
                    'AddSkill-string-5140-add-skill',
                  )}
                />
              </Modal>
            )}

            {isAfterAddSkillPopupOpen && (
              <Modal onDismiss={this.closeAfterAddSkillPopup}>
                <AfterAddSkillDialog
                  onRequestClose={this.closeAfterAddSkillPopup}
                />
              </Modal>
            )}
          </React.Fragment>
        )}
      </Translation>
    );
  }
}
