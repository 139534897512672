import React from 'react';
import i18n from 'i18next';
import { Type } from '@ui/Type';
import { Anchor } from '@ui/Links';
import { ReactComponent as ReferralImage } from '../../images/referral.svg';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon } from '@ui/Icon';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import cn from 'classnames';
import { getCookie } from 'cf-analytics/src/analytics/utils';
import { ReferralTooltip } from './ReferralTooltip';
import * as css from './ReferralDescription.css';

const REFERRAL_LP = 'https://referral.chatfuel.com';
const REFERRAL_LP_MALAYSIA = 'https://referral-malaysia.chatfuel.com';
const REFERRAL_MANUAL =
  'https://docs.chatfuel.com/en/articles/6834431-referral-promo-codes';

export const ReferralDescription = ({
  isParticipating,
}: {
  isParticipating: boolean;
}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const targetLanding =
    getCookie('country') === 'MY' || i18n.language === 'ms'
      ? REFERRAL_LP_MALAYSIA
      : REFERRAL_LP;

  return (
    <>
      <ReferralImage className={css.headerImage} />

      <div className={cn(css.content, { [css.halfWidth]: isParticipating })}>
        <Type
          color="white"
          weight="bold"
          size={isSmallScreenSize ? '24px' : '32px'}
          whiteSpace="break-spaces"
        >
          {t('modernComponents.ReferralProgram.description.header')}
          <ReferralTooltip />
        </Type>

        <Type size="15px" color="white">
          <ol className={css.list}>
            <li>
              {t('modernComponents.ReferralProgram.description.process1')}
            </li>
            <li>
              {t('modernComponents.ReferralProgram.description.process2')}
            </li>
            <li>
              {t('modernComponents.ReferralProgram.description.process3')}
              <b>
                {' '}
                &#39;
                {t(
                  'modernComponents.ReferralProgram.description.process3-accent',
                )}{' '}
                <Anchor
                  href={targetLanding}
                  hideArrow
                  className={css.inlineLink}
                  target="_blank"
                >
                  {t(
                    'modernComponents.ReferralProgram.description.process-lp-link',
                  )}
                </Anchor>
                &#39;
              </b>
            </li>
            <li>
              {t('modernComponents.ReferralProgram.description.process4-1')}
              <b>
                {' '}
                {t(
                  'modernComponents.ReferralProgram.description.process4-accent',
                )}{' '}
                <Anchor
                  href={targetLanding}
                  hideArrow
                  className={css.inlineLink}
                  target="_blank"
                >
                  {t(
                    'modernComponents.ReferralProgram.description.process-lp-link',
                  )}
                </Anchor>
              </b>{' '}
              {t('modernComponents.ReferralProgram.description.process4-2')}
            </li>
            <li>
              {t('modernComponents.ReferralProgram.description.process5')}
            </li>
          </ol>
        </Type>

        <Anchor href={REFERRAL_MANUAL} className={css.tosLink} target="_blank">
          {t('modernComponents.ReferralProgram.description.tos')}
        </Anchor>

        {isParticipating && (
          <div>
            <div className={css.paticipating}>
              <Icon icon="check" noColor />
              {t('modernComponents.ReferralProgram.description.participating')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
