import gql from 'graphql-tag';
import { useLazyQuery, useMutation } from 'react-apollo';
import { AdPostsQuery, AdPostsQueryVariables } from './@types/AdPostsQuery';
import {
  RemoveCommentAutoreplyAd,
  RemoveCommentAutoreplyAdVariables,
} from './@types/RemoveCommentAutoreplyAd';

export const AD_POSTS_QUERY = gql`
  query AdPostsQuery(
    $botId: String!
    $accountId: String!
    $limit: Int
    $after: String
  ) {
    commentsAutoreplyAds(
      botId: $botId
      adAccountId: $accountId
      limit: $limit
      after: $after
    ) {
      data {
        id
        name
        thumbnailUrl
        createdAt
        updatedAt
        entryPointId
        flowId
        flowTitle
        pageId
      }
      after
    }
  }
`;

export const useAdPosts = () => {
  return useLazyQuery<AdPostsQuery, AdPostsQueryVariables>(AD_POSTS_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
};

const REMOVE_AD_MUTATION = gql`
  mutation RemoveCommentAutoreplyAd($botId: String!, $adId: String!) {
    removeCommentAutoreplyAd(botId: $botId, adId: $adId) {
      id
      name
      thumbnailUrl
      createdAt
      updatedAt
      entryPointId
      flowId
      flowTitle
      pageId
    }
  }
`;

export const useMoveAdMutation = () => {
  return useMutation<
    RemoveCommentAutoreplyAd,
    RemoveCommentAutoreplyAdVariables
  >(REMOVE_AD_MUTATION);
};
