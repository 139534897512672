import { useEffect } from 'react';
import { sendEvent } from '@utils/Analytics';
import {
  bootIntercom,
  loadIntercom,
  $intercomOpenStatus,
} from '@utils/Intercom';
import { useIsFirstSessionTab } from '@utils/Routing';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { setHideIntercomDefaultLauncher } from '@utils/Intercom/Intercom';

const sendShowHideEvent = (isShow: boolean) => {
  sendEvent({
    category: 'support',
    action: isShow ? 'show intercom' : 'hide intercom',
  });
};

export const useStartIntercom = () => {
  const isFirstSessionTab = useIsFirstSessionTab();
  const { isSmallScreenSize } = useDeviceMedia();
  const hideIntercomDefaultLauncher = isFirstSessionTab || isSmallScreenSize;

  useEffect(() => {
    loadIntercom();
    bootIntercom();
  }, []);

  useEffect(() => {
    setHideIntercomDefaultLauncher(hideIntercomDefaultLauncher);
  }, [hideIntercomDefaultLauncher]);

  useEffect(() => {
    const subscription = $intercomOpenStatus.subscribe((isShow) => {
      sendShowHideEvent(isShow);
    });
    return () => subscription.unsubscribe();
  }, []);
};
