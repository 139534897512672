import React from 'react';
import ng from 'angular';
import { react2angular } from 'react2angular';
import { ApolloProvider } from '@apollo/react-common';
import { SponsoredMessageBanner } from './SponsoredMessageBanner';
import client from '../../common/services/ApolloService';

const SponsoredMessageBannerWrapper: React.FC = (props) => (
  <ApolloProvider client={client}>
    <SponsoredMessageBanner {...props} />
  </ApolloProvider>
);

export const ngSponsoredMessageBanner = ng
  .module('app.modernComponents.sponsoredMessageBanner', [])
  .component(
    'sponsoredMessageBanner',
    react2angular(SponsoredMessageBannerWrapper, [], []),
  ).name;
