import React, { CSSProperties } from 'react';
import { DynamicListView } from '../../../DynamicListView';
import { StateItemId } from '../consts';
import * as css from '../ChooseItemFromListDialog.css';
import { ItemLoadingPlaceholder } from './ItemLoadingPlaceholder';

interface RenderItemParams<T> {
  post: T;
  style: CSSProperties;
}

export interface PostsListProps<T> {
  loading: boolean;
  loadMore(): Promise<any>;
  currentPosts: (T | { id: string })[];
  notFoundState: React.ReactNode;
  invalidUrlState: React.ReactNode;
  renderPost(params: RenderItemParams<T>): React.ReactNode;
  hideCheckboxLoader?: boolean;
  height?: number;
}

export function ItemList<T extends { id: string }>({
  loading,
  loadMore,
  currentPosts,
  notFoundState,
  invalidUrlState,
  renderPost,
  hideCheckboxLoader,
  height,
}: PostsListProps<T>) {
  return (
    <div className={css.listBox}>
      <DynamicListView
        height={height || 360}
        width="100%"
        itemSize={52}
        isItemLoaded={() => loading}
        itemCount={currentPosts.length}
        threshold={20}
        loadMoreItems={loadMore}
      >
        {({ index, style }) => {
          const item = currentPosts[index];
          if (item.id === StateItemId.spacer) {
            return <div style={style} />;
          }
          if (item.id === StateItemId.loader) {
            return (
              <ItemLoadingPlaceholder
                style={style}
                hideCheckboxLoader={hideCheckboxLoader}
              />
            );
          }
          if (item.id === StateItemId.notFound) {
            return <>{notFoundState}</>;
          }
          if (item.id === StateItemId.invalidUrl) {
            return <>{invalidUrlState}</>;
          }

          return <>{renderPost({ post: item as T, style })}</>;
        }}
      </DynamicListView>
    </div>
  );
}
