import automateFaq from './images/automate_faq.png';
import exportInfo from './images/export_info.png';
import humanTakeover from './images/human_takeover.png';
import phoneAndEmail from './images/phone_and_email.png';
import qualifyLeads from './images/qualify_leads.png';

export const SKILLS_GROUPS = () => [
  {
    id: 'faq',
    title: window.i18next.t(
      'Skills-string--840-save-time-by-automating-responses-to-fa-qs',
    ),
  },
  {
    id: 'sales',
    title: window.i18next.t(
      'Skills-string-1342-increase-sales-using-messenger',
    ),
  },
];

export const SKILLS_LIST = () => [
  {
    id: 'AUTOMATE_FAQ',
    groupId: 'faq',
    title: window.i18next.t(
      'Skills-string--449-automate-frequently-asked-questions',
    ),
    text: window.i18next.t(
      'Skills-string--129-reduce-your-teams-manual-workload-improve-customer-wait-time-automate-3-10-fa-qs-that-contribute-to-50-or-more-of-all-incoming-requests-to-your-facebook-page-inbox',
    ),
    picture: automateFaq,
  },
  {
    id: 'PHONE',
    groupId: 'sales',
    title: window.i18next.t(
      'Skills-string-1427-collect-clients-emails-phone-numbers',
    ),
    text: window.i18next.t(
      'Skills-string-2079-build-your-contact-list-faster-with-less-effort-messenger-makes-it-easy-for-people-to-share-their-contact-details-with-you-in-one-click-you-can-export-this-info-to-your-crm-and-use-it-in-your-sales-and-marketing-process',
    ),
    picture: phoneAndEmail,
  },
  {
    id: 'QUALIFY',
    groupId: 'sales',
    title: window.i18next.t('Skills-string--455-qualify-leads'),
    text: window.i18next.t(
      'Skills-string-1570-get-higher-quality-leads-and-improve-sales-the-bot-asks-prospects-qualifying-questions-and-segments-out-the-most-valuable-ones-for-you-from-there-you-can-take-over-the-conversation-from-the-bot-to-close-the-deal-or-send-the-lead-info-to-your-crm',
    ),
    picture: qualifyLeads,
  },
  {
    id: 'HUMAN',
    groupId: 'sales',
    title: window.i18next.t('Skills-string--175-human-takeover'),
    text: window.i18next.t(
      'Skills-string--106-improve-sales-in-messenger-by-personally-chatting-with-high-value-leads-identify-people-that-are-stuck-mid-funnel-in-your-bot-and-help-push-them-forward-manually-use-this-skill-with-the-qualify-leads-skill-for-the-best-results',
    ),
    picture: humanTakeover,
  },
  {
    id: 'EXPORT_INFO',
    groupId: 'sales',
    title: window.i18next.t('Skills-string--486-export-info'),
    text: window.i18next.t(
      'Skills-string-8749-react-to-information-your-bot-collects-faster-and-close-leads-while-theyre-hot-send-an-email-to-your-sales-rep-immediately-after-the-bot-qualifies-the-prospect-export-contact-properties-to-a-google-sheet-to-analyze-and-process',
    ),
    picture: exportInfo,
  },
];
