import React, { useEffect } from 'react';
import { Modal } from '@ui/Modal';
import { DefaultDialog, Dialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import { PaymentFlowState } from '@utils/Payment/constants';
import { Payments } from '../Payments';
import { usePremiumPayment } from './hooks/usePremiumPayment';

interface DefaultPaymentModalProps {
  header: string;
  submitText: string;
  onDismiss: () => void;
  onSuccess: () => void;
}

export const DefaultPaymentModal: React.FC<DefaultPaymentModalProps> = ({
  children,
  header,
  submitText,
  onDismiss,
  onSuccess,
}) => {
  const {
    stripeIsReady,
    paymentFlowState,
    link3DSecure,
    errorMessage,
    submitPayment,
  } = usePremiumPayment();

  useEffect(() => {
    if (paymentFlowState === PaymentFlowState.success) {
      onSuccess();
    }
  }, [onSuccess, paymentFlowState]);

  const paymentIsSubmitting =
    paymentFlowState !== PaymentFlowState.idle &&
    paymentFlowState !== PaymentFlowState.error;

  const iframe3DSecureIsShown =
    link3DSecure && paymentFlowState === PaymentFlowState.redirecting3DSecure;
  return (
    <Modal onDismiss={onDismiss}>
      {iframe3DSecureIsShown ? (
        <Dialog style={{ width: '500px', overflow: 'hidden' }}>
          <iframe
            width={500}
            height={600}
            src={link3DSecure}
            title="3dsIframe"
            frameBorder={0}
            style={{ backgroundColor: 'white' }}
          />
        </Dialog>
      ) : (
        <DefaultDialog header={header} onDismiss={onDismiss}>
          {children}
          <Spacer factor={4} />
          <Payments
            ready={stripeIsReady}
            submitting={paymentIsSubmitting}
            onSubmit={submitPayment}
            submitText={submitText}
            formErrorMessage={errorMessage}
          />
        </DefaultDialog>
      )}
    </Modal>
  );
};
