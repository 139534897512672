import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';

export const FinishedForm = ({ onClose }: { onClose: () => any }) => {
  const { t } = useSafeTranslation();

  return (
    <div>
      <Type weight="bold" size="32px" as="div">
        {t('modernComponents.ReferralProgram.formFinished.header')}
      </Type>
      <Spacer factor={3} />

      <Type as="div">
        {t('modernComponents.ReferralProgram.formFinished.text')}
      </Type>
      <Spacer factor={3} />

      <Flex justifyContent="flex-end">
        <Button onClick={onClose}>
          {t('modernComponents.ReferralProgram.formFinished.button')}
        </Button>
      </Flex>
    </div>
  );
};
