import React from 'react';
import { Modal } from '@services/index';
import { ConnectStripeDialog } from './ConnectStripeDialog';

export const showConnectStripeDialog = () => (
  Modal.show(({ close }) => (
    <ConnectStripeDialog
      onDismiss={close}
    />
  ), {
    id: 'ConnectStripeDialog',
  })
)