import React, { useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Tooltip2 } from '@ui/Tooltip2';
import { Type } from '@ui/Type';
import { Badge, Kind } from '@ui/Badge';
import { sendEvent } from '@utils/Analytics';

interface TooltipTextProps {
  flowsUsingIt?: string[];
}

const TooltipText: React.FC<TooltipTextProps> = ({ flowsUsingIt }) => {
  const { t } = useSafeTranslation();

  const text = t('modernComponents.UiUserAttributeEditor.FlowsBadge.info', {
    count: flowsUsingIt?.length,
  });

  useEffect(() => {
    sendEvent({
      category: 'attributes editor',
      action: 'show tooltip',
      propertyBag: {
        text,
      },
    });
  }, [text]);

  return (
    <Type as="p" size="12px" color="white">
      {text}
    </Type>
  );
};

interface FlowsBadgeProps {
  flowsUsingIt?: string[];
}

export const FlowsBadge: React.FC<FlowsBadgeProps> = ({ flowsUsingIt }) => {
  if ((flowsUsingIt?.length ?? 0) <= 1) return null;

  return (
    <Tooltip2
      placement="right"
      boundariesElement="viewport"
      content={<TooltipText />}
      type="small"
      positionFixed
    >
      {(ref, bind) => (
        <Badge ref={ref} kind={Kind.primary} circle {...bind}>
          {flowsUsingIt?.length}
        </Badge>
      )}
    </Tooltip2>
  );
};
