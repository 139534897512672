import React from 'react';
import { Icon } from '@ui/Icon';
import { Tooltip2, TooltipPureProps } from '@ui/Tooltip2';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import {
  WorkspaceConversationWrapper,
  WorkspaceConversationWrapperProps,
} from '../WorkspaceConversationWrapper/WorkspaceConversationWrapper';
import css from './WorkspaceAnchorStatistics.css';

export interface WorkspaceAnchorStatistics
  extends WorkspaceConversationWrapperProps,
    Omit<TooltipPureProps, 'children'> {
  chargingEntityLabel: string;
}

export const WorkspaceAnchorStatistics: React.FC<WorkspaceAnchorStatistics> = ({
  count,
  maxCount,
  className,
  chargingEntityLabel,
  ...tooltipProps
}) => (
  <WorkspaceConversationWrapper
    count={count}
    maxCount={maxCount}
    className={className}
  >
    <Tooltip2 {...tooltipProps}>
      {(ref, bind) => (
        <Flex
          ref={ref}
          {...bind}
          alignItems="center"
          className={css.tooltipWrapper}
        >
          <Type
            size="15px"
            weight="regular"
            color="greyDark"
            data-testid="workspace_bots_table__charging_entity"
          >
            {chargingEntityLabel}
          </Type>
          <Spacer horizontalFactor={1} factor={0} />
          <Icon icon="info" color="greyDark" size="24px" />
        </Flex>
      )}
    </Tooltip2>
  </WorkspaceConversationWrapper>
);
