import gql from 'graphql-tag';

export const SHOPIFY_DISCOUNTS_CODES_QUERY = gql`
  query ShopifyDiscountsCodesQuery($botId: ID!) {
    shopifyDiscountsCodes(botId: $botId) {
      id
      code
      discount_type
      price_rule_id
      value
      value_type
      usage_limit
      once_per_customer
      start_date
      end_date
      shop_id
    }
  }
`;

export const SHOPIFY_DISCOUNTS_CODES_CREATE_MUTATION = gql`
  mutation ShopifyDiscountsCodesCreateMutation(
    $botId: ID!
    $shopifyDiscount: ShopifyDiscountsCodeInput!
  ) {
    createShopifyDiscount(botId: $botId, shopifyDiscount: $shopifyDiscount) {
      id
      code
      discount_type
      price_rule_id
      value
      value_type
      usage_limit
      once_per_customer
      start_date
      end_date
    }
  }
`;

export const SHOPIFY_DISCOUNTS_CODES_UPDATE_MUTATION = gql`
  mutation ShopifyDiscountsCodesUpdateMutation(
    $botId: ID!
    $shopifyDiscount: ShopifyDiscountsCodeUpdateInput!
  ) {
    updateShopifyDiscount(botId: $botId, shopifyDiscount: $shopifyDiscount) {
      id
      code
      discount_type
      price_rule_id
      value
      value_type
      usage_limit
      once_per_customer
      start_date
      end_date
    }
  }
`;

export const SHOPIFY_DISCOUNTS_CODES_DELETE_MUTATION = gql`
  mutation ShopifyDiscountsCodesDeleteMutation(
    $botId: ID!
    $priceRuleId: String!
  ) {
    deleteShopifyDiscount(botId: $botId, priceRuleId: $priceRuleId)
  }
`;
