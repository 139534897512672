import React, { useMemo } from 'react';
import { intersection } from 'ramda';
import OutsideClick from 'react-outsideclick';
import { StepProps } from '../types';
import { Callout } from '@ui/Callout';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { WhatsappImportUserDialogFooter } from '../../components/WhatsappImportUserDialogFooter';
import { Trans } from 'react-i18next';
import { Flex } from '@ui/Flex';
import { ScrollBox } from '@ui/ScrollBox';
import * as css from './MapFieldsStep.css';
import { useUserDataCsvFile } from '../../hocks/useUserDataCsvFile';
import { Type } from '@ui/Type';
import { AttributeInput } from '../../../../../AttributeInput';
import { useAttributes } from '@utils/AttributesUtils/AttributesData';
import { useCurrentBotId } from '@utils/Routing';
import { VariableSuggestType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { Platform, VariablesType } from '@globals';
import noop from 'lodash-es/noop';
import { Icon } from '@ui/Icon';
import { Input } from '@ui/Input';
import {
  WHATSAPP_PHONE_ATTRIBUTE,
  WHATSAPP_PHONE_ATTRIBUTE_NAME,
} from '../../consts';
import { Tooltip2 } from '@ui/Tooltip2';
import { PREVENT_INTERPOLATE } from '@utils/I18next/consts';
import { hasDuplicates } from 'cf-common/src/utils/Array/hasDuplicates';

export const MapFieldsStep: React.FC<StepProps> = ({ onBack, onDone }) => {
  const { t } = useSafeTranslation();
  const {
    fields,
    fieldsMap,
    setFieldMap,
    isWhatsappPhoneMapped,
    isWhatsappPhoneAutoMapped,
    resetIsWhatsappPhoneAutoMapped,
  } = useUserDataCsvFile();
  const botId = useCurrentBotId();
  const { attributes } = useAttributes(
    botId,
    VariableSuggestType.template,
    undefined,
    undefined,
    true,
  );

  const specificAttributes = useMemo(
    () =>
      attributes
        .filter(({ type }) => type === VariablesType.custom)
        .concat([WHATSAPP_PHONE_ATTRIBUTE]),
    [attributes],
  );

  const systemAttributesNames = useMemo(
    () =>
      attributes
        .filter(
          ({ type, name }) =>
            type === VariablesType.system &&
            name !== WHATSAPP_PHONE_ATTRIBUTE_NAME,
        )
        .map(({ name }) => name),
    [attributes],
  );

  const hasAttributeNameDuplicates = useMemo(
    () =>
      hasDuplicates(
        [...fieldsMap.values()].map((item) => item.trim()).filter(Boolean),
      ),
    [fieldsMap],
  );

  const hasSystemAttributes = useMemo(
    () =>
      intersection([...fieldsMap.values()], systemAttributesNames).length > 0,
    [fieldsMap, systemAttributesNames],
  );

  return (
    <>
      <Callout
        text={t('modernComponents.WhatsAppImportUser.dialog.MapFields.info1')}
        type="info"
      />
      <Spacer factor={4} />
      {isWhatsappPhoneMapped ? (
        <Callout
          text={
            <Trans
              i18nKey="modernComponents.WhatsAppImportUser.dialog.MapFields.info3"
              values={{ attributeName: WHATSAPP_PHONE_ATTRIBUTE_NAME }}
              tOptions={PREVENT_INTERPOLATE}
            />
          }
          type="info"
        />
      ) : (
        <Callout
          text={
            <Trans
              i18nKey="modernComponents.WhatsAppImportUser.dialog.MapFields.info2"
              values={{ attributeName: WHATSAPP_PHONE_ATTRIBUTE_NAME }}
              tOptions={PREVENT_INTERPOLATE}
            />
          }
          type="warning"
        />
      )}
      <Spacer factor={4} />
      <Flex gapFactor={4}>
        <Type size="12px" weight="semibold" className={css.col}>
          {t(
            'modernComponents.WhatsAppImportUser.dialog.MapFields.columnsExample',
          )}
        </Type>
        <Type size="12px" weight="semibold" className={css.col}>
          {t('modernComponents.WhatsAppImportUser.dialog.MapFields.fields')}
        </Type>
      </Flex>
      <Spacer factor={1} />
      <ScrollBox
        className={css.colBox}
        data-testid="import-contacts__mapping-table"
      >
        <Spacer factor={1} />
        {fields?.map((field, fieldIndex) => {
          const attributeName = fieldsMap.get(fieldIndex);
          const attributeError =
            attributeName &&
            (Array.from(fieldsMap.values()).filter(
              (value) => value.trim() === attributeName.trim(),
            ).length > 1
              ? t(
                  'modernComponents.WhatsAppImportUser.dialog.MapFields.alreadyInUse',
                )
              : systemAttributesNames.includes(attributeName.trim())
              ? t(
                  'modernComponents.WhatsAppImportUser.dialog.MapFields.systemNotUse',
                )
              : undefined);

          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={fieldIndex}>
              <Flex
                gapFactor={4}
                data-testid="import-contacts__mapping-table__row"
              >
                <div className={css.col}>
                  <Input
                    value={field}
                    readOnly
                    title={field}
                    data-testid="import-contacts__column-name"
                    onChange={noop}
                    renderIconEnd={() => (
                      <Icon
                        icon="rightArrow"
                        color={attributeName ? 'greenDark10' : 'grey'}
                      />
                    )}
                  />
                </div>
                <div className={css.col}>
                  <AttributeInput
                    placeholder={t(
                      'modernComponents.WhatsAppImportUser.dialog.MapFields.placeholder',
                    )}
                    platform={Platform.whatsapp}
                    value={attributeName || ''}
                    onChange={(attributeName) => {
                      setFieldMap(fieldIndex, attributeName);
                    }}
                    shouldShowTagsButton
                    attributes={specificAttributes}
                    renderIconEnd={() => {
                      const needShowTooltip =
                        isWhatsappPhoneAutoMapped &&
                        attributeName === WHATSAPP_PHONE_ATTRIBUTE_NAME;
                      return attributeName ? (
                        <Tooltip2
                          disabled={!needShowTooltip}
                          content={t(
                            'modernComponents.WhatsAppImportUser.dialog.ReviewChanges.tooltip',
                            PREVENT_INTERPOLATE,
                          )}
                          show={needShowTooltip}
                          boundariesElement="viewport"
                          placement="right"
                        >
                          {(ref, bind, scheduleUpdateRef) => {
                            if (needShowTooltip) {
                              setTimeout(() => {
                                scheduleUpdateRef.current?.(); // reposition tooltip after rendering
                              });
                            }
                            return (
                              <OutsideClick
                                onClickOutside={() => {
                                  resetIsWhatsappPhoneAutoMapped();
                                }}
                              >
                                <Icon
                                  icon="check_in_circle"
                                  color="green"
                                  ref={ref}
                                  {...bind}
                                />
                              </OutsideClick>
                            );
                          }}
                        </Tooltip2>
                      ) : null;
                    }}
                    inputStyle={{
                      maxWidth: 180,
                    }}
                    error={!!attributeError}
                  />
                  {attributeError && (
                    <>
                      <Spacer factor={1} />
                      <Type
                        size="12px"
                        color="red"
                        data-testid="import-contacts__attribute-error"
                      >
                        {attributeError}
                      </Type>
                    </>
                  )}
                </div>
              </Flex>
              <Spacer factor={2} />
            </React.Fragment>
          );
        })}
      </ScrollBox>

      <WhatsappImportUserDialogFooter
        showBackButton
        onNextStepButtonClick={onDone}
        onBackButtonClick={onBack}
        disabled={
          !isWhatsappPhoneMapped ||
          hasAttributeNameDuplicates ||
          hasSystemAttributes
        }
      />
    </>
  );
};
