import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import nanoid from 'nanoid';
import cn from 'classnames';
import {
  GroupingCombobox,
  GroupingCombobox_Group,
} from '@components/GroupingCombobox/GroupingCombobox';
import ScrollBox from '@ui/ScrollBox/ScrollBox';
import { sendEvent } from '@utils/Analytics';
import { Input } from '@ui/Input';
import { ButtonUnstyled } from '@ui/Button/Unstyled';
import { ReactComponent as AddIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import {
  Button as ButtonOld,
  ButtonSize,
  ButtonIntent,
} from '../../modern-ui/_deprecated/Button';
import { Button } from '@ui/Button';
import {
  AttributeGroupEditor,
  JS_ATTRIBUTES_BOX_CLASS,
} from './AttributeGroupEditor';
import { UserAttribute, UserAttributeType } from './UserAttributeTypes';
import { ReactComponent as SearchIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_search.svg';
import { ReactComponent as RemoveIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_trash.svg';
import {
  getAttributesByType,
  getAvailableForDeleteSelectedAttributesNames,
  getFilteredAttributes,
} from './utils';
import * as css from './UiUserAttributeEditor.module.less';
import i18next from 'i18next';

interface FlowFilter {
  groups: GroupingCombobox_Group[];
  selectedFlowId: string | null;
  onSelectFlowId(flowId: string | null): void;
}

export interface IUiUserAttributeEditorProps {
  attributes: UserAttribute[];
  onChange: (attributes: UserAttribute[]) => void;
  onAddAttr?: (attributes: UserAttribute[]) => void;
  attributesSuggestsName: string[];
  attributesSuggestsValues: string[];
  userName?: string;
  fixedAddButton?: boolean;
  hideFloatButton?: boolean;
  readOnly?: boolean;
  onRequestAttributeValuesLoad?: (attributeName: string, value: string) => void;
  // NOTE:
  // TODO:
  // This element should not be responsible for setting its own height
  // and for setting up its own scroll behavior.
  // The consumer element should take care of that. Then we wouldn't need to
  // pass arbitrary style props as we do here.
  scrollBoxStyle?: React.CSSProperties;
  onSaveRequest?: (attributes: UserAttribute[]) => void;
  onBulkDeleteRequest?: (names: string[]) => void;
  upgradeTitle?: boolean;
  onUpgradeToProClick?: () => void;
  onDismissModal?: () => void;
  excludeSystemAttributes?: boolean;
  withSearch?: boolean;
  flowFilter?: FlowFilter;
  attributeValuePlaceholder?: string;
  attributeValueColumnName?: string;
  undeletable?: boolean;
  emptyListComponent?: React.ReactNode;
  notFoundComponent?: React.ReactNode;
  leftSideAddButton?: boolean;
  isSmallScreenSize?: boolean;
  isSearchBoxNarrow?: boolean;
  showBulkRemoving?: boolean;
}

export interface IUiUserAttributeEditorState {
  showBottomButton: boolean;
  search: string;
  selectedAttributesNames: string[];
}

const SCROLL_BOX_ID = 'scroll-box';
const ADD_BUTTON_MIN_BOTTOM_POSITION = 16;

const DEFAULT_SCROLL_BOX_STYLES: React.CSSProperties = {
  position: 'static',
  overflowX: 'initial', // otherwise scroll hardly works for some reason
  paddingBottom: 32, // Make space for buttons
};

const ALL_FLOWS_ID = 'all_flows';

const allFlows = () => [
  {
    id: ALL_FLOWS_ID,
    title: i18next.t('UiUserAttributeEditor-string--330-all-attributes'),
  },
];

export class UiUserAttributeEditor extends React.Component<
  IUiUserAttributeEditorProps,
  IUiUserAttributeEditorState
> {
  state = {
    showBottomButton: !!this.props.fixedAddButton,
    search: '',
    selectedAttributesNames: [] as string[],
  };

  elAddButtonBox: HTMLDivElement | null = null;

  elAddButton: HTMLButtonElement | null = null;

  elBox: HTMLDivElement | null = null;

  preventScrollEvent: boolean = false;

  timeoutfocusToNewLine: number | undefined;

  timeoutScroll: number | undefined;

  componentDidMount() {
    this.testShowInnerAddButton();

    sendEvent({
      category: 'attributes editor',
      action: 'show',
      propertyBag: {
        flowId: this.props.flowFilter?.selectedFlowId,
      },
    });
  }

  componentDidUpdate(prevProps: IUiUserAttributeEditorProps) {
    const {
      attributes: { length },
      userName,
    } = this.props;
    const {
      attributes: { length: prevLength },
      userName: prevUserName,
    } = prevProps;
    const isAttributesUpdated = length !== prevLength;
    const isUserChange = userName !== prevUserName;
    if (isAttributesUpdated) {
      this.testShowInnerAddButton();
    }

    if (isUserChange) {
      this.scrollTo(0);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutScroll);
    window.clearTimeout(this.timeoutfocusToNewLine);
  }

  setFocusToNewLine(elAttributesGroupBox: Element) {
    this.timeoutfocusToNewLine = window.setTimeout(() => {
      // wait show animations
      const elsLineInputs = elAttributesGroupBox.querySelectorAll('input');
      const elSuggestInput = elsLineInputs[elsLineInputs.length - 2];
      if (elSuggestInput) {
        elSuggestInput.focus();
      }
    }, 100);
  }

  handleUndoLine = (index: number) => {
    this.scrollToLine(index);
  };

  handleValueInputKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.addAttribute();
    }
  };

  handleScroll = () => {
    this.testShowInnerAddButton();
  };

  handleAddAttrClick = () => {
    this.setState({
      search: '',
    });

    this.addAttribute();
  };

  handleSaveRequest = (customAattributes: UserAttribute[]) => {
    const { onSaveRequest, attributes } = this.props;
    if (onSaveRequest) {
      onSaveRequest([
        ...customAattributes,
        ...getAttributesByType(UserAttributeType.system, attributes),
      ]);
    }
  };

  handleBulkAttributesDelete = () => {
    const { onBulkDeleteRequest, attributes, onChange } = this.props;
    const availableForDeleteSelectedAttributesNames =
      this.getAvailableForDeleteSelectedAttributesNames();
    onBulkDeleteRequest?.(availableForDeleteSelectedAttributesNames);
    onChange(
      attributes.filter(
        ({ name }) => !availableForDeleteSelectedAttributesNames.includes(name),
      ),
    );
    this.setState({
      selectedAttributesNames: [],
    });
  };

  holderCustomAttributeChange = (customAttributes: UserAttribute[]) => {
    const { onChange, attributes } = this.props;
    onChange([
      ...customAttributes,
      ...getAttributesByType(UserAttributeType.system, attributes),
    ]);
  };

  testShowInnerAddButton = () => {
    const { fixedAddButton, hideFloatButton } = this.props;
    if (
      !hideFloatButton &&
      !fixedAddButton &&
      this.elAddButtonBox &&
      this.elBox &&
      !this.preventScrollEvent
    ) {
      const addButtonRect = this.elAddButtonBox.getBoundingClientRect();
      const boxRect = this.elBox.getBoundingClientRect();
      const showBottomButton =
        boxRect.bottom - addButtonRect.bottom < ADD_BUTTON_MIN_BOTTOM_POSITION;
      this.setState({
        showBottomButton,
      });
    }
  };

  getElScrollBox = (elBox: HTMLDivElement) => {
    this.elBox = elBox;
    // TODO: unnecessary return?
    return this.elBox;
  };

  getElAddButton = (elAddButton: HTMLButtonElement) => {
    this.elAddButton = elAddButton;
    // TODO: unnecessary return?
    return this.elAddButton;
  };

  scrollToLine(index: number, needSetFocus: boolean = false) {
    const { fixedAddButton } = this.props;
    const elAttributesGroupBox =
      this.elBox && this.elBox.querySelector(`.${JS_ATTRIBUTES_BOX_CLASS}`);
    if (elAttributesGroupBox) {
      const LINE_HEIGHT = 52;
      const BOTTOM_PADDING = 64;
      const BOTTOM_PADDING_FOR_FIXED_BUTTON = BOTTOM_PADDING + LINE_HEIGHT * 3;
      const BOTTOM_PADDING_FOR_FLOAT_BUTTON = BOTTOM_PADDING + LINE_HEIGHT * 2;
      const TOP_PADDING = 4;
      const lineTop =
        elAttributesGroupBox.getBoundingClientRect().top + LINE_HEIGHT * index;
      const boxRect = this.elBox!.getBoundingClientRect();
      const bottomPadding = fixedAddButton
        ? BOTTOM_PADDING_FOR_FIXED_BUTTON
        : BOTTOM_PADDING_FOR_FLOAT_BUTTON;

      const bottomDelta =
        boxRect.bottom - (lineTop + LINE_HEIGHT) - bottomPadding;
      const topDelta = boxRect.top - lineTop + TOP_PADDING;

      if (bottomDelta < 0) {
        this.scrollTo(-bottomDelta, true);
      }
      if (topDelta > 0) {
        this.scrollTo(-topDelta, true);
      }

      if (needSetFocus) {
        this.setFocusToNewLine(elAttributesGroupBox);
      }
    }
  }

  addAttribute() {
    const {
      attributes: [...newAttributes],
      onChange,
      onAddAttr,
    } = this.props;

    sendEvent({
      category: 'attributes editor',
      action: 'click add attribute',
    });

    newAttributes.push({
      id: nanoid(),
      name: '',
      values: [''],
      justAdded: true,
      type: UserAttributeType.custom,
    });

    if (onAddAttr) {
      onAddAttr(newAttributes);
    }
    onChange(newAttributes);
    this.scrollToLine(
      getAttributesByType(UserAttributeType.custom, newAttributes).length - 1,
      true,
    );
  }

  waitScrollAnimationsForTestShowAddButton() {
    this.preventScrollEvent = true;
    this.timeoutScroll = window.setTimeout(() => {
      this.preventScrollEvent = false;
      this.testShowInnerAddButton();
    }, 200);
  }

  scrollTo(pos: number, relative: boolean = false) {
    this.waitScrollAnimationsForTestShowAddButton();
    const scrollOptions = {
      containerId: SCROLL_BOX_ID,
      duration: 200,
      isDynamic: true,
      smooth: 'easeOutQuad',
    };
    if (relative) {
      scroll.scrollMore(pos, scrollOptions);
    } else {
      scroll.scrollTo(pos, scrollOptions);
    }
  }

  private createBottomAddButton() {
    const { readOnly, leftSideAddButton } = this.props;
    const addBtnContainerClassName = cn(css.bottomButtonBox, {
      [css.leftSide]: leftSideAddButton,
    });
    return (
      <div className={addBtnContainerClassName}>
        {!readOnly && (
          <ButtonOld
            data-testid="user-attributes__add-attribute-button"
            onClick={this.handleAddAttrClick}
            intent={ButtonIntent.primary}
            renderIcon={() => <AddIcon />}
            size={ButtonSize.m}
            className="test-add-user-attribute-button"
            innerRef={this.getElAddButton}
          >
            {i18next.t('UiUserAttributeEditor-JSXText-2050-add-new')}
          </ButtonOld>
        )}
      </div>
    );
  }

  private createInlineAddButton() {
    const { isSmallScreenSize } = this.props;
    return (
      <div
        ref={(elAddButtonBox) => {
          this.elAddButtonBox = elAddButtonBox;
        }}
        className={cn(
          css.addButtonLayout,
          isSmallScreenSize && css.addButtonLayout_mobile,
        )}
      >
        <ButtonOld
          data-testid="user-attributes__add-attribute-button"
          onClick={this.handleAddAttrClick}
          intent={ButtonIntent.secondary}
          renderIcon={() => <AddIcon />}
          size={ButtonSize.m}
          className={cn(
            'test-add-attribute-button',
            isSmallScreenSize && css.addButton_mobile,
          )}
          innerRef={this.getElAddButton}
        >
          {i18next.t('UiUserAttributeEditor-JSXText-1264-add')}
        </ButtonOld>
      </div>
    );
  }

  private createSystemAttributesGroup() {
    const { attributes, flowFilter, isSmallScreenSize, showBulkRemoving } =
      this.props;
    const { search } = this.state;

    return (
      <AttributeGroupEditor
        attributesType={UserAttributeType.system}
        attributes={getFilteredAttributes(
          attributes,
          UserAttributeType.system,
          search,
          flowFilter?.selectedFlowId,
        )}
        readOnly
        showHeader
        isSmallScreenSize={isSmallScreenSize}
        showBulkRemoving={showBulkRemoving}
      />
    );
  }

  private createCustomAttributesGroup() {
    const {
      attributesSuggestsName,
      onRequestAttributeValuesLoad,
      attributesSuggestsValues,
      readOnly,
      excludeSystemAttributes,
      attributeValueColumnName,
      notFoundComponent,
      emptyListComponent,
      isSmallScreenSize,
      showBulkRemoving,
    } = this.props;

    const { search } = this.state;

    const filteredAttributes = this.getCustomFilteredAttributes();

    const hasVisibleAttributes = filteredAttributes.some(
      ({ hidden }) => !hidden,
    );

    if (!hasVisibleAttributes && !!search && notFoundComponent) {
      return notFoundComponent;
    }

    if (!hasVisibleAttributes && !search && emptyListComponent) {
      return emptyListComponent;
    }

    return (
      <AttributeGroupEditor
        attributesType={UserAttributeType.custom}
        attributes={filteredAttributes}
        onChange={this.holderCustomAttributeChange}
        attributesSuggestsName={attributesSuggestsName}
        attributesSuggestsValues={attributesSuggestsValues}
        onUndoLine={this.handleUndoLine}
        onValueInputKeyDown={this.handleValueInputKeyDown}
        onRequestAttributeValuesLoad={onRequestAttributeValuesLoad}
        readOnly={readOnly}
        onSaveRequest={this.handleSaveRequest}
        showHeader
        attributeValuePlaceholder={this.props.attributeValuePlaceholder}
        attributesColumnName={
          excludeSystemAttributes
            ? i18next.t('UiUserAttributeEditor-string-2420-name')
            : undefined
        }
        attributeValueColumnName={attributeValueColumnName}
        undeletable={this.props.undeletable}
        isSmallScreenSize={isSmallScreenSize}
        showBulkRemoving={showBulkRemoving}
        onAttributesSelected={(names) => {
          this.setState({
            selectedAttributesNames: names,
          });
        }}
      />
    );
  }

  private renderRemoveIcon = () => {
    return (
      <ButtonUnstyled
        onClick={() => {
          this.setState({
            search: '',
          });
        }}
      >
        <RemoveIcon className={css.removeIcon} />
      </ButtonUnstyled>
    );
  };

  private getCustomFilteredAttributes = () => {
    const { flowFilter, attributes } = this.props;
    const { search } = this.state;
    return getFilteredAttributes(
      attributes,
      UserAttributeType.custom,
      search,
      flowFilter?.selectedFlowId,
    );
  };

  private getAvailableForDeleteSelectedAttributesNames = () => {
    const { selectedAttributesNames } = this.state;
    return getAvailableForDeleteSelectedAttributesNames(
      this.getCustomFilteredAttributes(),
      selectedAttributesNames,
    );
  };

  render() {
    const {
      userName,
      fixedAddButton,
      readOnly,
      scrollBoxStyle = DEFAULT_SCROLL_BOX_STYLES,
      upgradeTitle,
      onUpgradeToProClick,
      onDismissModal,
      excludeSystemAttributes,
      withSearch,
      flowFilter,
      isSmallScreenSize,
      isSearchBoxNarrow,
    } = this.props;

    const { showBottomButton, search } = this.state;

    const renderIconEnd = search && this.renderRemoveIcon;

    const availableForDeleteSelectedAttributesIds =
      this.getAvailableForDeleteSelectedAttributesNames();

    return (
      <div
        data-testid="user-attributes__editor"
        className={cn(
          css.layout,
          'test-user-attribute-editor',
          isSmallScreenSize && css.layout_mobile,
        )}
      >
        <ScrollBox
          className={css.scrollBox}
          elId={SCROLL_BOX_ID}
          fullHeight
          onScroll={this.handleScroll}
          innerRef={this.getElScrollBox}
          style={scrollBoxStyle}
        >
          {userName && (
            <div
              className={cn(
                css.userNameMakeUp,
                isSmallScreenSize && css.userNameMakeUp_mobile,
              )}
            >
              {userName}
            </div>
          )}

          {upgradeTitle && (
            <div className={css.upgradeTitle}>
              <div
                data-testid="user-attributes__upgrade-text"
                className={css.upgradeTitleText}
              >
                {i18next.t(
                  'UiUserAttributeEditor-JSXText--129-upgrade-to-the-pro-plan-to-see-and-edit-attributes-for-all-users',
                )}
              </div>
              <ButtonOld
                data-testid="user-attributes__upgrade-button"
                type="button"
                intent={ButtonIntent.primary}
                size={ButtonSize.m}
                onClick={() => {
                  if (onUpgradeToProClick) {
                    onUpgradeToProClick();
                  }
                  if (onDismissModal) {
                    onDismissModal();
                  }
                }}
              >
                {i18next.t('UiUserAttributeEditor-JSXText-1227-upgrade-to-pro')}
              </ButtonOld>
            </div>
          )}

          <div
            className={cn(
              css.editorsBoxSpacing,
              isSmallScreenSize && css.editorsBoxSpacing_mobile,
            )}
          >
            {flowFilter && (
              <div className={css.flowFilter}>
                <GroupingCombobox
                  groups={flowFilter.groups}
                  ungroupedItems={allFlows()}
                  selectedItemId={flowFilter.selectedFlowId ?? ALL_FLOWS_ID}
                  onSelect={(flowId) => {
                    sendEvent({
                      category: 'attributes editor',
                      action: 'show global attributes by specific flow',
                      propertyBag: {
                        flowId,
                      },
                    });
                    flowFilter.onSelectFlowId(
                      flowId === ALL_FLOWS_ID ? null : flowId,
                    );
                  }}
                />
              </div>
            )}
            {withSearch && (
              <div
                className={cn(
                  css.searchBox,
                  isSmallScreenSize && css.searchBox_mobile,
                  isSearchBoxNarrow && !isSmallScreenSize && css.narrow,
                )}
              >
                <Input
                  placeholder={i18next.t(
                    'UiUserAttributeEditor-string-5449-search-by-name-or-value',
                  )}
                  onChange={(event) => {
                    const { value } = event.target;

                    this.setState({
                      search: value,
                    });
                  }}
                  value={search}
                  onClick={() =>
                    sendEvent({
                      category: 'attributes editor',
                      action: 'search click',
                    })
                  }
                  renderIcon={() => <SearchIcon className={css.searchIcon} />}
                  {...(renderIconEnd && { renderIconEnd })}
                />
              </div>
            )}
            {this.createCustomAttributesGroup()}
            {!fixedAddButton && !readOnly && this.createInlineAddButton()}
            {!excludeSystemAttributes && this.createSystemAttributesGroup()}
          </div>
        </ScrollBox>
        {availableForDeleteSelectedAttributesIds.length > 0 && (
          <Button
            onClick={this.handleBulkAttributesDelete}
            intent="red"
            className={css.deleteAttributesButton}
            data-testid="user-attributes__delete-button"
          >
            {i18next.t(
              'modernComponents.GlobalAttributesDialog.DialogFooter.delete',
              { count: availableForDeleteSelectedAttributesIds.length },
            )}
          </Button>
        )}
        {showBottomButton && this.createBottomAddButton()}
      </div>
    );
  }
}

export default UiUserAttributeEditor;
