import { UserAttribute, UserAttributeType } from './UserAttributeTypes';

export const getAttributesByType = (
  type: UserAttributeType,
  attributes: UserAttribute[] = [],
) => attributes.filter(({ type: attributeType }) => attributeType === type);

export const getFilteredAttributes = (
  attributes: UserAttribute[],
  type: UserAttributeType,
  search: string,
  selectedFlowId?: string | null,
) => {
  const filteredByTypeAttributes = getAttributesByType(type, attributes);

  const searchLowerCase = search.toLocaleLowerCase();

  return filteredByTypeAttributes.map((attribute) => {
    const { name, values, flowsUsingIt } = attribute;

    const searchIsEmpty = !searchLowerCase;

    const nameIncludesSearch =
      searchIsEmpty || name.toLowerCase().includes(searchLowerCase);

    const valueIncludesSearch =
      searchIsEmpty ||
      values[0]?.toString().toLowerCase().includes(searchLowerCase);

    const usingInSelectedFlow =
      !selectedFlowId ||
      !Array.isArray(flowsUsingIt) ||
      flowsUsingIt.includes(selectedFlowId);

    const hidden =
      (!nameIncludesSearch && !valueIncludesSearch) || !usingInSelectedFlow;

    return {
      ...attribute,
      hidden,
    };
  });
};

export const getAvailableForDeleteSelectedAttributesNames = (
  attributes: UserAttribute[],
  selectedAttributesNames: string[],
) => {
  const visibleAttributesNames = attributes
    .filter(({ hidden }) => !hidden)
    .map(({ name }) => name);
  return selectedAttributesNames.filter((attributeName) =>
    visibleAttributesNames.includes(attributeName),
  );
};
