import React, { useMemo } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { TestEntryPointsDialogProps } from '@components/TestEntryPoints/dialogs/types';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import image1Src from './images/TestPopupDialog_1.png';
import image2Src from './images/TestPopupDialog_2.png';
import image3Src from './images/TestPopupDialog_3.png';
import { TestSteps } from './components';

export const TestPopupDialog: React.FC<TestEntryPointsDialogProps> = ({
  onRequestClose,
}) => {
  const { t } = useSafeTranslation();

  const TEST_STEPS = useMemo(
    () => [
      {
        text: <>1. {t('shopify.testPopupDialog.step1')}</>,
        src: image1Src,
      },
      {
        text: <>2. {t('shopify.testPopupDialog.step2')}</>,
        src: image2Src,
      },
      {
        text: <>3. {t('shopify.testPopupDialog.step3')}</>,
        src: image3Src,
      },
    ],
    [t],
  );

  return (
    <DefaultDialog
      dialogStyle={{
        width: 572,
      }}
      header={t('shopify.testPopupDialog.title')}
      onDismiss={onRequestClose}
    >
      <TestSteps steps={TEST_STEPS} />
    </DefaultDialog>
  );
};
