import React from 'react';
import { Anchor } from '@ui/Links';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';

interface PaymentInfoProps extends TestLocator {
  title: string;
  buttonTitle: string;
  info: string;
  onClick(): void;
  actionContent?: React.ReactNode;
}

export const PaymentInfoRow: React.FC<PaymentInfoProps> = ({
  title,
  buttonTitle,
  info,
  onClick,
  actionContent,
  ...props
}) => (
  <Flex justifyContent="space-between" gapFactor={25}>
    <div>
      <Type size="15px" weight="semibold" as="div">
        {title}
      </Type>
      <Spacer factor={1} />
      <Type size="15px" color="greyDark" as="div">
        {info}
      </Type>
    </div>
    <div>
      <Anchor
        intent="external"
        hideArrow
        data-testid={props['data-testid']}
        onClick={onClick}
      >
        {buttonTitle}
      </Anchor>
      {actionContent}
    </div>
  </Flex>
);
