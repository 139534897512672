import React, { useCallback } from 'react';
import { DefaultDialog, DialogHeading } from '@ui/Dialog';
import {
  DIALOG_STYLE,
  getStepTitles,
  WhatsappImportUserDialogSteps,
} from './consts';
import { useWhatsappImportUserDialogStep } from './hocks/useWhatsappImportUserDialogStep';
import { FileSelectStep } from './steps/FileSelectStep';
import { MapFieldsStep } from './steps/MapFieldsStep';
import { ReviewChangesStep } from './steps/ReviewChangesStep';
import { InProgressStep } from './steps/InProgressStep';
import { CompletedStep } from './steps/CompletedStep';
import { ErrorStep } from './steps/ErrorStep';

interface WhatsappImportUserDialogProps {
  onDone: VoidFunction;
  onDismiss: VoidFunction;
}

export const WhatsappImportUsersDialog: React.FC<WhatsappImportUserDialogProps> =
  ({ onDone, onDismiss }) => {
    const { setStep, step } = useWhatsappImportUserDialogStep();

    const inProcess = step === WhatsappImportUserDialogSteps.process;

    const dismissHandler = useCallback(() => {
      if (!inProcess) {
        onDismiss();
      }
    }, [onDismiss, inProcess]);

    return (
      <>
        <DefaultDialog
          closeButtonDisabled={inProcess}
          onDismiss={dismissHandler}
          dialogStyle={DIALOG_STYLE}
        >
          <DialogHeading>{getStepTitles()[step]}</DialogHeading>
          {step === WhatsappImportUserDialogSteps.selectFile && (
            <FileSelectStep
              onDone={() => {
                setStep(WhatsappImportUserDialogSteps.mapFields);
              }}
            />
          )}
          {step === WhatsappImportUserDialogSteps.mapFields && (
            <MapFieldsStep
              onDone={() => {
                setStep(WhatsappImportUserDialogSteps.review);
              }}
              onBack={() => {
                setStep(WhatsappImportUserDialogSteps.selectFile);
              }}
            />
          )}
          {step === WhatsappImportUserDialogSteps.review && (
            <ReviewChangesStep
              onDone={() => {
                setStep(WhatsappImportUserDialogSteps.process);
              }}
              onBack={() => {
                setStep(WhatsappImportUserDialogSteps.mapFields);
              }}
            />
          )}
          {step === WhatsappImportUserDialogSteps.process && <InProgressStep />}
          {step === WhatsappImportUserDialogSteps.success && (
            <CompletedStep onDone={onDone} />
          )}
          {step === WhatsappImportUserDialogSteps.error && (
            <ErrorStep
              onBack={() => {
                setStep(WhatsappImportUserDialogSteps.review);
              }}
            />
          )}
        </DefaultDialog>
        {
          inProcess && (
            <style>
              {
                "div[data-focus-lock-disabled]::before{content:''; position: absolute; top: 0; bottom:0; left: 0; right:0;z-index:var(--zindex-overlay);}"
              }
            </style>
          ) // prevent overlay click
        }
      </>
    );
  };
