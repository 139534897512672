import { ConnectInstagramStep, ConnectInstagramVariant } from '../../types';
import { InstagramConnectionProblem } from '@globals';

export const getInitialStep = (
  variant: ConnectInstagramVariant,
  hasPermissions: boolean,
  connectionProblem?: InstagramConnectionProblem | null,
) => {
  if (connectionProblem) {
    return ConnectInstagramStep.troubleshooting;
  }
  if (variant === ConnectInstagramVariant.refresh) {
    return ConnectInstagramStep.permissions;
  }
  if (variant === ConnectInstagramVariant.known) {
    return hasPermissions
      ? ConnectInstagramStep.permissions
      : ConnectInstagramStep.account;
  }
  return ConnectInstagramStep.givePermissions;
};

export const getNextStepByCurrentAndFlow = (
  currentStep: ConnectInstagramStep,
  variant: ConnectInstagramVariant,
  currentStepError?: any,
): ConnectInstagramStep => {
  if (currentStep === ConnectInstagramStep.givePermissions) {
    if (variant === ConnectInstagramVariant.full) {
      return ConnectInstagramStep.requirements;
    }
    return ConnectInstagramStep.account;
  }

  if (currentStep === ConnectInstagramStep.troubleshooting) {
    if (currentStepError) {
      return currentStepError in InstagramConnectionProblem
        ? ConnectInstagramStep.troubleshooting
        : ConnectInstagramStep.error;
    }
    return ConnectInstagramStep.success;
  }

  if (currentStep === ConnectInstagramStep.requirements) {
    return currentStepError
      ? ConnectInstagramStep.troubleshooting
      : ConnectInstagramStep.account;
  }

  if (currentStep === ConnectInstagramStep.account) {
    return currentStepError
      ? ConnectInstagramStep.troubleshooting
      : ConnectInstagramStep.success;
  }

  if (currentStep === ConnectInstagramStep.permissions) {
    return currentStepError
      ? ConnectInstagramStep.troubleshooting
      : ConnectInstagramStep.success;
  }

  return ConnectInstagramStep.done;
};
