import React from 'react';
import { Type } from '@ui/Type';
import { AssignPromoCodeError } from '@utils/Data/PromoCode/useAssignBotPromoCodeMutation';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useWhatsappEnabled } from '@utils/Whatsapp';

interface ErrorMessageProps {
  errorMessage: AssignPromoCodeError;
  className?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  errorMessage,
  className,
}) => {
  const { t } = useSafeTranslation();
  const { isWhatsappEnabled } = useWhatsappEnabled();

  return (
    <div className={className}>
      <Type size="15px" weight="medium" color="redNormal">
        {(errorMessage !== AssignPromoCodeError.page_absent ||
          !isWhatsappEnabled) &&
          t(`PromoCode.${errorMessage}`)}
        {errorMessage === AssignPromoCodeError.page_absent &&
          isWhatsappEnabled &&
          t(`PromoCode.phone_absent`)}
      </Type>
    </div>
  );
};
