import React from 'react';
import {
  BillingDetailsDialogProps,
  BillingDetailsDialog,
} from './BillingDetailsDialog';
import { Modal } from '../../../modern-ui/Modal';

export const BillingDetailsModal: React.FC<BillingDetailsDialogProps> = (
  props,
) => {
  return (
    <Modal onDismiss={props.onRequestClose}>
      <BillingDetailsDialog {...props} />
    </Modal>
  );
};
