import { useEffect, useState } from 'react';
import {
  testThisButtonEventEmitter,
  testFlowButtonMarkedChange,
  TestThisButtonEventPayload,
} from './events';

export const useTestThisMarked = () => {
  const [buttonIsMarked, setMarked] = useState(false);

  useEffect(() => {
    return testThisButtonEventEmitter.on<TestThisButtonEventPayload>(
      testFlowButtonMarkedChange,
      ({ marked }) => {
        setMarked(marked);
      },
    );
  }, []);

  return buttonIsMarked;
};
