import { useLayoutEffect, useRef } from 'react';
import noop from 'lodash-es/noop';
import { PostMessageInput, PostMessageHandler } from './types';

export const useAsyncPostMessageHandlerWrapper = (
  callback: (input: PostMessageInput, isMounted: () => boolean) => void,
): PostMessageHandler => {
  const ref = useRef<PostMessageHandler>(noop);

  useLayoutEffect(() => {
    let mounted = true;
    ref.current = (input) => callback(input, () => mounted);
    return () => {
      mounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return ref.current;
};
