import React from 'react';
import { ShopifyIntegrationContainer } from '@components/ShopifyIntegrationContainer';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';
import { BotLivelinessStatus, ShopifySubscriptionStatus } from '@globals';
import { ShopifyCallout } from './ShopifyCallout';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DateFormat } from '@utils/DateTime';
import { formatPrice } from '@utils/Format';
import { sendShopifyEvent } from './utils';
import { DeviceMediaProps } from '@utils/DOM/useDeviceMedia';
import { useCreateAndConfirmShopifySubscription } from '@utils/Integrations/Shopify/useCreateAndConfirmShopifySubscription';
import { useBotLivelinessStatus } from '@utils/Data/Bot';
import { Trans } from 'react-i18next';
import { openShopifyPlansPage, openShopifySettingsPage } from './helpers';

interface ShopifyPaymentTrialProps extends DeviceMediaProps {
  currency: string;
  price: string;
  trialDays: number;
  trialEndsOn: string;
}

const ShopifyPaymentTrial: React.FC<ShopifyPaymentTrialProps> = ({
  currency,
  price,
  trialDays,
  trialEndsOn,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const { createAndConfirmShopifySubscription } =
    useCreateAndConfirmShopifySubscription();

  const onClick = () => {
    createAndConfirmShopifySubscription();
    sendShopifyEvent('Open Shopify billing click', {
      type: ShopifySubscriptionStatus.pending,
    });
  };

  return (
    <ShopifyCallout
      type="dark"
      onClick={onClick}
      text={
        trialDays
          ? t('ShopifyOnboarding.payment.trial', {
              days: trialDays,
              date: DateFormat.MMMMD(Number(trialEndsOn)),
              amount: formatPrice(Number(price), { currency, decimals: 2 }),
            })
          : t('ShopifyOnboarding.payment.noTrial')
      }
      {...props}
    />
  );
};

interface ShopifyPaymentErrorProps extends DeviceMediaProps {
  text?: string;
}

const ShopifyPaymentError: React.FC<ShopifyPaymentErrorProps> = ({
  text,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const { createAndConfirmShopifySubscription } =
    useCreateAndConfirmShopifySubscription();

  const onClick = () => {
    sendShopifyEvent('Open Shopify billing click', {
      type: ShopifySubscriptionStatus.cancelled,
    });
    createAndConfirmShopifySubscription();
  };

  return (
    <ShopifyCallout
      type="error"
      text={text || t('ShopifyOnboarding.payment.error')}
      onClick={onClick}
      {...props}
    />
  );
};

interface ShopifyPaymentContainerProps extends DeviceMediaProps {}

const ShopifyPaymentContainer: React.FC<ShopifyPaymentContainerProps> = (
  props,
) => {
  const { t } = useSafeTranslation();
  const { loading: accountLoading, account } = useShopifyAccount();
  const { status: botStatus, loading: botStatusLoading } =
    useBotLivelinessStatus();

  if (accountLoading || botStatusLoading || !account) {
    return null;
  }

  const botWillBeStoppedAt = account.subscription?.botWillBeStoppedAt;
  const nextPeriodStart = account.subscription?.nextPeriodStart;
  const domain = account.domain.split('.')[0];

  if (botStatus === BotLivelinessStatus.SHOPIFY_STOPPED_STORE_FROZEN) {
    return (
      <ShopifyCallout
        type="dark"
        text={t('ShopifyOnboarding.callout.shopifyStoppedStoreFrozen.text')}
        buttonText={t(
          'ShopifyOnboarding.callout.shopifyStoppedStoreFrozen.button',
        )}
        onClick={() => {
          openShopifyPlansPage(domain);
        }}
      />
    );
  }

  if (botStatus === BotLivelinessStatus.SHOPIFY_STOPPED_CAP_REACHED) {
    return (
      <ShopifyPaymentError
        text={t('ShopifyOnboarding.callout.shopifyStoppedCapReached.text', {
          date: botWillBeStoppedAt ? DateFormat.MMMDD(botWillBeStoppedAt) : '',
        })}
      />
    );
  }

  if (botStatus === BotLivelinessStatus.SHOPIFY_STOPPED_CONNECTION_ISSUES) {
    return (
      <ShopifyCallout
        type="error"
        text={
          <Trans
            t={t}
            i18nKey="ShopifyOnboarding.callout.shopifyStoppedConnectionIssues.text"
          />
        }
        buttonText={t(
          'ShopifyOnboarding.callout.shopifyStoppedConnectionIssues.button',
        )}
        onClick={() => {
          openShopifySettingsPage(domain);
        }}
      />
    );
  }

  if (!account.subscription?.status) {
    return null;
  }

  const { status } = account.subscription;

  return {
    [ShopifySubscriptionStatus.pending]: (
      // @ts-expect-error
      <ShopifyPaymentTrial {...{ ...account.subscription, ...props }} />
    ),
    [ShopifySubscriptionStatus.active]: null,
    [ShopifySubscriptionStatus.cancelled]: <ShopifyPaymentError {...props} />,
    [ShopifySubscriptionStatus.cap_reached]: botWillBeStoppedAt ? (
      <ShopifyPaymentError
        text={t('ShopifyOnboarding.callout.shopifyStoppedCapReachedPre.text', {
          date1: botWillBeStoppedAt ? DateFormat.MMMDD(botWillBeStoppedAt) : '',
          date2: nextPeriodStart ? DateFormat.MMMDD(nextPeriodStart) : '',
        })}
      />
    ) : null,
  }[status];
};

export interface ShopifyPaymentViewProps extends DeviceMediaProps {}

export const ShopifyPaymentView: React.FC<ShopifyPaymentViewProps> = (
  props,
) => (
  <ShopifyIntegrationContainer>
    <ShopifyPaymentContainer {...props} />
  </ShopifyIntegrationContainer>
);
