import { useQuery } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';

import { useCurrentBotId } from '@utils/Routing';

import { useCallback } from 'react';
import { ServiceMessageType, toaster } from '@services/MessageService';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  ShopifyDiscountsCodeInput,
  ShopifyDiscountsCodeUpdateInput,
} from '@globals';

import {
  SHOPIFY_DISCOUNTS_CODES_CREATE_MUTATION,
  SHOPIFY_DISCOUNTS_CODES_DELETE_MUTATION,
  SHOPIFY_DISCOUNTS_CODES_QUERY,
  SHOPIFY_DISCOUNTS_CODES_UPDATE_MUTATION,
} from './queries';

import { ShopifyDiscountsCodesQuery } from './@types/ShopifyDiscountsCodesQuery';
import {
  ShopifyDiscountsCodesCreateMutation,
  ShopifyDiscountsCodesCreateMutationVariables,
} from './@types/ShopifyDiscountsCodesCreateMutation';
import {
  ShopifyDiscountsCodesUpdateMutation,
  ShopifyDiscountsCodesUpdateMutationVariables,
} from './@types/ShopifyDiscountsCodesUpdateMutation';
import {
  ShopifyDiscountsCodesDeleteMutation,
  ShopifyDiscountsCodesDeleteMutationVariables,
} from './@types/ShopifyDiscountsCodesDeleteMutation';

export const useShopifyDiscountsCodesList = () => {
  const botId = useCurrentBotId();
  const { data, error, loading, refetch } =
    useQuery<ShopifyDiscountsCodesQuery>(SHOPIFY_DISCOUNTS_CODES_QUERY, {
      variables: { botId },
      notifyOnNetworkStatusChange: true,
    });
  return {
    shopifyDiscountsCodesList: data?.shopifyDiscountsCodes ?? [],
    shopifyDiscountsCodesListError: error,
    shopifyDiscountsCodesListLoading: loading,
    shopifyDiscountsCodesListRefetch: refetch,
  };
};

export const useShopifyDiscountsCodesCreate = () => {
  const botId = useCurrentBotId();
  const [createShopifyDiscountsCode, { data, error, loading }] = useMutation<
    ShopifyDiscountsCodesCreateMutation,
    ShopifyDiscountsCodesCreateMutationVariables
  >(SHOPIFY_DISCOUNTS_CODES_CREATE_MUTATION);
  const handleCreateShopifyDiscountsCode = (
    shopifyDiscount: ShopifyDiscountsCodeInput,
  ) => {
    if (botId) {
      createShopifyDiscountsCode({
        variables: {
          botId,
          shopifyDiscount,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: SHOPIFY_DISCOUNTS_CODES_QUERY,
            variables: { botId },
          },
        ],
      });
    }
  };
  return {
    createShopifyDiscountsCode: handleCreateShopifyDiscountsCode,
    createShopifyDiscountsCodeData: data,
    createShopifyDiscountsCodeError: error,
    createShopifyDiscountsCodeLoading: loading,
  };
};

export const useShopifyDiscountsCodesUpdate = () => {
  const botId = useCurrentBotId();
  const [updateShopifyDiscountsCode, { data, error, loading }] = useMutation<
    ShopifyDiscountsCodesUpdateMutation,
    ShopifyDiscountsCodesUpdateMutationVariables
  >(SHOPIFY_DISCOUNTS_CODES_UPDATE_MUTATION);
  const handleUpdateShopifyDiscountsCode = (
    shopifyDiscount: ShopifyDiscountsCodeUpdateInput,
  ) => {
    if (botId) {
      updateShopifyDiscountsCode({
        variables: {
          botId,
          shopifyDiscount,
        },
        refetchQueries: [
          {
            query: SHOPIFY_DISCOUNTS_CODES_QUERY,
            variables: { botId },
          },
        ],
      });
    }
  };
  return {
    updateShopifyDiscountsCode: handleUpdateShopifyDiscountsCode,
    updateShopifyDiscountsCodeData: data,
    updateShopifyDiscountsCodeError: error,
    updateShopifyDiscountsCodeLoading: loading,
  };
};

export const useShopifyDiscountsCodesDelete = () => {
  const botId = useCurrentBotId();
  const [deleteShopifyDiscountsCode, { data, error, loading }] = useMutation<
    ShopifyDiscountsCodesDeleteMutation,
    ShopifyDiscountsCodesDeleteMutationVariables
  >(SHOPIFY_DISCOUNTS_CODES_DELETE_MUTATION);
  const { t } = useSafeTranslation();

  const handleDeleteShopifyDiscountsCode = useCallback(
    async (priceRuleId: string, code: string) => {
      if (botId) {
        await deleteShopifyDiscountsCode({
          variables: {
            botId,
            priceRuleId,
          },
          refetchQueries: [
            {
              query: SHOPIFY_DISCOUNTS_CODES_QUERY,
              variables: { botId },
            },
          ],
        });

        toaster.show({
          type: ServiceMessageType.default,
          payload: {
            message: t('shopify.openShopifyStore.codeDeleteDialog.deleted', {
              code,
            }),
          },
        });
      }
    },
    [botId, deleteShopifyDiscountsCode, t],
  );

  return {
    deleteShopifyDiscountsCode: handleDeleteShopifyDiscountsCode,
    deleteShopifyDiscountsCodeData: data,
    deleteShopifyDiscountsCodeError: error,
    deleteShopifyDiscountsCodeLoading: loading,
  };
};
