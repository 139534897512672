import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { useDialoguesPricing } from '@utils/Data/Pricing';
import { formatDate } from '@utils/Format';
import { DateUtils } from '@utils/DateTime';
import { Spacer } from '@ui/Spacer';
import { ScrollBox } from '@ui/ScrollBox';
import { Avatar } from '@ui/Avatar';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { TextEllipsis } from '@ui/TextEllipsis';
import * as css from './TransferPlanDialogPagesList.css';
import { PagesWithSubscriptionsQuery_pages } from '../../hooks/@types/PagesWithSubscriptionsQuery';
import { DIALOG_STYLE } from '../../consts';
import { getMonthsCount } from '../../../DialogsPricing/utils/getMonthsCount';
import { FacebookPagesFooter } from '../../../ConnectPageDialog/components/FacebookPagesFooter';
import { Page } from '../../hooks/useTransferPlan';
import { sendEvent } from '@utils/Analytics';
import { ChargingEntityType } from '@globals';
import { getTierNameByType } from '@components/DialogsPricing/constants';

interface TransferPlanDialogPagesListProps {
  pages: PagesWithSubscriptionsQuery_pages[];
  onDismiss: () => void;
  onRefetchPagesRequest: () => void;
  onTranferRequest: (page: Page) => void;
}

export const TransferPlanDialogPagesList: React.FC<TransferPlanDialogPagesListProps> =
  ({ onDismiss, onRefetchPagesRequest, onTranferRequest, pages }) => {
    const { t } = useSafeTranslation();
    const { pricing, pricingLoading } = useDialoguesPricing();

    if (pricingLoading) {
      return null;
    }

    const nextBillingDate = pricing?.next_billing_date;

    const period = nextBillingDate
      ? `${formatDate(
          DateUtils.backToNMonth(
            nextBillingDate,
            getMonthsCount(pricing?.current_subscription_period) || 1,
          ),
        )} – ${formatDate(nextBillingDate)}`
      : '';

    return (
      <DefaultDialog
        header={t(
          'modernComponents.TransferPlanDialog.TransferPlanDialogPagesList.title',
        )}
        onDismiss={onDismiss}
        dialogStyle={DIALOG_STYLE}
        footer={
          <FacebookPagesFooter
            showCreatePageButton
            onReturnAfterCreatePage={onRefetchPagesRequest}
            facebookPagesRefetch={onRefetchPagesRequest}
            className={css.footer}
          />
        }
      >
        <Type size="15px">
          {t(
            pricing?.charging_entity_type === ChargingEntityType.dialogs
              ? 'modernComponents.TransferPlanDialog.TransferPlanDialogPagesList.textDialogs'
              : 'modernComponents.TransferPlanDialog.TransferPlanDialogPagesList.textUsers',
            {
              plan: pricing?.current_tier
                ? getTierNameByType(pricing?.current_tier)
                : t('UpgradePage-string-8052-pro'),
              amount:
                (pricing?.current_tier_limit || 0) -
                (pricing?.reached_limit || 0),
              period,
            },
          )}
        </Type>
        <Spacer factor={6} />
        <ScrollBox data-testid="transfer-plan_pages_list">
          {pages.map(({ id, title, picture }) => (
            <React.Fragment key={id}>
              <Flex
                justifyContent="space-between"
                alignItems="center"
                title={title}
                data-testid="transfer-plan_page-item"
              >
                <Flex alignItems="center">
                  <Avatar customSize={36} src={picture} />
                  <Spacer factor={0} horizontalFactor={3} />
                  <TextEllipsis width={310}>
                    <Type size="15px" data-testid="transfer-plan_page-title">
                      {title}
                    </Type>
                  </TextEllipsis>
                </Flex>
                <Button
                  data-testid="transfer-plan_transfer-button"
                  onClick={() => {
                    sendEvent({
                      category: 'transfer plan',
                      label: 'pages list',
                      action: 'transfer plan click',
                    });
                    onTranferRequest({ id, title });
                  }}
                >
                  {t(
                    'modernComponents.TransferPlanDialog.TransferPlanDialogPagesList.transferButton',
                  )}
                </Button>
              </Flex>
              <Spacer factor={6} />
            </React.Fragment>
          ))}
        </ScrollBox>
      </DefaultDialog>
    );
  };
