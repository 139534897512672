import { useCurrentBotId } from '@utils/Routing';
import { useCallback } from 'react';
import { redirect } from '@utils/UrlUtils';
import Maybe from 'graphql/tsutils/Maybe';

export const useWhatsappImportUsersDownloadFailedData = (
  csvUploadSessionId: Maybe<string>,
) => {
  const botId = useCurrentBotId()!;
  const download = useCallback(async () => {
    redirect(
      `/api/whatsapp/bots/${botId}/import_contacts/${csvUploadSessionId}/download_declined_users`,
    );
  }, [botId, csvUploadSessionId]);
  return { download };
};
