import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import NavAiImage from './assets/navAi.png';

import * as css from './DefaultAnswerToAiDialog.css';

interface DefaultAnswerToAiDialogProps {
  onClose(): void;
}
export const DefaultAnswerToAiDialog: React.FC<DefaultAnswerToAiDialogProps> =
  ({ onClose }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultDialog
        header={t('modernComponents.DefaultAnswerToAiDialog.header')}
        footer={
          <Flex justifyContent="flex-end">
            <Button intent="primary" onClick={onClose}>
              {t('modernComponents.DefaultAnswerToAiDialog.button')}
            </Button>
          </Flex>
        }
        onDismiss={onClose}
      >
        <Flex justifyContent="center">
          <Flex
            className={css.imageContainer}
            justifyContent="center"
            alignItems="center"
          >
            <img className={css.image} src={NavAiImage} alt="AI" />
          </Flex>
        </Flex>
        <Spacer factor={6} />
        <Type size="15px_DEPRECATED">
          {t('modernComponents.DefaultAnswerToAiDialog.content')}
        </Type>
      </DefaultDialog>
    );
  };
