import { useMemo, useState } from 'react';
import merge from 'lodash-es/merge';
import { useMutation, useQuery } from '@apollo/react-hooks';
import nanoid from 'nanoid';
import {
  BOT_TESTING_QUERY,
  POST_BOT_TESTING_MESSAGE,
} from '@utils/Data/BotTesting/queries';
import { BotTestingQuery } from '@utils/Data/BotTesting/@types/BotTestingQuery';
import {
  PostBotTestingMessageMutation,
  PostBotTestingMessageMutationVariables,
} from '@utils/Data/BotTesting/@types/PostBotTestingMessageMutation';
import { createUserMessage } from './utils';
import { ClickedObjectTypes } from './consts';
import {
  ButtonClickHandler,
  GalleryButtonClickHandler,
  PostMessageHandler,
  QrClickHandler,
  TestThisMessageItems,
  WhatsappListReplyHandler,
} from './types';
import { useAsyncPostMessageHandlerWrapper } from './useAsyncWrapper';
import { emitUsedSendMessageEvent } from './events';
import { BotTestingPostMessageInput } from '@globals';

const getFilteredSentMessage = (
  sentMessages: TestThisMessageItems,
  serverMessages: TestThisMessageItems,
) => {
  return sentMessages.filter(
    ({ mid }) => !serverMessages.some((message) => message.mid === mid),
  );
};

/**
 * Хук для процессинга сообщений
 *
 * ВАЖНО!
 *
 * Процессинг сообщений устроен немного нетривиально, существует 3 стадии жизни сообщения отправленного пользователем:
 *   - 1 стадия. Пользователь набрал сообщение и нажал send.
 *     Мы сохраняем сообщение в `sentMessages`. Назначаем сообщению рандомный `id = nanoid()`,
 *     `mid` - не определен, задаем его равным `id`, чтобы не оставлять пустую строку. Отображаем его вместе
 *   - 2 стадия. Сообщение зарегистрированно на бэкенде, нам пришел ответ с `mid`. Обновляем `mid` в `sentMessages`
 *   - 3 стадия. Сообщение пришло из ручки с запроцешенными сообщениями (в поллинге пришла пачка ответов) - убираем сообщения с пришедшеми mid.
 */
export const useTestThisMessages = () => {
  const [sentMessages, setSentMessages] = useState<TestThisMessageItems>([]);
  const { data } = useQuery<BotTestingQuery>(BOT_TESTING_QUERY, {
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  const [performPostMessageMutation] = useMutation<
    PostBotTestingMessageMutation,
    PostBotTestingMessageMutationVariables
  >(POST_BOT_TESTING_MESSAGE);

  const createUserMessageByType = (
    id: string,
    input: BotTestingPostMessageInput,
  ) => {
    if (input.clickedObjectType === ClickedObjectTypes.whatsapp_list) {
      return merge(createUserMessage(id, input.text ?? ''), {
        message: {
          whatsapp_list_reply: {
            __typename: 'WhatsappListReply',
            row_title: input.title,
            row_description: input.description,
          },
        },
      });
    }

    return createUserMessage(id, input.text ?? '');
  };

  const postMessage: PostMessageHandler = useAsyncPostMessageHandlerWrapper(
    (input, isMounted) => {
      const id = nanoid();
      emitUsedSendMessageEvent();
      performPostMessageMutation({ variables: { input } })
        .then((response) => {
          if (!isMounted()) return;
          const mid = response?.data?.postBotTestingMessage.mid;
          setSentMessages((prevMessages) => {
            return mid
              ? prevMessages.map((message) =>
                  message.id === id ? { ...message, mid } : message,
                )
              : prevMessages.filter((message) => message.id !== id);
          });
        })
        .catch(() => {
          if (!isMounted()) return;
          setSentMessages((prevMessages) =>
            prevMessages.filter((message) => message.id !== id),
          );
        });
      setSentMessages((prevMessages) => [
        ...prevMessages,
        createUserMessageByType(id, input),
      ]);
    },
  );

  const handleButtonClick: ButtonClickHandler = (messageId, index, text) => {
    postMessage({
      text,
      clickedMessageId: messageId,
      clickedObjectType: ClickedObjectTypes.button,
      clickedObjectIndex: index,
    });
  };

  const handleQrClick: QrClickHandler = (messageId, index, text) => {
    postMessage({
      text,
      clickedMessageId: messageId,
      clickedObjectType: ClickedObjectTypes.quick_reply,
      clickedObjectIndex: index,
    });
  };

  const handleGalleryButtonClick: GalleryButtonClickHandler = (
    messageId,
    galleryIndex,
    buttonIndex,
    text,
  ) => {
    postMessage({
      text,
      clickedMessageId: messageId,
      clickedObjectType: ClickedObjectTypes.image_gallery,
      clickedObjectIndex: galleryIndex,
      clickedObjectSecondIndex: buttonIndex,
    });
  };

  const handleWhatsappListReply: WhatsappListReplyHandler = (
    messageId: string,
    sectionRowId: string,
    title: string,
    description: string | null,
  ) => {
    postMessage({
      clickedMessageId: messageId,
      clickedObjectType: ClickedObjectTypes.whatsapp_list,
      sectionRowId,
      title,
      description,
    });
  };

  const serverMessages = data?.botTesting;

  const messages = useMemo(() => {
    if (!serverMessages) return sentMessages;
    return [...serverMessages, ...sentMessages].sort((l, r) => l.date - r.date);
  }, [serverMessages, sentMessages]);

  const hasReceivedMessages = serverMessages?.some(({ mid }) =>
    sentMessages.some((sent) => sent.mid === mid),
  );

  if (hasReceivedMessages) {
    setSentMessages((prevSent) =>
      getFilteredSentMessage(prevSent, serverMessages!),
    );
  }

  return {
    messages,
    postMessage,
    handleQrClick,
    handleButtonClick,
    handleGalleryButtonClick,
    handleWhatsappListReply,
  };
};
