import React, { useMemo } from 'react';
import * as css from './WhatsappImportUserDialogFooterErrorsList.css';
import { useWhatsappImportUsersSession } from '../../hocks/useWhatsappImportUsersSession';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Spacer } from '@ui/Spacer';
import { ascend, prop, sortWith } from 'ramda';
import { WhatsappImportUsersSessionStatusQuery_whatsappImportUsersSessionStatus_stats_declinedErrorsStats as DeclinedErrorsStats } from '../../hocks/@types/WhatsappImportUsersSessionStatusQuery';
import { ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { useWhatsappImportUsersDownloadFailedData } from '../../hocks/useWhatsappImportUsersDownloadFailedData';
import { numberFormatter } from '@utils/Format/numberFormatter';
import { formatThousands } from '@utils/Number/formatThousands';

interface WhatsappImportUserDialogFooterErrorsListProps {}

const sortByPosition = sortWith<DeclinedErrorsStats>([
  ascend(prop('position')),
]);

export const WhatsappImportUserDialogFooterErrorsList: React.FC<WhatsappImportUserDialogFooterErrorsListProps> =
  () => {
    const { t } = useSafeTranslation();
    const { csvUploadSessionId, stats, canDownloadDeclinedUsersFile, limits } =
      useWhatsappImportUsersSession();
    const { download } =
      useWhatsappImportUsersDownloadFailedData(csvUploadSessionId);
    const declinedErrorsStats = stats?.declinedErrorsStats || [];
    const errors = useMemo(
      () => sortByPosition(declinedErrorsStats),
      [declinedErrorsStats],
    );

    if (!stats?.declined) {
      return null;
    }

    return (
      <div className={css.box}>
        <Type as="div" weight="bold">
          {t('modernComponents.WhatsAppImportUser.dialog.Errors.title', {
            count: stats.declined,
          })}
        </Type>
        <Spacer factor={6} />
        {errors.map(({ fileImportContactError, errorsCount }, index) => (
          <Flex flexDirection="column">
            <Flex>
              <Type weight="bold">{numberFormatter(errorsCount)}</Type>
              <Spacer factor={0} horizontalFactor={1} />
              <Type>
                {t(
                  `modernComponents.WhatsAppImportUser.dialog.Errors.${fileImportContactError}`,
                  {
                    count: errorsCount,
                    fileLimit: formatThousands(limits?.perFileLimit || 0),
                    monthLimit: formatThousands(limits?.perMonthLimit || 0),
                  },
                )}
              </Type>
            </Flex>

            {index < errors.length - 1 && (
              <>
                <Spacer factor={4} />
                <div className={css.divider} />
                <Spacer factor={4} />
              </>
            )}
          </Flex>
        ))}
        {canDownloadDeclinedUsersFile && (
          <>
            <Spacer factor={6} />
            <ButtonUnstyled onClick={download}>
              <Flex alignItems="center">
                <Icon icon="download" color="accent1Normal" />
                <Spacer factor={0} horizontalFactor={1} />
                <Type color="accent1Normal">
                  {t(
                    'modernComponents.WhatsAppImportUser.dialog.Errors.download',
                  )}
                </Type>
              </Flex>
            </ButtonUnstyled>
          </>
        )}
      </div>
    );
  };
