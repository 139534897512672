import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useFileUpload from 'react-use-file-upload';
import { log } from 'cf-common/src/logger';
import { READ_CSV_FILE_LIMIT, WHATSAPP_PHONE_ATTRIBUTE_NAME } from '../consts';
import { getPhoneFieldIndex } from './helpers/getPhoneFieldIndex';
import { CSV_FILE_FIELD_NAME } from './consts';
import { directPost } from '@utils/Fetch/directPost';
import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';
import { CsvError, parseCsv } from './helpers/parseCsv';

type FileEvent =
  | React.DragEvent<HTMLDivElement>
  | React.ChangeEvent<HTMLInputElement>;

interface UserDataCsvFileContextPayload {
  file: File | null;
  handleDragDropEvent: (event: React.DragEvent<HTMLDivElement>) => void;
  setFiles: (event: FileEvent) => void;
  fields: string[] | null;
  parsingError: boolean | string;
  networkError: boolean;
  csvReady: boolean;
  setFieldMap: (fieldIndex: number, attributeName: string) => void;
  fieldsMap: Map<number, string>;
  isWhatsappPhoneMapped: boolean;
  isWhatsappPhoneAutoMapped: boolean;
  resetIsWhatsappPhoneAutoMapped: VoidFunction;
  uploadCsv: (sId: string) => void;
  delimiter: string | null;
}

const UserDataCsvFileContext =
  React.createContext<UserDataCsvFileContextPayload>({
    file: null,
    setFiles: () => undefined,
    handleDragDropEvent: () => undefined,
    fields: null,
    parsingError: false,
    networkError: false,
    csvReady: false,
    setFieldMap: () => undefined,
    fieldsMap: new Map(),
    isWhatsappPhoneMapped: false,
    isWhatsappPhoneAutoMapped: false,
    resetIsWhatsappPhoneAutoMapped: () => undefined,
    uploadCsv: () => undefined,
    delimiter: null,
  });

export const UserDataCsvFileContextProvider: React.FC = ({ children }) => {
  const botId = useCurrentBotId();
  const {
    files,
    handleDragDropEvent,
    setFiles: setFilesState,
  } = useFileUpload();
  const [fields, setFields] = useState<string[] | null>(null);
  const [delimiter, setDelimiter] = useState<string | null>(null);
  const [parsingError, setParsingError] = useState<string | boolean>(false);
  const [networkError, setNetworkError] = useState<boolean>(false);
  const [isWhatsappPhoneAutoMapped, setIsWhatsappPhoneAutoMapped] =
    useState<boolean>(false);
  const [fieldsMap, setFieldsMap] = useState<Map<number, string>>(
    new Map<number, string>(),
  );

  const file = files[0] || null;

  const setFieldMap = useCallback(
    (fieldIndex: number, attributeName: string) =>
      setFieldsMap(
        (fieldsMap) => new Map(fieldsMap.set(fieldIndex, attributeName)),
      ),
    [],
  );

  const setFiles = useCallback(
    (
      event:
        | React.DragEvent<HTMLDivElement>
        | React.ChangeEvent<HTMLInputElement>,
    ) => {
      setFilesState(event);
      sendEvent({
        category: 'people tab',
        label: 'whatsapp user import',
        action: 'file change',
      });
    },
    [setFilesState],
  );

  const resetIsWhatsappPhoneAutoMapped = useCallback(() => {
    setIsWhatsappPhoneAutoMapped(false);
  }, []);

  const isWhatsappPhoneMapped = useMemo(
    () => [...fieldsMap.values()].includes(WHATSAPP_PHONE_ATTRIBUTE_NAME),
    [fieldsMap],
  );

  useEffect(() => {
    setParsingError(false);
    setFields(null);
    if (!file) {
      return undefined;
    }
    if (!file.type?.startsWith('text')) {
      setParsingError(true);
      return undefined;
    }
    const reader = new FileReader();
    reader.onloadend = (event) => {
      if (
        event.target?.readyState === FileReader.DONE &&
        typeof event.target.result === 'string'
      ) {
        try {
          const { result } = event.target;
          if (!result.trim()) {
            throw Error('Empty csv');
          }
          const { fields, delimiter, lines } = parseCsv(result);
          setFields(fields);
          setDelimiter(delimiter);
          const phoneFieldIndex = getPhoneFieldIndex(lines);
          if (phoneFieldIndex !== -1) {
            setFieldMap(phoneFieldIndex, WHATSAPP_PHONE_ATTRIBUTE_NAME);
            setIsWhatsappPhoneAutoMapped(true);
          }
        } catch (error) {
          if (error instanceof CsvError) {
            setParsingError(error.message);
            return;
          }
          log.error({ error, msg: 'Failed to parse CSV' });
          setParsingError(true);
        }
      }
    };
    reader.onerror = () => {
      setParsingError(true);
    };
    const blob = file.slice(0, READ_CSV_FILE_LIMIT);
    reader.readAsText(blob);
    return () => {
      reader.abort();
    };
  }, [file, setFieldMap]);

  const csvReady = !!fields;

  const uploadCsv = useCallback(
    async (sId: string) => {
      setNetworkError(false);
      if (!csvReady) {
        return;
      }
      const formData = new FormData();
      formData.append(CSV_FILE_FIELD_NAME, file);
      try {
        await directPost(
          `/whatsapp/bots/${botId}/import_contacts/${sId}`,
          formData,
        );
      } catch {
        setNetworkError(true);
      }
    },
    [botId, csvReady, file],
  );

  return (
    <UserDataCsvFileContext.Provider
      value={{
        file,
        setFiles,
        handleDragDropEvent,
        parsingError,
        networkError,
        fields,
        csvReady,
        fieldsMap,
        setFieldMap,
        isWhatsappPhoneMapped,
        isWhatsappPhoneAutoMapped,
        resetIsWhatsappPhoneAutoMapped,
        uploadCsv,
        delimiter,
      }}
    >
      {children}
    </UserDataCsvFileContext.Provider>
  );
};

export const useUserDataCsvFile = () => useContext(UserDataCsvFileContext);
