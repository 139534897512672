import { goToGetInstagramPermissions } from '@utils/FacebookPages/goToGetPermissions';
import React, { useState } from 'react';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { useCurrentBotId } from '@utils/Routing';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { TutorialLinks } from '@ui/Links';
import { Spacer } from '@ui/Spacer';
import { CenteredLoader } from '@ui/Loader';
import {
  useBotInstagramAccount,
  InstagramAccountType,
} from '@utils/Data/Instagram/BotAccount';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ApolloError } from 'apollo-client';

export interface ConnectInstagramAccountDialogProps {
  onConnect?(): void;
  onConnected?(account: InstagramAccountType): Promise<void> | void;
  onConnectedFailed?(error: ApolloError): void;
  onTutorialClick?(): void;
  onDismiss(): void;
}

export const ConnectInstagramAccountDialog: React.FC<ConnectInstagramAccountDialogProps> =
  ({
    onConnect,
    onConnected,
    onConnectedFailed,
    onTutorialClick,
    onDismiss,
  }) => {
    const { t } = useSafeTranslation();
    const manualDocLink = t(
      'common.helpDocLinks.connecting_your_facebook_or_instagram_page',
    );
    const [connecting, setConnecting] = useState(false);
    const botId = useCurrentBotId() ?? '';
    const {
      connectInstagram,
      instagramConnectedAccount,
      hasInstagramPermissions,
      instagramConnected,
    } = useBotInstagramAccount(botId, {
      onConnected: () => {
        const instagramIsConnected =
          instagramConnected && hasInstagramPermissions;
        if (instagramIsConnected) {
          onConnected?.(instagramConnectedAccount);
        }
      },
      onConnectedFailed: (error) => {
        onConnectedFailed?.(error);
      },
    });

    const handleConnect = async () => {
      onConnect?.();
      setConnecting(true);
      if (!hasInstagramPermissions) {
        goToGetInstagramPermissions();
      } else {
        try {
          await connectInstagram();
        } finally {
          setConnecting(false);
        }
      }
    };

    return (
      <DefaultDialog
        dialogStyle={{ width: '640px' }}
        header={t('common.Instagram.dialogHeader')}
        footer={
          <Flex alignItems="center">
            <TutorialLinks
              variant="noaccent"
              linkItems={[
                {
                  text: t('common.Instagram.tutorialLinkTitle'),
                  href: manualDocLink,
                  onClick: () => {
                    onTutorialClick?.();
                  },
                },
              ]}
            />
            <Spacer horizontalFactor={4} factor={0} />
            <Button
              intent="primary"
              onClick={handleConnect}
              disabled={connecting}
            >
              {t('common.Instagram.connectAccountButton')}
            </Button>
          </Flex>
        }
        onDismiss={onDismiss}
      >
        <Flex alignItems="center">
          <Icon icon="instagram" size="20px" />
          <Spacer horizontalFactor={2} factor={0} />
          <Type weight="semibold" size="18px">
            {t('common.Instagram.accountTitle')}
          </Type>
        </Flex>
        <Spacer factor={1} />
        {connecting ? (
          <CenteredLoader style={{ height: 47 }} />
        ) : (
          <Type size="18px">{t('common.Instagram.permissionsNeededText')}</Type>
        )}
      </DefaultDialog>
    );
  };
