import { ImportUsersAttribute } from '../types';
import nanoid from 'nanoid';

export const createNewUserAttribute = () => (
  {
    id: nanoid(),
    name: '',
    value: '',
  } as ImportUsersAttribute
)
