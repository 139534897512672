import React from 'react';
import { UpgradeToProPopup } from './UpgradeToProPopup';
import { Modal } from '../../modern-ui/Modal';
import { ButtonUnstyled } from '../../modern-ui/Button';
import * as css from './clickVeil.css';

interface IClickVeilProps {
  popupAction?: () => void;
}

interface IClickVeilState {
  showDialog: boolean;
}

export class ClickVeil extends React.Component<
  IClickVeilProps,
  IClickVeilState
> {
  state = { showDialog: false };

  handleDismiss = () => this.setState({ showDialog: false });

  handleSubmit = () => {
    this.setState({ showDialog: false });

    if (this.props.popupAction) {
      this.props.popupAction();
    }
  };

  render() {
    const { showDialog } = this.state;
    return (
      <>
        <ButtonUnstyled
          className={css.veil}
          onClick={() => {
            this.setState({ showDialog: true });
          }}
        />
        {showDialog ? (
          <Modal onDismiss={this.handleDismiss}>
            <UpgradeToProPopup
              onSubmit={this.handleSubmit}
              onDismiss={this.handleDismiss}
            />
          </Modal>
        ) : null}
      </>
    );
  }
}
