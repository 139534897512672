import { UserAttributeType } from '@globals';

export { UserAttributeType };

export interface UserAttribute {
  id: string;
  name: string;
  values: string[];
  flowsUsingIt?: string[];
  blocksUsingIt?: string[];
  hidden?: boolean;
  justAdded?: boolean;
  duplicateName?: boolean;
  type: UserAttributeType | null;
}
