import ng from 'angular';
import { react2angular } from 'react2angular';
import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import { useBotFeatures } from '@utils/Data/Bot';
import { useBotWorkspaceId } from '@utils/Data/Bot/useBotWorkspaceId';
import client from '../../common/services/ApolloService';
import { globalHistory } from '../../utils/Routing';
import { ProPlanSection } from './ProPlanSection';
import { ProPlanSectionLoader } from './ProPlanSectionLoader';
import { ToasterProvider } from '../../modern-ui/Toaster';

interface IProPlanSectionWrapperProps {
  $rootScope: any;
}

const ProPlanSectionContent: React.FC<IProPlanSectionWrapperProps> = ({
  $rootScope,
}) => {
  const [loadingProp, setLoadingProp] = useState(false);
  const { botId } = $rootScope.stateParams;
  const { botFeatures, botFeaturesLoading } = useBotFeatures(botId);
  const [workspaceId, workspaceLoading] = useBotWorkspaceId(botId);

  $rootScope.$on(
    '$pageOperation',
    (event: { name: string }, data: 'start' | 'finish') => {
      if (event.name === '$pageOperation' && data === 'start') {
        setLoadingProp(true);
      }
      if (event.name === '$pageOperation' && data === 'finish') {
        setLoadingProp(false);
      }
    },
  );

  const dataIsLoading = !botId || botFeaturesLoading || workspaceLoading;
  const botHasDialogPricing = botFeatures?.dialogs_pricing_enabled;
  const botHasWorkspace = !!workspaceId;

  if (dataIsLoading || botHasDialogPricing || botHasWorkspace) {
    return null;
  }

  return (
    <Router history={globalHistory}>
      <ToasterProvider>
        {loadingProp ? (
          <ProPlanSectionLoader />
        ) : (
          <ProPlanSection botId={botId} />
        )}
      </ToasterProvider>
    </Router>
  );
};

const ProPlanSectionWrapper: React.FC<IProPlanSectionWrapperProps> = (
  props,
) => {
  return (
    <ApolloProvider client={client}>
      <ProPlanSectionContent {...props} />
    </ApolloProvider>
  );
};

export const ngProPlanSection = ng
  .module('app.modernСomponents.proPlanSection', [])
  .component(
    'proPlanSection',
    react2angular(ProPlanSectionWrapper, [], ['$rootScope']),
  ).name;
