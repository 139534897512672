import React, { useCallback, useMemo, useState } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { useCurrentBotId } from '@utils/Routing';
import { usePageConnected } from '@utils/FacebookPages/usePageConnected';
import { sendEvent } from '@utils/Analytics';
import {
  AdAccountsDropdown,
  AdAccountsDropdownProps,
} from '@components/FlowBuilder/views/entry-points/common/components/ChooseAdsPopup/components/AdAccountsDropdown';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  adCommentsAutoreplyEntryPointFragment_config_adAccount,
  adCommentsAutoreplyEntryPointFragment_config_posts,
} from '@components/Plugins/AdCommentsAutoreplyEntryPoint/@types/adCommentsAutoreplyEntryPointFragment';
import { ChooseAdPostsContent } from './ChooseAdPostsContent';
import { useMoveAdMutation } from './useAdPosts';
import { SomethingWentWrong } from '@components/FlowBuilder/views/entry-points/common/components/ChooseAdsPopup/components/SomethingWentWrong';
import { NoBoostedPostsView } from '@components/FlowBuilder/views/entry-points/common/components/ChooseAdsPopup/components/NoAdsView';
import { Platform } from '@globals';
import { searchByIdOrName } from '@components/FlowBuilder/views/entry-points/common/components/ChooseAdsPopup/components/SearchableTree';
import { AdPostsQuery_commentsAutoreplyAds_data } from './@types/AdPostsQuery';
import { useAdCommentsAutoreplyPostsFetch } from './useAdCommentsAutoreplyPostsFetch';
import { NetworkStatus } from 'apollo-client';
import { differenceWith } from 'ramda';
import { AdPost } from './ChooseAdPostsList';
import { PostSelectorDialog } from '../types';
import * as css from './ChooseAdPostsDialog.css';

export interface ChooseAdPostsDialogSubmitProps {
  adAccount: adCommentsAutoreplyEntryPointFragment_config_adAccount | null;
  posts: adCommentsAutoreplyEntryPointFragment_config_posts[];
}

export interface ChooseAdPostsDialogProps
  extends PostSelectorDialog<
    ChooseAdPostsDialogSubmitProps,
    adCommentsAutoreplyEntryPointFragment_config_posts
  > {
  // Костыль, почему то импортирование PluginType создает цикл зависимость все валится(
  pluginId: any;
  accountId: AdAccountsDropdownProps['accountId'];
  entryPointId: string;
}

const mapCommentsAutoreplyAds = (
  post: AdPostsQuery_commentsAutoreplyAds_data,
): AdPost => ({
  id: post.id,
  name: post.name,
  flow_id: post.flowId,
  page_id: post.pageId,
  entry_point_bot_id: post.entryPointId,
  flow_title: post.flowTitle,
  preview_image_big: post.thumbnailUrl,
  preview_image_small: post.thumbnailUrl,
  created_time: Number(post.createdAt),
});

const mapAdPost = (
  post: AdPostsQuery_commentsAutoreplyAds_data,
): adCommentsAutoreplyEntryPointFragment_config_posts => ({
  __typename: 'AdPosts' as const,
  ad: {
    ...post,
    __typename: 'CommentReplyAd' as const,
  },
  postIds: [],
});

export const ChooseAdPostsDialog: React.FC<ChooseAdPostsDialogProps> = ({
  selectedPosts,
  onDismiss,
  pluginId,
  accountId,
  onSubmit,
  onPostRemove,
  entryPointId,
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { pageId } = usePageConnected(botId);
  const [selectedAdAccountId, setSelectedAdAccountId] = useState<string>(
    accountId || '',
  );
  const [searchValue, setSearchValue] = useState('');
  const [checked, setChecked] = useState<Map<string, boolean>>(
    new Map(selectedPosts.map((checked) => [checked.ad.id, true])),
  );
  const { posts, postsLoading, loadMore, refetch, error, networkStatus } =
    useAdCommentsAutoreplyPostsFetch({ accountId: selectedAdAccountId });
  const [moveAd] = useMoveAdMutation();

  const onDropdownChange = useCallback(
    (item) => {
      sendEvent({
        category: 'flows',
        action: 'dropdown change',
        label: pluginId,
      });
      setSelectedAdAccountId(item.id);
    },
    [setSelectedAdAccountId, pluginId],
  );

  const onSubmitHandler = useCallback(
    (selectedItem) => {
      const checkedPosts = posts.filter((post) => checked.get(post.id));
      const mappedPosts: adCommentsAutoreplyEntryPointFragment_config_posts[] =
        checkedPosts.map(mapAdPost);
      const adAccount = selectedItem
        ? { ...selectedItem, tos_accepted: null }
        : null;

      // Может быть случай, когда была выбрана первая реклама и тысячная, из-за виртуального скролла
      // грузятся не все айтемы и mappedPosts может не содержать реклам, которые уже были выбраны
      // ранее. Поэтому надо добавлять рекламы которые могли еще не загрузиться
      const notLoadedButCheckedAds = differenceWith(
        (checked, mapped) => checked.ad.id === mapped.ad.id,
        selectedPosts.filter((post) => checked.get(post.ad.id)),
        mappedPosts,
      );

      onSubmit({
        adAccount,
        posts: mappedPosts.concat(notLoadedButCheckedAds),
      });
    },
    [checked, onSubmit, posts, selectedPosts],
  );

  const resolveAdConflict = useCallback(
    (adId) => {
      moveAd({ variables: { botId, adId } }).then(() => {
        const post = posts.find((post) => post.id === adId);

        if (post) {
          if (checked.has(post.id)) {
            checked.set(post.id, false);
          }

          onPostRemove?.(mapAdPost(post));
        }
      });
    },
    [moveAd, botId, posts, onPostRemove, checked],
  );

  const filteredPosts = useMemo(
    () =>
      posts
        .filter((post) => searchByIdOrName(post, searchValue))
        .map(mapCommentsAutoreplyAds),
    [posts, searchValue],
  );

  const checkedPostsLength = [...checked.values()].filter(Boolean).length;
  const isEditingPosts = Boolean(selectedPosts.length);

  return (
    <DefaultDialog
      header={
        isEditingPosts
          ? t(
              'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.headerEdit',
            )
          : t(
              'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.headerChoose',
            )
      }
      onDismiss={onDismiss}
      dialogStyle={{ width: 700, height: 600 }}
    >
      <AdAccountsDropdown
        pageId={pageId}
        pluginId={pluginId}
        accountId={accountId}
        onChange={onDropdownChange}
        tooltipContent={t(
          'modernComponents.FlowBuilder.modals.ChooseAdPostsDialog.tooltipError',
        )}
        isTooltipDisabled={!checkedPostsLength || Boolean(error)}
        selectedAdAccountId={selectedAdAccountId}
        setSelectedAdAccountId={setSelectedAdAccountId}
        isDropdownDisabled={Boolean(
          error ? isEditingPosts : checkedPostsLength,
        )}
      >
        {({ selectedItem }) => {
          if (error) {
            return (
              <SomethingWentWrong
                style={{ height: '428px' }}
                pluginId={pluginId}
                onRefresh={refetch}
              />
            );
          }

          if (!posts.length && networkStatus === NetworkStatus.ready) {
            return (
              <NoBoostedPostsView
                hideVideo
                onRefresh={refetch}
                pluginId={pluginId}
                platform={Platform.facebook}
                className={css.noBoostedPostsWrapper}
              />
            );
          }

          return (
            <ChooseAdPostsContent
              isEditingPosts={isEditingPosts}
              postsLoading={postsLoading}
              loadMore={loadMore}
              pageId={pageId!}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              pluginId={pluginId}
              checked={checked}
              setChecked={setChecked}
              posts={filteredPosts}
              onSubmit={() => onSubmitHandler(selectedItem)}
              resolveAdConflict={resolveAdConflict}
              currentEntryPointId={entryPointId}
            />
          );
        }}
      </AdAccountsDropdown>
    </DefaultDialog>
  );
};
