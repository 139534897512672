import { useCurrentBotId } from '@utils/Routing';
import { useMutation } from 'react-apollo';
import {
  StartWhatsappImportUsersSessionMutation,
  StartWhatsappImportUsersSessionMutationVariables,
} from './@types/StartWhatsappImportUsersSessionMutation';
import { START_WHATSAPP_IMPORT_USERS_SESSION_MUTATION } from './queries';
import { useCallback } from 'react';
import { useUserDataCsvFile } from './useUserDataCsvFile';
import { COMMA } from './helpers/common/consts';
import { UsersAttribute } from '@globals';
import { TAGS_HASH } from './consts';

interface UseStartWhatsappImportUsersSessionArgs {
  onError: () => void;
}

export const useStartWhatsappImportUsersSession = ({
  onError,
}: UseStartWhatsappImportUsersSessionArgs) => {
  const botId = useCurrentBotId();
  const { fieldsMap, delimiter } = useUserDataCsvFile();
  const [startWhatsappImportUsersSessionMutation, { data, ...mutationState }] =
    useMutation<
      StartWhatsappImportUsersSessionMutation,
      StartWhatsappImportUsersSessionMutationVariables
    >(START_WHATSAPP_IMPORT_USERS_SESSION_MUTATION);

  const startWhatsappImportUsersSession = useCallback(
    async (attributes: UsersAttribute[]) => {
      try {
        return (
          await startWhatsappImportUsersSessionMutation({
            variables: {
              botId: botId || '',
              payload: {
                tagsColumnIndex:
                  Array.from(fieldsMap.entries())
                    .map(([index, attributeName]) => ({
                      index,
                      attributeName: attributeName.trim(),
                    }))
                    .find(({ attributeName }) => attributeName === TAGS_HASH)
                    ?.index || null,
                attributesMappings: Array.from(fieldsMap.entries())
                  .map(([index, attributeName]) => ({
                    index,
                    attributeName: attributeName.trim(),
                  }))
                  .filter(({ attributeName }) => attributeName.length > 0)
                  .filter(({ attributeName }) => attributeName !== TAGS_HASH),
                csvDelimiter: delimiter || COMMA,
                commonAttributes: attributes,
                commonTagNames: [], // tags.map((t) => t.name),
              },
            },
          })
        ).data?.startWhatsappImportUsersSession.id;
      } catch {
        onError();
        return undefined;
      }
    },
    [
      botId,
      delimiter,
      fieldsMap,
      onError,
      startWhatsappImportUsersSessionMutation,
    ],
  );

  const csvUploadSessionId = data?.startWhatsappImportUsersSession.id;

  return {
    startWhatsappImportUsersSession,
    csvUploadSessionId,
    ...mutationState,
  };
};
