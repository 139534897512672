import memoizeOne from 'memoize-one';
import { clone } from 'ramda';
import { FacebookPagePostCursorInput, FacebookPagePostType } from '@globals';
import { ComposeTabId } from '../ChooseItemFromListDialog';
import { FacebookPagePostsQuery_page_morePosts_posts as FacebookPost } from './@types/FacebookPagePostsQuery';

export const mergeCursors = (
  newCursors: FacebookPagePostCursorInput[],
  oldCursors: FacebookPagePostCursorInput[],
) =>
  clone(newCursors).reduce((cursors, cursor) => {
    const updatedCursor = cursors.find(({ type }) => type === cursor.type);
    if (updatedCursor) {
      updatedCursor.after = cursor.after;
    } else {
      cursors.push(cursor);
    }
    return cursors;
  }, clone(oldCursors));

export const getPostTypesForQuery = (tabId: string) =>
  Object.values(ComposeTabId).includes(tabId as ComposeTabId)
    ? [
        FacebookPagePostType.published,
        FacebookPagePostType.scheduled,
        FacebookPagePostType.promoted,
      ]
    : [tabId];

export const getEmptyCursorByType = (type: string) => ({
  type: type as FacebookPagePostType,
});

export const validateFacebookPostUrl = (urlStr: string) => {
  try {
    const url = new URL(urlStr);
    return url.host.indexOf('facebook.com') !== -1;
  } catch {
    return false;
  }
};

export const filterPostsBySearchString = memoizeOne(
  (searchString: string, { message }: FacebookPost) => {
    const clearSearchString = searchString.trim().toLowerCase();
    if (clearSearchString) {
      return !!message?.toLowerCase().includes(clearSearchString);
    }
    return true;
  },
);
