import React, { useState } from 'react';
import { Mutation, Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { removeTypename } from '@utils/GQL/utils';
import * as css from './BillingDetails.css';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogHeading,
} from '../../../modern-ui/Dialog';
import {
  UpdateBillingDetailsMutationVariables,
  UpdateBillingDetailsMutation as UpdateBillingDetails,
} from './@types/UpdateBillingDetailsMutation';
import { BillingDetailsQuery as BillingDetails } from './@types/BillingDetailsQuery';
import { Loader } from '../../../modern-ui/Loader';
import { Flex } from '../../../modern-ui/Flex';
import {
  BillingDetailsForm,
  BillingDetailsFormProps,
  FormState,
} from '../../Payments/BillingDetails';

const UPDATE_BILLING_DETAILS_MUTATION = gql`
  mutation UpdateBillingDetailsMutation($details: PremiumAccountInput!) {
    updatePremiumBillingDetails(details: $details) {
      id
      billingDetails {
        company_name
        email
        tax_id
        street
        city
        state
        postcode
        country
      }
    }
  }
`;

const BILLING_DETAILS_QUERY = gql`
  query BillingDetailsQuery {
    me {
      id
      premiumAccount {
        id
        billingDetails {
          company_name
          email
          tax_id
          street
          city
          state
          postcode
          country
        }
      }
    }
  }
`;

export interface BillingDetailsDialogProps {
  onRequestClose: () => void;
}

// TODO в чем отличие от packages/dashboard/src/modern-components/ProPlanSection/components/BillingDetails/BillingDetailsDialog.tsx
export const BillingDetailsDialog: React.FC<BillingDetailsDialogProps> = (
  props,
) => {
  const [successChange, setSuccessChange] = useState(false);
  return (
    <Dialog className={css.billingDetailsDialog}>
      <DialogContent>
        <DialogHeading>
          {window.i18next.t(
            'BillingDetailsDialog-JSXText-1753-billing-details',
          )}
        </DialogHeading>
        <div className={css.dialogSubtitle}>
          {window.i18next.t(
            'BillingDetailsDialog-JSXText--129-these-billing-details-will-appear-on-invoices-you-receive',
          )}
          <br />
          {window.i18next.t('BillingDetailsDialog-JSXText-1890-from-chatfuel')}
        </div>
        <Query<BillingDetails>
          query={BILLING_DETAILS_QUERY}
          variables={{ botId: '' }}
        >
          {({
            data: billingDetailsData,
            loading: billingDetailsLoading,
            error: billingDetailsError,
          }) => {
            if (billingDetailsLoading) {
              return (
                <Flex justifyContent="center" style={{ width: '100%' }}>
                  <Loader />
                </Flex>
              );
            }
            return (
              <Mutation<
                UpdateBillingDetails,
                UpdateBillingDetailsMutationVariables
              >
                mutation={UPDATE_BILLING_DETAILS_MUTATION}
              >
                {(
                  UpdateBillingDetailsMutation,
                  { loading: updateLoading, error: updateError },
                ) => {
                  if (
                    billingDetailsError ||
                    !billingDetailsData ||
                    !billingDetailsData.me ||
                    !billingDetailsData.me.premiumAccount
                  ) {
                    return (
                      <div>
                        {window.i18next.t(
                          'BillingDetailsDialog-JSXText--199-error-please-try-later',
                        )}
                      </div>
                    );
                  }
                  const billingDetails = removeTypename(
                    billingDetailsData.me.premiumAccount.billingDetails || {
                      company_name: '',
                      email: '',
                      tax_id: '',
                      street: '',
                      city: '',
                      state: '',
                      postcode: '',
                      country: '',
                    },
                  );

                  const onSubmit: BillingDetailsFormProps['onSubmit'] = (
                    values,
                  ) => {
                    setSuccessChange(false);
                    if (!billingDetailsData.me.premiumAccount) {
                      return;
                    }
                    UpdateBillingDetailsMutation({
                      variables: {
                        details: {
                          id: billingDetailsData.me.premiumAccount.id,
                          billingDetails: values,
                        },
                      },
                      update: () => {
                        setSuccessChange(true);
                      },
                    });
                  };

                  return (
                    <BillingDetailsForm
                      initialValues={billingDetails}
                      onSubmit={onSubmit}
                      state={
                        successChange
                          ? FormState.success
                          : updateError
                          ? FormState.error
                          : updateLoading
                          ? FormState.loading
                          : FormState.pending
                      }
                    />
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </DialogContent>
      <DialogCloseButton onClick={props.onRequestClose} />
    </Dialog>
  );
};
