import gql from 'graphql-tag';

export const BOT_BILLING_DETAILS_MUTATION = gql`
  mutation UpdateBotBillingDetailsMutation(
    $botId: String!
    $details: BillingDetailsInput!
  ) {
    updateBillingDetails(botId: $botId, details: $details) {
      id
      city
      company_name
      country
      email
      postcode
      street
      state
      tax_id
    }
  }
`;

export const BOT_BILLING_DETAILS = gql`
  query BotBillingDetailsQuery($botId: String!) {
    bot(id: $botId) {
      id
      billingDetails {
        id
        city
        company_name
        country
        email
        postcode
        street
        state
        tax_id
      }
    }
  }
`;
