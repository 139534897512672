import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';
import Maybe from 'graphql/tsutils/Maybe';
import { useCallback, useRef } from 'react';
import { useQuery } from 'react-apollo';
import { AdPostsQuery, AdPostsQueryVariables } from './@types/AdPostsQuery';
import { AD_POSTS_QUERY } from './useAdPosts';

const POST_LOADING_ATTEMPT_LIMIT = 100;
const POST_LOADING_TOTAL_LIMIT = 1000;

interface UseAdCommentsAutoreplyPostsFetchParams {
  accountId: Maybe<string>;
}

// Копипаста хука useInstagramPostFetch который копипаста (почти) хука useFacebookPostsFetch
export const useAdCommentsAutoreplyPostsFetch = ({
  accountId,
}: UseAdCommentsAutoreplyPostsFetchParams) => {
  const botId = useCurrentBotId()!;
  const loadedLastPostRef = useRef<boolean>(false);

  const { data, loading, fetchMore, refetch, networkStatus, error, called } =
    useQuery<AdPostsQuery, AdPostsQueryVariables>(AD_POSTS_QUERY, {
      variables: {
        botId,
        accountId: accountId!,
        limit: POST_LOADING_ATTEMPT_LIMIT,
      },
      fetchPolicy: 'network-only',
      skip: !accountId,
      notifyOnNetworkStatusChange: true,
    });

  const { data: posts, after } = data?.commentsAutoreplyAds || {};

  const loadMore = useCallback(() => {
    if (
      loading ||
      (posts || []).length > POST_LOADING_TOTAL_LIMIT ||
      loadedLastPostRef.current ||
      after === null
    ) {
      return Promise.resolve();
    }

    sendEvent({
      category: 'flows',
      action: 'Load more ad comments autoreply posts',
    });

    return fetchMore<keyof AdPostsQueryVariables>({
      query: AD_POSTS_QUERY,
      variables: {
        botId,
        accountId: accountId!,
        after,
        limit: POST_LOADING_ATTEMPT_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.commentsAutoreplyAds?.data) {
          return prev;
        }

        loadedLastPostRef.current =
          fetchMoreResult.commentsAutoreplyAds.after === null;

        // eslint-disable-next-line no-param-reassign
        fetchMoreResult.commentsAutoreplyAds.data = [
          ...(prev.commentsAutoreplyAds?.data || []),
          ...(fetchMoreResult.commentsAutoreplyAds?.data || []),
        ];

        return fetchMoreResult;
      },
    });
  }, [botId, accountId, loading, posts, after, fetchMore]);

  return {
    posts: posts ?? [],
    postsLoading: loading,
    loading,
    refetch,
    networkStatus,
    loadMore,
    error,
    called,
  };
};
