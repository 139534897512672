import React from 'react';
import { Modal } from '@services/index';
import { TransferPlanDialog } from './TransferPlanDialog';

export const showTransferPlanDialog = () => {
  Modal.show(({ close }) => (
    <TransferPlanDialog
      onDismiss={() => {
        close();
      }}
    />
  ));
};
