import React, { useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import {
  useInstagramAccount,
  useInstagramPermissions,
} from '@utils/Data/Instagram/BotAccount';
import { useCurrentBotId } from '@utils/Routing';

import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { TutorialLinks } from '@ui/Links';
import { CenteredLoader } from '@ui/Loader';
import { Spacer } from '@ui/Spacer';

import { ConnectInstagramStep, ConnectInstagramVariant } from '../../types';
import { useConnectInstagramQueryParams } from '../../helpers';

import { ConnectInstagramAccount } from '../ConnectInstagramAccount';
import { ConnectInstagramError } from '../ConnectInstagramError';
import { ConnectInstagramFacebookPageError } from '../ConnectInstagramFacebookPageError';
import { ConnectInstagramGivePermissions } from '../ConnectInstagramGivePermissions';
import {
  ConnectInstagramPermissions,
  ConnectInstagramPermissionsType,
} from '../ConnectInstagramPermissions';
import { ConnectInstagramSuccess } from '../ConnectInstagramSuccess';
import { ConnectInstagramTroubleshooting } from '../ConnectInstagramTroubleshooting';
import { ConnectInstagramRequirements } from '../ConnectInstagramRequirements';

import { getInitialStep, getNextStepByCurrentAndFlow } from './helpers';

import * as css from './ConnectInstagramDialog.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

export interface ConnectInstagramDialogProps {
  initialStep?: ConnectInstagramStep | null;
  connectVariant: ConnectInstagramVariant;
  onClose(): void;
  onConnected?: () => void;
}

export const ConnectInstagramDialog: React.FC<ConnectInstagramDialogProps> = ({
  initialStep,
  connectVariant,
  onClose,
  onConnected,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const { setStepQueryParam } = useConnectInstagramQueryParams();

  const { hasInstagramMessagingPermissions, instagramPermissionsLoading } =
    useInstagramPermissions();

  const botId = useCurrentBotId()!;
  const { instagramConnectionProblem, instagramDataIsLoading } =
    useInstagramAccount(botId)!;

  const [step, setStep] = useState(initialStep);
  useEffect(() => {
    if (!instagramPermissionsLoading && !instagramDataIsLoading && !step) {
      setStep(
        getInitialStep(
          connectVariant,
          !!hasInstagramMessagingPermissions,
          instagramConnectionProblem,
        ),
      );
    }
  }, [
    connectVariant,
    hasInstagramMessagingPermissions,
    instagramConnectionProblem,
    instagramDataIsLoading,
    instagramPermissionsLoading,
    step,
  ]);

  const handleNextStep = (error?: any) => {
    if (step) {
      const nextStep = getNextStepByCurrentAndFlow(
        step,
        connectVariant,
        error ?? instagramConnectionProblem,
      );
      setStep(nextStep);
    }
  };
  useEffect(() => {
    if (step === ConnectInstagramStep.done) {
      onConnected?.();
      onClose();
    }
  }, [onClose, onConnected, step]);

  useEffect(() => {
    if (step === ConnectInstagramStep.givingPermissions) {
      onClose();
    }
  }, [onClose, step]);

  const handleBeforeGivePermission = () => {
    const nextStep = getNextStepByCurrentAndFlow(
      ConnectInstagramStep.givePermissions,
      connectVariant,
    );
    setStepQueryParam(nextStep);
  };

  return (
    <DefaultDialog
      header={() => (
        <Flex alignItems="center">
          <Icon icon="instagramFilled" size="32px" />
          <Spacer horizontalFactor={5} />
          <TutorialLinks
            variant="default"
            linkItems={[
              {
                text: t('common.Instagram.dialog.tutorialLinkTitle'),
                href: t(
                  'common.helpDocLinks.problems_with_instagram_account_connection',
                ),
                maxWidth: 200,
              },
            ]}
          />
        </Flex>
      )}
      className={css.dialog}
      contentClassName={css.dialogContent}
      onDismiss={onClose}
      mobileAdaptive={isSmallScreenSize}
    >
      {!step && <CenteredLoader />}
      {step === ConnectInstagramStep.givePermissions && (
        <ConnectInstagramGivePermissions
          onBeforeGivePermission={handleBeforeGivePermission}
          onAfterGivePermission={() =>
            setStep(ConnectInstagramStep.givingPermissions)
          }
        />
      )}
      {step === ConnectInstagramStep.account && (
        <ConnectInstagramAccount
          autoConnect={connectVariant === ConnectInstagramVariant.known}
          onConnect={handleNextStep}
          onConnectAnotherAccount={() => {
            setStep(ConnectInstagramStep.facebookPageError);
          }}
        />
      )}
      {step === ConnectInstagramStep.permissions && (
        <ConnectInstagramPermissions
          type={
            connectVariant === ConnectInstagramVariant.known
              ? ConnectInstagramPermissionsType.get
              : ConnectInstagramPermissionsType.update
          }
          onConnected={() => {
            setStep(ConnectInstagramStep.success);
          }}
        />
      )}
      {step === ConnectInstagramStep.success && (
        <ConnectInstagramSuccess
          onDone={() => {
            setStep(ConnectInstagramStep.done);
          }}
        />
      )}
      {step === ConnectInstagramStep.error && (
        <ConnectInstagramError
          onGoBack={() => {
            setStep(ConnectInstagramStep.account);
          }}
        />
      )}
      {step === ConnectInstagramStep.facebookPageError && (
        <ConnectInstagramFacebookPageError
          onGoBack={() => {
            setStep(ConnectInstagramStep.account);
          }}
        />
      )}
      {step === ConnectInstagramStep.troubleshooting && (
        <ConnectInstagramTroubleshooting
          onUpdated={handleNextStep}
          onClose={onClose}
          onBeforeGivePermission={handleBeforeGivePermission}
        />
      )}
      {step === ConnectInstagramStep.requirements && (
        <ConnectInstagramRequirements onNext={handleNextStep} />
      )}
    </DefaultDialog>
  );
};
