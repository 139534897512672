import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { useResetMetaForMobile } from '@utils/css/useResetMetaForMobile';
import { ReferralDescription } from './description/ReferralDescription';
import { ReferralContactForm } from './form/ReferralContactForm';
import { log } from 'cf-common/src/logger';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { useCurrentBotId } from '@utils/Routing';
import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { useBotProStatus } from '@utils/Data/Bot';
import { useBotWorkspaceId } from '@utils/Data/Bot/useBotWorkspaceId';
import { ReferralFormValue, sendReferralDataToSheet } from '../api';
import { FinishedForm } from './FinishedForm';
import * as css from './ReferralProgramForm.css';

export const ReferralProgramForm = () => {
  useResetMetaForMobile();
  const isWhiteLabel = isWhiteLabelDomain();
  const botId = useCurrentBotId();
  const { replace } = useHistory();
  const { botIsPro, botIsManualPro } = useBotProStatus();
  const [botWorkspaceId] = useBotWorkspaceId(botId);

  const [isParticipating, setIsParticipating, { loading }] = useServerStorage(
    ServerStorageItemKeys.referralProgramPocFormFilled,
  );
  const [isFormFinished, setFormFinished] = useState(false);

  useEffect(() => {
    if (isWhiteLabel || botWorkspaceId || !(botIsPro || botIsManualPro)) {
      replace(`/bot/${botId}/home`);
    }
  }, [isWhiteLabel, botWorkspaceId, botIsPro, replace, botId, botIsManualPro]);

  const handleFormSubmit = async (form: ReferralFormValue) => {
    try {
      await sendReferralDataToSheet(form);
      setFormFinished(true);
      setIsParticipating(true);
    } catch (error) {
      log.error({
        error,
      });
    }
  };

  return (
    <div className={css.container}>
      <div className={css.content}>
        <div
          className={cn(css.leftSide, {
            [css.fill]: isParticipating && !isFormFinished,
          })}
        >
          <ReferralDescription
            isParticipating={isParticipating && !isFormFinished}
          />
        </div>

        {(!isParticipating || isFormFinished) && !loading && (
          <div className={css.rightSide}>
            {isFormFinished ? (
              <FinishedForm onClose={() => setFormFinished(false)} />
            ) : (
              <ReferralContactForm onSubmit={handleFormSubmit} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
