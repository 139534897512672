import { UsersAttribute } from '@globals';

export interface ImportUsersAttribute extends UsersAttribute {
  id: string;
}

export enum AttributeErrorType {
  duplicate ='duplicate',
  system = 'system',
  emptyName = 'emptyName',
  emptyValue = 'emptyValue',
  useInMapping = 'useInMapping',
}

export type AttributesErrors = Record<string, AttributeErrorType>;
