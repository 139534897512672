import memoize from 'lodash-es/memoize';
import i18next from 'i18next';
import { HEXColors } from '@ui/_common/colors';
import { VariablesType } from '@globals';
import { AttributesQuery_bot_variableSuggest } from '@utils/AttributesUtils/@types/AttributesQuery';

export const DIALOG_STYLE = {
  width: 600,
  backgroundColor: HEXColors.white,
};

export enum WhatsappImportUserDialogSteps {
  selectFile = 'selectFile',
  mapFields = 'mapFields',
  review = 'review',
  process = 'process',
  success = 'success',
  error = 'error',
}

export const getStepTitles = memoize(() => ({
  [WhatsappImportUserDialogSteps.selectFile]: i18next.t(
    'modernComponents.WhatsAppImportUser.dialog.steps.selectFile',
  ),
  [WhatsappImportUserDialogSteps.mapFields]: i18next.t(
    'modernComponents.WhatsAppImportUser.dialog.steps.mapFields',
  ),
  [WhatsappImportUserDialogSteps.review]: i18next.t(
    'modernComponents.WhatsAppImportUser.dialog.steps.review',
  ),
  [WhatsappImportUserDialogSteps.process]: i18next.t(
    'modernComponents.WhatsAppImportUser.dialog.steps.process',
  ),
  [WhatsappImportUserDialogSteps.success]: i18next.t(
    'modernComponents.WhatsAppImportUser.dialog.steps.success',
  ),
  [WhatsappImportUserDialogSteps.error]: i18next.t(
    'modernComponents.WhatsAppImportUser.dialog.steps.error',
  ),
}));

export const WHATSAPP_PHONE_ATTRIBUTE_NAME = 'whatsapp phone';

export const READ_CSV_FILE_LIMIT = 10000;

export const WHATSAPP_PHONE_ATTRIBUTE: AttributesQuery_bot_variableSuggest = {
  __typename: 'BotUserVariable',
  id: `${WHATSAPP_PHONE_ATTRIBUTE_NAME}_${VariablesType.system}`,
  name: WHATSAPP_PHONE_ATTRIBUTE_NAME,
  type: VariablesType.system,
  count: null,
};
